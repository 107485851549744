import React from 'react';
import { Label, VerticalCenter, TextInput } from '@gower/react-ui/components';
import ReactDataSheet from '@gower/react-ui/datasheet';
import type { AuthorizationStore } from '../../store';
import type { AuthorizationRequestItem } from '@gower/cm-mdp-sdk/src/lib/AuthorizationRequests/services';

export type CellProps = {
    item: AuthorizationRequestItem,
    context: AuthorizationStore,
};
export const Editor: React.FC<CellProps & ReactDataSheet.DataEditorProps<CellProps>> = ( { item, context, row, ...props } ) => {
    const isReadonly = !item.isValid || ( row > 1 && !context.Request.Items[row - 2]?.isValid );

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [inputValue, setInputValue] = React.useState( 0 );

    async function handleCommit ( newValue ) {
        if ( !Number( newValue ).isNan() && Number( newValue ) > 0 )
            await context.addService( item.Service?.Id, newValue );
        props.onRevert();
    }

    return (
        <TextInput
            readOnly={isReadonly}
            selectAllOnFocus
            large
            numeric
            borderless
            autoFocus
            value={inputValue}
            onChangeText={( t ) => setInputValue( t as any )}
            clearable={false}
            onFocus={() => isReadonly && props.onRevert()}
            onEscapeUp={() => props.onRevert()}
            onEnterUp={( event ) => handleCommit( event.target['value'] )}
        />
    );
};

export const Viewer: React.FC<CellProps & ReactDataSheet.ValueViewerProps<any, CellProps>> = ( { item, context, ...props } ) => {
    if ( !item.isValid ) return null;

    return (
        <VerticalCenter justifyCenter>
            <Label p>{item.Quantity?.toDecimal( 0, 2 )}</Label>
        </VerticalCenter>
    );

};

export const Header: React.FC<CellProps> = ( { context, ...props } ) => {
    return (
        <VerticalCenter textBlack justifyCenter xsmall noSelect textVerticalCenter gutter>
            Cantidad
        </VerticalCenter>
    );
};