import { Col, Content, Row } from '@gower/react-ui/components';

export const ContingencyView = () => {

    return <>
        <Content
            position="absolute"
            top="0"
            right="0"
            color="blue-0"
            height="30%"
            minHeight={200}
        />
        <Content justifyCenter start scroll paddingY={20} color="surface-0">
            <Col 
            sm={11} 
            cols={8} 
            maxWidth={600}
            >
                <Row columns white boxShadow="box-shadow-1" padding={20}>
                    <img src="/assets/contingencia.jpg" alt="Contingencia" />
                </Row>
            </Col>

        </Content>
    </>;

};
