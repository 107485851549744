import React from 'react';
import { Col, Label, Row } from '@gower/react-ui/components';
import { ProgressSlider } from '@gower/react-ui/template/progress-slider';
import { useStore } from '../../store';
import { Authorization } from '@gower/cm-mdp-sdk/authorizations';

export const Progress: React.FC = () => {

    const store = useStore();

    return <>
        <Row columns paddingY={10}>
            <Col padding={15}>
                <Label xxsmall textGrey label="Progreso" />
            </Col>
            <Col padding={20} show={
                store.Authorization.Status.isPending ||
                store.Authorization.Status.isIssued ||
                store.Authorization.Status.isSubmitted
            }>
                <ProgressSlider
                    items={[
                        { label: 'Pendiente' },
                        { label: 'Emitida' },
                        { label: 'Presentada' },
                    ]}
                    selected={{
                        [Authorization.VO.Status.Pending.Id]: 0,
                        [Authorization.VO.Status.Issued.Id]: 1,
                        [Authorization.VO.Status.Submitted.Id]: 2,
                    }[store.Authorization.Status.Id]}
                />
            </Col>
            <Col padding={20} show={
                store.Authorization.Status.isReject ||
                store.Authorization.Status.isCanceled ||
                store.Authorization.Status.isErrored
            }>
                <ProgressSlider
                    color="red"
                    items={[
                        { label: 'Pendiente' },
                        {
                            label: {
                                [Authorization.VO.Status.Reject.Id]: 'Rechazada',
                                [Authorization.VO.Status.Canceled.Id]: 'Emitida',
                                [Authorization.VO.Status.Errored.Id]: 'Rechazada',
                            }[store.Authorization.Status.Id]
                        },
                        {
                            label: {
                                [Authorization.VO.Status.Reject.Id]: 'Emitida',
                                [Authorization.VO.Status.Canceled.Id]: 'Cancelada',
                                [Authorization.VO.Status.Errored.Id]: 'Emitida',
                            }[store.Authorization.Status.Id]
                        },
                    ]}
                    selected={{
                        [Authorization.VO.Status.Reject.Id]: 1,
                        [Authorization.VO.Status.Canceled.Id]: 2,
                        [Authorization.VO.Status.Errored.Id]: 1,
                    }[store.Authorization.Status.Id]}
                />
            </Col>
        </Row>

    </>;

};