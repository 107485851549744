import type { InstanceOfModel } from 'temp/SidedCrud/store/common';
import type { TemplatesProps, ContextTemplatesProps } from './list.props';

type Props = TemplatesProps & ContextTemplatesProps;

export function getActions ( { store, crudStore, ...props }: Props )
{
    return {
        search: ( text: string ) =>
        {
            if ( props.onSearch )
                props.onSearch( { searchText: text,  ...store.getProps()} );
            else
                store.search( text );
        },

        reload: () =>
        {
            if ( props.onReload )
                props.onReload( store.getProps() );
            else
                store.reload();
        },
        create: () =>
        {
            if ( props.onCreate )
                props.onCreate( store.getProps() );
            else
                crudStore.goCreate();
        },
        open: ( item: InstanceOfModel ) =>
        {
            if ( props.onItemClick )
                props.onItemClick( { item, ...store.getProps() } );
            else
                crudStore.goView( item );
        },
        back: () =>
        {
            if ( props.onBack )
                props.onBack( store.getProps() );
            else
                crudStore.goList();
        },
    };
} 