import React from 'react';
import CredentialProvider from 'app/credential-provider';
import { User } from '@gower/shared-sdk/core/users';

export function useSession () {
    const user = React.useRef( new User( CredentialProvider.getUser() as any ) );
    const accountId = React.useRef( CredentialProvider.getAccountId() );

    return {
        accountId: accountId.current,
        user: user.current,
        checkIsAdmin: () => CredentialProvider.isAdministrator(),
    };

}