import React from 'react';
import { Button, Col, Content, Div, TextInput, Label, Row, Spacer, Alert } from '@gower/react-ui/components';
import { provider, useStore } from './store';
import { AiOutlineLock, AiOutlineUser } from '@gower/react-ui/icons';
import { RoutesEnum } from 'app/routes';
import { useSession } from 'features/hooks/useSession';

export const ChangePasswordView = provider( () => {

    const { user } = useSession();
    const store = useStore();
    const { status } = store;
    const [password1, setPassword1] = React.useState( '' );
    const [password2, setPassword2] = React.useState( '' );

    return <>
        <Content
            position="absolute"
            top="0"
            right="0"
            color="blue-0"
            height="30%"
            minHeight={200}
        />
        <Content justifyCenter start scroll paddingY={100}>
            <Col start maxWidth={400}>
                <Row
                    white
                    boxShadow="box-shadow-1"
                    padding={20}
                >
                    <Col cols={12}>
                        <Row>
                            <Col center>
                                <Label>Cambiar contraseña</Label>
                            </Col>
                            <Spacer />
                            <Col center>
                                <Button
                                    text
                                    label="Volver a mi perfil"
                                    reactLink={RoutesEnum.Profile}
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col cols={12} paddingTop={20}>
                        <TextInput
                            borderless
                            autoComplete="username"
                            autoCapitalize="false"
                            large
                            readOnly
                            contentLeft={<AiOutlineUser size={18} />}
                            value={user?.Nickname}
                        />
                    </Col>
                    <Col cols={12} paddingY={10}>
                        <TextInput
                            autoFocus
                            autoComplete="new-password"
                            autoCapitalize="false"
                            large
                            password
                            placeholder="Ingresá tu nueva contraseña"
                            readOnly={status.isLoading}
                            contentLeft={<AiOutlineLock size={18} />}
                            value={password1}
                            onChange={( e ) => setPassword1( e.target.value )}
                        />
                    </Col>
                    <Col cols={12} paddingY={10}>
                        <TextInput
                            autoComplete="new-password"
                            autoCapitalize="false"
                            large
                            password
                            placeholder="Repite tu nueva contraseña"
                            readOnly={status.isLoading}
                            contentLeft={<AiOutlineLock size={18} />}
                            value={password2}
                            onChange={( e ) => setPassword2( e.target.value )}
                        />
                    </Col>
                    <Col cols={12} paddingY={20}>
                        <Alert
                            hidden={store.LastErrors?.length > 0}
                            fullWidth
                            content="Recuerda elegir una contraseña segura."
                        />
                        <Div fullWidth block minHeight={40} maxHeight={100} pt-2 textCenter scrollOnlyVertical show={store.LastErrors?.length > 0}>
                            <Alert
                                error
                                fullWidth
                                content={<>
                                    {store.LastErrors.map( ( error, key ) => <Label p key={key} normal>{error}</Label> )}
                                </>}
                            />
                        </Div>
                    </Col>
                </Row>
                <Row justifyEnd paddingY={10}>
                    <Col center paddingRight={5}>
                        <Button
                            minWidth={150}
                            normal
                            label="Cancelar"
                            disabled={status.isLoading}
                            reactLink={RoutesEnum.Home}
                        />
                    </Col>
                    <Col center>
                        <Button
                            minWidth={150}
                            primary
                            normal
                            label="Actualizar"
                            loading={status.isLoading}
                            disabled={status.isErrored}
                            onClick={() => store.createPassword( password1, password2 )}
                        />
                    </Col>
                </Row>
            </Col>
        </Content>
    </>;
} );