import React from 'react';
import { Col, Content, Div, Label, Row, Spacer, Button, Colors, Chip, Panel } from '@gower/react-ui/components';
import { useStore } from '../store';
import { MdAddCircleOutline, MdFileDownload, TbChevronDown } from '@gower/react-ui/icons';
import { DataGrid, DataGridColumn, DataGridTemplateProps } from '@gower/react-ui/template/data-grid';
import { User } from '@gower/shared-sdk/core/users';
import { RoutesEnum } from 'app/routes';

export const UsersDesktop = () => {

    const store = useStore();

    return <>

        <Content columns>
            <Col>
                <Row padding={15}>
                    <Col gutter center>
                        <Div padding={10} >
                            <Label xxsemi p bold marginBottom={5}>Usuarios</Label>
                            <Label xsmall p textGrey>Operadores del sistema.</Label>
                        </Div>
                    </Col>
                    <Spacer />
                    <Col gutter center>
                        <Button
                            actionTheme
                            icon={<MdFileDownload size={16} color={Colors['surface-6']} />}
                            label="Exportar"
                            disabled={!store.Status.isOk}
                            loading={store.DownloadStatus.isLoading}
                            onClick={() => store.downloadList()}
                        />
                    </Col>
                    <Col gutter center>
                        <CreateMenu />
                    </Col>
                </Row>
            </Col>
            <Spacer vertical noScroll>
                <DataGrid
                    loader
                    store={store}
                    autoSorting
                    showFilters={["status", "group"]}
                    showReload
                    scroll
                    showPagination
                    showSearch
                    mobileTemplate={( { data } ) => (
                        <Row white center borderRadius={5} minHeight={45} padding={10} activeEffect>
                            <Col cols={6}>
                                <Label label={data.Id} />
                            </Col>
                            <Col cols={6}>
                                <Label label={data.CompleteName} />
                            </Col>
                        </Row>
                    )}
                >

                    <DataGridColumn field="id" title="Usuario"
                        template={( { data }: DataGridTemplateProps<User> ) => (
                            <Div block>
                                <Label p label={data.Nickname} />
                            </Div>
                        )}
                    />

                    <DataGridColumn field="id" title="Nombre"
                        template={( { data }: DataGridTemplateProps<User> ) => (
                            <Div block>
                                <Label p label={data.CompleteName} />
                            </Div>
                        )}
                    />

                    <DataGridColumn field="email" title="Email"
                        template={( { data }: DataGridTemplateProps<User> ) => (
                            <Div block>
                                <Label p label={data.Email} />
                            </Div>
                        )}
                    />

                    <DataGridColumn field="licenses" title="Matrículas"
                        template={( { data }: DataGridTemplateProps<User> ) => (
                            <Div block>
                                <Label p label={data.Metadata.licenses?.map( ( item, idx ) => (
                                    <Chip padding={3} xsmall label={item} margin={4} />
                                ) )} />
                            </Div>
                        )}
                    />

                    <DataGridColumn field="status" title="Estado" size={80}
                        template={( { data }: DataGridTemplateProps<User> ) => (
                            <Row justifyCenter >
                                <Chip
                                    xsmall
                                    textCenter
                                    red={data.Status.isBloqued}
                                    green={data.Status.isActive}
                                >
                                    {data.Status.Description}
                                </Chip>
                            </Row>
                        )}
                    />

                    <DataGridColumn field="group" title="Grupo"
                        size={80}
                        template={( { data }: DataGridTemplateProps<User> ) => (
                            <Div block textCenter>
                                <Label p label={data.Group.Description} />
                            </Div>
                        )}
                    />

                    <DataGridColumn
                        field="actions"
                        title=""
                        size={130}
                        template={( { data }: DataGridTemplateProps<User> ) => (
                            <Row end noWrap justifyEnd>
                                <Col>
                                    <Button
                                        block
                                        bold
                                        xxsmall
                                        label="Editar"
                                        reactLink={`${ RoutesEnum.UsersEdit }/${ data.Id }`}
                                    />
                                </Col>
                            </Row>
                        )}
                    />
                </DataGrid>
            </Spacer>
        </Content>
    </>;
};

const CreateMenu: React.FC = ( { } ) => {

    const menuEl = React.useRef( null );

    function closeMenu () {
        menuEl.current?.hide();
        return true;
    }

    return <>

        <Button
            primary
            icon={<MdAddCircleOutline size={16} />}
            label="Crear nuevo"
            onClick={( event ) => menuEl.current.toggle( event )}
            contentRight={<TbChevronDown size={16} />}
        />
        <Panel dismissable ref={menuEl}>
            <Row width={200} noWrap columns onClick={closeMenu}>
                <Button xxsmall noneTheme justify="flex-start" width={200} flat borderRadius={0} label="Crear nuevo usuario" reactLink={RoutesEnum.UsersCreate} />
                <Button xxsmall noneTheme justify="flex-start" width={200} flat borderRadius={0} label="Importar desde archivo" reactLink={RoutesEnum.UsersImport} />
            </Row>
        </Panel>
    </>;
};