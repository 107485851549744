import React from "react";
import Logo, { AppLogoProps } from './AppLogo';
import { Col, Div, Row, RowProps } from '@gower/react-ui/components';

export type AppNameProps = RowProps & {
  dark?: boolean;
  size?: number;
  logo?: boolean;
  vertical?: boolean;
  logoProps?: AppLogoProps;
};

export const AppName: React.FunctionComponent<AppNameProps> = ( { size, color, ...props } ) =>
{
  const RenderName = (
    <AppLogo
      width={size}
      fillColor={color}
    />
  );

  if ( !props.logo )
    return <Div {...props}>
      {RenderName}
    </Div>;
  else
    return (
      <Row {...{ ...props, size: undefined }}>
        <Col auto gutter center>
          <Logo dark={props.dark} {...props.logoProps} />
        </Col>
        {props.vertical && <Col cols={12} />}
        <Col auto gutter textLeft clip>
          {RenderName}
        </Col>
      </Row>
    );

};



function AppLogo ( { width, fillColor, ...props } )
{
  fillColor = `var(--${ fillColor })`;
  return (
    <svg
      width={width}
      viewBox="0 0 564.484 32.932"
      {...props}
    >
      <path fill={fillColor} d="M23.411,30.944c-1.015,0.356-2.112,0.631-3.291,0.822c-1.179,0.191-2.372,0.288-3.579,0.288
        c-2.55,0-4.662-0.397-6.334-1.192c-1.673-0.795-3.002-1.823-3.99-3.085c-0.987-1.261-1.68-2.68-2.077-4.257
        c-0.398-1.576-0.596-3.146-0.596-4.709c0-2.248,0.301-4.298,0.905-6.149c0.603-1.851,1.501-3.441,2.694-4.771
        c1.193-1.33,2.66-2.358,4.401-3.085c1.741-0.726,3.763-1.09,6.066-1.09c2.001,0,3.894,0.233,5.676,0.699l-0.74,5.799
        c-0.796-0.219-1.619-0.397-2.468-0.535c-0.85-0.137-1.714-0.206-2.591-0.206c-2.249,0-3.963,0.748-5.142,2.242
        c-1.179,1.495-1.769,3.503-1.769,6.025c0,2.742,0.576,4.84,1.728,6.293c1.152,1.454,2.988,2.18,5.512,2.18
        c0.712,0,1.535-0.088,2.468-0.267c0.932-0.179,1.728-0.418,2.385-0.72L23.411,30.944z"/>
      <path fill={fillColor} d="M44.963,20.25c0,0.438-0.021,0.884-0.062,1.336c-0.041,0.453-0.089,0.925-0.144,1.419H32.583
        c0.027,1.384,0.453,2.45,1.275,3.198c0.822,0.747,2.083,1.121,3.784,1.121c1.014,0,2.029-0.106,3.044-0.318
        c1.014-0.212,2.001-0.545,2.961-0.998l0.576,4.729c-1.152,0.467-2.386,0.816-3.702,1.049c-1.316,0.233-2.605,0.35-3.866,0.35
        c-3.373,0-5.951-0.836-7.733-2.509c-1.783-1.672-2.673-4.318-2.673-7.938c0-1.563,0.212-3.016,0.638-4.36
        c0.425-1.343,1.055-2.502,1.892-3.476c0.836-0.973,1.864-1.734,3.084-2.283c1.22-0.548,2.625-0.823,4.216-0.823
        c1.536,0,2.865,0.247,3.99,0.74c1.124,0.494,2.05,1.166,2.776,2.016c0.726,0.85,1.261,1.851,1.604,3.002
        C44.792,17.659,44.963,18.907,44.963,20.25z M38.793,18.852c0.055-1.151-0.185-2.029-0.72-2.632
        c-0.535-0.603-1.254-0.905-2.16-0.905c-0.905,0-1.659,0.329-2.262,0.987s-0.946,1.508-1.028,2.55H38.793z"/>
      <path fill={fillColor} d="M61.004,31.685V18.653c0-0.825-0.138-1.471-0.411-1.938c-0.274-0.467-0.768-0.701-1.481-0.701
        c-0.549,0-1.069,0.158-1.563,0.475c-0.494,0.316-0.925,0.736-1.295,1.257c-0.37,0.522-0.666,1.134-0.884,1.835
        c-0.22,0.701-0.329,1.423-0.329,2.165v9.939h-6.252V11.214h5.84c0,0.384-0.014,0.891-0.041,1.521
        c-0.027,0.631-0.096,1.193-0.206,1.687h0.041c0.63-1.033,1.508-1.897,2.632-2.591c1.124-0.693,2.509-1.041,4.154-1.041
        c1.152,0,2.111,0.172,2.879,0.514c0.767,0.343,1.384,0.809,1.851,1.398c0.466,0.59,0.802,1.275,1.008,2.057
        c0.206,0.782,0.309,1.611,0.309,2.488v14.437H61.004z"/>
      <path fill={fillColor} d="M83.79,31.562c-0.686,0.164-1.413,0.301-2.18,0.411c-0.768,0.109-1.522,0.164-2.262,0.164
        c-1.171,0-2.152-0.109-2.942-0.329c-0.79-0.219-1.43-0.582-1.92-1.09c-0.49-0.507-0.837-1.179-1.042-2.016
        c-0.204-0.836-0.306-1.885-0.306-3.146v-9.501h-3.085v-4.812h3.085V5.196l6.21-1.686v7.732h4.442v4.812h-4.401v8.062
        c0,1.042,0.15,1.768,0.453,2.18c0.301,0.411,0.85,0.616,1.645,0.616c0.384,0,0.782-0.034,1.193-0.103
        c0.412-0.068,0.782-0.144,1.111-0.227V31.562z"/>
      <path fill={fillColor} d="M99.132,16.96c-1.728-0.384-3.03-0.075-3.908,0.925c-0.878,1.001-1.316,2.791-1.316,5.368v8.432h-6.252
        V11.243h5.84c0,0.439-0.021,0.994-0.062,1.666c-0.041,0.672-0.117,1.323-0.226,1.954h0.082c0.521-1.207,1.282-2.221,2.283-3.044
        c1.001-0.822,2.283-1.179,3.846-1.069L99.132,16.96z"/>
      <path fill={fillColor} d="M121.63,21.032c0,1.645-0.274,3.153-0.823,4.524c-0.549,1.372-1.303,2.544-2.262,3.518
        c-0.96,0.974-2.098,1.727-3.414,2.262c-1.316,0.535-2.742,0.802-4.277,0.802c-1.481,0-2.825-0.191-4.031-0.575
        c-1.207-0.384-2.242-0.987-3.105-1.811c-0.864-0.822-1.529-1.877-1.995-3.167c-0.467-1.288-0.699-2.837-0.699-4.647
        c0-1.672,0.267-3.194,0.802-4.565c0.535-1.371,1.289-2.543,2.262-3.517c0.973-0.973,2.118-1.728,3.435-2.262
        c1.316-0.535,2.756-0.802,4.319-0.802c3.125,0,5.538,0.851,7.239,2.55C120.779,15.041,121.63,17.604,121.63,21.032z
         M115.131,21.381c0-1.748-0.288-3.059-0.864-3.933c-0.576-0.874-1.521-1.311-2.838-1.311c-0.494,0-0.974,0.096-1.439,0.287
        c-0.467,0.192-0.884,0.492-1.254,0.901s-0.665,0.936-0.884,1.577c-0.22,0.642-0.329,1.414-0.329,2.315
        c0,1.885,0.301,3.264,0.905,4.138c0.603,0.874,1.563,1.312,2.879,1.312c1.152,0,2.077-0.451,2.776-1.353
        S115.131,23.103,115.131,21.381z"/>
      <path fill={fillColor} d="M160.292,31.685l-1.769-11.434c-0.192-1.152-0.35-2.248-0.473-3.291c-0.123-1.042-0.24-2.098-0.35-3.167
        h-0.082c-0.22,1.042-0.453,2.091-0.699,3.146s-0.549,2.187-0.905,3.393L152.6,31.685h-6.169L143.14,21.32
        c-0.384-1.207-0.734-2.454-1.049-3.743c-0.315-1.289-0.569-2.55-0.761-3.784h-0.042c-0.137,1.316-0.274,2.529-0.411,3.64
        c-0.138,1.111-0.274,2.242-0.411,3.394l-1.398,10.858h-6.458l4.442-27.763h7.362l3.866,12.175c0.302,0.96,0.569,1.933,0.802,2.92
        c0.233,0.987,0.459,2.03,0.679,3.126h0.082c0.219-1.124,0.453-2.152,0.699-3.084c0.247-0.932,0.535-1.933,0.864-3.003l3.949-12.133
        h7.239l4.442,27.763H160.292z"/>
      <path fill={fillColor} d="M187.767,20.25c0,0.438-0.021,0.884-0.062,1.336c-0.041,0.453-0.089,0.925-0.144,1.419h-12.175
        c0.027,1.384,0.453,2.45,1.275,3.198c0.822,0.747,2.083,1.121,3.784,1.121c1.014,0,2.029-0.106,3.044-0.318
        c1.014-0.212,2.001-0.545,2.961-0.998l0.576,4.729c-1.152,0.467-2.386,0.816-3.702,1.049c-1.316,0.233-2.605,0.35-3.866,0.35
        c-3.373,0-5.951-0.836-7.733-2.509c-1.783-1.672-2.673-4.318-2.673-7.938c0-1.563,0.212-3.016,0.638-4.36
        c0.425-1.343,1.055-2.502,1.892-3.476c0.836-0.973,1.864-1.734,3.084-2.283c1.22-0.548,2.625-0.823,4.216-0.823
        c1.536,0,2.865,0.247,3.99,0.74c1.124,0.494,2.05,1.166,2.776,2.016c0.726,0.85,1.261,1.851,1.604,3.002
        C187.595,17.659,187.767,18.907,187.767,20.25z M176.991,8.857l-2.016-3.619l8.021-4.483l2.427,4.319L176.991,8.857z
         M181.597,18.852c0.055-1.151-0.185-2.029-0.72-2.632c-0.535-0.603-1.254-0.905-2.16-0.905c-0.905,0-1.659,0.329-2.262,0.987
        s-0.946,1.508-1.028,2.55H181.597z"/>
      <path fill={fillColor} d="M203.931,31.685c0-0.438,0.013-0.905,0.041-1.398c0.027-0.493,0.082-1.001,0.165-1.521h-0.083
        c-0.549,0.986-1.336,1.803-2.365,2.447c-1.028,0.644-2.311,0.966-3.846,0.966c-2.468,0-4.326-0.863-5.573-2.591
        c-1.248-1.728-1.872-4.058-1.872-6.992c0-1.672,0.247-3.229,0.74-4.668s1.207-2.68,2.139-3.722
        c0.932-1.042,2.077-1.864,3.434-2.468c1.357-0.603,2.913-0.905,4.668-0.905c0.713,0,1.398,0.041,2.057,0.123V1.659h6.252v30.025
        H203.931z M203.438,16.095c-0.718-0.164-1.435-0.246-2.152-0.246c-0.607,0-1.173,0.103-1.697,0.307
        c-0.524,0.205-0.98,0.546-1.366,1.024c-0.386,0.479-0.69,1.107-0.911,1.886c-0.221,0.779-0.331,1.742-0.331,2.889
        c0,3.333,0.952,4.999,2.856,4.999c0.524,0,1.007-0.143,1.449-0.43c0.441-0.286,0.821-0.689,1.139-1.209
        c0.317-0.519,0.565-1.141,0.745-1.865c0.179-0.724,0.27-1.509,0.27-2.356V16.095z"/>
      <path fill={fillColor} d="M221.535,5.402c0,0.494-0.103,0.967-0.309,1.419c-0.206,0.452-0.48,0.85-0.823,1.192
        c-0.343,0.343-0.754,0.611-1.234,0.802c-0.48,0.192-0.98,0.288-1.501,0.288c-0.521,0-1.015-0.096-1.481-0.288
        c-0.466-0.191-0.871-0.453-1.213-0.781c-0.343-0.329-0.617-0.72-0.822-1.172c-0.206-0.453-0.309-0.939-0.309-1.46
        c0-0.521,0.103-1.007,0.309-1.46c0.206-0.452,0.479-0.843,0.822-1.172s0.747-0.589,1.213-0.781
        c0.466-0.191,0.959-0.288,1.481-0.288c0.521,0,1.021,0.097,1.501,0.288c0.479,0.192,0.891,0.452,1.234,0.781
        s0.617,0.72,0.823,1.172C221.432,4.395,221.535,4.881,221.535,5.402z M214.543,31.685V11.243h6.252v20.442H214.543z"/>
      <path fill={fillColor} d="M239.262,31.232c-0.713,0.274-1.536,0.486-2.468,0.638c-0.933,0.15-1.892,0.226-2.879,0.226
        c-3.134,0-5.487-0.836-7.06-2.509c-1.573-1.672-2.359-4.168-2.359-7.486c0-1.535,0.213-2.988,0.642-4.36
        c0.427-1.371,1.083-2.571,1.966-3.599c0.883-1.028,2.006-1.844,3.369-2.447s2.976-0.905,4.841-0.905
        c0.658,0,1.323,0.048,1.995,0.144c0.672,0.097,1.323,0.226,1.954,0.391l-0.658,5.429c-0.521-0.164-1.049-0.301-1.583-0.411
        c-0.535-0.109-1.077-0.165-1.625-0.165c-1.454,0-2.536,0.478-3.249,1.434c-0.713,0.956-1.069,2.281-1.069,3.974
        c0,1.557,0.35,2.787,1.049,3.688c0.699,0.9,1.761,1.352,3.188,1.352c0.603,0,1.207-0.059,1.81-0.175
        c0.603-0.117,1.152-0.292,1.646-0.524L239.262,31.232z"/>
      <path fill={fillColor} d="M262.213,21.032c0,1.645-0.274,3.153-0.823,4.524c-0.549,1.372-1.303,2.544-2.262,3.518
        c-0.96,0.974-2.098,1.727-3.414,2.262c-1.316,0.535-2.742,0.802-4.277,0.802c-1.481,0-2.825-0.191-4.031-0.575
        c-1.207-0.384-2.242-0.987-3.105-1.811c-0.864-0.822-1.529-1.877-1.995-3.167c-0.467-1.288-0.699-2.837-0.699-4.647
        c0-1.672,0.267-3.194,0.802-4.565c0.535-1.371,1.289-2.543,2.262-3.517c0.973-0.973,2.118-1.728,3.435-2.262
        c1.316-0.535,2.756-0.802,4.319-0.802c3.125,0,5.538,0.851,7.239,2.55C261.362,15.041,262.213,17.604,262.213,21.032z
         M255.714,21.381c0-1.748-0.288-3.059-0.864-3.933c-0.576-0.874-1.521-1.311-2.838-1.311c-0.494,0-0.974,0.096-1.439,0.287
        c-0.467,0.192-0.884,0.492-1.254,0.901s-0.665,0.936-0.884,1.577c-0.22,0.642-0.329,1.414-0.329,2.315
        c0,1.885,0.301,3.264,0.905,4.138c0.603,0.874,1.563,1.312,2.879,1.312c1.152,0,2.077-0.451,2.776-1.353
        S255.714,23.103,255.714,21.381z"/>
      <path fill={fillColor} d="M287.137,31.685c0-0.438,0.014-0.905,0.041-1.398s0.084-1.001,0.166-1.521h-0.082
        c-0.549,0.986-1.337,1.803-2.366,2.447c-1.028,0.644-2.311,0.966-3.846,0.966c-2.468,0-4.326-0.863-5.573-2.591
        c-1.248-1.728-1.872-4.058-1.872-6.992c0-1.672,0.247-3.229,0.74-4.668s1.207-2.68,2.139-3.722
        c0.932-1.042,2.077-1.864,3.434-2.468c1.357-0.603,2.913-0.905,4.668-0.905c0.713,0,1.398,0.041,2.057,0.123V1.659h6.252v30.025
        H287.137z M286.645,16.095c-0.718-0.164-1.436-0.246-2.153-0.246c-0.607,0-1.173,0.103-1.697,0.307
        c-0.524,0.205-0.98,0.546-1.366,1.024c-0.386,0.479-0.69,1.107-0.911,1.886c-0.221,0.779-0.331,1.742-0.331,2.889
        c0,3.333,0.952,4.999,2.856,4.999c0.524,0,1.007-0.143,1.449-0.43c0.441-0.286,0.821-0.689,1.139-1.209
        c0.317-0.519,0.565-1.141,0.745-1.865c0.18-0.724,0.27-1.509,0.27-2.356V16.095z"/>
      <path fill={fillColor} d="M315.271,20.25c0,0.438-0.021,0.884-0.062,1.336c-0.041,0.453-0.09,0.925-0.144,1.419h-12.175
        c0.026,1.384,0.452,2.45,1.274,3.198c0.823,0.747,2.084,1.121,3.784,1.121c1.015,0,2.029-0.106,3.044-0.318
        c1.014-0.212,2.001-0.545,2.961-0.998l0.576,4.729c-1.151,0.467-2.386,0.816-3.702,1.049c-1.315,0.233-2.604,0.35-3.866,0.35
        c-3.372,0-5.95-0.836-7.732-2.509c-1.782-1.672-2.673-4.318-2.673-7.938c0-1.563,0.212-3.016,0.637-4.36
        c0.425-1.343,1.056-2.502,1.893-3.476c0.836-0.973,1.864-1.734,3.085-2.283c1.22-0.548,2.625-0.823,4.216-0.823
        c1.535,0,2.864,0.247,3.989,0.74c1.124,0.494,2.05,1.166,2.776,2.016c0.727,0.85,1.261,1.851,1.604,3.002
        C315.099,17.659,315.271,18.907,315.271,20.25z M309.101,18.852c0.055-1.151-0.185-2.029-0.72-2.632
        c-0.534-0.603-1.254-0.905-2.159-0.905c-0.904,0-1.659,0.329-2.262,0.987c-0.604,0.658-0.946,1.508-1.028,2.55H309.101z"/>
      <path fill={fillColor} d="M354.139,31.685L352.37,20.25c-0.192-1.152-0.35-2.248-0.474-3.291c-0.123-1.042-0.24-2.098-0.35-3.167
        h-0.082c-0.22,1.042-0.452,2.091-0.699,3.146s-0.549,2.187-0.905,3.393l-3.413,11.352h-6.17l-3.29-10.365
        c-0.385-1.207-0.734-2.454-1.049-3.743c-0.316-1.289-0.57-2.55-0.762-3.784h-0.041c-0.138,1.316-0.274,2.529-0.411,3.64
        c-0.138,1.111-0.274,2.242-0.411,3.394l-1.398,10.858h-6.458l4.442-27.763h7.362l3.866,12.175c0.302,0.96,0.569,1.933,0.803,2.92
        c0.232,0.987,0.458,2.03,0.678,3.126h0.083c0.219-1.124,0.452-2.152,0.699-3.084c0.246-0.932,0.534-1.933,0.863-3.003l3.948-12.133
        h7.239l4.442,27.763H354.139z"/>
      <path fill={fillColor} d="M375.11,31.654c0-0.493,0.006-1.001,0.021-1.521c0.014-0.521,0.062-1.042,0.144-1.563h-0.041
        c-0.496,1.079-1.311,1.943-2.44,2.593c-1.131,0.649-2.413,0.975-3.848,0.975c-1.975,0-3.462-0.521-4.463-1.563
        s-1.501-2.386-1.501-4.031c0-1.288,0.295-2.412,0.888-3.373c0.591-0.959,1.402-1.754,2.435-2.385s2.257-1.104,3.674-1.419
        c1.417-0.314,2.938-0.473,4.562-0.473v-0.782c0-0.85-0.269-1.48-0.806-1.892c-0.538-0.411-1.494-0.617-2.87-0.617
        c-0.909,0-1.921,0.13-3.037,0.391c-1.115,0.261-2.1,0.624-2.953,1.09l-0.493-5.182c1.042-0.329,2.214-0.61,3.517-0.843
        c1.302-0.233,2.598-0.35,3.887-0.35c1.755,0,3.215,0.165,4.381,0.493c1.165,0.329,2.09,0.801,2.775,1.417
        c0.686,0.616,1.166,1.376,1.44,2.279c0.273,0.904,0.411,1.944,0.411,3.121v8.787c0,0.739,0.014,1.534,0.041,2.384
        s0.068,1.672,0.123,2.467H375.11z M374.539,22.883c-1.133,0-2.065,0.075-2.797,0.224c-0.732,0.15-1.306,0.347-1.72,0.591
        s-0.704,0.529-0.87,0.855c-0.165,0.326-0.248,0.666-0.248,1.019c0,0.517,0.179,0.938,0.538,1.264
        c0.358,0.326,0.87,0.489,1.533,0.489c0.524,0,1.008-0.109,1.45-0.326c0.442-0.218,0.815-0.503,1.119-0.856
        c0.303-0.353,0.545-0.767,0.725-1.242s0.27-0.958,0.27-1.447V22.883z"/>
      <path fill={fillColor} d="M397.201,16.96c-1.728-0.384-3.03-0.075-3.907,0.925c-0.878,1.001-1.316,2.791-1.316,5.368v8.432h-6.252
        V11.243h5.841c0,0.439-0.021,0.994-0.063,1.666c-0.041,0.672-0.116,1.323-0.226,1.954h0.082c0.521-1.207,1.281-2.221,2.283-3.044
        c1-0.822,2.282-1.179,3.846-1.069L397.201,16.96z"/>
      <path fill={fillColor} d="M421.673,31.685c0-0.438,0.014-0.905,0.041-1.398s0.083-1.001,0.165-1.521h-0.082
        c-0.549,0.986-1.337,1.803-2.365,2.447c-1.028,0.644-2.311,0.966-3.846,0.966c-2.468,0-4.326-0.863-5.573-2.591
        c-1.248-1.728-1.871-4.058-1.871-6.992c0-1.672,0.246-3.229,0.74-4.668c0.493-1.439,1.206-2.68,2.139-3.722
        c0.932-1.042,2.077-1.864,3.435-2.468c1.357-0.603,2.913-0.905,4.668-0.905c0.713,0,1.398,0.041,2.057,0.123V1.659h6.252v30.025
        H421.673z M421.18,16.095c-0.718-0.164-1.436-0.246-2.152-0.246c-0.607,0-1.173,0.103-1.697,0.307
        c-0.524,0.205-0.98,0.546-1.366,1.024c-0.387,0.479-0.689,1.107-0.911,1.886c-0.221,0.779-0.331,1.742-0.331,2.889
        c0,3.333,0.952,4.999,2.856,4.999c0.524,0,1.007-0.143,1.448-0.43c0.441-0.286,0.821-0.689,1.139-1.209
        c0.318-0.519,0.566-1.141,0.745-1.865c0.18-0.724,0.27-1.509,0.27-2.356V16.095z"/>
      <path fill={fillColor} d="M449.807,20.25c0,0.438-0.021,0.884-0.062,1.336c-0.041,0.453-0.09,0.925-0.144,1.419h-12.175
        c0.026,1.384,0.452,2.45,1.274,3.198c0.823,0.747,2.084,1.121,3.784,1.121c1.015,0,2.029-0.106,3.044-0.318
        c1.014-0.212,2.001-0.545,2.961-0.998l0.576,4.729c-1.151,0.467-2.386,0.816-3.702,1.049c-1.315,0.233-2.604,0.35-3.866,0.35
        c-3.372,0-5.95-0.836-7.732-2.509c-1.782-1.672-2.673-4.318-2.673-7.938c0-1.563,0.212-3.016,0.637-4.36
        c0.425-1.343,1.056-2.502,1.893-3.476c0.836-0.973,1.864-1.734,3.085-2.283c1.22-0.548,2.625-0.823,4.216-0.823
        c1.535,0,2.864,0.247,3.989,0.74c1.124,0.494,2.05,1.166,2.776,2.016c0.727,0.85,1.261,1.851,1.604,3.002
        C449.635,17.659,449.807,18.907,449.807,20.25z M443.637,18.852c0.055-1.151-0.185-2.029-0.72-2.632
        c-0.534-0.603-1.254-0.905-2.159-0.905c-0.904,0-1.659,0.329-2.262,0.987c-0.604,0.658-0.946,1.508-1.028,2.55H443.637z"/>
      <path fill={fillColor} d="M453.632,31.685V1.659h6.252v30.025H453.632z" />
      <path fill={fillColor} d="M492.623,12.641c0,1.344-0.24,2.612-0.72,3.805c-0.48,1.193-1.18,2.242-2.098,3.146
        c-0.919,0.905-2.063,1.618-3.435,2.139c-1.371,0.521-2.935,0.781-4.688,0.781c-0.165,0-0.378,0-0.638,0
        c-0.261,0-0.542-0.013-0.844-0.041v9.213h-6.416V3.921c1.206-0.055,2.461-0.103,3.764-0.144c1.302-0.041,2.68-0.062,4.134-0.062
        c3.563,0,6.278,0.768,8.144,2.303C491.69,7.555,492.623,9.762,492.623,12.641z M486.042,12.929c0-1.179-0.346-2.139-1.036-2.879
        c-0.689-0.74-1.836-1.11-3.438-1.11c-0.221,0-0.449,0-0.684,0s-0.462,0.014-0.684,0.041v8.144c0.222,0.027,0.421,0.048,0.602,0.062
        c0.179,0.014,0.352,0.021,0.518,0.021c1.353-0.027,2.478-0.404,3.375-1.131S486.042,14.3,486.042,12.929z"/>
      <path fill={fillColor} d="M495.79,31.685V1.659h6.252v30.025H495.79z" />
      <path fill={fillColor} d="M517.954,31.654c0-0.493,0.006-1.001,0.021-1.521c0.014-0.521,0.062-1.042,0.144-1.563h-0.041
        c-0.496,1.079-1.311,1.943-2.44,2.593c-1.131,0.649-2.413,0.975-3.848,0.975c-1.975,0-3.462-0.521-4.463-1.563
        s-1.501-2.386-1.501-4.031c0-1.288,0.295-2.412,0.888-3.373c0.591-0.959,1.402-1.754,2.435-2.385s2.257-1.104,3.674-1.419
        c1.417-0.314,2.938-0.473,4.562-0.473v-0.782c0-0.85-0.269-1.48-0.806-1.892c-0.538-0.411-1.494-0.617-2.87-0.617
        c-0.909,0-1.921,0.13-3.037,0.391c-1.115,0.261-2.1,0.624-2.953,1.09l-0.493-5.182c1.042-0.329,2.214-0.61,3.517-0.843
        c1.302-0.233,2.598-0.35,3.887-0.35c1.755,0,3.215,0.165,4.381,0.493c1.165,0.329,2.09,0.801,2.775,1.417
        c0.686,0.616,1.166,1.376,1.44,2.279c0.273,0.904,0.411,1.944,0.411,3.121v8.787c0,0.739,0.014,1.534,0.041,2.384
        s0.068,1.672,0.123,2.467H517.954z M517.383,22.883c-1.133,0-2.065,0.075-2.797,0.224c-0.732,0.15-1.306,0.347-1.72,0.591
        s-0.704,0.529-0.87,0.855c-0.165,0.326-0.248,0.666-0.248,1.019c0,0.517,0.179,0.938,0.538,1.264
        c0.358,0.326,0.87,0.489,1.533,0.489c0.524,0,1.008-0.109,1.45-0.326c0.442-0.218,0.815-0.503,1.119-0.856
        c0.303-0.353,0.545-0.767,0.725-1.242s0.27-0.958,0.27-1.447V22.883z"/>
      <path fill={fillColor} d="M540.21,31.562c-0.686,0.164-1.412,0.301-2.18,0.411c-0.768,0.109-1.521,0.164-2.262,0.164
        c-1.172,0-2.152-0.109-2.942-0.329c-0.79-0.219-1.43-0.582-1.921-1.09c-0.49-0.507-0.837-1.179-1.041-2.016
        c-0.205-0.836-0.307-1.885-0.307-3.146v-9.501h-3.085v-4.812h3.085V5.196l6.211-1.686v7.732h4.441v4.812h-4.4v8.062
        c0,1.042,0.15,1.768,0.452,2.18c0.302,0.411,0.85,0.616,1.646,0.616c0.384,0,0.781-0.034,1.192-0.103s0.781-0.144,1.11-0.227
        V31.562z"/>
      <path fill={fillColor} d="M555.095,31.654c0-0.493,0.006-1.001,0.021-1.521c0.014-0.521,0.062-1.042,0.144-1.563h-0.041
        c-0.496,1.079-1.311,1.943-2.44,2.593c-1.131,0.649-2.413,0.975-3.848,0.975c-1.975,0-3.462-0.521-4.463-1.563
        s-1.501-2.386-1.501-4.031c0-1.288,0.295-2.412,0.888-3.373c0.591-0.959,1.402-1.754,2.435-2.385s2.257-1.104,3.674-1.419
        c1.417-0.314,2.938-0.473,4.562-0.473v-0.782c0-0.85-0.269-1.48-0.806-1.892c-0.538-0.411-1.494-0.617-2.87-0.617
        c-0.909,0-1.921,0.13-3.037,0.391c-1.115,0.261-2.1,0.624-2.953,1.09l-0.493-5.182c1.042-0.329,2.214-0.61,3.517-0.843
        c1.302-0.233,2.598-0.35,3.887-0.35c1.755,0,3.215,0.165,4.381,0.493c1.165,0.329,2.09,0.801,2.775,1.417
        c0.686,0.616,1.166,1.376,1.44,2.279c0.273,0.904,0.411,1.944,0.411,3.121v8.787c0,0.739,0.014,1.534,0.041,2.384
        s0.068,1.672,0.123,2.467H555.095z M554.523,22.883c-1.133,0-2.065,0.075-2.797,0.224c-0.732,0.15-1.306,0.347-1.72,0.591
        s-0.704,0.529-0.87,0.855c-0.165,0.326-0.248,0.666-0.248,1.019c0,0.517,0.179,0.938,0.538,1.264
        c0.358,0.326,0.87,0.489,1.533,0.489c0.524,0,1.008-0.109,1.45-0.326c0.442-0.218,0.815-0.503,1.119-0.856
        c0.303-0.353,0.545-0.767,0.725-1.242s0.27-0.958,0.27-1.447V22.883z"/>
    </svg>
  );

}

export default AppNameProps;
