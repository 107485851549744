import React from 'react';
import { Button, Col, Div, Divider, Label, Row, Spacer, TextInput } from '@gower/react-ui/components';
import { expose } from '@gower/react-ui/store';
import { ConfigurationSubStore } from '../store';
import { Validators } from '@gower/utils';

class QuickReponseSubStore extends ConfigurationSubStore {

    public Emails = [];

    onAfterRender () {

        const emails = this.Data?.quick_response?.targets || '';
        this.Emails = emails.split( ',' ).filter( item => Validators.Email( item ) );
    }

    addEmail ( email: string ) {
        email = email.trim().toLowerCase();
        if ( !this.Emails.some( e => e === email ) ) {
            this.Emails.push( email );
            this.Data.quick_response.targets = this.Emails.join( ',' );
        }
    }

    removeEmail ( email: string ) {
        this.Emails.remove( ( e ) => email === e );
        this.Data.quick_response.targets = this.Emails.join( ',' );
    }
}

const { useStore, provider } = expose( QuickReponseSubStore );

export const QuickReponseConfiguration = provider( () => {

    const store = useStore();
    const { Data } = store;
    const { quick_response } = Data;

    return <>

        <Row paddingBottom={10}>
            <Col gutter center cols={12}>
                <Label p bold normal>Respuesta rápida</Label>
            </Col>
            <Col cols={12}>
                <Divider />
            </Col>
        </Row>

        <EmailList />

        <Row>
            <Col gutter center cols={6}>
                <Div block>
                    <Label p bold xxsmall>Protocolo</Label>
                    <Label p textGrey xsmall>Protocolo del servidor</Label>
                </Div>
            </Col>
            <Col gutter center cols={6}>
                <TextInput
                    filled
                    value="SMTP"
                    placeholder="Protocolo"
                    readOnly
                />
            </Col>
        </Row>

        <Row>
            <Col gutter center cols={6}>
                <Div block>
                    <Label p bold xxsmall>Email emisor</Label>
                    <Label p textGrey xsmall>Emisor del servicio</Label>
                </Div>
            </Col>
            <Col gutter center cols={6}>
                <TextInput
                    filled
                    value={quick_response.email}
                    onChangeText={t => quick_response.email = t}
                    placeholder="Email emisor"
                    readOnly={!store.Status.isOk}
                    error={store.ErrorManager.getEmail()}
                />
            </Col>
        </Row>

        <Row>
            <Col gutter center cols={6}>
                <Div block>
                    <Label p bold xxsmall>Host</Label>
                    <Label p textGrey xsmall>Servidor de emails</Label>
                </Div>
            </Col>
            <Col gutter center cols={6}>
                <TextInput
                    filled
                    value={quick_response.host}
                    onChangeText={t => quick_response.host = t}
                    placeholder="Host"
                    readOnly={!store.Status.isOk}
                //error={store.ErrorManager.getNickname()}
                />
            </Col>
        </Row>

        <Row>
            <Col gutter center cols={6}>
                <Div block>
                    <Label p bold xxsmall>Puerto</Label>
                    <Label p textGrey xsmall>Puerto del servicio de emails</Label>
                </Div>
            </Col>
            <Col gutter center cols={6}>
                <TextInput
                    filled
                    value={quick_response.port}
                    onChangeText={t => quick_response.port = t}
                    placeholder="Puerto"
                    readOnly={!store.Status.isOk}
                    error={store.ErrorManager.getPort()}
                />
            </Col>
        </Row>

        <Row>
            <Col gutter center cols={6}>
                <Div block>
                    <Label p bold xxsmall>Contraseña</Label>
                    <Label p textGrey xsmall>Contraseña del servicio de emails</Label>
                </Div>
            </Col>
            <Col gutter center cols={6}>
                <TextInput
                    filled
                    value={quick_response.password}
                    onChangeText={t => quick_response.password = t}
                    placeholder="Password"
                    readOnly={!store.Status.isOk}
                //error={store.ErrorManager.getNickname()}
                />
            </Col>
        </Row>
    </>;
} );

const EmailList = () => {

    const store = useStore();
    const [value, setValue] = React.useState( '' );

    async function add () {
        if ( Validators.Email( value ) )
            store.addEmail( value );
        setValue( '' );

    }

    async function remove ( item ) {
        store.removeEmail( item );
        setValue( '' );
    }

    return <>
        <Row rows>
            <Col gutter center cols={6}>
                <Div block>
                    <Label p bold xxsmall>Remitentes</Label>
                    <Label p textGrey xsmall>Destinatarios del email</Label>
                </Div>
            </Col>
            <Col gutter center cols={6}>
                <TextInput
                    filled
                    value={value}
                    onChangeText={setValue}
                    placeholder="Ingresá un email"
                    readOnly={!store.Status.isOk}
                    onEnterUp={add}
                    error={store.ErrorManager.getTargets()}
                    contentRight={(
                        <Button
                            small
                            text
                            pointer
                            bold
                            label="Agregar"
                            disabled={!value}
                            onClick={add}
                        />
                    )}
                />
            </Col>
            <Col gutter start cols={12} minHeight={100}>
                <Div block textCenter padding={20} color="surface-2" show={!store.Emails.length}>

                </Div>
                {store.Emails.map( ( item, idx ) => ( <>
                    <Row padding={5} noSelect hoverEffect key={idx}>
                        <Col gutter center>
                            <Div block>
                                <Label p xxsmall bold>{item}</Label>
                            </Div>
                        </Col>
                        <Spacer />
                        <Col gutter center>
                            <Button
                                pointer
                                bold
                                textRed
                                text
                                noneTheme
                                label="Eliminar"
                                onClick={() => remove( item )}
                            />
                        </Col>
                    </Row>
                </> ) )}
            </Col>
        </Row>
    </>;
};