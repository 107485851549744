import { MessageBox } from '@gower/react-ui/message-box';
import { Notifications } from '@gower/react-ui/notifications';
import { expose, MutableStore, ViewStatus } from '@gower/react-ui/store';
import { Redirect } from '@gower/react-ui/utils';
import { User } from '@gower/shared-sdk/core/users';
import { Wait } from '@gower/utils';
import CredentialProvider from 'app/credential-provider';
import { RoutesEnum } from 'app/routes';

class EditUserStore extends MutableStore {

    public CurrentItem: User = null;
    public Status = new ViewStatus( 'pending' );

    async setup () {

        this.Status.setPending();

        const userSession = CredentialProvider.getUser();
        const user = await User.findById( userSession.id );

        if ( user ) {
            this.CurrentItem = user;
            this.Status.setOk();
        }
    }

    async onAfterRender () {
        this.setup();
    }

    async save () {

        this.Status.setSaving();

        const result = await this.CurrentItem.save();
        if ( result.result ) {

            Notifications.Saved();
            await Wait( 1000 );
            Redirect.go( RoutesEnum.Home );
        } else {
            await MessageBox.Error( result.errorList );
        }

        this.Status.setOk();

    }

}

export const { provider, useStore } = expose( EditUserStore );