import React from 'react';
import { Autocomplete, Col, Colors, Label, Row, Spacer, TextInput, Tooltip } from '@gower/react-ui/components';
import { prevent, NextFocus } from '@gower/react-ui/utils';
import { useStore } from '../../store';
import { Authorization } from '@gower/cm-mdp-sdk/authorizations';
import { ViewStatus, useStatus } from '@gower/react-ui/hooks';
import { MdOutlineHelpOutline } from '@gower/react-ui/icons';

const TokenTooltip = {
    [Authorization.VO.Agreement.SAMI.Id]: 'Ingresá el token de la App Credencial Digital SAMI',
    [Authorization.VO.Agreement.IOMA.Id]: 'Ingresá el token de seguridad',
    [Authorization.VO.Agreement.OSPIA.Id]: 'Ingresá el token de seguridad',
};

type InputRef = {
    getValue: () => string,
    focus: () => void,
};

export type InputAffiliateByDocumentProps = {

};
export const InputAffiliateByDocument: React.FC<InputAffiliateByDocumentProps> = ( { } ) => {

    const store = useStore();
    const inputStatus = useStatus( 'ok' );
    const needSecureCode = store.needToken;
    const documentRef = React.useRef<InputRef>();
    const tokenRef = React.useRef<InputRef>();
    const [document, setDocument] = React.useState( {
        type: Authorization.VO.Documents.DNI,
        number: ''
    } );

    const [tokenValue, setTokenValue] = React.useState( '' );


    async function submit ( event ,isTokenInput) {

        prevent( event );

        if ( inputStatus.isLoading ) return;

        const isvalid = document?.type && Number( document?.number ) > 4000;

        if ( needSecureCode ) {

            if ( isvalid && ( Boolean( tokenValue ) || isTokenInput ) ) {
                if ( await find() )
                    event && NextFocus( '* input, * textarea' );
            } else if ( !document?.number ) {
                event && documentRef.current?.focus();
            } else if ( document?.number && !tokenValue ) {
                event && tokenRef.current?.focus();
            }

        } else if ( isvalid ) {
            if ( await find() )
                event && NextFocus( '* input, * textarea' );
        }

    };

    async function find () {

        inputStatus.setLoading();

        if ( await store.addAffiliateByDocument( document?.type, document?.number, tokenValue ) ) {
            setDocument( {
                type: document?.type,
                number: store?.Affiliate?.Document?.Number || ''
            } );
            inputStatus.setOk();
            return true;
        }

        clear();
        inputStatus.setOk();
    }

    function clear () {
        setDocument( {
            type: Authorization.VO.Documents.DNI,
            number: ''
        } );
        setTokenValue( '' );
        store.removeAffiliate();
    };

    React.useEffect( () => {
        const listener = () => { clear(); };
        store.on( 'reset', listener );
        return () => { store.removeListener( 'reset', listener ); };
    }, [] );

    if ( store.AffiliateInputMode === 'document' || store.Agreement.isOSPIA || store.Agreement.isOSPF )
        return <>
            <Col
                xs={6}
                sm={5}
                cols={4}
                paddingY={4}
                minWidth={300}
                center >
                <RenderAffiliateInput
                    ref={documentRef}
                    value={document}
                    onChange={setDocument}
                    inputStatus={inputStatus}
                    onClear={clear}
                    onSubmit={( e ) => submit( e, false)}
                />
            </Col>
            <Col
                xl={3}
                cols={4}
                maxWidth={200}
                paddingY={4}
                paddingLeft={4}
                show={needSecureCode}
            >
                <RenderSecureCodeInput
                    ref={tokenRef}
                    value={tokenValue}
                    onChange={setTokenValue}
                    inputStatus={inputStatus}
                    onClear={clear}
                    onSubmit={( e ) => submit( e, true )}
                />
            </Col>

            {store.Request.Affiliate?.Name && ( <>
                <Spacer paddingX={10} center>
                    <Label>{store.Request.Affiliate?.Name}</Label>
                </Spacer>
            </> )}
            {!store.Request.Affiliate?.Name && (
                <Spacer paddingX={10} center>
                    <Label gutter>{store.Request.Affiliate?.Document.Type}</Label>
                    <Label gutter>{store.Request.Affiliate?.Document.Number}</Label>
                </Spacer>
            )}
            <Col cols={12} />
        </>;

    return null;
};

type RenderAffiliateInputProps = {
    onSubmit: ( event?: any ) => void;
    onClear: () => void;
    inputStatus: ViewStatus;
    value: { type: InstanceType<( typeof Authorization )['VO']['Documents']>, number: string; };
    onChange: ( t: { type: InstanceType<( typeof Authorization )['VO']['Documents']>, number: string; } ) => void;
    ref?: any;
};
const RenderAffiliateInput: React.FC<RenderAffiliateInputProps> = React.forwardRef( ( {
    onSubmit,
    onClear,
    onChange,
    value,
    inputStatus,
}, ref ) => {

    const store = useStore();
    const status = store.Status;
    const inputRef = React.useRef<HTMLInputElement>();
    const readOnly = !store.canAddAffiliate;

    const [documentValue, setDocumentValue] = React.useState( '' );
    const [typeValue, setTypeValue] = React.useState( Authorization.VO.Documents.DNI );

    React.useEffect( () => {
        if ( ref ) {
            ref['current'] = {
                getValue: () => ( {
                    type: typeValue,
                    number: documentValue,
                } ),
                focus: () => inputRef.current?.focus(),
            };
        }
    }, [ref] );

    React.useEffect( () => {
        setDocumentValue( value?.number );
        setTypeValue( value?.type );
    }, [value] );

    async function submit ( event ) {

        prevent( event );

        if ( inputStatus.isLoading ) return;

        if ( typeValue?.Id && Number( documentValue ) > 4000 ) {
            onChange( {
                type: typeValue,
                number: documentValue,
            } );
            onSubmit( event );
        }

    };

    function setDocument ( document ) {
        setDocumentValue( document );
        onChange( {
            type: typeValue,
            number: document,
        } );
    }

    function setType ( type ) {
        setTypeValue( type );
        onChange( {
            type: type,
            number: documentValue,
        } );
    }

    return (
        <TextInput
            large
            autoFocus
            noDelayed
            prefix="Nº Documento:"
            placeholder="# # # # # # # #"
            value={documentValue}
            onChangeText={setDocument}
            selectAllOnFocus
            readOnly={readOnly || status.isLoading || status.isSaving}
            loading={inputStatus.isLoading}
            onClear={onClear}
            clearableOnEscape
            onEnterUp={submit}
            onBlur={() => submit( null )}
            preventClickLeftContent
            contentLeft={
                <Autocomplete
                    readOnly={inputStatus.isLoading}
                    noFocuseable
                    width={90}
                    listWidth={250}
                    borderless
                    select
                    notEmpty
                    selectedKey="Id"
                    options={Authorization.VO.Documents.getOptions( false )}
                    value={typeValue}
                    onChange={( event, option ) => setType( option )}
                    selectedTemplate={( selected ) => (
                        <Row textRight>
                            <Label bold>{selected.Id}</Label>
                        </Row>
                    )}
                />
            }

        />
    );
} );



type RenderSecureCodeInputProps = {
    onSubmit: ( event?: any ) => void;
    onClear: () => void;
    inputStatus: ViewStatus;
    value: string;
    onChange: ( t: string ) => void;
    ref?: any;
};
const RenderSecureCodeInput: React.FC<RenderSecureCodeInputProps> = React.forwardRef( ( {
    onSubmit,
    onClear,
    value,
    onChange,
    inputStatus,
}, ref ) => {

    const store = useStore();
    const inputRef = React.useRef<HTMLInputElement>();
    const readOnly = !store.canAddAffiliate || Boolean( store.Affiliate ) || inputStatus.isLoading;

    React.useEffect( () => {
        if ( ref ) {
            ref['current'] = {
                getValue: () => value,
                focus: () => inputRef.current?.focus(),
            };
        }
    }, [ref] );

    return (
        <TextInput
            ref={inputRef}
            large
            noDelayed
            prefix="Token:"
            placeholder="####"
            contentRight={
                <Col center paddingX={5} paddingTop={5}>
                    <Tooltip title={TokenTooltip[store.Agreement?.Id]}>
                        <MdOutlineHelpOutline size={20} color={Colors['primary']} />
                    </Tooltip>
                </Col>
            }
            value={value}
            onChangeText={onChange}
            selectAllOnFocus
            readOnly={readOnly}
            onClear={() => onClear()}
            onEscapeUp={() => onClear()}
            onEnterUp={( event ) => onSubmit( event )}
            onBlur={() => onSubmit()}
        />
    );
} );