import { ActivateUserService } from '@gower/shared-sdk/src/lib/Core/Authentication/ActivateUser';
import { MutableStore, expose, ViewStatus } from '@gower/react-ui/store';
import { Redirect } from '@gower/react-ui/utils';
import { RoutesEnum } from 'app/routes';
import { Notifications } from '@gower/react-ui/notifications';
import { Wait } from '@gower/utils';
import { User } from '@gower/shared-sdk/core/users';
import CredentialProvider from 'app/credential-provider';

class ChangePasswordStore extends MutableStore {
    public status = new ViewStatus( 'ok' );

    private errorMessage: string[] = [];
    public get LastErrors () { return this.errorMessage; };
    public set LastErrors ( value: string[] ) {
        this.errorMessage = value || [];
        this.status.setErrored();
        setTimeout( () => {
            this.errorMessage.clear();
            this.status.setOk();

        }, 10000 );
    };

    private service = ActivateUserService.get();
    public get userEmail () { return this.service.Email; }


    public async createPassword ( password1: string, password2: string ) {
        if ( password1.length < 6 ) {
            this.LastErrors = ['Ingresá al menos 6 caracteres'];
            return;
        }
        else if ( password1 !== password2 ) {
            this.LastErrors = ['Las contraseñas no coinciden'];
            return;
        }

        this.status.setLoading();

        const userId = CredentialProvider.getUserId();
        const user = await User.findById( userId );
        user.Password = password1;
        if ( await user.save() ) {

            Notifications.Success( 'Contraseña actualizada' );
            await Wait( 1000 );
            this.status.setOk();
            Redirect.go( RoutesEnum.Home );
        }
        else {
            this.LastErrors = this.service.LastErrors as string[];
        }
    }

}

export const { useStore, provider } = expose( ChangePasswordStore );