import { theme, MediaQuery } from '@gower/react-ui/components';
import { provider } from './store';
import { SpecialtiesDesktop } from './desktop';
import { SpecialtiesMobile } from './mobile';

export const SpecialtiesView = provider( () => {
    return <>
        <MediaQuery minWidth={theme.current.Breakpoints.XsMin}>
            <SpecialtiesDesktop />
        </MediaQuery>
        <MediaQuery
            maxWidth={theme.current.Breakpoints.MobileMax}
        >
            <SpecialtiesMobile />
        </MediaQuery>
    </>;
}, { keepAlive: true } );
