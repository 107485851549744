import { Specialty, Filters } from '@gower/cm-mdp-sdk/specialties';
import { expose, SubStore } from '@gower/react-ui/store';
import { DataGridStore } from '@gower/react-ui/template/data-grid';

export class SpecialtySubStore extends SubStore<SpecialtyStore> {
    public get Selected () { return this.MainStore.Selected; }
}

class SpecialtyStore extends DataGridStore( Specialty, Filters, { viewName: 'HCV' } )
{
    public Mode: 'edit' | 'create' | 'list' = 'list';
    public Selected: Specialty = null;

    onAfterRender () {
        this.list();
    }

    edit ( item: Specialty ) {
        this.Selected = item;
        this.Mode = 'edit';
    }

    create () {
        this.Selected = null;
        this.Mode = 'create';
    }

    list ( newItem?: Specialty ) {
        this.Selected = null;
        if ( newItem )
            this.Items.unshift( newItem );
        this.Mode = 'list';
    }
}

export const { useStore, provider } = expose( SpecialtyStore );