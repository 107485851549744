import { Render, Row } from '@gower/react-ui/components';
import { ContextTemplatesProps, TemplatesProps } from '../list.props';
import { getActions } from '../list.actions';

export type TableItemProps = TemplatesProps & ContextTemplatesProps & {
    item: any;
};
export const TableItem: React.FC<TableItemProps & { item: any; }> = ( { item, ...props } ) =>
{
    const { store } = props;
    const { open } = getActions( props );

    return <>
        {props.itemTemplate && <Render content={props.itemTemplate} props={{ ...props.injectProps, ...store.getProps(), item, ...getActions( props ) }} />}
        {!props.itemTemplate && <>
            <Row fullWidth
                margin={5}
                padding={10}
                noSelect
                activeEffect
                onClick={() => open( item )}
            >
                <Render content={props.itemContent} props={{ ...props.injectProps, ...store.getProps(), item, ...getActions( props ) }} />
            </Row>
        </>}
    </>;
};