import React from 'react';
import dayjs from 'dayjs';
import CredentialProvider, { adminOnly } from 'app/credential-provider';
import { Col, Content, Div, Label, Row, Spacer, Alert, Button, Colors, Panel, Chip } from '@gower/react-ui/components';
import { useStore } from '../store';
import { MdAddCircleOutline, MdFileDownload } from '@gower/react-ui/icons';
import { DataGrid, DataGridColumn, DataGridTemplateProps } from '@gower/react-ui/template/data-grid';
import { RoutesEnum } from 'app/routes';
import { TbChevronDown } from '@gower/react-ui/icons';
import { Authorization } from '@gower/cm-mdp-sdk/authorizations';
import { useStatus } from '@gower/react-ui/hooks';
import { Link } from 'react-router-dom';

export const AuthorizationsDesktop = () => {

    const store = useStore();
    const isAdministrator = CredentialProvider.isAdministrator();

    return <>
        <Content columns color="surface-0">
            <Col>
                <Row padding={15}>
                    <Col gutter center>
                        <Div padding={10} >
                            <Label xxsemi p bold marginBottom={5}>Historial de Autorizaciones</Label>
                            <Label xsmall p textGrey>Autorizaciones previamente realizadas.</Label>
                        </Div>
                    </Col>
                    <Spacer />
                    <Col gutter center>
                        <Button
                            actionTheme
                            icon={<MdFileDownload size={16} color={Colors['surface-6']} />}
                            label="Exportar"
                            disabled={!store.Status.isOk}
                            loading={store.DownloadStatus.isLoading}
                            onClick={() => store.downloadList()}
                        />
                    </Col>
                    <Col gutter center>
                        <Button
                            primary
                            icon={<MdAddCircleOutline size={16} />}
                            label="Nueva autorización"
                            reactLink={RoutesEnum.Home}
                        />
                    </Col>
                </Row>
            </Col>
            <Spacer vertical noScroll>
                <DataGrid
                    loader
                    store={store}
                    autoSorting
                    showFilters={["status", "agreement", "effector_license", "billing_license", isAdministrator ? 'user_id' : '', "date_range"]}
                    showReload
                    scroll
                    showPagination
                    showSearch
                    mobileTemplate={( { data } ) => (
                        <Row white center borderRadius={5} minHeight={45} padding={10} activeEffect>
                            <Col cols={6}>
                                <Label label={data.Id} />
                            </Col>
                            <Col cols={6}>
                                <Label label={data.CompleteName} />
                            </Col>
                        </Row>
                    )}
                >
                    <DataGridColumn
                        field="AuthorizationId"
                        title="Nº de Autorización"
                        size={80}
                        minSize={50}
                        template={( { data }: DataGridTemplateProps<Authorization> ) => (
                            <Div block>
                                <Link to={`${ RoutesEnum.Authorizations }/${ data.Id }`}>
                                    <Label p show={!!data.AuthorizationId}>
                                        {data.AuthorizationId}
                                    </Label>
                                    <Label p show={!data.AuthorizationId && !!data.ReferenceId}>
                                        {data.ReferenceId} (ref. provisional)
                                    </Label>
                                    <Label p show={!data.AuthorizationId && !data.ReferenceId && !!data.LocalId}>
                                        {data.LocalId} (provisional)
                                    </Label>
                                </Link>
                            </Div>
                        )}
                    />

                    <DataGridColumn field="agreement" title="Convenio"
                        size={80}
                        template={( { data }: DataGridTemplateProps<Authorization> ) => (
                            <Div block textCenter>
                                <Label p label={data.Agreement.Description} />
                            </Div>
                        )}
                    />
                    <DataGridColumn field="status" title="Estado" size={80}
                        template={( { data }: DataGridTemplateProps<Authorization> ) => (
                            <Row justifyCenter >
                                <Chip
                                    xsmall
                                    textCenter
                                    red={data.Status.isReject || data.Status.isErrored || data.Status.isCanceled}
                                    green={data.Status.isIssued}
                                    orange={data.Status.isSubmitted}
                                >
                                    {data.Status.Description}
                                </Chip>
                            </Row>
                        )}
                    />
                    <DataGridColumn field="affiliate" title="Socio / Afiliado"
                        template={( { data }: DataGridTemplateProps<Authorization> ) => (
                            <Div block>
                                <Label p xsmall label={data.Affiliate.Id} />
                                <Label p xsmall label={data.Affiliate.Name} />
                            </Div>
                        )}
                    />
                    <DataGridColumn field="effectorProfessional" title="Pres. Efector"
                        template={( { data }: DataGridTemplateProps<Authorization> ) => (
                            <Div block>
                                <Label p xsmall label={data.EffectorProfessional.License} />
                                <Label p xsmall label={data.EffectorProfessional.Name} />
                            </Div>
                        )}
                    />
                    <DataGridColumn field="billingProfessional" title="Pres. Facturante"
                        template={( { data }: DataGridTemplateProps<Authorization> ) => (
                            <Div block>
                                <Label p xsmall label={data.BillingProfessional.License} />
                                <Label p xsmall label={data.BillingProfessional.Name} />
                            </Div>
                        )}
                    />
                    <DataGridColumn field="date" title="Fecha" size={100}
                        template={( { data }: DataGridTemplateProps<Authorization> ) => (
                            <Label p xsmall>
                                {dayjs( data.AuthorizationDate || null ).isValid() && ( data.AuthorizationDate ).toStringDate( 'short', true )}
                                {!dayjs( data.AuthorizationDate || null ).isValid() && ( data.CreatedDate ).toStringDate( 'short', true )}
                            </Label>
                        )}
                    />
                    <DataGridColumn field="user" title="Usuario" size={100}
                        show={isAdministrator}
                        template={( { data }: DataGridTemplateProps<Authorization> ) => (
                            <Label p xsmall>
                                {data.CreatedUser}
                            </Label>
                        )}
                    />
                    <DataGridColumn
                        field="actions"
                        title=""
                        size={130}
                        template={( { data }: DataGridTemplateProps<Authorization> ) => (
                            <Row end noWrap justifyEnd>
                                <Col center gutter show={data.Type.isPrescription}>
                                    <Chip
                                        xsmall
                                        textCenter
                                        blue
                                        label="Recetario"
                                    />
                                </Col>
                                <Col center gutter>
                                    {data.canCancel() && data.Type.isAuthorization && <Button block bold xxsmall textRed label="Anular" disabled={store.isCanceling} onClick={() => store.cancel( data )} />}
                                    {data.canDelete() && adminOnly() && <Button block bold xxsmall textRed label="Eliminar" disabled={store.isCanceling} onClick={() => store.remove( data )} />}
                                </Col>
                                <Col center gutter>
                                    <PrintMenu item={data} />
                                </Col>
                                <Col center gutter>
                                    <Button block bold xxsmall label="Detalles" reactLink={`${ RoutesEnum.Authorizations }/${ data.Id }`} />
                                </Col>
                            </Row>
                        )}
                    />
                </DataGrid>
            </Spacer>
        </Content>

        {/* 
        <Content start columns>
            <Col padding={20}>
                <Row>
                    <Col padding={20}>
                        <Div>
                            <Label p xxnormal>Historial de Autorizaciones</Label>
                            <Label p xxsmall>Autorizaciones previamente realizadas.</Label>
                        </Div>
                    </Col>
                    <Spacer />
                    <Col cols={4} mobileHidden>
                        <Alert
                            title="Importante"
                            content="Solo podrán anularse autorizaciones que no se encuentren presentadas y hayan sido aceptadas previamente por el convenio y el Centro Médico."
                        />
                    </Col>
                </Row>
            </Col>
            <Spacer vertical noScroll white>
                <AuthorizationsDataGrid />
            </Spacer>
        </Content> */}
    </>;
};



const PrintMenu: React.FC<{ item: Authorization; }> = ( { item } ) => {

    const menuEl = React.useRef( null );
    const status = useStatus( 'ok' );

    function closeMenu () {
        menuEl.current?.hide();
        return true;
    }

    async function download ( type: 'budget' | 'form' | 'prescription' ) {

        let data = null;

        status.setLoading();
        if ( type === 'budget' )
            data = await item.getBudgetUrl();
        else if ( type === 'form' )
            data = await item.getFormUrl();
        else if ( type === 'prescription' )
            data = await item.getPrescriptionUrl();

        if ( data?.url ) {
            window.open( data.url );
        }

        status.setOk();
    }

    return <>
        <Button
            block
            bold
            xxsmall
            textBlue
            label="Reimprimir"
            onClick={( event ) => menuEl.current.toggle( event )}
            contentRight={<TbChevronDown size={16} />}
            loading={status.isLoading}
        />
        <Panel dismissable ref={menuEl}>
            <Row width={200} noWrap columns onClick={closeMenu}>
                <Col cols={12} padding={10}>
                    <Label bold xsmall label="Reimprimir:" />
                </Col>
                <Button xxsmall noneTheme justify="flex-start" width={200} flat borderRadius={0} disabled={status.isLoading || !item.canDownloadBudget(adminOnly())} onClick={() => download( 'budget' )} label="Bono de autorización" />
                {item.Agreement.isSAMI && <Button xxsmall noneTheme justify="flex-start" width={200} flat borderRadius={0} disabled={status.isLoading || !item.canDownloadForm()} onClick={() => download( 'form' )} label="Planilla de sesiones" />}
                {item.Agreement.isSAMI && <Button xxsmall noneTheme justify="flex-start" width={200} flat borderRadius={0} disabled={status.isLoading || !item.canDownloadPrescription()} onClick={() => download( 'prescription' )} label="Recetario" />}
            </Row>
        </Panel>
    </>;
};