import { Model, InstanceOfModel, CrudSubStore } from './common';
import { ViewStatus } from '@gower/react-ui/store';
import { Notifications } from '@gower/react-ui/notifications';
import { MessageBox } from '@gower/react-ui/message-box';
import { Wait } from '@gower/utils';

export class CreateStore<T = Model> extends CrudSubStore {
    public show () {
        this.Status.setLoading();
        this.currentEditItem = new this.MainStore.Model();
        this.setCanSave( false );
        this.Status.setOk();
    }

    public hide () {
        this.Status.setLoading();
        this.currentEditItem = null;
    }

    public getProps (): any {
        return {
            crudStore: this.MainStore,
            createStore: this,
            currentItem: this.currentEditItem,
            currentEditItem: this.currentEditItem,

        };
    }


    setup () {
        this.MainStore.watchInternalNative( 'CreateStore.currentEditItem', () => this.setCanSave( true ) );
    }

    public readonly Status = new ViewStatus( 'pending' );
    public get CurrentEditItem () { return this.currentEditItem as T; }
    private currentEditItem: InstanceOfModel = null;
    public get CanSave () { return this.canSave; }
    private canSave = false;
    private setCanSave ( value: boolean ) {
        this.canSave = value;
    }

    async restore () {
        if ( await MessageBox.UnsavedChanges() ) {
            this.Status.setLoading();
            this.currentEditItem = new this.MainStore.Model();
            this.setCanSave( false );
            this.Status.setOk();
        }
    }

    async save () {
        this.Status.setSaving();
        await Wait( 1000 );
        const result = await this.currentEditItem.save();
        if ( result.result ) {
            
            this.MainStore['onCreate'] && await this.MainStore['onCreate']( this.currentEditItem );
            this.MainStore['onSave'] && await this.MainStore['onSave']( this.currentEditItem );

            Notifications.Saved();
            if ( await MessageBox.CreateMore() ) {
                this.show();
            } else {
                this.MainStore.ListStore.Items.unshift( this.currentEditItem as any );
                this.MainStore.goList();
            }

        } else {
            await MessageBox.Error( result.errorList );
        }

        this.Status.setOk();
    }
}
