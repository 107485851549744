import React from 'react';
import { Alert, Col, Content, Label, Row } from '@gower/react-ui/components';
import { AiOutlineVerticalAlignBottom, AiOutlineVerticalAlignTop } from '@gower/react-ui/icons';
import { useStore } from '../../store';
import { DetailField } from '@gower/react-ui/template/detail-field';

export const TechnicalDetailTab: React.FC = () => {

    const store = useStore();
    const data = store.Authorization;
    const logs = store.AuthorizationLogs;

    return <>
        <Row columns>
            <Col center padding={20}>
                <Alert info content="Detalle de la comunicación con el Convenio. Para mas información consulte el historial de la autorización." />
            </Col>
            <Col>
                <Row padding={10}>
                    <Col cols={4}>
                        <DetailField titleWidth="50px" title="ID CA" value={data.Id} />
                    </Col>
                    <Col cols={4}>
                        <DetailField titleWidth="50%" title="Ref. Convenio" value={data.AgreementId} />
                    </Col>
                    <Col cols={4}>
                        <DetailField titleWidth="50%" title="Ref. CM" value={data.ReferenceId} />
                    </Col>
                </Row>
            </Col>
            <Col>
                <Row>
                    <Col cols={6}>
                        <Row columns padding={10}>
                            <Col noSelect paddingBottom={20}>
                                <Col center>
                                    <AiOutlineVerticalAlignTop size={18} />
                                </Col>
                                <Col center>
                                    <Label gutter xxsmall bold lineHeight={0} label="Request Payload" />
                                </Col>
                            </Col>
                            <Col>
                                <Content
                                    maxHeight={600}
                                    padding={10}
                                    xsmall
                                    color="surface-1"
                                    border
                                    borderColor="surface-4"
                                    borderRadius={5}
                                    scroll
                                >
                                    <pre>
                                        {logs?.Request || 'SIN DATOS'}
                                    </pre>
                                </Content>
                            </Col>
                        </Row>
                    </Col>
                    <Col cols={6}>
                        <Row columns padding={10}>
                            <Col noSelect paddingBottom={20}>
                                <Col center>
                                    <AiOutlineVerticalAlignBottom size={18} />
                                </Col>
                                <Col center>
                                    <Label gutter xxsmall bold lineHeight={0} label="Response Payload" />
                                </Col>
                            </Col>
                            <Col>
                                <Content
                                    maxHeight={600}
                                    padding={10}
                                    xsmall
                                    color="surface-1"
                                    border
                                    borderColor="surface-4"
                                    borderRadius={5}
                                    scroll
                                >
                                    <pre>
                                        {logs?.Response || 'SIN DATOS'}
                                    </pre>
                                </Content>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
        </Row>
    </>;

};