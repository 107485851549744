
import CredentialsProvider from './credential-provider';
import { configureFromObject, configurePreExecuteMethod } from '@gower/cm-mdp-sdk';
import { configureFromObject as sharedConfigureFromObject, configurePreExecuteMethod as sharedConfigurePreExecuteMethod } from '@gower/shared-sdk';

const BackendHost = '/api';

( function configure () {
    
    configureFromObject( {
        CORE_HOST: BackendHost,
        PRINTER_HOST: BackendHost,
        ACCOUNT_HOST: BackendHost,
        AUTHORIZATIONS_HOST: BackendHost,
    } );
    
    sharedConfigureFromObject( {
        CORE_HOST: BackendHost,
        PRINTER_HOST: BackendHost,
        ACCOUNTS_HOST: BackendHost,
    } );

    configurePreExecuteMethod( async ( req ) => {
        if ( CredentialsProvider.isAuthenticated ) {
            req.headers['authorization'] = `Bearer ${ await CredentialsProvider.getToken() }`;
        }
        return true;
    } );

    sharedConfigurePreExecuteMethod( async ( req ) => {
        if ( CredentialsProvider.isAuthenticated ) {
            req.headers['authorization'] = `Bearer ${ await CredentialsProvider.getToken() }`;
        }
        return true;
    } );
} )();