import { Button, Col, Content, Div, Label, Row, Spacer, Title, Tooltip } from '@gower/react-ui/components';
import { AppLogo, AppName } from 'lib/components/logo';
import { PropsWithChildren } from 'react';

import { AiOutlineBook, BiError, FiAlertCircle, IoMdHelpCircleOutline } from '@gower/react-ui/icons';
import { DrawerWrapper } from '@gower/react-ui/template/drawer';
import { ProfileMenu } from '@gower/react-ui/template/profile-menu';
import { ProfileCard } from '@gower/react-ui/template/profile-card';
import { useSession } from 'features/hooks/useSession';
import { useLogout } from 'features/hooks/useLogout';
import { DrawerItems, RoutesEnum } from 'app/routes';
import { useBoolean } from '@gower/react-ui/hooks';
import { QuickResponseModal } from 'lib/components/quick-response';

export type HeaderProps = {

};
export const Header: React.FC<PropsWithChildren<HeaderProps>> = ( { children } ) => {
    const { user } = useSession();
    const { logout } = useLogout();
    const quickResponseModal = useBoolean( false );

    return <>
        <Content color='white' box-shadow-1 height={64} noWrap zIndex={10}>
            <Col>
                <DrawerWrapper
                    items={DrawerItems}
                    logo={
                        <Row justifyCenter fullWidth noWrap noScroll marginLeft={-8}>
                            <Col center pr-1 >
                                <AppLogo color="surface-7" size={30} />
                            </Col>
                            <Col center paddingTop={8}>
                                <Div block textLeft>
                                    <AppName color="black" width={155} />
                                    <Title xsmall lineHeight={1.55} light letterSpacing="wide" label="Centro de Autorizaciones" />
                                </Div>
                            </Col >
                        </Row>
                    }
                    drawerFooter={
                        <Row paddingX={20} paddingY={5}>
                            <Col >
                                <Div block small textGrey>
                                    <Label p>Centro de autorizaciones</Label>
                                    {window.env?.REACT_APP_STAGE === 'pub' && <>
                                        <Label p>v{window.env?.REACT_APP_VERSION}</Label>
                                    </>}
                                    {window.env?.REACT_APP_STAGE !== 'pub' && <>
                                        <Label p>v{window.env?.REACT_APP_VERSION} ({window.env?.REACT_APP_STAGE})</Label>
                                    </>}
                                </Div>
                            </Col>
                        </Row>
                    }
                />
            </Col>
            <Col center px-2>
                <Button pa-1 borderRadiusAll={5} noneTheme text reactLink='/'>
                    <Row justifyCenter fullWidth noWrap noScroll>
                        <Col center pr-2 >
                            <AppLogo color="surface-7" size={30} />
                        </Col>
                        <Col center >
                            <Div block textLeft>
                                <AppName color="black" width={180} />
                                <Title xsmall ma-0 light letterSpacing="wide" label="Centro de Autorizaciones" />
                            </Div>
                        </Col >
                        <Col center paddingX={10} mobileHidden>
                            {window.env?.REACT_APP_STAGE !== 'pub' && <>
                                <Label p large textRed>Entorno de prueba ({window.env?.REACT_APP_STAGE})</Label>
                            </>}
                        </Col >
                    </Row>
                </Button>
            </Col>
            <Spacer noScroll height="100%" px-1>
                {children}
            </Spacer>

            <Col>
                <Row noWrap
                    xlHidden
                    lgHidden
                    mobileHidden
                >
                    <Col center gutter>
                        <Tooltip title="Contingencia">
                            <Button
                                pointer
                                noneTheme
                                text
                                normal
                                textColor="orange-6"
                                reactLink={RoutesEnum.Contingency}
                                icon={<BiError size={22} />}
                            />
                        </Tooltip>
                    </Col>
                    <Col center gutter>
                        <Tooltip title="Asistencia rápida">
                            <Button
                                pointer
                                noneTheme
                                text
                                normal
                                onClick={quickResponseModal.enable}
                                icon={<FiAlertCircle size={22} />}
                            />
                        </Tooltip>
                    </Col>
                    <Col center gutter>
                        <Tooltip title="Instructivo">
                            <Button
                                pointer
                                noneTheme
                                text
                                normal
                                //reactLink={RoutesEnum.Manual}
                                onClick={() => window.open( '/assets/manual.pdf' )}
                                icon={<AiOutlineBook size={22} />}
                            />
                        </Tooltip>
                    </Col>
                    <Col center gutter>
                        <Tooltip title="Ayuda">
                            <Button
                                pointer
                                noneTheme
                                text
                                normal
                                //reactLink={RoutesEnum.FAQ}
                                onClick={() => window.open( '/assets/faq.pdf' )}
                                icon={<IoMdHelpCircleOutline size={22} />}
                            />
                        </Tooltip>
                    </Col>

                </Row>

                <Row noWrap
                    mdHidden
                    smHidden
                    mobileHidden
                    xsHidden
                >
                    <Col center gutter>
                        <Button
                            label="Contingencia"
                            pointer
                            noneTheme
                            text
                            normal
                            textColor="orange-6"
                            reactLink={RoutesEnum.Contingency}
                            icon={<BiError size={22} />}
                        />
                    </Col>
                    <Col center gutter>
                        <Button
                            label="Asistencia rápida"
                            pointer
                            noneTheme
                            text
                            normal
                            textColor="blue-7"
                            onClick={quickResponseModal.enable}
                            icon={<FiAlertCircle size={22} />}
                        />
                    </Col>
                    <Col center gutter>
                        <Button
                            label="Instructivo"
                            pointer
                            noneTheme
                            text
                            normal
                            //reactLink={RoutesEnum.Manual}
                            onClick={() => window.open( '/assets/manual.pdf' )}
                            icon={<AiOutlineBook size={22} />}
                        />
                    </Col>
                    <Col center gutter>
                        <Button
                            label="Ayuda"
                            pointer
                            normal
                            noneTheme
                            text
                            //reactLink={RoutesEnum.FAQ}
                            onClick={() => window.open( '/assets/faq.pdf' )}
                            icon={<IoMdHelpCircleOutline size={22} />}
                        />
                    </Col>

                </Row>
            </Col>

            <Col width="5%" mobileHidden xsHidden />
            <Col fullHeight>
                <ProfileMenu
                    user={user.getJson() as any}
                    logout={logout}
                    myProfileLink={RoutesEnum.Profile}
                />
            </Col>
        </Content>
        <QuickResponseModal isOpen={quickResponseModal.Actived} onClose={quickResponseModal.disable} />
    </>;
};

