import { Professional, Authorization } from '@gower/cm-mdp-sdk/authorizations';
import { ProfessionalsService } from '@gower/cm-mdp-sdk/src/lib/Authorizations/services';
import { expose, MutableStore, ViewStatus } from '@gower/react-ui/store';

export class VerifyProfesionalModalStore extends MutableStore {

    public Status = ViewStatus.new( 'pending' );

    public SearchText = '';
    public lastErrors: string[] = [];
    public Professional: Professional = null;
    public Agreement: InstanceType<typeof Authorization.VO.Agreement>;
    private professionalsService: ProfessionalsService;

    async search () {

        if ( !this.SearchText ) return;

        this.Status.setLoading();
        const value = await this.professionalsService.find( { license: this.SearchText } );
        if ( value ) {
            this.Professional = value;
            this.Status.setOk();
        }
        else {
            this.lastErrors = this.professionalsService.LastErrors || [];
            this.Status.setErrored();
        }

        this.SearchText = '';
    }

    onReceiveProps ( props ) {

        const agreementType = props?.agreement;
        if ( agreementType && agreementType !== this.Agreement?.Id ) {
            this.SearchText = '';
            this.lastErrors = [];
            this.Professional = null;
            this.Agreement = Authorization.VO.Agreement.toType( agreementType );
            this.professionalsService = new ProfessionalsService( this.Agreement );
            this.Status.setPending();
        }

    }

}

export const { provider, useStore } = expose( VerifyProfesionalModalStore );