import React from 'react';
import { Chip, Div, Button, Col, Content, Spacer } from '@gower/react-ui/components';
import { AiFillCloseCircle } from '@gower/react-ui/icons';

import { Step1Form } from './step-1-form';
import { Step2Form } from './step-2-form';
import { Step3Form } from './step-3-form';
import { HeaderLogin } from 'lib/components/header-login';
import { context, provider } from './store';


const steps = {
    1: Step1Form,
    2: Step2Form,
    3: Step3Form,
};

export const ActivateUserView = provider( () =>
{
    return <>
        <Content fullHeight fullWidth scroll justifyCenter>
            <Col
                center
                width="auto"
                maxWidth={400}
                minWidth={350}
                minHeight={400}
                maxHeight="90%"
                useMediaQuery
                xsWidth="100%"
                xsMaxWidth="100%"
                xsHeight="100%"
                xsMaxHeight="100%"
                white
                box-shadow-1
                py-14
                px-8
            >
                <ActivateUserForm />
            </Col>
        </Content>
    </>;
} );

const ActivateUserForm = () =>
{
    const store = React.useContext( context ).current;
    const Step = steps[store.currentStep];

    return <>
        <Content columns>
            <HeaderLogin title="Activar nuevo usuario" />
            <Col fullWidth py-3 noScroll show={store.currentStep > 1}>
                <Div mx-auto display='block' >
                    <Chip
                        minWidth={120}
                        ellipsis
                        textGrey
                        xxsmall
                        color="transparent"
                        borderAll={1}
                        boxShadow='none'
                        label={store.userEmail}
                        borderRadiusAll={8}
                        paddingX={6}
                        contentRight={
                            <Button text pa-0 size={20} pointer textGrey label={<AiFillCloseCircle size={18} />} onClick={() => store.restart()} />
                        }
                    />
                </Div>
            </Col>
            <Spacer vertical>
                <Step />
            </Spacer>
        </Content>
    </>;
};
