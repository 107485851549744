
import { Col, Div, Label, Row, Spacer } from '@gower/react-ui/components';
import { useBoolean } from '@gower/react-ui/hooks';
import { AgreementLogo } from 'lib/components/agreement-logo';
import { useStore } from '../../store';

export type AuthorizationHeaderProps = {


};
export const AuthorizationHeader: React.FC<AuthorizationHeaderProps> = ( { } ) => {

    const store = useStore();
    const agreement = store.Agreement;

return <>
        <Row noWrap>
            <Spacer>
                <Row noWrap>
                    <Col center padding={10}>
                        <AgreementLogo agreement={agreement?.Id} height="40px" width="60px" />
                    </Col>
                    <Spacer center padding={10}>
                        <Div block>
                            <Label p bold>{agreement?.Description}</Label>
                            <Label p>{agreement?.Name}</Label>
                        </Div>
                    </Spacer>
                </Row>
            </Spacer>
        </Row>
    </>;
};