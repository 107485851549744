import { createTheme } from '@mui/material/styles';
import { red } from '@mui/material/colors';
import { theme as gsTheme } from '@gower/react-ui/components';

// A custom theme for this app
const theme = createTheme( {
  palette: {
    primary: {
      main: '#1A803F',
      //main: '#556cd6',
    },
    secondary: {
      main: '#19857b',
    },
    error: {
      main: red.A400,
    },
  },
} );

export default theme;

gsTheme.setColors( {
  primary: '#3A8852'
} );
gsTheme.setButtonProps( {
  base: {
    variant: 'flat',
    borderRadius: 5
  },
  action: {
    border: true,
    borderColor: 'surface-5',
    //    color: 'primary',
  },
  cancel: {

  },
  error: {
    textColor: 'red-7',
  },
} );

gsTheme.setTextInputProps( {

} );

gsTheme.setTextAreaInputProps( {
} );

