import type { AutoCompleteProps } from '@gower/react-ui/components';
import type { FilterKey } from '@gower/react-ui/finder';
import type { ListStore } from './sided-crud-store';

export const getListFilterProps = ( store: ListStore<any> ) =>
{
    return {
        filterProps: ( filterKey: string ): AutoCompleteProps =>
        {
            const filter: FilterKey = store.Filters.Values[filterKey];

            if ( filter )
                return {
                    small: true,
                    select: true,
                    strict: true,
                    variant: 'borderless',
                    prefix: `${ filter.TITLE }:`,
                    options: filter.Options,
                    value: filter.Selected,
                    onChange: ( e, option ) => store.setFilter( filter.KEY, option )
                };
        }
    };
};
