import { Button, Col, Render, Row } from '@gower/react-ui/components';
import { ContextTemplatesProps, TemplatesProps } from '../list.props';
import { getActions } from '../list.actions';

export type TablePaginationProps = TemplatesProps & ContextTemplatesProps & {
    
};
export const TablePagination: React.FC<TablePaginationProps> = ( {  ...props } ) =>
{
    const { store } = props;

    if ( props.hidePagination ) return null;

    return <>
        {props.paginationTemplate && <Render content={props.paginationTemplate} props={{ ...props.injectProps, ...store.getProps(), ...getActions( props ) }} />}
        {!props.paginationTemplate && <>
            <Row fullWidth justifyCenter margin={5} padding={10} show={store.Pagination.CanNext()}>
                <Col center>
                    <Button
                        normal
                        text
                        letterSpacing="wide"
                        label="Cargar más"
                        loading={store.isLoadingMore}
                        onClick={() => store.findMore()}
                    />
                </Col>
            </Row>
        </>}
    </>;

};