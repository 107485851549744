import React from 'react';
import { Label, VerticalCenter } from '@gower/react-ui/components';
import ReactDataSheet from '@gower/react-ui/datasheet';
import type { AuthorizationStore } from '../../store';
import type { AuthorizationRequestItem } from '@gower/cm-mdp-sdk/src/lib/AuthorizationRequests/services';

export type CellProps = {
    item: AuthorizationRequestItem,
    context: AuthorizationStore,
};

export const Viewer: React.FC<CellProps & ReactDataSheet.DataEditorProps<any, CellProps>> = ( { item, context, ...props } ) => {
    if ( !item.isValid ) return null;
    
    return (
        <VerticalCenter justifyEnd>
            <Label p bold show={item.TotalAmount > 0}>{item.TotalAmount?.toCurrency()}</Label>
            <Label p bold show={!item.TotalAmount}>-</Label>
        </VerticalCenter>
    );

};

export const Header: React.FC<CellProps> = ( { context, ...props } ) => {
    return (
        <VerticalCenter textBlack justifyCenter xsmall noSelect textVerticalCenter gutter>
            Imp. Copago
        </VerticalCenter>
    );
};