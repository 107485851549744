import { theme, MediaQuery } from '@gower/react-ui/components';
import { provider } from './store';
import { AuthorizationDetailsDesktop } from './desktop';
import { AuthorizationDetailsMobile } from './mobile';

export const AuthorizationDetailsView = provider( () => {
    return <>
        <MediaQuery minWidth={theme.current.Breakpoints.XsMin}>
            <AuthorizationDetailsDesktop />
        </MediaQuery>
        <MediaQuery
            maxWidth={theme.current.Breakpoints.MobileMax}

        >
             <AuthorizationDetailsMobile /> 
        </MediaQuery>
    </>;
}, {  } );
