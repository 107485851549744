import React from 'react';
import { Col, Render, Row, SearchInput, Spacer } from '@gower/react-ui/components';
import { ContextTemplatesProps, TemplatesProps } from '../list.props';
import { getActions } from '../list.actions';

export type HeaderActionsProps = TemplatesProps & ContextTemplatesProps & {

};
export const HeaderActions: React.FC<HeaderActionsProps> = ( { ...props } ) =>
{
    const { store } = props;
    const { search } = getActions( props );

    if ( props.hideActions ) return null;


    return <>
        {props.actionsTemplate && <Render content={props.actionsTemplate} props={{ ...props.injectProps, ...store.getProps(), ...getActions( props ) }} />}
        {!props.actionsTemplate && <>
            <Col paddingY={5} cols={12} disabled={store.Status.isLoading}>
                <Row fullWidth>
                    <Spacer />
                    <Render content={props.actionsContent} props={{ ...props.injectProps, ...store.getProps(), ...getActions( props ) }} />
                    <Col center gutter cols={3} hidden={props.hideSearchInput}>
                        <SearchInput
                            disabled={store.Status.isLoading}
                            defaultValue={store.Filters.Values.SearchText}
                            onSearch={( text ) =>
                            {
                                search( text );
                            }}
                        />
                    </Col>
                </Row >
            </Col >
        </>}
    </>;
};
