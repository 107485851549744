import { MessageBox } from '@gower/react-ui/message-box';
import { EditManager, expose, MutableStore, SubStore, ViewStatus } from '@gower/react-ui/store';
import { ErrorManager } from '@gower/react-ui/utils';
import { User } from '@gower/shared-sdk/core/users';
import CredentialProvider from 'app/credential-provider';

export class ProfileStoreSubstore extends SubStore<ProfileStore>{

    protected get Item () { return this.MainStore.Item; }
}

class ProfileStore extends MutableStore {

    public readonly Status = ViewStatus.new( 'loading' );
    public Item: User = null;

    async onAfterRender () {
        
        const userId = CredentialProvider.getUserId();
        this.Status.setLoading();

        if ( userId ) {
            const user = await User.findById( userId );
            if ( user ) {
                this.Item = user;
                this.Status.setOk();
            }
        }

        if ( !this.Item ) {
            this.Status.setEmpty();
            MessageBox.Accept( 'Usuario no existe' );
        }
    }
}

export const { useStore, provider } = expose( ProfileStore );