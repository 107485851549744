import React from 'react';
import { Button, Col, Div, Divider, Label, Row, Spacer, TextInput } from '@gower/react-ui/components';
import { expose } from '@gower/react-ui/store';
import { ConfigurationSubStore } from '../store';
import { Validators } from '@gower/utils';

class QuickReponseSubStore extends ConfigurationSubStore {

    onAfterRender () {

    }
}

const { useStore, provider } = expose( QuickReponseSubStore );

export const GsPrecriptionsConfiguration = provider( () => {

    const store = useStore();
    const { Data } = store;
    const { gs_prescriptions } = Data;

    return <>

        <Row paddingBottom={10}>
            <Col gutter center cols={12}>
                <Label p bold normal>Receta Digital</Label>
            </Col>
            <Col cols={12}>
                <Divider />
            </Col>
        </Row>

        <Row>
            <Col gutter center cols={6}>
                <Div block>
                    <Label p bold xxsmall>Código de cliente</Label>
                </Div>
            </Col>
            <Col gutter center cols={6}>
                <TextInput
                    filled
                    value={gs_prescriptions.client_id}
                    onChangeText={t => gs_prescriptions.client_id = t}
                    placeholder="Código de cliente"
                    readOnly={!store.Status.isOk}
                />
            </Col>
        </Row>

        <Row>
            <Col gutter center cols={6}>
                <Div block>
                    <Label p bold xxsmall>Callback URL</Label>
                </Div>
            </Col>
            <Col gutter center cols={6}>
                <TextInput
                    filled
                    value={gs_prescriptions.callback}
                    onChangeText={t => gs_prescriptions.callback = t}
                    placeholder="Callback"
                    readOnly={!store.Status.isOk}
                />
            </Col>
        </Row>
      
    </>;
} );
 