import React from 'react';
import { Button, Checkbox, Col, Div, Divider, Label, Row, Spacer, TextInput } from '@gower/react-ui/components';
import { expose } from '@gower/react-ui/store';
import { ConfigurationSubStore } from '../store';
import { Validators } from '@gower/utils';

class UnregisteredPrecriptionsConfigurationSubStore extends ConfigurationSubStore {

    onAfterRender () {

    }
}

const { useStore, provider } = expose( UnregisteredPrecriptionsConfigurationSubStore );

export const UnregisteredPrecriptionsConfiguration = provider( () => {

    const store = useStore();
    const { Data } = store;
    const { unregistered_prescriptions } = Data;

    return <>

        <Row paddingBottom={10}>
            <Col gutter center cols={12}>
                <Label p bold normal>Receta Digital para convenios no registrados</Label>
            </Col>
            <Col cols={12}>
                <Divider />
            </Col>
        </Row>

        <Row>
            <Col gutter center cols={6}>
                <Div block>
                    <Label p bold xxsmall>Estado</Label>
                </Div>
            </Col>
            <Col gutter center cols={6}>
                <Checkbox
                    value={unregistered_prescriptions.enabled}
                    onCheck={value => unregistered_prescriptions.enabled = value}
                    label="Activado"
                    disabled={!store.Status.isOk}
                />
            </Col>
        </Row>
    </>;
} );
