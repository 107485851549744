import { Specialty, SpecialtyDTO } from '@gower/cm-mdp-sdk/specialties';
import { Content, Row, Col, TextInput, Label, Div, Spacer, Button, Divider, Code, Chip, Autocomplete } from '@gower/react-ui/components';
import { useBoolean } from '@gower/react-ui/hooks';
import { FormInput } from '@gower/react-ui/template/form';
import { User } from '@gower/shared-sdk/core/users';
import React from 'react';
import { provider, useStore } from './store';


export const UsersCreateView = provider( () => {

    const store = useStore();
    const { EditItem } = store;

    return <>
        <Content columns scrollOnlyVertical>
            <Col>
                <Row padding={15}>
                    <Col gutter center>
                        <Div padding={10} >
                            <Label xxsemi p bold marginBottom={5}>Crear nuevo usuario</Label>
                            <Label xsmall p textGrey>Configurá y editá los datos del nuevo usuario.</Label>
                        </Div>
                    </Col>
                    <Spacer />
                </Row>
            </Col>
            <Spacer>
                <Content start padding={20} justifyCenter>
                    <Col start maxWidth={800} paddingX={20} paddingBottom={100}>

                        <Row>
                            <Col gutter center cols={6}>
                                <Div block>
                                    <Label p bold xxsmall>Nickname</Label>
                                    <Label p textGrey xsmall>Se usará para iniciar sesión</Label>
                                </Div>
                            </Col>
                            <Col gutter center cols={6}>
                                <TextInput
                                    filled
                                    value={EditItem.Nickname}
                                    onChangeText={t => EditItem.Nickname = t}
                                    placeholder="Nickname de usuario"
                                    readOnly={!store.Status.isOk}
                                    hint="Minimo 4 carácteres"
                                    error={store.ErrorManager.getNickname()}
                                />
                            </Col>
                        </Row>

                        <Row>
                            <Col gutter center cols={6}>
                                <Div block>
                                    <Label p bold xxsmall>Grupo</Label>
                                    <Label p textGrey xsmall>Tipo de usuario</Label>
                                </Div>
                            </Col>
                            <Col gutter center cols={6}>
                                <Autocomplete
                                    filled
                                    placeholder="Grupo del usuario"
                                    options={User.VO.Group.getOptions()}
                                    value={store.EditItem.Group}
                                    onChange={( e ) => store.EditItem.Group = e.target.value}
                                    readOnly={!store.Status.isOk}
                                    errorMessage={store.ErrorManager.getEmail()}
                                />
                            </Col>
                        </Row>

                        <FormInput
                            title="Especialidad"
                            subTitle="Especialidad del usuario"
                        >
                            <Autocomplete
                                filled
                                select
                                selectedAndItemTemplate={( option ) => (
                                    <Label>{option.Name}</Label>
                                )}
                                asyncFindAll={() => Specialty.find( {}, null, { all: true } )}
                                value={store.Specialty}
                                onChange={( e, o: Specialty ) => {
                                    store.Specialty = o?.toDTO() || new SpecialtyDTO();
                                }}
                                placeholder="Especialidad"
                                readOnly={!store.Status.isOk}
                            />
                        </FormInput>

                        <Row>
                            <Col gutter center cols={6}>
                                <Div block>
                                    <Label p bold xxsmall>Email</Label>
                                    <Label p textGrey xsmall>Correo electrónico del usuario</Label>
                                </Div>
                            </Col>
                            <Col gutter center cols={6}>
                                <TextInput
                                    filled
                                    value={EditItem.Email}
                                    onChangeText={t => EditItem.Email = t}
                                    placeholder="Email"
                                    readOnly={!store.Status.isOk}
                                    hint="Ingresá un email"
                                    error={store.ErrorManager.getEmail()}
                                />
                            </Col>
                        </Row>

                        <Row>
                            <Col gutter center cols={6}>
                                <Div block>
                                    <Label p bold xxsmall>Nombre</Label>
                                    <Label p textGrey xsmall>El nombre del usuario</Label>
                                </Div>
                            </Col>
                            <Col gutter center cols={6}>
                                <TextInput
                                    filled
                                    value={EditItem.Name}
                                    onChangeText={t => EditItem.Name = t}
                                    placeholder="Nombre del usuario"
                                    readOnly={!store.Status.isOk}
                                    error={store.ErrorManager.getName()}
                                />
                            </Col>
                        </Row>

                        <Row>
                            <Col gutter center cols={6}>
                                <Div block>
                                    <Label p bold xxsmall>Apellido</Label>
                                    <Label p textGrey xsmall>El apellido del usuario</Label>
                                </Div>
                            </Col>
                            <Col gutter center cols={6}>
                                <TextInput
                                    filled
                                    value={EditItem.Lastname}
                                    onChangeText={t => EditItem.Lastname = t}
                                    placeholder="Apellido del usuario"
                                    readOnly={!store.Status.isOk}
                                />
                            </Col>
                        </Row>

                        <Row>
                            <Col gutter center cols={6}>
                                <Div block>
                                    <Label p bold xxsmall>Celular</Label>
                                    <Label p textGrey xsmall>Celular del usuario</Label>
                                </Div>
                            </Col>
                            <Col gutter center cols={6}>
                                <TextInput
                                    filled
                                    value={EditItem.Cellphone}
                                    onChangeText={t => EditItem.Cellphone = t}
                                    placeholder="Teléfono celular"
                                    readOnly={!store.Status.isOk}
                                />
                            </Col>
                        </Row>

                        <Row>
                            <Col gutter center cols={6}>
                                <Div block>
                                    <Label p bold xxsmall>Teléfono</Label>
                                    <Label p textGrey xsmall>Teléfono laboral del usuario</Label>
                                </Div>
                            </Col>
                            <Col gutter center cols={6}>
                                <TextInput
                                    filled
                                    value={EditItem.Telephone}
                                    onChangeText={t => EditItem.Telephone = t}
                                    placeholder="Teléfono laboral"
                                    readOnly={!store.Status.isOk}
                                />
                            </Col>
                        </Row>

                        <LicensesList />

                        <Row gutter>
                            <Divider />
                        </Row>

                        <Row>
                            <Col gutter center cols={6}>
                                <Div block>
                                    <Label p bold xxsmall>Contraseña</Label>
                                    <Label p textGrey xsmall>Establece la primera contraseña del usuario</Label>
                                </Div>
                            </Col>
                            <Col gutter center cols={6}>
                                <TextInput
                                    filled
                                    password
                                    autoComplete="new-password"
                                    value={store.Password1}
                                    onChangeText={t => store.Password1 = t}
                                    placeholder="Password"
                                    readOnly={!store.Status.isOk}
                                    error={store.ErrorManager.getPassword()}
                                    hint="Minimo 6 carácteres."
                                />
                            </Col>
                        </Row>

                        <Row>
                            <Col gutter center cols={6}>
                                <Div block>
                                    <Label p bold xxsmall>Confirmar contraseña</Label>
                                    <Label p textGrey xsmall>Repite la contraseña del usuario</Label>
                                </Div>
                            </Col>
                            <Col gutter center cols={6}>
                                <TextInput
                                    filled
                                    password
                                    autoComplete="new-password"
                                    value={store.Password2}
                                    onChangeText={t => store.Password2 = t}
                                    placeholder="Password"
                                    readOnly={!store.Status.isOk}
                                />
                            </Col>
                        </Row>


                        <Row justifyEnd >
                            <Col gutter hidden>
                                <Button
                                    actionTheme
                                    disabled={!store.Status.isOk}
                                    label="Cancelar"
                                />
                            </Col>
                            <Col gutter >
                                <Button
                                    primary
                                    disabled={store.Status.isLoading}
                                    loading={store.Status.isSaving}
                                    onClick={() => store.save()}
                                    label="Guardar usuario"
                                />
                            </Col>
                        </Row>

                    </Col>



                </Content>
            </Spacer>
        </Content>
    </>;
}, { keepAlive: false } );

const LicensesList = () => {

    const store = useStore();
    const loading = useBoolean();
    const [value, setValue] = React.useState( '' );
    const { EditItem } = store;

    async function add () {
        loading.enable();
        await store.addLicense( value );
        setValue( '' );
        loading.disable();
    }

    async function remove ( item ) {
        loading.enable();
        await store.removeLicense( item.License );
        setValue( '' );
        loading.disable();
    }

    return <>
        <Row rows>
            <Col gutter center cols={6}>
                <Div block>
                    <Label p bold xxsmall>Acceso a prestadores</Label>
                    <Label p textGrey xsmall>Ingresá matrículas habilitadas y pulsa Enter</Label>
                </Div>
            </Col>
            <Col gutter center cols={6}>
                <TextInput
                    filled
                    value={value}
                    onChangeText={setValue}
                    placeholder="# # # # # #"
                    readOnly={!store.Status.isOk}
                    loading={loading.value}
                    onEnterUp={add}
                    contentRight={(
                        <Button
                            small
                            text
                            pointer
                            bold
                            label="Agregar"
                            disabled={!value || loading.value}
                            onClick={add}
                        />
                    )}
                />
            </Col>
            <Col gutter start cols={12} minHeight={100}>
                <Div block textCenter padding={20} color="surface-2" show={!store.Licenses.length}>
                    <Label textGrey xxsmall>Agrega prestadores habilitados aquí</Label>
                </Div>
                {store.Licenses.map( ( item, idx ) => ( <>
                    <Row padding={5} noSelect hoverEffect key={idx}>
                        <Col gutter center>
                            <Div block>
                                <Label p xxsmall bold>{item.License}</Label>
                                <Label p xsmall bold>{item.Name}</Label>
                            </Div>
                        </Col>
                        <Spacer />
                        <Col gutter center>
                            <Button
                                pointer
                                bold
                                textRed
                                text
                                noneTheme
                                label="Eliminar"
                                onClick={() => remove( item )}
                            />
                        </Col>
                    </Row>
                </> ) )}
            </Col>
        </Row>
    </>;
};