import dayjs from 'dayjs';
import { News } from '@gower/cm-mdp-sdk/news';
import Event from 'typescript.events';

type NewsProviderObjectCache = {
    general: any[];
    agreement: any[];
};

export class NewsProviderStore extends Event {

    public get SecureStore () { return window.localStorage; }


    private generalNews: News[] = [];
    private unregisteredPrescriptionNews : News[] = [];
    private agreementNews: News[] = [];

    public get GeneralNews () { return this.generalNews; }
    public get UnregisteredPrescriptionNews () { return this.unregisteredPrescriptionNews ; }
    public get AgreementNews () { return this.agreementNews; }

    public getAgreementNews ( agreementId: string ) {
        return this.agreementNews.filter( item => item.Agreement?.Id === agreementId );
    }

    public async refresh ( force?: boolean ) {

        if ( !force ) {
            force = this.checkForcedReload();
        }

        if ( !force ) {
            force = this.checkLastUpdated();
        }

        if ( !force ) {
            const restored = this.restoreCache();
            if ( !restored ) {
                await this.find();
            }
        } else {
            await this.find();
        }
    }

    private async find () {
        const news = await News.find( { status: News.VO.Status.Active.Id }, null, { all: true } );
        if ( news ) {

            this.generalNews = news.items.filter( item => item.Type.isGeneral );
            this.unregisteredPrescriptionNews = news.items.filter( item => item.Type.isUnregisteredElectronicPrescriptions );
            this.agreementNews = news.items.filter( item => item.Type.isAgreement );
            this.saveCache();
        }
    }

    private checkLastUpdated () {
        const lastTimestamp = Number( this.SecureStore.getItem( 'News.LastUpdated' ) );
        if ( lastTimestamp > 0 && !Number.isNaN( lastTimestamp ) ) {

            const date = dayjs( lastTimestamp + 1000 );

            if ( dayjs().diff( date, 'minutes' ) > 5 ) {
                return true;
            }
        }
    }

    private checkForcedReload () {
        const reload = this.SecureStore.getItem( 'News.Reload' );
        if ( reload === '1' ) {
            return true;
        }
    }

    public activeForcedReload () {
        this.SecureStore.setItem( 'News.Reload', '1' );
    }

    public disableForcedReload () {
        this.SecureStore.setItem( 'News.Reload', '0' );
    }

    private restoreCache () {

        const data = this.SecureStore.getItem( 'News.Latest' );
        if ( data ) {
            const json = atob( data.substring( 1, data.length ) );
            if ( json ) {
                try {

                    const obj: NewsProviderObjectCache = JSON.parse( json );

                    if ( Array.isArray( obj.general ) ) {
                        this.generalNews = obj.general?.map( item => new News( item ) ) || [];
                    }

                    if ( Array.isArray( obj.agreement ) ) {
                        this.agreementNews = obj.agreement?.map( item => new News( item ) ) || [];
                    }

                    return true;

                } catch ( error ) {

                }
            }
        }

    }

    async clear () {
        this.SecureStore.removeItem( 'News.LastUpdated' );
        this.SecureStore.removeItem( 'News.Latest' );
    }

    private saveCache () {

        const newsObject = {
            general: this.generalNews.map( item => item.getJson() ),
            agreement: this.agreementNews.map( item => item.getJson() ),
        };

        const newsCache = `n${ btoa( JSON.stringify( newsObject ) ) }`;
        this.SecureStore.setItem( 'News.LastUpdated', new Date().getTime().toString() );
        this.SecureStore.setItem( 'News.Latest', newsCache );
    }

}

export const NewsProvider = new NewsProviderStore();

