import { Row, Col, Render } from '@gower/react-ui/components';
import { Loader } from '@gower/react-ui/template/loader';
import { ContextTemplatesProps, TemplatesProps } from '../list.props';
import { TableItem } from './table-item';
import { TablePagination } from './table-pagination';
import { TableLoader } from './table-loader';
import { getActions } from '../list.actions';

export type TableProps = TemplatesProps & ContextTemplatesProps & {

};
export const Table: React.FC<TableProps> = ( { ...props } ) =>
{
    const { store } = props;

    if ( props.hideTable ) return null;

    return <>
        {props.tableTemplate && <Render content={props.tableTemplate} props={{ ...props.injectProps, ...store.getProps() , ...getActions( props )}} />}
        {!props.tableTemplate && <>
            <Col start fullWidth>
                <Row paddingBottom={100}>
                    <TableLoader {...props}>
                        {store.Items.map( ( item, index ) => ( <TableItem key={index} {...props} item={item} /> ) )}
                        <TablePagination {...props} />
                    </TableLoader>
                </Row>
            </Col>
        </>}
    </>;
};