import { Authorization } from '@gower/cm-mdp-sdk/authorizations';
import { Button, Col, Content, Div, Label, ModalWindow, Row, SearchInput, Spacer } from '@gower/react-ui/components';
import { AiFillInfoCircle } from '@gower/react-ui/icons';
import { provider, useStore } from './store';
export type VerifyProfessionalModalProps = {
    agreement: string,
    isOpen: boolean;
    onClose: () => void;
};
export const VerifyProfessionalModal: React.FC<VerifyProfessionalModalProps> = provider( ( { isOpen, onClose } ) => {

    const store = useStore();

    function close () {

        store.Status.setOk();
        store.Professional = null;
        onClose && onClose();
    }

    return <>
        <ModalWindow
            variant="modal"
            overlayProps={{
                top: 0,
                left: 0,
                position: 'fixed',
                width: "100vw",
                height: "100vh",
                zIndex: 10,
                onClick: close
            }}
            isOpen={isOpen}
            onClose={close}
            title={`Verificar prestador - ${ store.Agreement?.Description }`}

        >
            <Row
                fullWidth
                padding={20}
                maxWidth={1000}
                maxHeight={400}
                height="100%"
            >
                <Content columns>
                    <Col gutter>
                        <Row justifyEnd>
                            <Spacer center gutter>
                                <SearchInput
                                    large
                                    autoFocus
                                    prefix="Matrícula:"
                                    clearable={false}
                                    readOnly={store.Status.isLoading}
                                    placeholder="Ingresá tu búsqueda"
                                    value={store.SearchText}
                                    onChangeText={t => store.SearchText = t}
                                    onSearch={() => store.search()}
                                />
                            </Spacer>
                            <Col center gutter>
                                <Button
                                    loading={store.Status.isLoading}
                                    actionTheme
                                    primary
                                    label="Consultar"
                                    onClick={() => store.search()}
                                />
                            </Col>
                        </Row>
                    </Col>

                    <Spacer vertical gutter show={store.Status.isPending || store.Status.isLoading}>
                        <Content
                            textColor="blue-7"
                            color="surface-1"
                            rounded
                            padding={10}
                        >
                            <Col fullWidth center hidden={store.Status.isLoading}>
                                <Row justifyCenter>
                                    <Col center padding={5}>
                                        <AiFillInfoCircle />
                                    </Col>
                                    <Col center>
                                        <Label bold normal>Ingresá un número de matricula o profesional para validar su estado.</Label>
                                    </Col>
                                </Row>
                            </Col>
                        </Content>
                    </Spacer>

                    <Col gutter show={store.Professional && store.Status.isOk}>
                        <Row>
                            <Spacer center>
                                <Div block>
                                    <Label p xxsmall label={`MAT: ${ store.Professional?.License }`} />
                                    <Label p normal label={store.Professional?.Name} />
                                </Div>
                            </Spacer>
                            <Col center>
                                <Div block>
                                    <Label textGreen bold normal>Prestador habilitado</Label>
                                </Div>
                            </Col>
                        </Row>
                    </Col>

                    <Col gutter show={store.Status.isErrored}>
                        <Row justifyCenter>
                            <Col center>
                                <Div block>
                                    {store.lastErrors.map( ( text, key ) => <Label key={key} p textRed bold normal>{text}</Label> )}
                                </Div>
                            </Col>
                        </Row>
                    </Col>
                </Content>
            </Row>
        </ModalWindow>
    </>;

} );