import React from 'react';
import { ErrorManager } from '@gower/react-ui/utils';
import { Content, Row, Col, TextInput, Label, Div, Spacer, Button, Divider, Code, Chip, Autocomplete, Checkbox } from '@gower/react-ui/components';
import { MessageBox } from '@gower/react-ui/message-box';
import { DataGrid, DataGridColumn, DataGridTemplateProps } from '@gower/react-ui/template/data-grid';
import { Notifications } from '@gower/react-ui/notifications';
import { expose, ViewStatus } from '@gower/react-ui/store';
import { Wait } from '@gower/utils';
import { AgreementConfigsSubStore } from '../../store';
import { AgreementConfig } from '@gower/cm-mdp-sdk/agreement-config';
import { Specialty } from '@gower/cm-mdp-sdk/specialties';
import { FormInput } from '@gower/react-ui/template/form';

type SpecialtyItem = { blocked: boolean; specialty: Specialty; };
class AgreementConfigsEditorStore extends AgreementConfigsSubStore {

    public Status = ViewStatus.new( 'pending' );
    public EditItem = new AgreementConfig();
    public Specialities: SpecialtyItem[] = [];
    public ErrorManager = ErrorManager.new( {
        fields: ['status']
    } );

    async onAfterRender () {
        this.Status.setPending();
        this.EditItem = AgreementConfig.clone( this.MainStore.Selected );

        const specs = await Specialty.find( {}, null, { all: true } );
        if ( specs ) {
            this.Specialities = specs.items.map( item => ( {
                blocked: this.EditItem.BlockedSpecialities.some( id => id === item.Id ),
                specialty: item,
            } ) );
        }

        if ( this.EditItem )
            this.Status.setOk();
    }

    public checkErrors () {

        this.ErrorManager.clear();
        if ( !this.EditItem.Status || this.EditItem.Status.isUnspecified ) this.ErrorManager.setStatus( 'Completá este campo' );

        return !this.ErrorManager.hasErrors();
    }


    toggleSpecialty ( active: boolean, item: SpecialtyItem ) {

        if ( active ) {
            item.blocked = false;
            this.EditItem.BlockedSpecialities.remove( ( id ) => id === item.specialty.Id );
        } else {
            item.blocked = true;
            this.EditItem.BlockedSpecialities.push( item.specialty.Id );
        }
    }

    async save () {

        if ( this.checkErrors() ) {
            this.Status.setSaving();

            const result = await this.EditItem.save();
            if ( result.result ) {

                Notifications.Saved();
                await Wait( 1000 );
                this.MainStore.Selected.refresh( this.EditItem.getJson() );
                this.MainStore.list();
            } else {
                await MessageBox.Error( result.errorList );
            }

            this.Status.setOk();
        }
    }

    async close () {
        this.MainStore.list();
    }
}

const { provider, useStore } = expose( AgreementConfigsEditorStore );

export const AgreementConfigsEditor = provider( () => {

    const store = useStore();
    const { EditItem } = store;

    return <>
        <Content columns scrollOnlyVertical>
            <Col>
                <Row padding={15}>
                    <Col gutter center>
                        <Div padding={10} >
                            <Label xxsemi p bold marginBottom={5}>Editar convenio</Label>
                            {store.Selected && <Label xsmall p textGrey>{store.Selected.Agreement.Description}</Label>}
                        </Div>
                    </Col>
                    <Spacer />
                    <Col center>
                        <Row justifyEnd >
                            <Col gutter>
                                <Button
                                    actionTheme
                                    disabled={!store.Status.isOk}
                                    label="Cancelar"
                                    onClick={() => store.close()}
                                />
                            </Col>
                            <Col gutter >
                                <Button
                                    primary
                                    disabled={store.Status.isLoading}
                                    loading={store.Status.isSaving}
                                    onClick={() => store.save()}
                                    label="Guardar"
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
            <Spacer>
                <Content start padding={20} justifyCenter>
                    <Col start maxWidth={800} paddingX={20} paddingBottom={100}>

                        <Row>
                            <Col gutter center cols={6}>
                                <Div block>
                                    <Label p bold xxsmall>Estado</Label>
                                    <Label p textGrey xsmall>Estado del convenio</Label>
                                </Div>
                            </Col>
                            <Col gutter center cols={6}>
                                <Autocomplete
                                    filled
                                    placeholder="Estado de la noticia"
                                    options={AgreementConfig.VO.Status.getOptions()}
                                    value={store.EditItem.Status}
                                    onChange={( e ) => store.EditItem.Status = e.target.value}
                                    readOnly={!store.Status.isOk}
                                />
                            </Col>
                        </Row>

                        <FormInput
                            title="Controles"
                            subTitle="Controles al autorizar"
                        >
                            <Checkbox
                                value={EditItem.NeedEffector}
                                onCheck={( v ) => EditItem.NeedEffector = v}
                                label="Controla Mat. Efector"
                                disabled={!store.Status.isOk}
                            />
                            <Checkbox
                                value={EditItem.NeedBilling}
                                onCheck={( v ) => EditItem.NeedBilling = v}
                                label="Controla Mat. Facturante"
                                disabled={!store.Status.isOk}
                            />
                            <Checkbox
                                value={EditItem.NeedApplicant}
                                onCheck={( v ) => EditItem.NeedApplicant = v}
                                label="Controla Mat. Solicitante"
                                disabled={!store.Status.isOk}
                            />
                            <Checkbox
                                value={EditItem.RequiredToken}
                                onCheck={( v ) => EditItem.RequiredToken = v}
                                label="Requiere token de seguridad"
                                disabled={!store.Status.isOk}
                            />
                            <Checkbox
                                value={EditItem.CheckDiagnostic}
                                onCheck={( v ) => EditItem.CheckDiagnostic = v}
                                label="Controlar diágnostico en practicas"
                                disabled={!store.Status.isOk}
                            />
                            <Checkbox
                                value={EditItem.AllowSessionsForm}
                                onCheck={( v ) => EditItem.AllowSessionsForm = v}
                                label="Permite descargar planilla de sesiones"
                                disabled={!store.Status.isOk}
                            />
                            <Checkbox
                                value={EditItem.AllowPrescriptionForm}
                                onCheck={( v ) => EditItem.AllowPrescriptionForm = v}
                                label="Permite recetarios en papel"
                                disabled={!store.Status.isOk}
                            />
                            <Checkbox
                                value={EditItem.NeedPreauthorizationCode}
                                onCheck={( v ) => EditItem.NeedPreauthorizationCode = v}
                                label="Permite código de Auditoría"
                                disabled={!store.Status.isOk}
                            />
                            <Checkbox
                                value={EditItem.NeedDelegation}
                                onCheck={( v ) => EditItem.NeedDelegation = v}
                                label="Permite código de delegación"
                                disabled={!store.Status.isOk}
                            />
                        </FormInput>

                        {window.env?.REACT_APP_GS_PRESCRIPTIONS_ENABLE === 'true' && <>

                            <Divider marginY={8} />

                            <Row>
                                <Col gutter center cols={6}>
                                    <Div block>
                                        <Label p bold xxsmall>Receta Digital</Label>
                                    </Div>
                                </Col>
                                <Col gutter start cols={6}>
                                    <Row columns>
                                        <Col>
                                            <Checkbox
                                                value={EditItem.GsPrescriptionActive}
                                                onCheck={value => EditItem.GsPrescriptionActive = value}
                                                label="Activado"
                                            />
                                        </Col>
                                        <Col paddingTop={4}>
                                            <TextInput
                                                filled
                                                value={EditItem.GsPrescriptionCode}
                                                onChangeText={t => EditItem.GsPrescriptionCode = t}
                                                prefix="Token:"
                                                placeholder="Código del convenio"
                                                readOnly={!store.Status.isOk}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </>}

                        {window.env?.REACT_APP_MEDERE_PRESCRIPTIONS_ENABLE === 'true' && <>

                            <Divider marginY={8} />

                            <Row>
                                <Col gutter center cols={6}>
                                    <Div block>
                                        <Label p bold xxsmall>Receta Digital Medere</Label>
                                    </Div>
                                </Col>
                                <Col gutter start cols={6}>
                                    <Row columns>
                                        <Col>
                                            <Checkbox
                                                value={EditItem.MederePrescriptionActive}
                                                onCheck={value => EditItem.MederePrescriptionActive = value}
                                                label="Activado"
                                            />
                                        </Col>
                                        <Col paddingTop={4}>
                                            <TextInput
                                                filled
                                                value={EditItem.MederePrescriptionCode}
                                                onChangeText={t => EditItem.MederePrescriptionCode = t}
                                                prefix="Código del convenio:"
                                                placeholder="Código del convenio"
                                                readOnly={!store.Status.isOk}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </>}
                        
                        {window.env?.REACT_APP_IOMA_PRESCRIPTIONS_ENABLE === 'true' && <>

                            <Divider marginY={8} />

                            <Row>
                                <Col gutter center cols={6}>
                                    <Div block>
                                        <Label p bold xxsmall>Receta Digital IOMA</Label>
                                    </Div>
                                </Col>
                                <Col gutter start cols={6}>
                                    <Row columns>
                                        <Col>
                                            <Checkbox
                                                value={EditItem.IOMAPrescriptionActive}
                                                onCheck={value => EditItem.IOMAPrescriptionActive = value}
                                                label="Activado"
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </>}

                        <Divider marginY={8} />

                        <Row>
                            <Col gutter center cols={12}>
                                <Div block>
                                    <Label p bold xxsmall>Especialidades habilitadas</Label>
                                </Div>
                            </Col>
                            <Col gutter center cols={12}>
                                <DataGrid
                                    height={400}
                                    scroll
                                    data={store.Specialities}
                                    searchFn={( text, data: SpecialtyItem[] ) => data.filter( item => item.specialty.Name.toLowerCase().includes( text.toLowerCase() ) )}
                                    showSearch
                                >
                                    <DataGridColumn type="CUSTOM" field="status" title="Activado"
                                        template={( { data }: DataGridTemplateProps<SpecialtyItem> ) => {
                                            return <>
                                                <Checkbox value={!data.blocked} onCheck={( v ) => store.toggleSpecialty( v, data )} />
                                            </>;
                                        }}
                                    />
                                    <DataGridColumn field="specialty.Name" title="Especialidad" />
                                </DataGrid>
                            </Col>
                        </Row>
                    </Col>
                </Content>
            </Spacer>
        </Content>
    </>;

} );