import { AgreementConfig } from '@gower/cm-mdp-sdk/agreement-config';
import { MessageBox } from '@gower/react-ui/message-box';
import { expose, MutableStore, SubStore, ViewStatus } from '@gower/react-ui/store';

export class AgreementConfigsSubStore extends SubStore<AgreementConfigsStore> {
    public get Selected () { return this.MainStore.Selected; }
}

class AgreementConfigsStore extends MutableStore {

    public Mode: 'edit' | 'list' = 'list';
    public Selected: AgreementConfig = null;
    public Items: AgreementConfig[] = [];
    public readonly Status = new ViewStatus( 'loading' );

    async onAfterRender () {

        this.Status.setLoading();
        this.Items.clear();

        const items = await AgreementConfig.getConfigs();
        if ( items.length ) {
            this.Items.push( ...items );
            this.Status.setOk();
        } else {
            this.Status.setEmpty();
        }
    }

    edit ( item: AgreementConfig ) {
        this.Selected = item;
        this.Mode = 'edit';
    }

    list () {
        this.Selected = null;
        this.Mode = 'list';
    }

    async active ( item: AgreementConfig ) {
        if ( item && !item.Status.isActive ) {
            item.Status = AgreementConfig.VO.Status.Active;
            const result = await item.save();
            if ( !result.result ) {
                await MessageBox.Error( result.errorList );
            }
        }
    }

    async disable ( item: AgreementConfig ) {
        if ( item && !item.Status.isDeactivated ) {
            item.Status = AgreementConfig.VO.Status.Deactivated;
            const result = await item.save();
            if ( !result.result ) {
                await MessageBox.Error( result.errorList );
            }
        }
    }

    async hide ( item: AgreementConfig ) {
        if ( item && !item.Status.isHidden ) {
            item.Status = AgreementConfig.VO.Status.Hidden;
            const result = await item.save();
            if ( !result.result ) {
                await MessageBox.Error( result.errorList );
            }
        }
    }
}

export const { useStore, provider } = expose( AgreementConfigsStore );