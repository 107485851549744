import { Col, Divider, Label, Row } from '@gower/react-ui/components';
import { DataGrid, DataGridColumn } from '@gower/react-ui/template/data-grid';
import { DetailField, DetailFieldVertical } from '@gower/react-ui/template/detail-field';
import React from 'react';
import { useStore } from '../../store';
import CredentialProvider from 'app/credential-provider';

export const DetailsTab: React.FC = () => {

    const store = useStore();
    const data = store.Authorization;
    const isAdministrator = CredentialProvider.isAdministrator();

    return <>
        <Row>

            <Row padding={10}>
                <Col cols={6}>
                    <DetailField title="Convenio" value={data.Agreement.Description} />
                </Col>
                <Col cols={6}>
                    <DetailField title="Fecha" value={data.AuthorizationDate.format( 'DD/MM/YYYY HH:mm' )} />
                </Col>

                <Col cols={6}>
                    <DetailField title="Usuario" value={data.CreatedUser} />
                </Col>
                <Col cols={6}>
                    <DetailField title="Estado" value={`${ data.Status.Description } (${ data.StatusDetail.Description })`} />
                </Col>
                <Col cols={6}>
                    <DetailField title="Auditoría" value={data.PreAuthorizationCode} noData={!data.PreAuthorizationCode} />
                </Col>
                <Col cols={6} show={!!data.Delegation}>
                    <DetailField title="Delegación" value={data.Delegation} noData={!data.Delegation} />
                </Col>
            </Row>

            <Divider />

            <Row padding={10}>
                <Col cols={6}>
                    <DetailField title="Afiliado" noData={!Boolean( data.Affiliate?.Id )}>
                        <Label xxsmall bold label={data.Affiliate?.Id} />
                        <Label gutter xxsmall label={data.Affiliate?.Name} />
                    </DetailField>
                </Col>
                <Col cols={6} />
            </Row>
            <Divider />
            <Row padding={10}>
                <Col cols={6}>
                    <DetailField title="Pres. Efector" noData={!Boolean( data.EffectorProfessional?.Id )}>
                        <Label xxsmall bold label={`MAT. ${ data.EffectorProfessional?.Id }`} />
                        <Label gutter xxsmall label={data.EffectorProfessional?.Name} />
                    </DetailField>
                </Col>
                <Col cols={6}>
                    <DetailField title="Pres. Solicitante" noData={!Boolean( data.ApplicantProfessional?.Id )}>
                        <Label xxsmall bold label={`MAT. ${ data.ApplicantProfessional?.Id }`} />
                        <Label gutter xxsmall label={data.ApplicantProfessional?.Name} />
                    </DetailField>
                </Col>
                <Col cols={6}>
                    <DetailField title="Pres. Facturación" noData={!Boolean( data.BillingProfessional?.Id )}>
                        <Label xxsmall bold label={`MAT. ${ data.BillingProfessional?.Id }`} />
                        <Label gutter xxsmall label={data.BillingProfessional?.Name} />
                    </DetailField>
                </Col>
            </Row>
            <Divider />
            <Row padding={10}>
                <Col cols={12}>
                    <DetailFieldVertical title="Observación" value={data.Message} noData={!data.Message} />
                </Col>
            </Row>
            <Divider />
            <Row padding={10}>
                <Col cols={6}>
                    <DetailField title="Prestaciones" noData={!Boolean( data.ServiceCodes?.length )}>
                        <Label xxsmall label={`${ String( data.ServiceCodes?.length ) } autorizada(s)`} />
                    </DetailField>
                </Col>
                <Col cols={6}>
                    <DetailField title="Importe copago">
                        <Label xxsmall label={data.CopaymentTotalAmount?.toCurrency()} />
                    </DetailField>
                </Col>
            </Row>
            <Divider />
            <Row columns paddingY={10}>
                <Col padding={15}>
                    <Label xxsmall bold label="Prestaciones" />
                </Col>
                <Col>
                    <DataGrid
                        data={data.ServiceCodes}
                        scroll
                        height={200}
                    >
                        <DataGridColumn title="Código de prestación" field="ServiceCode.Id" />
                        <DataGridColumn title="Descripción" field="ServiceCode.Name" />
                        <DataGridColumn type="NUMBER" title="Cantidad" field="Quantity" />
                        <DataGridColumn type="CURRENCY" title="Copago" field="TotalAmount" />
                    </DataGrid>
                </Col>
            </Row>

            {isAdministrator && <>
                <Divider />
                <Row columns paddingY={10}>
                    <Col padding={15}>
                        <Label xxsmall bold label="Bonos emitidos" />
                    </Col>
                    <Col>
                        <DataGrid
                            data={data.Budgets}
                            scroll
                            height={200}
                        >
                            <DataGridColumn title="Tipo" field="Type.Description" />
                            <DataGridColumn title="Código interno" field="Id" />
                        </DataGrid>
                    </Col>
                </Row>
            </>}

        </Row >
    </>;

};
