import React from 'react';
import { Chip, Col, Div, Label, Row, Spacer } from '@gower/react-ui/components';
import { ProgressSlider } from '@gower/react-ui/template/progress-slider';
import { useStore } from '../../store';
import { AgreementLogo } from 'lib/components/agreement-logo';
import { AiOutlineCalendar } from '@gower/react-ui/icons';

export const Header: React.FC = () => {

    const store = useStore();
    const data = store.Authorization;

    return <>
        <Row noWrap paddingY={10}>
            <Col center padding={10}>
                <AgreementLogo agreement={data.Agreement?.Id} height="32px" />
            </Col>
            <Spacer center gutter ellipsis>
                <Div>
                    <Label p>
                        <Label noSelect normal label="Autorización" />
                        <Label noSelect normal marginLeft={4} bold label="#" />
                        <Label normal bold show={!!data.AuthorizationId}>
                            {data.AuthorizationId}
                        </Label>
                        <Label normal bold show={!data.AuthorizationId && !!data.ReferenceId}>
                            {data.ReferenceId} (ref. provisional)
                        </Label>
                        <Label normal bold show={!data.AuthorizationId && !data.ReferenceId && !!data.LocalId}>
                            {data.LocalId} (provisional)
                        </Label>
                    </Label>
                    <Label p>
                        <Label noSelect xxsmall label={String( data.Agreement.Description )} />
                        <Label noSelect gutter xxsmall label="-" />
                        <Label noSelect xxsmall label={String( data.Agreement.Name )} />
                    </Label>
                </Div>
            </Spacer>
            <Col center gutter>
                <Row columns justifyEnd>
                    <Col textGrey paddingBottom={4}>
                        <Row justifyEnd>
                            <Col center gutter>
                                <AiOutlineCalendar size={14} />
                            </Col>
                            <Col center gutter>
                                <Label xxsmall lineHeight={0} label={data.AuthorizationDate.format( 'DD/MM/YYYY HH:mm' )} />
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        <Row justifyEnd>
                            <Col center gutter show={data.Type.isPrescription}>
                                <Chip
                                    normal
                                    textCenter
                                    blue
                                    label="Recetario"
                                />
                            </Col>
                            <Col center gutter>
                                <Chip
                                    normal={data.Type.isPrescription}
                                    xnormal={!data.Type.isPrescription}
                                    textCenter
                                    red={data.Status.isReject || data.Status.isErrored || data.Status.isCanceled}
                                    green={data.Status.isIssued}
                                    orange={data.Status.isSubmitted}
                                >
                                    {data.Status.Description}
                                </Chip>
                            </Col>
                        </Row>
                    </Col>
                </Row>

            </Col>
        </Row>
    </>;

};