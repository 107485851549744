import { Col, Label, Row } from '@gower/react-ui/components';
import { DataGrid, DataGridColumn } from '@gower/react-ui/template/data-grid';
import React from 'react';
import { useStore } from '../../store';

export const HistoryTab: React.FC = () => {

    const store = useStore();
    const history = store.AuthorizationHistory;

    return <>
        <Row columns paddingY={10}>
            <Col padding={15}>
                <Label xxsmall bold label="Historial de estado" />
            </Col>
            <Col>
                <DataGrid
                    data={history}
                    scroll
                    height={200}
                    loading={store.Status.isLoading}
                >
                    <DataGridColumn title="Fecha" field="Date" size={100}
                        template={( { data } ) => (
                            <Label>
                                {data.Date?.toStringDate( 'short', true )}
                            </Label>
                        )}
                    />
                    <DataGridColumn title="Estado" field="Status.Description" />
                    <DataGridColumn title="Detalle" field="StatusDetail.Description" />
                    <DataGridColumn title="" field="Message" />
                </DataGrid>
            </Col>
        </Row>
    </>;

};