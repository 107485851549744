import { Button, Col, Render, Row, Spacer, Title } from '@gower/react-ui/components';
import { ContextTemplatesProps, TemplatesProps } from '../view.props';
import { getActions } from '../view.actions';

export type HeaderToolbarProps = TemplatesProps & ContextTemplatesProps & {

};
export const HeaderToolbar: React.FC<HeaderToolbarProps> = ( { ...props } ) =>
{
    const { store } = props;
    const { remove, edit, restore, save } = getActions( props );

    if ( props.hideToolbar ) return null;

    return <>
        {props.toolbarTemplate && <Render content={props.toolbarTemplate} props={{ ...props.injectProps, ...store.getProps(), ...getActions( props ) }} />}
        {!props.toolbarTemplate && <>
            <Col cols={12} padding={5} color='surface-1'>
                <Row fullWidth justifyEnd>
                    <Col center>
                        {!store.Status.isLoading && !store.Status.isEmpty && <>
                            <Render
                                content={props.subTitle}
                                ifString={( { text } ) => <Title xxsmall>{text}</Title>}
                                props={{ ...props.injectProps, ...store.getProps(), ...getActions( props ) }}
                            />
                        </>}
                    </Col>
                    <Spacer >
                        <Row fullWidth disabled={!store.Status.isOk}>
                            <Spacer />
                            <Col center>
                                <Render content={props.toolbarContent} props={{ ...props.injectProps, ...store.getProps(), ...getActions( props ) }} />
                            </Col>
                            <Col center gutter hidden={props.hideEditButton || store.isEditing}>
                                <Button
                                    disabled={store.Status.isSaving || store.Status.isLoading}
                                    color="red-4"
                                    xxsmall
                                    minWidth={120}
                                    label={props.deleteButtonLabel || "Eliminar"}
                                    onClick={remove}
                                />
                            </Col>
                            <Col center gutter hidden={props.hideEditButton || store.isEditing}>
                                <Button
                                    disabled={store.Status.isSaving || store.Status.isLoading}
                                    primary
                                    xxsmall
                                    minWidth={120}
                                    label={props.editButtonLabel || "Editar"}
                                    onClick={edit}
                                />
                            </Col>
                            <Col center gutter hidden={props.hideRestorButton || !store.isEditing}>
                                <Button
                                    disabled={store.Status.isSaving || store.Status.isLoading}
                                    minWidth={120}
                                    label={props.retoreButtonLabel || "Cancelar"}
                                    onClick={restore}
                                />
                            </Col>
                            <Col center gutter hidden={props.hideSaveButton || !store.isEditing}>
                                <Button
                                    disabled={store.Status.isLoading || !store.CanSave}
                                    loading={store.Status.isSaving}
                                    primary
                                    xxsmall
                                    minWidth={120}
                                    label={props.saveButtonLabel || "Guardar"}
                                    onClick={save}
                                />
                            </Col>
                        </Row>
                    </Spacer>
                </Row>
            </Col>
        </>}
    </>;
};