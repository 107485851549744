import React from 'react';
import { Col, Colors, TextInput, Tooltip } from '@gower/react-ui/components';
import { MdOutlineHelpOutline } from '@gower/react-ui/icons';
import { useStore } from '../../store';
import { useStatus, ViewStatus } from '@gower/react-ui/hooks';
import { Authorization } from '@gower/cm-mdp-sdk/authorizations';
import { NextFocus, prevent } from '@gower/react-ui/utils';

const InputConfig = {
    [Authorization.VO.Agreement.OSAPM.Id]: 'Nº de Doc Cred:'
};

const TokenTooltip = {
    [Authorization.VO.Agreement.SAMI.Id]: 'Ingresá el token de la App Credencial Digital SAMI',
    [Authorization.VO.Agreement.IOMA.Id]: 'Ingresá el token de seguridad',
};

type InputRef = {
    getValue: () => string,
    focus: () => void,
};

export type InputAffiliateProps = {

};
export const InputAffiliate: React.FC<InputAffiliateProps> = ( { } ) => {

    const store = useStore();
    const inputStatus = useStatus( 'ok' );
    const needSecureCode = store.needToken;
    const credentialRef = React.useRef<InputRef>();
    const tokenRef = React.useRef<InputRef>();
    const [credentialValue, setCredentialValue] = React.useState( window.env?.REACT_APP_DEFAULT_AFFILIATE || '' );
    const [tokenValue, setTokenValue] = React.useState( '' );

    async function submit ( event: any ) {

        prevent( event );

        if ( inputStatus.isLoading ) return;

        if ( needSecureCode ) {

            if ( credentialValue && tokenValue ) {
                if ( await find() )
                    event && NextFocus( '* input, * textarea' );
            } else if ( !credentialValue ) {
                event && credentialRef.current?.focus();
            } else if ( credentialValue && !tokenValue ) {
                event && tokenRef.current?.focus();
            }

        }
        else {

            if ( credentialValue ) {
                if ( await find() )
                    event && NextFocus( '* input, * textarea' );
            } else if ( !credentialValue ) {
                event && credentialRef.current?.focus();
            }
        }
    };

    async function find () {

        inputStatus.setLoading();

        if ( await store.addAffiliate( credentialValue, tokenValue ) ) {
            setCredentialValue( store.Affiliate?.Id || '' );
            inputStatus.setOk();
            return true;
        }

        clear();
        inputStatus.setOk();
    }

    function clear () {
        setCredentialValue( '' );
        setTokenValue( '' );
        store.removeAffiliate();
    };

    React.useEffect( () => {
        const listener = () => { clear(); };
        store.on( 'reset', listener );
        return () => { store.removeListener( 'reset', listener ); };
    }, [] );

    if ( store.AffiliateInputMode === 'credential' && !store.Agreement.isOSPIA && !store.Agreement.isOSPF )
        return <>
            <Col cols={12} minWidth={300} paddingY={4} center>
                <RenderAffiliateInput
                    ref={credentialRef}
                    value={credentialValue}
                    onChange={setCredentialValue}
                    inputStatus={inputStatus}
                    onClear={clear}
                    onSubmit={submit}
                />
            </Col>
            <Col
                cols={12}
                paddingY={4}
                show={needSecureCode}
            >
                <RenderSecureCodeInput
                    ref={tokenRef}
                    value={tokenValue}
                    onChange={setTokenValue}
                    inputStatus={inputStatus}
                    onClear={clear}
                    onSubmit={submit}
                />
            </Col>
            <Col cols={12} />
        </>;

    return null;
};

type RenderAffiliateInputProps = {
    onSubmit: ( event?: any ) => void;
    onClear: () => void;
    inputStatus: ViewStatus;
    value: string;
    onChange: ( t: string ) => void;
    ref?: any;
};
export const RenderAffiliateInput: React.FC<RenderAffiliateInputProps> = React.forwardRef( ( {
    onSubmit,
    onClear,
    value,
    onChange,
    inputStatus,
}, ref ) => {

    const store = useStore();
    const inputRef = React.useRef<HTMLInputElement>();
    const readOnly = !store.canAddAffiliate;
    const loading = inputStatus.isLoading;

    React.useEffect( () => {
        if ( ref ) {
            ref['current'] = {
                getValue: () => value,
                focus: () => inputRef.current?.focus(),
            };
        }
    }, [ref] );

    return (
        <TextInput
            ref={inputRef}
            large
            autoFocus
            noDelayed
            numeric
            prefix={InputConfig[store.Agreement.Id] || 'Número de afiliado:'}
            placeholder="Ingresá el socio a autorizar"
            selectAllOnFocus
            readOnly={readOnly}
            loading={loading}
            clearable={Boolean( store.Affiliate?.Id )}
            value={value}
            onChangeText={onChange}
            suffix={store.Affiliate?.Name}
            onClear={() => onClear()}
            onEscapeUp={() => onClear()}
            onEnterUp={( event ) => onSubmit( event )}
            onBlur={() => onSubmit()}
        />
    );
} );

type RenderSecureCodeInputProps = {
    onSubmit: ( event?: any ) => void;
    onClear: () => void;
    inputStatus: ViewStatus;
    value: string;
    onChange: ( t: string ) => void;
    ref?: any;
};
const RenderSecureCodeInput: React.FC<RenderSecureCodeInputProps> = React.forwardRef( ( {
    onSubmit,
    onClear,
    value,
    onChange,
    inputStatus,
}, ref ) => {

    const store = useStore();
    const inputRef = React.useRef<HTMLInputElement>();
    const readOnly = !store.canAddAffiliate || Boolean( store.Affiliate ) || inputStatus.isLoading;

    React.useEffect( () => {
        if ( ref ) {
            ref['current'] = {
                getValue: () => value,
                focus: () => inputRef.current?.focus(),
            };
        }
    }, [ref] );

    return (
        <TextInput
            ref={inputRef}
            large
            noDelayed
            numeric
            prefix="Token:"
            placeholder="# # # #"
            contentRight={
                <Col center paddingX={5} paddingTop={5}>
                    <Tooltip title={TokenTooltip[store.Agreement?.Id]}>
                        <MdOutlineHelpOutline size={20} color={Colors['primary']} />
                    </Tooltip>
                </Col>
            }
            value={value}
            onChangeText={onChange}
            selectAllOnFocus
            readOnly={readOnly}
            onClear={() => onClear()}
            onEscapeUp={() => onClear()}
            onEnterUp={( event ) => onSubmit( event )}
            onBlur={() => onSubmit()}
        />
    );
} );
