import {  CrudList, CrudCreate,CrudView } from './sided-crud-components';

export function getComponentsProps ( items: any[] )
{
    if ( !Array.isArray( items ) ) items = [items];
    const comps = {
        listProps: null,
        createProps: null,
        viewProps: null,
    };

    for ( const item of items )
    {
        if ( item?.type?.name === CrudList.name ) comps.listProps = item.props;
        if ( item?.type?.name === CrudCreate.name ) comps.createProps = item.props;
        if ( item?.type?.name === CrudView.name ) comps.viewProps = item.props;
    }

    return comps;
}