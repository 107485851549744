import { AuthenticationService } from '@gower/shared-sdk/src/lib/Core/Authentication/Session';
import CredentialProvider from 'app/credential-provider';
import { NewsProvider } from 'app/news-provider';
import { RoutesEnum } from 'app/routes';

export function useLogout () {

    async function logout () {
        AuthenticationService.get().logout();
        CredentialProvider.clear();
        NewsProvider.clear();
        window.location.href = RoutesEnum.Login;

    }

    return {
        logout
    };

}