import { MessageBox } from '@gower/react-ui/message-box';
import { expose, SubStore, MutableStore, ViewStatus } from '@gower/react-ui/store';
import { User } from '@gower/shared-sdk/core/users';

export class UserEditStoreSubstore extends SubStore<UserEditStore>{

    protected get Item () { return this.MainStore.Item; }
}

class UserEditStore extends MutableStore {

    public readonly Status = ViewStatus.new( 'loading' );
    public Item: User = null;

    async onAfterRender ( props ) {

        const userId = props?.routerParams?.id;
        this.Status.setLoading();
        const user = await User.findById( userId );
        if ( user ) {
            this.Item = user;
            this.Status.setOk();
        }

        if ( !this.Item ) {
            this.Status.setEmpty();
            MessageBox.Accept( 'Usuario no existe' );
        }

    }
}

export const { useStore, provider } = expose( UserEditStore );