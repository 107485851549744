import React from 'react';
import { Col, Label, Row, Spacer, TextAreaInput } from '@gower/react-ui/components';
import { useStore } from '../../store';
import { NextFocus, prevent } from '@gower/react-ui/utils';

export type InputMessageProps = {

};
export const InputMessage: React.FC<InputMessageProps> = ( { } ) => {

    const store = useStore();
    const readOnly = store.Status.isLoading || store.Status.isSaving;
    const loading = false;
    const [value, setValue] = React.useState( '' );

    async function submit ( event ) {
        prevent( event );
        store.Request.setMessage( value );
        if ( event ) {
            NextFocus( '* input, * textarea' );
        }
    };

    function clear () {
        store.Request.setMessage( '' );
        setValue( '' );
    };

    React.useEffect( () => {
        const listener = () => { clear(); };
        store.on( 'reset', listener );
        return () => { store.removeListener( 'reset', listener ); };
    }, [] );

    return <>
        <Row
            columns
            color="surface-1"
            borderRadius={5}
            minHeight={200}
        >
            <Col>
                <Row columns padding={8}>
                    <Col>
                        <Row noWrap height={50} noSelect>
                            <Col gutter center show={!store.needDiagnostic}>
                                <Label bold xxsmall>Observaciones (Opcional)</Label>
                            </Col>
                            <Col gutter center show={store.needDiagnostic}>
                                <Label bold xxsmall>Diágnostico (Requerido)</Label>
                            </Col>
                            <Spacer />
                        </Row>
                    </Col>
                    <Col>
                        <TextAreaInput
                            height={170}
                            large
                            noDelayed
                            prefix={store.needDiagnostic ? "Diágnostico" : "Observación:"}
                            placeholder={store.needDiagnostic ? "Ingresá el diágnostico" : "Maximo 200 carácteres"}
                            showCounter
                            maxLength={200}
                            value={value}
                            onChangeText={setValue}
                            selectAllOnFocus
                            disabled={readOnly}
                            loading={loading}
                            onClear={clear}
                            clearableOnEscape
                            onEnterUp={( event ) => submit( event )}
                            onBlur={() => submit( null )}

                        />
                    </Col>
                </Row>
            </Col>
        </Row>
    </>;
};