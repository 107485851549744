import { SecureCodes } from '@gower/shared-sdk/src/lib/Core/SecureCodes';
import { Chip, Code, Col, Content, Div, Input, Label, Spacer, Title } from '@gower/react-ui/components';
import
{
    SidedCrud,
    CrudList,
    CrudView,
    ListItemTemplate,
    ViewTemplate,

} from '../../temp/SidedCrud';
import { provider, useStore } from './store';

export const SecureCodesView = provider( () =>
{
    const store = useStore();
    return <>
        <SidedCrud
            store={store}
            title="Códigos de seguridad"
            maxWidth={900}
        >
            <CrudList
                itemContent={Item}
                hideBackButton
                hideSearchInput
                hideCreateButton
            />
            <CrudView
                title={ViewFormTitle}
                subTitle={ViewFormSubTitle}
                content={ViewForm}
                hideEditButton
            />
        </SidedCrud>
    </>;
} );

const ViewFormTitle: ViewTemplate<SecureCodes> = ( { currentItem } ) =>
{
    return (
        <Div>
            <Label p xsmall textGrey>#{currentItem.Id}</Label>
            <Label p xxnormal label="Código de Seguridad" />
        </Div>
    );
};

const ViewFormSubTitle: ViewTemplate<SecureCodes> = ( { currentItem } ) =>
{
    return (
        <Div>
            <Label p xxsmall>
                <Label gutter>Fecha límite:</Label>
                <Label bold>{currentItem.ExpireDate?.toStringDate()}</Label>
            </Label>
            <Label p xxsmall>
                <Label gutter>Estado:</Label>
                <Label bold>{currentItem.Status.Description}</Label>
            </Label>
        </Div>
    );
};

const ViewForm: ViewTemplate<SecureCodes> = ( { currentItem } ) =>
{
    return (
        <Content start>
            <Col cols={6} gutter>
                <Input label='Nº de cuenta' readOnly value={currentItem.Owner} />
            </Col>
            <Col cols={6} gutter>
                <Input label='Método' readOnly value={currentItem.Mode.Description} />
            </Col>
            <Col cols={6} gutter>
                <Input label='Alcance' readOnly value={currentItem.Scope.Description} />
            </Col>
            <Col cols={6} gutter>
                <Input label='Estado' readOnly value={currentItem.Status.Description} />
            </Col>
            <Col cols={6} gutter>
                <Input label='Fecha de utilización' readOnly value={currentItem.ClosedDate?.toStringDate()} />
            </Col>
        </Content>
    );
};

const Item: ListItemTemplate<SecureCodes> = ( { item, crudStore } ) =>
{

    return (
        <Content>
            <Col center paddingX={15}>
                <Div block>

                    <Title >
                        <Code xxsmall bold mr-2>#{item.Id}</Code >
                    </Title>
                    <Title xxsmall textGrey>Método: {item.Mode.Description}</Title>
                </Div>
            </Col>
            <Spacer />
            <Col center gutter>
                <Chip
                    green={item.Status.isFulfilled}
                    red={item.Status.isExpired}
                    xsmall label={item.Status.Description} />
            </Col>
        </Content>
    );
};
