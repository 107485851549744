import React from 'react';
import {
    BrowserRouter,
    Routes,
    Route,
    useLocation,
    Navigate,
} from "react-router-dom";

import { CircularProgress, Col, Content } from '@gower/react-ui/components';
import { Page404 } from '@gower/react-ui/template/page404';
import { RoutesEnum } from './routes';
import CredentialProvider, { adminOnly } from 'app/credential-provider';
import { Layout } from '../layout';
import { LayoutEmpty } from '../layout-empty';
import { useCheckSession } from 'features/hooks/useCheckSession';
// AUTH
import { LoginView } from 'views/login';
import { ForgotPasswordView } from 'views/forgot-password';
import { ActivateUserView } from 'views/activate-user';
// VIEWS
import { AuthorizationsView } from 'views/authorizations';
import { HomeView } from 'views/home';
import { UsersView } from 'views/users';
import { NewsView } from 'views/news';
import { ProfileView } from 'views/profile';
import { SecureCodesView } from 'views/secure-codes/secure-codes';
import { AuthorizationFormsView } from 'views/authorization-forms';
import { AuthorizationDetailsView } from 'views/authorization-details';
import { EditUserView } from 'views/edit-user';
import { UsersCreateView } from 'views/users-create';
import { UsersEditView } from 'views/users-edit';
import { ImportUsersView } from 'views/import-users';
import { QuickResponseView } from 'views/quick-response';
import { ChangePasswordView } from 'views/change-password';
import { ContingencyView } from 'views/contingency';
import { AgreementConfigsView } from 'views/agreement-configs';
import { UnregisteredAgreementsView } from 'views/unregistered-agreements';
import { ConfigurationView } from 'views/configuration';
import { SpecialtiesView } from 'views/specialties';
import { UnregisteredElectronicPrescriptionsView } from 'views/unregistered-electronic-prescriptions';


export const Router = () => {

    const { check, checkIsAdmin } = useCheckSession();
    const [loading, setLoading] = React.useState( true );
    const [isAdmin, setIsAdmin] = React.useState( false );

    React.useEffect( () => {

        ( async () => {
            await check();
            setIsAdmin( checkIsAdmin() );
            setLoading( false );
        } )();

    }, [] );

    if ( loading )
        return <Loading />;

    return <>
        <BrowserRouter>
            <Routes>
                <Route path={RoutesEnum.Base} element={<RequireAuth Element={Layout} />} >
                    <Route path={RoutesEnum.Home} element={<HomeView />} />
                    <Route path={RoutesEnum.Authorize} element={<HomeView />} />
                    <Route path={RoutesEnum.Authorizations} element={<AuthorizationsView />} />
                    <Route path={`${ RoutesEnum.Authorizations }/:id`} element={<AuthorizationDetailsView />} />
                    <Route path={`${ RoutesEnum.AuthorizeForm }/:agreement`} element={<AuthorizationFormsView />} />
                    <Route path={RoutesEnum.Profile} element={<ProfileView />} />
                    <Route path={RoutesEnum.EditUser} element={<EditUserView />} />
                    <Route path={RoutesEnum.ChangePassword} element={<ChangePasswordView />} />
                    <Route path={RoutesEnum.QuickResponse} element={<QuickResponseView />} />
                    <Route path={RoutesEnum.Contingency} element={<ContingencyView />} />
                    {<Route path={RoutesEnum.UnregisteredElectronicPrescriptions} element={<UnregisteredElectronicPrescriptionsView />} />}
                    {isAdmin && <Route path={RoutesEnum.SecureCodes} element={<SecureCodesView />} />}
                    {isAdmin && <Route path={RoutesEnum.Users} element={<UsersView />} />}
                    {isAdmin && <Route path={RoutesEnum.UsersImport} element={<ImportUsersView />} />}
                    {isAdmin && <Route path={RoutesEnum.UsersCreate} element={<UsersCreateView />} />}
                    {isAdmin && <Route path={`${ RoutesEnum.UsersEdit }/:id`} element={<UsersEditView />} />}
                    {isAdmin && <Route path={RoutesEnum.News} element={<NewsView />} />}
                    {isAdmin && <Route path={RoutesEnum.Configuration} element={<ConfigurationView />} />}
                    {isAdmin && <Route path={RoutesEnum.AgreementConfigs} element={<AgreementConfigsView />} />}
                    {isAdmin && <Route path={RoutesEnum.Specialties} element={<SpecialtiesView />} />}
                    {isAdmin && <Route path={RoutesEnum.UnregisteredAgreements} element={<UnregisteredAgreementsView />} />}
                    <Route path='*' element={<NotFoundPage />} />
                </Route>
                <Route path={RoutesEnum.BaseAuth} element={<RequireAuth reverse Element={LayoutEmpty} />} >
                    <Route index element={<LoginView />} />
                    <Route path={RoutesEnum.Login} element={<LoginView />} />
                    <Route path={RoutesEnum.ForgotPassword} element={<ForgotPasswordView />} />
                    <Route path={RoutesEnum.ActivateUser} element={<ActivateUserView />} />
                    {/* <Route path={RoutesEnum.QuickResponse} element={<QuickResponseView />} /> */}
                    <Route path={RoutesEnum.PublicContingency} element={<ContingencyView />} />
                    <Route path={RoutesEnum.PublicQuickResponse} element={<QuickResponseView />} />
                    <Route path='*' element={<LoginView />} />
                </Route>
            </Routes>
        </BrowserRouter>
    </>;
};


type RequireAuthProps = {
    reverse?: boolean;
    Element?: any;
};
const RequireAuth: React.FC<RequireAuthProps> = ( { Element, reverse } ) => {
    const isAuthenticated = CredentialProvider.isAuthenticated;
    const location = useLocation();

    if ( !reverse && !isAuthenticated )
        return <Navigate to={RoutesEnum.Login} state={{ from: location }} replace />;

    if ( reverse && isAuthenticated )
        return <Navigate to={RoutesEnum.Home} state={{ from: location }} replace />;

    return <Element />;
};

const NotFoundPage: React.FC = () => {
    return (
        <Content fullWidth fullHeight justifyCenter>
            <Col center>
                <Page404 />
            </Col>
        </Content>
    );
};

const Loading: React.FC = () => {
    return (
        <Content
            height="100vh"
            width="100vw"
            justifyCenter
        >
            <Col center textColor="primary">
                <CircularProgress
                    color="inherit"
                    size={36}
                    thickness={5}
                />
            </Col>
        </Content>
    );
};