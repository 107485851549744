import { Button, Col, Render, Row, Spacer, Title } from '@gower/react-ui/components';
import { ContextTemplatesProps, TemplatesProps } from '../create.props';
import { getActions } from '../create.actions';

export type HeaderToolbarProps = TemplatesProps & ContextTemplatesProps & {

};
export const HeaderToolbar: React.FC<HeaderToolbarProps> = ( { ...props } ) =>
{
    const { store } = props;
    const { save, restore } = getActions( props );
    if ( props.hideToolbar ) return null;


    return <>
        {props.toolbarTemplate && <Render content={props.toolbarTemplate} props={{ ...props.injectProps, ...store.getProps(), ...getActions( props ) }} />}
        {!props.toolbarTemplate && <>
            <Col cols={12} padding={5} color='surface-1'>
                <Row fullWidth justifyEnd>
                    <Col center>
                        <Render
                            content={props.subTitle}
                            ifString={( { text } ) => <Title xxsmall>{text}</Title>}
                            props={{ ...props.injectProps, ...store.getProps(), ...getActions( props ) }}
                        />
                    </Col>
                    <Spacer >
                        <Row fullWidth disabled={!store.Status.isOk}>
                            <Spacer />
                            <Col center>
                                <Render content={props.toolbarContent} props={{ ...props.injectProps, ...store.getProps(), ...getActions( props ) }} />
                            </Col>
                            <Col center gutter hidden={props.hideRestorButton}>
                                <Button
                                    disabled={store.Status.isSaving}
                                    minWidth={120}
                                    label={props.retoreButtonLabel || "Restablecer"}
                                    onClick={restore}
                                />
                            </Col>
                            <Col center gutter hidden={props.hideSaveButton}>
                                <Button
                                    loading={store.Status.isSaving}
                                    disabled={!store.CanSave}
                                    primary
                                    xxsmall
                                    minWidth={120}
                                    label={props.saveButtonLabel || "Guardar"}
                                    onClick={save}
                                />
                            </Col>
                        </Row>
                    </Spacer>
                </Row>
            </Col>
        </>}
    </>;
};