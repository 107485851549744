import { Button, Chip, Col, Content, Div, Label, Row, Spacer, Tooltip } from '@gower/react-ui/components';
import { DataSheet, DataSheetColumns } from '@gower/react-ui/template/datasheet-controlled';
import { Paginator } from '@gower/react-ui/template/paginator';
import { Validators } from '@gower/utils';
import { SelectFile } from '@gower/react-ui/utils';
import { provider, useStore } from './store';
import { AiOutlineFileExcel } from '@gower/react-ui/icons';
import { Loader } from '@gower/react-ui/template/loader';


export const ImportUsersView = provider( () => {

    return <ImportUsersTemplate />;

} );

const ImportUsersTemplate = () => {

    const store = useStore();

    return <>
        <Content noScroll columns>
            <Col gutter>
                <Row noWrap padding={5}>
                    <Col gutter center show={!store.Status.isOk}>
                        <Button
                            loading={store.Status.isLoading}
                            label="Seleccionar archivo"
                            onClick={() => SelectFile( ( file ) => store.uploadFile( file ), { multiple: false, accept: '.csv, .txt' } )}
                        />
                    </Col>
                    <Col gutter center show={store.Status.isOk}>
                        <Tooltip title="Seleccionar otro archivo.">
                            <Button
                                pointer
                                primary
                                loading={store.Status.isLoading}
                                iconLeft={<AiOutlineFileExcel size={18} />}
                                label={store.FileName}
                                onClick={() => SelectFile( ( file ) => store.uploadFile( file ), { multiple: false, accept: '.csv, .txt' } )}
                            />
                        </Tooltip>
                    </Col>
                    <Spacer gutter center />
                    <Col gutter center show={Boolean( store.CurrentPage )}>
                        <Chip
                            noSelect
                            green={store.ProcessedPages === store.Pages.length}
                        >
                            <Label
                                xxsmall
                                show={store.ProcessedPages > 0}
                                label={`${ store.ProcessedPages } procesadas de ${ store.Pages.length }`} />
                            <Label
                                xxsmall
                                show={!store.ProcessedPages}
                                label={`Comprueba los datos y pulsa importar.`} />
                        </Chip>
                    </Col>
                    <Col gutter center show={Boolean( store.CurrentPage )}>
                        <Button
                            show={!store.CurrentPage?.Processed}
                            primary
                            loading={store.Status.isSaving}
                            label="Importar página y continuar"
                            onClick={() => store.import()}
                        />
                        <Button
                            show={store.CurrentPage?.Processed}
                            color="green-7"
                            textWhite
                            loading={store.Status.isSaving}
                            label="Datos guardados"
                        />
                    </Col>
                </Row>
            </Col>
            <Spacer noScroll vertical disabled={!store.Status.isOk}>
                <Loader
                    status={store.Status}
                    loadingTitle="Cargando archivo. Aguarde"
                >
                    <DataSheet
                        data={store.CurrentPage?.Items || []}
                    >
                        <DataSheetColumns.Custom
                            readOnly
                            width={30}
                            justify="flex-start"
                            viewerTemplate={( { item, row } ) => (
                                <Label
                                    textColor={item['Processed'] ? 'green-5' : undefined}
                                    label={`${ row + ( store.Pagination.Page - 1 ) * store.Pagination.Results }.`}
                                />
                            )}
                        />
                        <DataSheetColumns.Text
                            width={150}
                            header="Usuario"
                            valueKey="Nickname"
                            readOnly={store.CurrentPage?.Processed}
                        />
                        <DataSheetColumns.Text
                            width={250}
                            justify="flex-start"
                            header="Nombre"
                            valueKey="Name"
                            validate={( v ) => v?.length > 3}
                            readOnly={store.CurrentPage?.Processed}
                        />
                        <DataSheetColumns.Text
                            width={250}
                            justify="flex-start"
                            header="Apellido"
                            valueKey="Lastname"
                            readOnly={store.CurrentPage?.Processed}
                        />
                        <DataSheetColumns.Text
                            width={250}
                            justify="flex-start"
                            header="Email"
                            valueKey="Email"
                            validate={( v ) => Validators.Email( v )}
                            readOnly={store.CurrentPage?.Processed}
                        />
                        <DataSheetColumns.Text
                            width={250}
                            justify="center"
                            header="Matrículas"
                            valueKey="Metadata.text_licenses"
                            readOnly={store.CurrentPage?.Processed}
                        />
                        <DataSheetColumns.Custom
                            header="Problemas encontrados"
                            justify="flex-start"
                            readOnly
                            viewerTemplate={( { item } ) => {

                                const problems = ( item as any ).getProblems();

                                return (
                                    <Div paddingX={10}>
                                        {problems.includes( 'email' ) && <Label p textLeft textRed xsmall label="Revisá el email del usuario" />}
                                        {problems.includes( 'nickname' ) && <Label p lineHeight={1} textLeft textRed xsmall label="Nickname incorrecto. Revisa carac. especiales y espacios." />}
                                        {problems.includes( 'name' ) && <Label p lineHeight={1} textLeft textRed xsmall label="Nombre incorrecto. Revisa carac. especiales y espacios." />}
                                        {problems.includes( 'license' ) && <Label p lineHeight={1} textLeft textRed xsmall label="Matrículas inválidas. Revisa carac. y espacios." />}
                                    </Div>
                                );
                            }}
                        />
                    </DataSheet>
                </Loader>
            </Spacer>
            <Col>
                <Paginator
                    disabled={!store.Status.isOk}
                    pagination={store.Pagination}
                    onNext={() => store.next()}
                    onPrev={() => store.prev()}
                />
            </Col>
        </Content>
    </>;
};

