import { getListFilterProps } from 'temp/SidedCrud/sided-crud-utils';
import { Model, CrudSubStore, InstanceOfModel } from './common';
import { URI } from '@gower/react-ui/utils';
import { Finder } from '@gower/react-ui/finder';

export class ListStore<T = Model> extends CrudSubStore 
{

    public show ()
    {
        this.Filters.setDefault();
        this.Filters.set( URI.getQuery( this.viewName ) );
        this.find();
    }

    public hide ()
    {

    }

    public getProps (): any
    {
        return {
            crudStore: this.MainStore,
            listStore: this,
            items: this.Items,
            ...getListFilterProps( this )
        };
    }

    private reloading = false;
    private finder: ReturnType<typeof Finder> = null;

    public get Status () { return this.finder.Status; };
    public get isReloading () { return this.reloading; };
    public get Finder () { return this.finder; };
    public get Items () { return this.finder.Items as T[]; };
    public get Pagination () { return this.finder.Pagination; };
    public get Filters () { return this.finder.Filters; };
    public get isLoadingMore () { return this.finder.isLoadingMore; };

    public search ( searchText: string )
    {
        this.Finder.Filters.Values.SearchText = searchText || '';
        this.find();
    }

    public reload ()
    {
        this.reloading = true;
        this.find();
    }

    public findMore ()
    {
        this.finder.find( this.Pagination.Page, true );
    }

    private async find ()
    {
        await this.finder.find();
    }

    public setFilter ( filterKey: string, value: any )
    {
        this.Filters.setValue( filterKey, value );
        this.find();
    }

    public setup ( silent?: boolean )
    {

        this.finder = Finder( this.MainStore.Model, this.MainStore.Filters, {
            silent,
            prefix: this.viewName
        } );
        this.Filters.setDefault();
        this.Finder.Status.on( 'changed', ( { status } ) => 
        {
            if ( status !== 'loading' )
                this.reloading = false;
        } );
    }

    public Open ( item: InstanceOfModel )
    {
        this.MainStore.goView( item );
    }
}

