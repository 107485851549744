import React, { SVGAttributes } from "react";

export const LogoOSPTV: React.FC<Omit<SVGAttributes<any>, 'dangerouslySetInnerHTML'>> = ( { ...props } ) => {
  return (
    <img
      {...props}
      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAJUAAAAZCAYAAAA40GzsAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsQAAA7EAZUrDhsAAAAZdEVYdFNvZnR3YXJlAEFkb2JlIEltYWdlUmVhZHlxyWU8AAAFJklEQVRoQ+2asW8bVRzHX5AQQwe8wICEEg8ZqJCaiE5lSIxYyuRIDGwkC4yxh86O/wInI12SbmzJRBeEnYFORQlSVYYOdmBrF4MEUtWhzef1ne/d8++d353vWmL7MzT3XOfOvve97+/7fi9LLy9RCxYUiCiqXq+nzs/P1XA4NK9kp1KpqLW1NbW5uWlemR4+z/7+vhnF1Ot1fa0sTPv9smDfg95fz1Tvz2dm9Oapr36k1j6smFE+fN9h7/Pr+mdCVNzora0tNRgMzCvTs7Kyoo6PjzNPusTOzo46OjoyoxgmrdvtmlEYPDi1Ws2MyqPVaqm9vT0zurzxvz5W7QePzag8Nj/+QE++C4I6+/ZLM8rO8PkLVf3hJ/3TpvHZqup8cUMfv6P/NRQtKOB8RUwe55EEBQjE939vmyKdOgutS9dYef+aGcWcPx2qo0f553j/4ZMxQVXee1dfL2IkKlyqaEFFUGaY+GnApdJot9tvrJxdFTq1187h0n7wx5gwQuB3Dn57YkYxu5cuhbAiRuXv5OREO5XN9va2Wl5eNqNwLi4uxpzDLQNZCC1VWa4Rmql81w0tt1G2jMAl7j26MCM/g3/+U4O//zWjGMpaCJQiSl3tx1OxDLZuXR9loFCk0o0bUk5FUTEZPO023Lg89i2JYBpRVavVMRclnPMg2DCBZ2dnOscVBeJbX183oxgripaCL3u9vPO1OQoDYVbv3jejGETQ//6rhBjS8J3n8PZNtf1p8n4nMlXRIEjEhDgbjYZ5NRus9lxBcd7Dw0MtIhucx30w5h2chBDtQilr/vK7GU2GkumCE7qCglJExaTzJCMm3ImxK4AQfCJBqJyPny6U3Wnz26xBiJYciVJMcJ8ELiWF+2i151KqU02LFL7JeVFJxv2kUrdwqyQIyl6d2YS4VbM7/h6ynS/fjTIVT7i7wiJg5nGYCCZ8Y2NDCyErlDyylEu/308ISfrcQHnMc12Xq56pbMhEUvjvfrPhFQghn7DvQjj3NVFHogpdYeWBUE0DNAusRN0gjjN1Oh0zipGCPMIjtE/zUMAsiconEHJX/7vbZpREWj2SowjoPkblL2/uCQFxuAJJA4FLKzspQwGu5ILIpC2deQY3YpvGxZeZEJPcjvjEHMkkMpXkAkVxejr+hPhoNpvmKGZ3d9creh6IKGfZHBwcjDnYvONriJKt3IaolLfob0mdepvE3h9g97hEFhFIuCswJj2kYShlpJBS5ivfeUqvzSyVvwjfue2GKM61c/+hPo4I7W2NiaoolpaWzNFrQkTFSo98FNLpzkLeJi7MoqhwJGlTGMhWOJEU6mkhSD0vl/9VS4EMVLSgQCqn8wxO4+sx0eTEpVxB+ZqoElpUTCTlg0Ylqy7cIu+WSl7IPmSgMsBt3L3IeYcVnNQSQFBylkoP5zZaVNx08ghNQ/LU2wi3UqOzSHCrMs9/FfG5lVsWWTVK2zE+dKaSQi4ZhMZlXtyudlqm8oVsWgVSx3wSOJ7UwsizqT2LmcqGMC61E2zSmqMSXlEVDd1tqZ8EXJvPYJP2/kngSL7A73bkJzHroiI7rd/7WQztQF/ruH7LjMLQ5Q8XKavxGeFzPd8GsK/RGQLfhb6WxCK0JyGA80d2Pnx9rTRGqz/fJBQBe4j0i1xwEmnzF5fKU/ZsfJvNlEVJxPNM4+aq2NAkR01qdEqMREXWoNww+UW5FmJicslS0jlxKXdRwPuK6OxzHp/bLdwqif4rBmd1l9Z2mERpzc8F84pSrwArXff19tObMwAAAABJRU5ErkJggg=="
      style={{
        contain: 'content',
        objectFit:'contain' ,
      }}
    ></img>
  );
};