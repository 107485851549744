import { Button, Col, Render, Row, Title } from '@gower/react-ui/components';
import { HiOutlineArrowLeft } from '@gower/react-ui/icons';
import { ContextTemplatesProps, TemplatesProps } from '../create.props';
import { getActions } from '../create.actions';
import { HeaderActions } from './header-actions';
import { HeaderToolbar } from './header-toolbar';


export type HeaderProps = TemplatesProps & ContextTemplatesProps & {

};
export const Header: React.FC<HeaderProps> = ( { ...props } ) =>
{
    const { store } = props;
    const { back } = getActions( props );
    if ( props.hideHeader ) return null;

    return <>
        {props.template && <Render content={props.template} props={{ ...props.injectProps, ...store.getProps(), ...getActions( props ) }} />}
        {!props.template && <>
            <Col fullWidth top={0} left={0} position="sticky" white padding={15} zIndex={1}>
                <Row maxWidth={props.maxWidth}>
                    <Col cols={12} paddingX={5} paddingY={15}>
                        <Row justifyStart>
                            <Col hidden={props.hideBackButton}>
                                <Button
                                    disabled={!store.Status.isOk}
                                    flat
                                    circle
                                    size={38}
                                    paddingLeft={8}
                                    fontSize={20}
                                    lineHeight={38}
                                    textColor="surface-8"
                                    hoverTextColor='black'
                                    icon={HiOutlineArrowLeft}
                                    onClick={back}
                                />
                            </Col>
                            <Col center paddingX={5} >
                                <Render
                                    content={props.title}
                                    ifString={( { text } ) => <Title xlarge>{text}</Title>}
                                    props={{ ...props.injectProps, ...store.getProps(), ...getActions( props ) }}
                                />
                            </Col>
                        </Row>
                    </Col>
                    <HeaderToolbar {...props} />
                    <HeaderActions {...props} />
                </Row>
            </Col>
        </>}
    </>;

};