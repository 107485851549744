import React from 'react';
import { useLocation } from 'react-router-dom';
import { theme, MediaQuery } from '@gower/react-ui/components';
import { provider, useStore } from './store';
import { AuthorizationFormsDesktop } from './desktop';
import { AuthorizationFormsMobile } from './mobile';

export const AuthorizationFormsView = provider( () => {

    const location = useLocation();
    const store = useStore();

    React.useEffect( () => {
        if ( store?.Affiliate )
            store?.reset();
    }, [location] );

    return <>
        <MediaQuery minWidth={theme.current.Breakpoints.XsMin}>
            <AuthorizationFormsDesktop />
        </MediaQuery>
        <MediaQuery
            maxWidth={theme.current.Breakpoints.MobileMax}
        >
            <AuthorizationFormsMobile />
        </MediaQuery>
    </>;
}, {} );
