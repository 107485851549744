import { Authorization, Filters } from '@gower/cm-mdp-sdk/authorizations';
import { AuthorizationsService } from '@gower/cm-mdp-sdk/src/lib/Authorizations/services/Authorizations';
import { ViewStatus } from '@gower/models-sdk/utils';
import { MessageBox } from '@gower/react-ui/message-box';
import { Notifications } from '@gower/react-ui/notifications';
import { expose } from '@gower/react-ui/store';
import { DataGridStore } from '@gower/react-ui/template/data-grid';

class AuthorizationsStore extends DataGridStore( Authorization, Filters, { viewName: 'AUTHS' } )
{
    public isCanceling = false;
    
    async remove ( item: Authorization ) {

        const result = await MessageBox.YesNo( {
            title: 'Eliminar autorización',
            content: '¿Estás seguro de eliminar la autorizacion?. Esta acción no puede deshacerse'
        } );

        if ( result ) {
            if ( await item.delete() ) {
                this.Items.remove( item );
                Notifications.Success( 'Autorización eliminada.' );
            } else {
                Notifications.Error( 'Error al eliminar.' );
            }
        }

    }

    async cancel ( item: Authorization ) {

        const response = await MessageBox.YesNo( {
            title: 'Anular autorización',
            content: '¿Estás seguro de anular la autorizacion?. Esta acción no puede deshacerse'
        } );

        if ( response ) {
            this.isCanceling = true;
            const result = await item.cancel();
            if ( result?.result )
                Notifications.Success( 'Autorización anulada.' );
            else {
                if ( result?.errors ) {
                    MessageBox.Error( result?.errors );
                } else if ( item.Agreement.isOSARPyH || item.Agreement.isAMTAR ) {
                    MessageBox.Error( 'Se produjo un ERROR. Por favor contactarse con la OOSS.' );
                } else {
                    MessageBox.Error( 'Error al anular. Compruebe el estado de la autorización.' );
                }
            }
            this.isCanceling = false;
        }
    }

    public readonly DownloadStatus = ViewStatus.new( 'ok' );

    async downloadList () {
        const service = new AuthorizationsService();
        this.DownloadStatus.setLoading();
        const file = await service.getListUrl( this.Finder.Filters.fechParams() as any );
        if ( file?.url ) {
            window.open( file.url );
        } else {
            await MessageBox.Error( service.LastErrors );
        }
        this.DownloadStatus.setOk();
    }
}

export const { useStore, provider } = expose( AuthorizationsStore );