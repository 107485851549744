import CredentialProvider from 'app/credential-provider';
import { AuthenticationService } from '@gower/shared-sdk/src/lib/Core/Authentication/Session';
import { WaitAtLeast } from '@gower/utils';
import { Specialty } from '@gower/cm-mdp-sdk/specialties';

export function useCheckSession () {

    async function check () {

        const wait = WaitAtLeast( 1000 );
        const service = AuthenticationService.get();
        const token = CredentialProvider.getToken();
        const user = await service.me( token );
        if ( !user ) {
            CredentialProvider.clear();
        } else {

            let specialty: Specialty = null;
            if ( user['metadata']?.specialty_id ) {
                specialty = await Specialty.findById( user['metadata']?.specialty_id );
            }

            CredentialProvider.updateUser( user, specialty );
        }
        await WaitAtLeast( wait );
    }

    return {
        check,
        checkIsAdmin: () => CredentialProvider.isAdministrator(),
    };

}