import { AgreementConfig } from '@gower/cm-mdp-sdk/agreement-config';
import { MutableStore, expose, ViewStatus } from '@gower/react-ui/store';
import { WaitAtLeast } from '@gower/utils';
import CredentialProvider from 'app/credential-provider';

export class HomeStore extends MutableStore {

    public Agreements: AgreementConfig[] = [];
    public Status = new ViewStatus( 'loading' );
    public allowPrescriptions = false;

    onAfterRender () {
        this.find();
    }

    async find () {

        this.Status.setLoading();
        this.Agreements.clear();

        const wait = WaitAtLeast( 300 );
        let items = await AgreementConfig.getConfigs();
        await WaitAtLeast( wait );
        if ( items?.length ) {

            const specialty = CredentialProvider.getSpecialty();
            
            if ( specialty ) {
                items = items.filter( item => !item.BlockedSpecialities.some( id => id === specialty.Id ) );
                this.allowPrescriptions = (specialty.AllowPrescription && !specialty.AllowPrescriptionPapperOnly) || false;
            }

            this.Agreements.push( ...items );
            this.Status.setOk();
        } else {
            this.Status.setErrored();
        }
    }

}

export const { provider, useStore } = expose( HomeStore );