import React from 'react';
import { ResponsiveView } from '@gower/react-ui/template/responsive-view';
import { provider } from './store';
import { UnregisteredAgreementsDesktop } from './desktop';
import { UnregisteredAgreementsMobile } from './mobile';

export const UnregisteredAgreementsView = provider( () => {
    return <>
        <ResponsiveView
            desktop={UnregisteredAgreementsDesktop}
            mobile={UnregisteredAgreementsMobile}
        />
    </>;
}, { keepAlive: true } );
