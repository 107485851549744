import React from 'react';
import { getComponentsProps } from './sided-crud-components-props';
import { SidedCrudProps } from './sided-crud.props';
import { ViewTemplate } from './view';
import { ListTemplate } from './list';
import { CreateTemplate } from './create';

export type { SidedCrudProps };
export const SidedCrud: React.FC<SidedCrudProps> = ( {
    store,
    children,
    maxWidth,
    title,

} ) =>
{

    const { listProps, createProps, viewProps } = getComponentsProps( children );
    const baseProps = {
        title,
        maxWidth,
        crudStore: store,
    };

    return <>
        {store.Mode.isList && listProps && <ListTemplate {...baseProps} {...listProps as any} />}
        {store.Mode.isCreate && createProps && <CreateTemplate backButton {...baseProps} {...createProps as any} />}
        {store.Mode.isView && viewProps && <ViewTemplate backButton {...baseProps} {...viewProps as any} />}
    </>;
};
