import React from 'react';
import { Col } from '@gower/react-ui/components';
import { useStore } from '../../store';
import { ButtonGroup, ButtonGroupItem } from '@gower/react-ui/template/button-group';

export type AffliateModelSelectorProps = {

};
export const AffliateModelSelector: React.FC<AffliateModelSelectorProps> = ( { } ) => {

    const store = useStore();
    const agreement = store.Agreement;

    if ( agreement?.isAMEBPBA )
        return <>
            <Col cols={12} paddingY={4} center>
                <ButtonGroup
                    mandatory
                    active={( () => {

                        if ( store.AffiliateInputMode === 'document' )
                            return 0;
                        return 1;
                    } )()}
                    onChange={() => store.removeAffiliate()}
                >
                    <ButtonGroupItem disabled={store.Status.isLoading || store.Status.isSaving} width="50%" label="Nº de Afiliado" onSelected={() => store.setAffiliateInputMode( 'credential' )} />
                    <ButtonGroupItem disabled={store.Status.isLoading || store.Status.isSaving} width="50%" label="Documento" onSelected={() => store.setAffiliateInputMode( 'document' )} />
                </ButtonGroup>
            </Col>
        </>;

    return null;
};
