import React, { SVGAttributes } from "react";

export const LogoPATCAB: React.FC<Omit<SVGAttributes<any>, 'dangerouslySetInnerHTML'>> = ( { ...props } ) => {
  return (
    <svg
      {...props}
      viewBox="0 0 189.33 170.52"
      dangerouslySetInnerHTML={{
        __html: `
          <g>
            <path fill="#808184" d="M140.579,0c1.813,.398,3.639,.749,5.438,1.206,3.231,.82,6.365,1.917,9.392,3.334,3.775,1.767,7.401,3.772,10.775,6.235,4.103,2.996,7.785,6.399,10.871,10.469,2.363,3.117,4.442,6.385,6.115,9.912,2.245,4.733,3.791,9.705,4.762,14.84,.65,3.439,1.006,6.921,1.291,10.423,.312,3.83-.115,7.561-.527,11.329-.682,6.234-2.226,12.256-4.335,18.137-2.234,6.232-5.175,12.127-8.655,17.756-2.439,3.945-5.136,7.716-8.008,11.345-2.055,2.597-4.243,5.098-6.473,7.559-2.92,3.222-6.045,6.231-9.122,9.289-3.476,3.454-7.255,6.57-11.033,9.694-6.853,5.667-14.136,10.716-21.574,15.557-4.405,2.867-8.892,5.602-13.468,8.189-2.117,1.197-4.227,2.406-6.323,3.64-3.346,1.969-6.65,2.251-10.105,.151-4.089-2.485-8.313-4.745-12.407-7.222-6.609-3.999-13.117-8.164-19.376-12.697-3.626-2.626-7.202-5.323-10.648-8.195-3.648-3.04-7.159-6.222-10.661-9.428-3.378-3.092-6.434-6.473-9.467-9.867-3.692-4.129-7.039-8.552-10.202-13.117-3.026-4.367-5.796-8.886-8.164-13.624-3.338-6.678-5.857-13.647-7.434-20.997C-.214,67.145-.142,60.319,.222,53.538c.369-6.859,2.123-13.535,4.944-19.87,2.285-5.131,5.206-9.839,8.809-14.161,4.421-5.304,9.657-9.56,15.632-12.974,4.917-2.809,10.193-4.619,15.701-5.753,1.485-.306,3.014-.611,4.566-.548,.254,.01,.574,.115,.73-.233h7.418c1.405,.248,2.84,.109,4.248,.335,7.571,1.217,14.432,4.156,20.694,8.546,3.01,2.11,5.78,4.496,8.359,7.12,2.703,2.749,3.866,2.676,6.666-.012,6.241-5.99,13.165-10.953,21.36-13.95C122.269,.973,125.263,.22,128.375,0,132.443,0,136.511,0,140.579,0Z"/>
            <path fill="#68b9e1" d="M94.297,44.991c3.27-4.553,6.062-9.267,9.43-13.574,4.621-5.908,9.939-11.029,16.798-14.304,5.123-2.447,10.58-3.476,16.191-2.922,8.02,.792,15.443,3.453,21.888,8.4,4.032,3.095,7.287,6.922,9.836,11.364,2.756,4.802,4.413,9.987,5.574,15.346,1.144,5.279,1.341,10.668,.963,16.017-.486,6.877-2.364,13.451-5.194,19.758-3.368,7.507-7.832,14.305-12.926,20.728-2.762,3.482-5.661,6.833-8.637,10.134-2.861,3.173-5.927,6.126-9.087,8.966-5.83,5.24-11.985,10.088-18.427,14.573-6.278,4.371-12.735,8.456-19.299,12.372-1.622,.968-3.245,1.974-5.123,2.48-2.612,.704-4.846-.185-6.978-1.497-6.307-3.881-12.636-7.732-18.724-11.955-5.431-3.767-10.704-7.738-15.816-11.938-5.024-4.127-9.726-8.589-14.148-13.325-6.041-6.468-11.365-13.504-15.865-21.161-3.208-5.459-5.999-11.102-8.009-17.105-1.459-4.358-2.377-8.83-2.789-13.438-.77-8.597,.779-16.722,4.397-24.504,2.084-4.482,4.715-8.59,7.943-12.352,3.204-3.734,7.025-6.645,11.334-8.898,4.136-2.162,8.573-3.346,13.25-3.823,4.944-.505,9.743,.07,14.397,1.58,4.7,1.525,8.97,3.92,12.806,7.124,5.15,4.301,8.793,9.745,12.194,15.408,1.305,2.173,2.653,4.32,4.022,6.546Z"/>
          </g>
      `}}>
    </svg>
  );
};