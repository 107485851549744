import React from 'react';
import { Button, Checkbox, Col, Content, Div, Divider, Row, Spacer } from '@gower/react-ui/components';
import { useStore } from '../store';
import {
    AuthorizationHeader,
    AffliateModelSelector,
    InputAffiliate,
    InputAffiliateByDocument,
    InputProfessional,
    InputMessage,
    PrescriptionFormModal,
    Services,
} from './components';
import { useBoolean } from '@gower/react-ui/hooks';
import { useNews } from 'features/hooks/useNews';
import { NewsContainer } from 'lib/components/news-container';
import { OnKeyGridHandler } from '@gower/react-ui/utils';
import { Loader } from '@gower/react-ui/template/loader';

export const AuthorizationFormsDesktop = () => {
    const store = useStore();
    const status = store.Status;
    const showBottomToolbar = useBoolean( false );
    const authRef = React.createRef<HTMLButtonElement>();
    const authRefMouseOver = React.useRef<boolean>( false );
    const news = useNews();
    const prescriptionFormModal = useBoolean( false );

    React.useEffect( () => {

        let observer: IntersectionObserver = null;

        const setFocused = () => {
            authRefMouseOver.current = true;
        };
        const removeFocused = () => {
            authRefMouseOver.current = false;
        };

        if ( authRef.current ) {

            authRef.current.addEventListener( 'mouseenter', setFocused );
            authRef.current.addEventListener( 'mouseleave', removeFocused );

            observer = new IntersectionObserver( function ( entries ) {
                if ( entries[0].isIntersecting === true || authRefMouseOver.current )
                    showBottomToolbar.disable();
                else
                    showBottomToolbar.enable();
            }, { threshold: [1] } );
            observer.observe( authRef.current );
        }

        return () => {
            authRef.current?.removeEventListener( 'mouseleave', setFocused );
            authRef.current?.removeEventListener( 'mouseenter', setFocused );
            observer?.disconnect();
        };


    }, [authRef] );

    function onKeyUp ( event: React.KeyboardEvent<HTMLDivElement> ) {
        if ( event.ctrlKey && event.key === 'Enter' ) {
            if ( !status.isSaving && store.canConfirm )
                store.authorize();
        }
    }

    return <>
        <Content
            justifyCenter
            scrollOnlyVertical
            onKeyUp={onKeyUp}
        >
            <Loader status={store.SetupStatus}>
                {store.SetupStatus.isOk && <>
                    <Col
                        cols={12}
                        maxWidth={1200}
                        paddingTop={20}
                        paddingX={20}
                        paddingBottom={100}
                    >

                        <NewsContainer news={news.getAgreementNews( store.Agreement?.Id )} />

                        <Div
                            fullWidth
                            white
                            box-shadow-1
                            padding={20}
                        >
                            <Row>
                                <AuthorizationHeader />
                            </Row>
                            <Divider paddingY={10} />

                            <Row start
                                refOnKeyUp={OnKeyGridHandler( '* input,* textarea', { handle: ['ArrowDown', 'ArrowUp'], handlers: {} } )}
                            >
                                <AffliateModelSelector />
                                <InputAffiliateByDocument />
                                <InputAffiliate />
                                <InputProfessional type="applicant" />
                                <InputProfessional type="effector" />
                                <InputProfessional type="billing" />
                                <Col sm={12} cols={8} paddingTop={4} paddingRight={2} start>
                                    <Services />
                                </Col>
                                <Col sm={12} cols={4} paddingTop={4} paddingRight={2} start>
                                    <InputMessage />
                                </Col>
                            </Row>

                            <Divider paddingY={10} />

                            <Row paddingTop={20}>
                                <Col center gutter>
                                    <Button
                                        onClick={() => store.manualReset()}
                                        flat
                                        disabled={store.Status.isSaving || store.Status.isLoading}
                                        borderRadiusAll={5}
                                        block
                                        textColor="black"
                                        xxsmall
                                        label="Restablecer"
                                    />
                                </Col>
                                <Spacer />
                                <Col center paddingX={20}>
                                    {store.allowForms &&
                                        <Checkbox
                                            disabled={store.Status.isSaving || store.Status.isLoading}
                                            value={store.emitForms}
                                            onCheck={( v ) => store.emitForms = v}
                                            label="Emitir planilla" />
                                    }
                                </Col>
                                <Col center gutter show={store.showElectronicPrescription}>
                                    <Button
                                        block
                                        pointer
                                        loading={store.downloadingPrescription}
                                        onClick={() => store.openElectronicPrescription()}
                                        disabled={!store.canGenerateElectronicPrescription || status.isSaving}
                                        flat
                                        color="blue-4"
                                        textWhite
                                        normal
                                        label="Receta electrónica"
                                    />
                                </Col>
                                <Col center gutter show={store.showMedereElectronicPrescription}>
                                    <Button
                                        block
                                        pointer
                                        loading={store.downloadingPrescription}
                                        onClick={() => store.openMedereElectronicPrescription()}
                                        disabled={!store.canGenerateElectronicPrescription || status.isSaving}
                                        flat
                                        color="blue-4"
                                        textWhite
                                        normal
                                        label="Receta electrónica"
                                    />
                                </Col>
                                <Col center gutter show={store.showIOMAElectronicPrescription}>
                                    <Button
                                        block
                                        pointer
                                        loading={store.downloadingPrescription}
                                        onClick={() => store.openIOMAElectronicPrescription()}
                                        disabled={!store.canGenerateElectronicPrescription || status.isSaving}
                                        flat
                                        color="blue-4"
                                        textWhite
                                        normal
                                        label="Receta electrónica"
                                    />
                                </Col>
                                <Col center gutter show={store.showPapperPrescription}>
                                    <Button
                                        block
                                        pointer
                                        loading={store.downloadingPrescription}
                                        onClick={() => prescriptionFormModal.enable()}
                                        disabled={!store.canGenerateAllPrescriptions || status.isSaving}
                                        flat
                                        color="blue-4"
                                        textWhite
                                        normal
                                        label="Descargar Recetario"
                                    />
                                </Col>
                                <Col center gutter>
                                    <Button
                                        ref={authRef}
                                        block
                                        pointer
                                        flat
                                        onClick={() => store.authorize()}
                                        disabled={!store.canConfirm || store.downloadingPrescription}
                                        loading={status.isSaving && !store.downloadingPrescription}
                                        primary
                                        textWhite
                                        normal
                                        label="Generar Autorización"
                                    />
                                </Col>
                            </Row>
                        </Div>
                    </Col>
                    <Col
                        show={showBottomToolbar.Actived}
                        white
                        boxShadow="box-shadow-1"
                        fullWidth
                        position="sticky"
                        bottom={0}
                        left={0}
                    >
                        <Row padding={10}>
                            <Col center gutter>
                                <Button
                                    onClick={() => store.manualReset()}
                                    flat
                                    disabled={store.Status.isSaving || store.Status.isLoading}
                                    borderRadiusAll={5}
                                    block
                                    textColor="black"
                                    xxsmall
                                    label="Restablecer"
                                />
                            </Col>
                            <Spacer />
                            <Col center paddingX={20}>
                                {store.allowForms &&
                                    <Checkbox
                                        disabled={store.Status.isSaving || store.Status.isLoading}
                                        value={store.emitForms}
                                        onCheck={( v ) => store.emitForms = v}
                                        label="Emitir planilla" />
                                }
                            </Col>
                            <Col center gutter show={store.showElectronicPrescription}>
                                <Button
                                    block
                                    pointer
                                    loading={store.downloadingPrescription}
                                    onClick={() => store.openElectronicPrescription()}
                                    disabled={!store.canGenerateElectronicPrescription || status.isSaving}
                                    flat
                                    color="blue-4"
                                    textWhite
                                    normal
                                    label="Receta electrónica"
                                />
                            </Col>
                            <Col center gutter show={store.showMedereElectronicPrescription}>
                                <Button
                                    block
                                    pointer
                                    loading={store.downloadingPrescription}
                                    onClick={() => store.openMedereElectronicPrescription()}
                                    disabled={!store.canGenerateElectronicPrescription || status.isSaving}
                                    flat
                                    color="blue-4"
                                    textWhite
                                    normal
                                    label="Receta electrónica"
                                />
                            </Col>
                            <Col center gutter show={store.showIOMAElectronicPrescription}>
                                <Button
                                    block
                                    pointer
                                    loading={store.downloadingPrescription}
                                    onClick={() => store.openIOMAElectronicPrescription()}
                                    disabled={!store.canGenerateElectronicPrescription || status.isSaving}
                                    flat
                                    color="blue-4"
                                    textWhite
                                    normal
                                    label="Receta electrónica"
                                />
                            </Col>
                            <Col center gutter show={store.showPapperPrescription}>
                                <Button
                                    block
                                    pointer
                                    loading={store.downloadingPrescription}
                                    onClick={() => prescriptionFormModal.enable()}
                                    disabled={!store.canGenerateAllPrescriptions || status.isSaving}
                                    flat
                                    color="blue-4"
                                    textWhite
                                    normal
                                    label="Descargar Recetario"
                                />
                            </Col>
                            <Col center gutter>
                                <Button
                                    block
                                    pointer
                                    flat
                                    onClick={() => store.authorize()}
                                    disabled={!store.canConfirm || store.downloadingPrescription}
                                    loading={status.isSaving && !store.downloadingPrescription}
                                    primary
                                    textWhite
                                    normal
                                    label="Generar Autorización"
                                />
                            </Col>
                        </Row>
                    </Col>
                    <PrescriptionFormModal isOpen={prescriptionFormModal.Actived} onClose={prescriptionFormModal.disable} />
                </>}
            </Loader>
        </Content >
    </>;
};

