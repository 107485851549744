import { UnregisteredAgreement, Filters } from '@gower/cm-mdp-sdk/unregistered-agreements';
import { MessageBox } from '@gower/react-ui/message-box';
import { expose, SubStore } from '@gower/react-ui/store';
import { DataGridStore } from '@gower/react-ui/template/data-grid';
import { NewsProvider } from 'app/news-provider';

export class UnregisteredAgreementSubStore extends SubStore<UnregisteredAgreementStore> {
    public get Selected () { return this.MainStore.Selected; }
}

class UnregisteredAgreementStore extends DataGridStore( UnregisteredAgreement, Filters, { viewName: 'UNAGR' } )
{
    public Mode: 'edit' | 'create' | 'list' = 'list';
    public Selected: UnregisteredAgreement = null;

    onAfterRender () {
        this.list();
    }

    edit ( news: UnregisteredAgreement ) {
        this.Selected = news;
        this.Mode = 'edit';
    }

    create () {
        this.Selected = null;
        this.Mode = 'create';
    }

    list ( newItem?: UnregisteredAgreement ) {
        this.Selected = null;
        if ( newItem )
            this.Items.unshift( newItem );
        this.Mode = 'list';
    }

    async active ( item: UnregisteredAgreement ) {
        if ( item && !item.Status.isActive ) {
            item.Status = UnregisteredAgreement.VO.Status.Active;
            const result = await item.save();
            if ( !result.result ) {
                await MessageBox.Error( result.errorList );
            } else {
                await NewsProvider.activeForcedReload();
            }
        }
    }

    async disable ( item: UnregisteredAgreement ) {
        if ( item && !item.Status.isDeactivated ) {
            item.Status = UnregisteredAgreement.VO.Status.Deactivated;
            const result = await item.save();
            if ( !result.result ) {
                await MessageBox.Error( result.errorList );
            } else {
                await NewsProvider.activeForcedReload();
            }
        }
    }
}

export const { useStore, provider } = expose( UnregisteredAgreementStore );