import { Notifications } from '@gower/react-ui/notifications';
import { expose, MutableStore, ViewStatus } from '@gower/react-ui/store';
import { WaitAtLeast } from '@gower/utils';
import { QuickResponseService } from '@gower/cm-mdp-sdk/src/lib/Authorizations/services/QuickResponse';
import CredentialProvider from 'app/credential-provider';


export class QuickResponseFormStore extends MutableStore {

    public Status = new ViewStatus( 'pending' );
    public User = '';
    public Email = '';
    public Telephone = '';
    public Message = '';
    public blockReadonly = false;

    async send () {

        const wait = WaitAtLeast( 3000 );

        this.Status.setLoading();

        const service = new QuickResponseService();
        const result = await service.send( {
            username: this.User,
            email: this.Email,
            telephone: this.Telephone,
            message: this.Message,
        } );


        await WaitAtLeast( wait );

        if ( result ) {
            Notifications.Success( 'Consulta enviada' );
            this.Status.setOk();
        }
        else {
            Notifications.Error( 'Al enviar el mensaje. Compruebe los datos ingresados.' );
            this.Status.setPending();
        }
    }

    reset () {
        this.User = '';
        this.Email = '';
        this.Telephone = '';
        this.Message = '';
        this.blockReadonly = false;
        this.Status.setPending();
    }

    get canContinue () {
        return Boolean(
            this.User &&
            this.Email &&
            this.Telephone &&
            this.Message
        );
    }

    protected onAfterRender () {

        const user = CredentialProvider.getUser();
        if ( user ) {
            this.User = user.nickname;
            this.blockReadonly = true;
        }
        else {
            this.User = '';
            this.blockReadonly = false;
        }
    }
}

export const { provider, useStore } = expose( QuickResponseFormStore );