import React from 'react';
import { Chip, Col, Input, Label, Row, VerticalCenter } from '@gower/react-ui/components';
import ReactDataSheet from '@gower/react-ui/datasheet';
import type { AuthorizationStore } from '../../store';
import type { AuthorizationRequestItem } from '@gower/cm-mdp-sdk/src/lib/AuthorizationRequests/services';

export type CellProps = {
    item: AuthorizationRequestItem,
    context: AuthorizationStore,
};

export const Viewer: React.FC<CellProps & ReactDataSheet.ValueViewerProps<any, CellProps>> = ( { item, context, ...props } ) => {
    if ( !item.isValid ) return null;

    const name = item.Service.Name ? ` - ${ item.Service.Name }` : '';

    return (
        <VerticalCenter>
            <Row columns paddingY={4}>
                <Col>
                    <Label p ellipsis>{item.Service.Id}{name}</Label>
                </Col>
                <Col show={item.Service?.AllowBirthControlPrescription}><Chip small textCenter blue label="Recetario de Anticonceptivos" /></Col>
                <Col show={item.Service?.AllowPrescription}><Chip small textCenter blue label="Recetario Crónico" /></Col>
                <Col show={item.Service?.AllowForm}><Chip small textCenter blue label="Planilla de sesiones" /></Col>
            </Row>
        </VerticalCenter>
    );

};

export const Header: React.FC<CellProps> = ( { context, ...props } ) => {
    return (
        <VerticalCenter textBlack xsmall noSelect textVerticalCenter gutter>
            Prestación
        </VerticalCenter>
    );
};