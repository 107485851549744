import React from 'react';
import { ErrorManager } from '@gower/react-ui/utils';
import { Specialty } from '@gower/cm-mdp-sdk/specialties';
import { Content, Row, Col, TextInput, Label, Div, Spacer, Button, Autocomplete, Divider, Checkbox } from '@gower/react-ui/components';
import { MessageBox } from '@gower/react-ui/message-box';
import { Notifications } from '@gower/react-ui/notifications';
import { expose, ViewStatus } from '@gower/react-ui/store';
import { Wait } from '@gower/utils';
import { SpecialtySubStore } from '../../store';
import { Form, FormHeader, FormInput } from '@gower/react-ui/template/form';

class SpecialtyEditorStore extends SpecialtySubStore {

    public Status = ViewStatus.new( 'pending' );
    public EditItem = new Specialty();
    public ErrorManager = ErrorManager.new( {
        fields: ['name']
    } );

    onAfterRender () {
        this.Status.setPending();
        this.EditItem = Specialty.clone( this.MainStore.Selected );
        if ( this.EditItem )
            this.Status.setOk();
    }

    public checkErrors () {

        this.ErrorManager.clear();
        if ( !this.EditItem.Name ) this.ErrorManager.setName( 'Completá este campo' );

        return !this.ErrorManager.hasErrors();
    }

    async save () {

        if ( this.checkErrors() ) {
            this.Status.setSaving();

            const result = await this.EditItem.save();
            if ( result.result ) {

                Notifications.Saved();
                await Wait( 1000 );
                this.MainStore.Selected.refresh( this.EditItem.getJson() );
                this.MainStore.list();
            } else {
                await MessageBox.Error( result.errorList );
            }

            this.Status.setOk();
        }
    }

    async delete () {
        const result = await MessageBox.YesNo( {
            title: 'Eliminar autorización',
            content: '¿Estás seguro de eliminar la autorizacion?. Esta acción no puede deshacerse'
        } );

        if ( result ) {
            this.Status.setLoading();
            if ( await this.Selected.delete() ) {
                Notifications.Success( 'Eliminada.' );
                this.MainStore.Items.remove( this.Selected );
                this.MainStore.list();
            } else {
                Notifications.Error( 'Al eliminar.' );
            }
            this.Status.setOk();
        }
    }

    async close () {
        this.MainStore.list();
    }
}

const { provider, useStore } = expose( SpecialtyEditorStore );

export const SpecialtyEditor = provider( () => {

    const store = useStore();
    const { EditItem } = store;

    return <>
        <Content columns scrollOnlyVertical>
            <Col>

                <FormHeader
                    title="Editar entidad"
                    subTitle={store.Selected?.Name}
                >

                    <Col gutter center>
                        <Button
                            actionTheme
                            disabled={!store.Status.isOk}
                            label="Cancelar"
                            onClick={() => store.close()}
                        />
                    </Col>
                    <Col gutter center>
                        <Button
                            primary
                            disabled={store.Status.isLoading}
                            loading={store.Status.isSaving}
                            onClick={() => store.save()}
                            label="Guardar"
                        />
                    </Col>
                </FormHeader>
            </Col>
            <Spacer>
                <Form orientation="center">
                    <FormInput
                        title="Nombre"
                        subTitle="Nombre de la especialidad"
                    >
                        <TextInput
                            filled
                            value={EditItem.Name}
                            onChangeText={t => EditItem.Name = t}
                            placeholder="Nombre"
                            readOnly={!store.Status.isOk}
                            error={store.ErrorManager.getName()}
                        />
                    </FormInput>

                    <FormInput
                        title="Estado"
                        subTitle="Estado de la especialidad"
                    >
                        <Autocomplete
                            filled
                            placeholder="Estado de la especialidad"
                            options={Specialty.VO.Status.getOptions()}
                            value={store.EditItem.Status}
                            onChange={( e ) => store.EditItem.Status = e.target.value}
                            readOnly={!store.Status.isOk}
                        />
                    </FormInput>

                    <FormInput
                        title="Código externo"
                        subTitle="Opcional. Código externo de la especialidad"
                    >
                        <TextInput
                            filled
                            value={EditItem.ExternalId}
                            onChangeText={t => EditItem.ExternalId = t}
                            placeholder="Código externo"
                            readOnly={!store.Status.isOk}
                        />
                    </FormInput>

                    <FormInput
                        title="Controles"
                        subTitle="Controles al autorizar"
                    >
                        <Div display="block">
                            <Checkbox
                                value={EditItem.NeedEffector}
                                onCheck={( v ) => EditItem.NeedEffector = v}
                                label="Controla Mat. Efector"
                                disabled={!store.Status.isOk}
                            />
                            <Checkbox
                                value={EditItem.NeedBilling}
                                onCheck={( v ) => EditItem.NeedBilling = v}
                                label="Controla Mat. Facturante"
                                disabled={!store.Status.isOk}
                            />
                            <Checkbox
                                value={EditItem.NeedApplicant}
                                onCheck={( v ) => EditItem.NeedApplicant = v}
                                label="Controla Mat. Solicitante"
                                disabled={!store.Status.isOk}
                            />
                        </Div>
                    </FormInput>

                    <FormInput
                        title="Configuración"
                        subTitle="Configuración al autorizar"
                    >
                        <Div display="block">
                            <Checkbox
                                value={EditItem.RequiredToken}
                                onCheck={( v ) => EditItem.RequiredToken = v}
                                label="Requiere token de afiliado"
                                disabled={!store.Status.isOk}
                            />
                            <Checkbox
                                value={EditItem.AllowPrescription}
                                onCheck={( v ) => EditItem.AllowPrescription = v}
                                label="Emite recetarios"
                                disabled={!store.Status.isOk}
                            />
                            <Div disabled={!EditItem.AllowPrescription}>
                                <Checkbox
                                    value={EditItem.AllowPrescription ? EditItem.AllowPrescriptionPapperOnly : false}
                                    onCheck={( v ) => EditItem.AllowPrescriptionPapperOnly = v}
                                    label="Emite solo recetarios en papel"
                                    disabled={!store.Status.isOk}
                                />
                            </Div>
                        </Div>
                    </FormInput>

                </Form>
            </Spacer>
        </Content>
    </>;

} );