import React from 'react';
import { useNews } from 'features/hooks/useNews';
import { Col, Content, Div, Label, Row, Spacer, Button, Autocomplete2, Alert, Colors, TextInput2, Tooltip } from '@gower/react-ui/components';
import { useStore } from '../store';
import { UnregisteredAgreement } from '@gower/cm-mdp-sdk/unregistered-agreements';
import { NewsContainer } from 'lib/components/news-container';
import { ButtonGroup, ButtonGroupItem } from '@gower/react-ui/template/button-group';
import { Authorization } from '@gower/cm-mdp-sdk/authorizations';

export const CalculatorDesktop = () => {

    const store = useStore();
    const news = useNews();

    React.useEffect( () => {
        news.refresh();
    }, [] );

    return <>
        <Content columns color="surface-0" scrollOnlyVertical>
            <Col>
                <Row padding={15}>
                    <Col gutter center>
                        <Div padding={10} >
                            <Label xxsemi p bold marginBottom={5}>Receta Electrónica</Label>
                            <Label xsmall p textGrey>Resto de convenios - Centro Médico de Mar del Plata.</Label>
                        </Div>
                    </Col>
                    <Spacer />
                    <Col gutter center>

                    </Col>
                </Row>
            </Col>

            <Col center fullWidth maxWidth={800}>
                <NewsContainer news={news.unregisteredPrescriptionNews} />
            </Col>

            <Col padding={10} >
                <Content columns justifyCenter gap={10}>
                    <Col center white borderRadius={5} fullWidth maxWidth={800} padding={20}>
                        <Row columns>

                            <Col height={30} />

                            <Col padding={8}>
                                <Autocomplete2
                                    large
                                    submitOnEnter
                                    disabled={!store.Status.isPending}
                                    prefix="Convenio:"
                                    clearable
                                    placeholder="Busque y seleccione el convenio a prescribir"
                                    options={() => UnregisteredAgreement.find( {
                                        status: UnregisteredAgreement.VO.Status.Active.Id,
                                        medere_enabled: true
                                    }, null, { all: true } )}
                                    filterKey={["Acronym", "Name"]}
                                    value={store.agreement}
                                    onChangeValue={v => store.agreement = v}
                                    displayKey={( { item, format } ) => (
                                        <Row noWrap>
                                            <Col gutter>
                                                <Label bold html={format( item.Acronym, { color: Colors['primary'] } )} />
                                            </Col>
                                            <Col gutter>
                                                <Label html={format( item.Name )} />
                                            </Col>
                                        </Row>
                                    )}
                                    selectedKey={( { item } ) => (
                                        <Row noWrap>
                                            <Col gutter>
                                                <Label bold label={item.Acronym} />
                                            </Col>
                                            <Col gutter>
                                                <Label label={item.Name} />
                                            </Col>
                                        </Row>
                                    )}
                                />

                            </Col>

                            <Col padding={8}>
                                <ButtonGroup
                                    mandatory
                                    active={store.AffiliateInputMode === 'document' ? 0 : 1}
                                    onChange={() => store.removeAffiliate()}
                                >
                                    <ButtonGroupItem disabled={store.Status.isSaving || store.Status.isLoading} label="Nº de Afiliado" onSelected={() => store.setAffiliateInputMode( 'credential' )} />
                                    <ButtonGroupItem disabled={store.Status.isSaving || store.Status.isLoading} label="Documento" onSelected={() => store.setAffiliateInputMode( 'document' )} />
                                </ButtonGroup>
                            </Col>

                            <Col padding={8} show={store.AffiliateInputMode === 'credential'}>
                                <TextInput2
                                    large
                                    disabled={!store.Status.isPending}
                                    prefix="Número de afiliado:"
                                    clearable
                                    value={store.affiliateId}
                                    onChangeValue={v => store.affiliateId = v}
                                    placeholder="Ingresá el socio a prescribir"
                                />
                            </Col>

                            <Col padding={8} show={store.AffiliateInputMode === 'document'}>
                                <TextInput2
                                    large
                                    disabled={!store.Status.isPending}
                                    prefix="Nº Documento del socio:"
                                    clearable
                                    value={store.affiliateDocumentNumber}
                                    onChangeValue={v => store.affiliateDocumentNumber = v}
                                    placeholder="Ingresá el documento a prescribir"
                                    contentLeft={
                                        <Autocomplete2
                                            disabled={!store.Status.isPending}
                                            width={90}
                                            borderless
                                            select
                                            selectedKey="Id"
                                            options={Authorization.VO.Documents.getOptions( false )}
                                            value={store.affiliateDocumentType}
                                            onChangeValue={( option ) => store.affiliateDocumentType = option.Id}
                                            displayKey={( { item } ) => (
                                                <Row textRight>
                                                    <Label bold>{item.Id}</Label>
                                                </Row>
                                            )}
                                        />
                                    }
                                />
                            </Col>

                            <Col padding={8}>
                                <TextInput2
                                    large
                                    disabled={!store.Status.isPending}
                                    prefix="Matrícula Efector:"
                                    clearable
                                    value={store.professionalId}
                                    onChangeValue={v => store.professionalId = v}
                                    placeholder="Matrícula profesional"
                                />
                            </Col>

                            <Col gutter>
                                <Row>
                                    <Col gutter show={!store.Status.isOk}>
                                        <Button width={120} text label="Restablecer" disabled={store.Status.isLoading} onClick={() => store.reset()} />
                                    </Col>
                                    <Spacer />
                                    <Col gutter show={!store.Status.isOk}>
                                        <Button minWidth={240} primary normal letterSpacing="wide" label="Generar receta electrónica" loading={store.Status.isLoading} disabled={!store.canConfirm} onClick={() => store.confirm()} />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>

                </Content>
            </Col>
        </Content>
    </>;
};