import React from 'react';
import { ReactCodeInput, Div, Button, Row, Col, Content, Label, Spacer } from '@gower/react-ui/components';
import { context } from './store';

export const Step2Form: React.FC = () =>
{
    const store = React.useContext( context ).current;
    const { status } = store;
    const [code, setCode] = React.useState( '' );

    return <>
        <Content columns fullHeight>
            <Col fullWidth center py-5 display='block' >
                <Label p normal textCenter textWeight='500' letterSpacing="wide" label='Ingresá el código de verificación que enviamos a tu email.' mb-4 />
                <Row justifyCenter py-2 onKeyUp={( e ) => e.key === 'Enter' && store.sendSecurityCode( code )}>
                    <Col>
                        <ReactCodeInput
                            autoFocus
                            fields={6}
                            placeholder={Array( 6 ).fill( '#' )}
                            fieldWidth={55}
                            onComplete={( e ) => setCode( e )}
                        />
                    </Col>
                </Row>
            </Col>

            <Spacer vertical>
                <Div fullWidth block minHeight={40} maxHeight={100} pt-2 textCenter scrollOnlyVertical>
                    {store.LastErrors && store.LastErrors.map( ( error,key ) => <Label p key={key} textRed normal>{error}</Label> )}
                </Div>
                <Div fullWidth useMediaQuery height={46} xsHeight={64}>
                    <Button pointer
                        normal
                        textWhite
                        color="primary"
                        fullWidth
                        fullHeight
                        textWeight='500'
                        letterSpacing='wide'
                        loading={status.isLoading}
                        disabled={status.isErrored}
                        onClick={() => store.sendSecurityCode( code )}
                    >
                        Verificar
                    </Button>
                </Div>
                <Div fullWidth mt-2 useMediaQuery height={46} xsHeight={64}>
                    <Button
                        disabled={status.isLoading || status.isErrored}
                        text
                        normal
                        fullWidth
                        fullHeight
                        textColor='surface-8'
                        textWeight='500'
                        letterSpacing='wide'
                        label="Volver"
                        onClick={() => store.backStep()}
                    />
                </Div>
            </Spacer>
        </Content>
    </>;
};