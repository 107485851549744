import React from 'react';
import { SidedCrudStore } from '../sided-crud-store';
import { Content, Render, Row, Spacer } from '@gower/react-ui/components';
import { TemplatesProps } from './list.props';
import { getActions } from './list.actions';
import { Header } from './components/header';
import { Table } from './components/table';

export type ListProps = TemplatesProps &
{
    crudStore: SidedCrudStore;
};
export const ListTemplate: React.FC<ListProps> = ( { ...props } ) =>
{
    const store = props.crudStore.ListStore;

    return <>
        {props.template && <Render content={props.template} props={{ ...props.injectProps, ...store.getProps(), ...getActions( { store, ...props } ) }} />}
        {!props.template && <>
            <Content width="100%" height="100%" color="white" noScroll>
                <Content columns fullWidth fullHeight scrollOnlyVertical>
                    <Header {...props} store={store} />
                    <Spacer vertical paddingX={15}>
                        <Row maxWidth={props.maxWidth}>
                            <Table {...props} store={store} />
                        </Row>
                    </Spacer>
                </Content>
            </Content>
        </>}
    </>;
};