import { provider, useStore } from './store';
import { Content, Row, Col, TextInput, Label, Div, Spacer, Button, Divider, Code, Chip, Autocomplete } from '@gower/react-ui/components';
import { Loader } from '@gower/react-ui/template/loader';
import { QuickReponseConfiguration } from './components/quick-response-config';
import { GsPrecriptionsConfiguration } from './components/gs-prescriptions';
import { UnregisteredPrecriptionsConfiguration } from './components/unregistered-prescriptions';

export const ConfigurationView = provider( () => {

    const store = useStore();

    return <>
        <Content columns scrollOnlyVertical>
            <Col>
                <Row padding={15}>
                    <Col gutter center>
                        <Div padding={10} >
                            <Label xxsemi p bold marginBottom={5}>Configuración</Label>
                            <Label xsmall p textGrey>Administración del sistema</Label>
                        </Div>
                    </Col>
                    <Spacer />
                </Row>
            </Col>
            <Spacer>
                <Content start padding={20} justifyStart>
                    <Loader status={store.Status}>
                        {!store.Status.isLoading && <>
                            <Col start maxWidth={800} paddingX={20} paddingBottom={100}>

                                <QuickReponseConfiguration store={store} />
                                <Row gutter>
                                    <Divider />
                                </Row>

                                <UnregisteredPrecriptionsConfiguration store={store} />
                                <Row gutter>
                                    <Divider />
                                </Row>

                                {window.env?.REACT_APP_GS_PRESCRIPTIONS_ENABLE === 'true' && <>
                                    <GsPrecriptionsConfiguration store={store} />
                                    <Row gutter>
                                        <Divider />
                                    </Row>
                                </>}

                                <Row justifyEnd >
                                    <Col gutter hidden>
                                        <Button
                                            actionTheme
                                            disabled={!store.Status.isOk}
                                            label="Cancelar"
                                        />
                                    </Col>
                                    <Col gutter >
                                        <Button
                                            primary
                                            disabled={store.Status.isLoading}
                                            loading={store.Status.isSaving}
                                            onClick={() => store.save()}
                                            label="Guardar"
                                        />
                                    </Col>
                                </Row>

                            </Col>
                        </>}
                    </Loader>
                </Content>
            </Spacer>
        </Content>
    </>;
}, {} );
