import React from 'react';
import { Col, Content, Div, Label, Row, Spacer, Button } from '@gower/react-ui/components';
import { DataGrid, DataGridColumn, DataGridTemplateProps } from '@gower/react-ui/template/data-grid';
import { useStore } from '../store';
import { AgreementConfigsEditor } from './components/agreement-configs-editor';
import { News } from '@gower/cm-mdp-sdk/news';
import { WaitAtLeast } from '@gower/utils';
import { AgreementConfig } from '@gower/cm-mdp-sdk/agreement-config';
import { AiOutlineCalendar } from '@gower/react-ui/icons';

export const AgreementConfigsDesktop = () => {

    const store = useStore();

    if ( store.Mode === 'edit' )
        return <>
            <AgreementConfigsEditor store={store} />
        </>;
    else if ( store.Mode === 'list' ) {
        return <>
            <Content columns>
                <Col>
                    <Row padding={15}>
                        <Col gutter center>
                            <Div padding={10} >
                                <Label xxsemi p bold marginBottom={5}>Convenios</Label>
                                <Label xsmall p textGrey>Configuración de convenios del sistema.</Label>
                            </Div>
                        </Col>
                        <Spacer />
                        <Col gutter center>

                        </Col>
                    </Row>
                </Col>
                <Spacer vertical noScroll>
                    <DataGrid

                        scroll
                        autoSorting
                        data={store.Items}
                        status={store.Status.Value}
                        loader
                        mobileTemplate={( { data }: { data: AgreementConfig; } ) => (
                            <Row
                                white
                                center
                                borderRadius={5}
                                padding={5}
                                activeEffect
                                noWrap
                            >
                                <Col
                                    stretch
                                    width={4}
                                    color="surface-2"
                                />
                                <Spacer center gutter paddingX={8} ellipsis>
                                    <Row columns>
                                        <Col>
                                            <Label p xxsmall bold>
                                                {data.Agreement.Name}
                                            </Label>
                                        </Col>
                                        {data.LastUpdated && <Col>
                                            <Col center horizontalGutter>
                                                <AiOutlineCalendar />
                                            </Col>
                                            <Col center horizontalGutter>
                                                <Label p>
                                                    <Label gutter xsmall label={( data.LastUpdated as any ).toStringDate( 'short' )} />
                                                </Label>
                                            </Col>
                                        </Col>}
                                    </Row>
                                </Spacer>
                                <Col center gutter>
                                    <Div textRight>
                                        <Label p xxsmall
                                            xsmall
                                            textRed={data.Status.isDeactivated}
                                            textGreen={data.Status.isActive}
                                            label={data.Status.Description} />
                                    </Div>
                                </Col>
                            </Row>
                        )}
                    >
                        <DataGridColumn field="Agreement.Description" title="Convenio" cellProps={{ bold: true }} />
                        <DataGridColumn field="Agreement.Name" title="Nombre" />
                        <DataGridColumn field="Status.Description" title="Estado" />
                        <DataGridColumn field="LastUpdated" title="Ultimo Cambio" type="DATE" />
                        <DataGridColumn
                            field="actions"
                            title=""
                            size={130}
                            template={( { data }: DataGridTemplateProps<News> ) => (
                                <Actions data={data} />
                            )}
                        />
                    </DataGrid>
                </Spacer>
            </Content>
        </>;
    }

};

const Actions = ( { data } ) => {

    const store = useStore();
    const [loading, setLoading] = React.useState<'none' | 'hide' | 'active' | 'disable'>( 'none' );

    async function active () {
        setLoading( 'active' );
        const wait = WaitAtLeast( 500 );
        await store.active( data );
        await WaitAtLeast( wait );
        setLoading( 'none' );
    }

    async function disable () {
        setLoading( 'disable' );
        const wait = WaitAtLeast( 500 );
        await store.disable( data );
        await WaitAtLeast( wait );
        setLoading( 'none' );
    }

    async function hide () {
        setLoading( 'hide' );
        const wait = WaitAtLeast( 500 );
        await store.hide( data );
        await WaitAtLeast( wait );
        setLoading( 'none' );
    }

    return <>
        <Row end noWrap justifyEnd>
            <Col gutter center>
                <Button
                    minHeight={28}
                    height={28}
                    depressed
                    green={data.Status.isActive}
                    textWhite={data.Status.isActive}
                    hoverTextColor={data.Status.isActive ? "white" : undefined}
                    xsmall
                    label="Activo"
                    padding={4}
                    borderRadiusRight={0}
                    disabled={loading !== 'none'}
                    loading={loading === 'active'}
                    onClick={() => active()}
                />
                <Button
                    minHeight={28}
                    height={28}
                    depressed
                    red={data.Status.isDeactivated}
                    textWhite={data.Status.isDeactivated}
                    hoverTextColor={data.Status.isDeactivated ? "white" : undefined}
                    xsmall
                    label="Desactivado"
                    padding={4}
                    borderRadius={0}
                    disabled={loading !== 'none'}
                    loading={loading === 'disable'}
                    onClick={() => disable()}
                />
                <Button
                    minHeight={28}
                    height={28}
                    depressed
                    red={data.Status.isHidden}
                    textWhite={data.Status.isHidden}
                    hoverTextColor={data.Status.isHidden ? "white" : undefined}
                    xsmall
                    label="Oculto"
                    padding={4}
                    borderRadiusLeft={0}
                    disabled={loading !== 'none'}
                    loading={loading === 'hide'}
                    onClick={() => hide()}
                />
            </Col>
            <Col gutter>
                <Button
                    block
                    bold
                    xxsmall
                    label="Editar"
                    onClick={() => store.edit( data )}
                    disabled={loading !== 'none'}
                />
            </Col>
        </Row>
    </>;
};
