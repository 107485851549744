import React from 'react';
import { ErrorManager } from '@gower/react-ui/utils';
import { UnregisteredAgreement } from '@gower/cm-mdp-sdk/unregistered-agreements';
import { Content, Row, Col, TextInput, Label, Div, Spacer, Button, Autocomplete, Colors, Divider, Checkbox } from '@gower/react-ui/components';
import { MessageBox } from '@gower/react-ui/message-box';
import { Notifications } from '@gower/react-ui/notifications';
import { Form, FormHeader, FormInput } from '@gower/react-ui/template/form';
import { expose, ViewStatus } from '@gower/react-ui/store';
import { UnregisteredAgreementSubStore } from '../../store';


class UnregisteredAgreementsEditorStore extends UnregisteredAgreementSubStore {

    public Status = ViewStatus.new( 'pending' );
    public EditItem = new UnregisteredAgreement();
    public ErrorManager = ErrorManager.new( {
        fields: ['name', 'acronym', 'status','externalCode']
    } );

    onAfterRender () {
        this.Status.setPending();
        this.EditItem = UnregisteredAgreement.clone( this.MainStore.Selected );
        if ( this.EditItem )
            this.Status.setOk();
    }

    public checkErrors () {

        this.ErrorManager.clear();
        if ( !this.EditItem.Name ) this.ErrorManager.setName( 'Completá este campo' );
        if ( !this.EditItem.Acronym ) this.ErrorManager.setAcronym( 'Completá este campo' );
        if ( !this.EditItem.ExternalCode ) this.ErrorManager.setExternalCode( 'Completá este campo' );
        if ( !this.EditItem.Status || this.EditItem.Status.isUnspecified ) this.ErrorManager.setStatus( 'Completá este campo' );

        return !this.ErrorManager.hasErrors();
    }

    async save () {

        if ( this.checkErrors() ) {
            this.Status.setSaving();

            const result = await this.EditItem.save();
            if ( result.result ) {

                Notifications.Saved();
                this.MainStore.Selected.refresh( this.EditItem.getJson() );
                this.MainStore.list();
            } else {
                await MessageBox.Error( result.errorList );
            }

            this.Status.setOk();
        }
    }

    async delete () {
        const result = await MessageBox.YesNo( {
            title: 'Eliminar',
            content: '¿Estás seguro de eliminar el elemento?. Esta acción no puede deshacerse'
        } );

        if ( result ) {
            this.Status.setLoading();
            if ( await this.Selected.delete() ) {
                Notifications.Success( 'Eliminado.' );
                this.MainStore.Items.remove( this.Selected );
                this.MainStore.list();
            } else {
                Notifications.Error( 'Al eliminar.' );
            }
            this.Status.setOk();
        }
    }

    async close () {
        this.MainStore.list();
    }
}

const { provider, useStore } = expose( UnregisteredAgreementsEditorStore );

export const UnregisteredAgreementsEditor = provider( () => {

    const store = useStore();
    const { EditItem } = store;


    function save () {
        store.save();
    }

    return <>
        <Content columns scrollOnlyVertical>
            <FormHeader
                title="Editar convenio"
                subTitle={store.Selected?.Name}
            >
                <Col gutter center>
                    <Button
                        actionTheme
                        disabled={!store.Status.isOk}
                        label="Cancelar"
                        onClick={() => store.close()}
                    />
                </Col>
                <Col gutter center>
                    <Button
                        primary
                        disabled={store.Status.isLoading}
                        loading={store.Status.isSaving}
                        onClick={() => save()}
                        label="Guardar"
                    />
                </Col>
            </FormHeader>
            <Form>
                <FormInput
                    title="Nombre"
                    subTitle="Acrónimo del convenio"
                >
                    <TextInput
                        filled
                        value={EditItem.Acronym}
                        onChangeText={t => EditItem.Acronym = t}
                        placeholder="Ej. SAMI"
                        readOnly={!store.Status.isOk}
                        error={store.ErrorManager.getAcronym()}
                    />
                </FormInput>

                <FormInput
                    title="Descripción"
                    subTitle="Nombre completo del convenio"
                >
                    <TextInput
                        filled
                        value={EditItem.Name}
                        onChangeText={t => EditItem.Name = t}
                        placeholder="Nombre completo"
                        readOnly={!store.Status.isOk}
                        error={store.ErrorManager.getName()}
                        hint="Minimo 4 carácteres"
                    />
                </FormInput>

                <FormInput
                    title="Código externo"
                    subTitle="Referencia en el sistema"
                >
                    <TextInput
                        filled
                        value={EditItem.ExternalCode}
                        onChangeText={t => EditItem.ExternalCode = t}
                        placeholder="# # # #"
                        readOnly={!store.Status.isOk}
                        error={store.ErrorManager.getExternalCode()}
                    />
                </FormInput>

                <FormInput
                    title="Estado"
                    subTitle="Estado del convenio"
                >
                    <Autocomplete
                        filled
                        placeholder="Estado"
                        options={UnregisteredAgreement.VO.Status.getOptions()}
                        value={store.EditItem.Status}
                        onChange={( e ) => store.EditItem.Status = e.target.value}
                        readOnly={!store.Status.isOk}
                    />
                </FormInput>

                {window.env?.REACT_APP_MEDERE_PRESCRIPTIONS_ENABLE === 'true' && <>

                    <Divider marginY={8} />

                    <Row>
                        <Col gutter center cols={6}>
                            <Div block>
                                <Label p bold xxsmall>Receta Digital Medere</Label>
                            </Div>
                        </Col>
                        <Col gutter start cols={6}>
                            <Row columns>
                                <Col>
                                    <Checkbox
                                        value={EditItem.MederePrescriptionActive}
                                        onCheck={value => EditItem.MederePrescriptionActive = value}
                                        label="Activado"
                                    />
                                </Col>
                                <Col paddingTop={4}>
                                    <TextInput
                                        filled
                                        value={EditItem.MederePrescriptionCode}
                                        onChangeText={t => EditItem.MederePrescriptionCode = t}
                                        prefix="Código del convenio:"
                                        placeholder="Código del convenio"
                                        readOnly={!store.Status.isOk}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </>}

            </Form>
        </Content>
    </>;

} );