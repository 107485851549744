import React from "react";
import { ReactDataSheet } from '@gower/react-ui/datasheet';
import { AuthorizationStore } from '../../store';

import * as CounterCol from './CounterCol';
import * as ServiceCol from './ServiceCol';
import * as QuantityCol from './QuantityCol';
import * as AmountCol from './AmountCol';
import * as ActionCol from './ActionCol';

export type GridProps = {
    Store: AuthorizationStore;
};
export const Grid: React.FC<GridProps> = ( { Store, ...props } ) => {
    const gridRef = React.useRef<any>( null );
    const Headers: any[] = [
        {
            className: "header",
            width: 30,
            readOnly: true,
            valueViewer: ( { ...props }: any ) => <CounterCol.Header {...{ ...props, context: Store }} />
        },
        {
            className: "header",
            readOnly: true,
            valueViewer: ( { ...props }: any ) => <ServiceCol.Header {...{ ...props, context: Store }} />
        },
        {
            className: "header",
            readOnly: true,
            width: 100,
            valueViewer: ( { ...props }: any ) => <QuantityCol.Header {...{ ...props, context: Store }} />
        },
        // {
        //     className: "header",
        //     readOnly: true,
        //     width: 110,
        //     valueViewer: ( { ...props }: any ) => <PriceCol.Header {...{ ...props, context: Store }} />
        // },
        {
            className: "header",
            readOnly: true,
            width: 110,
            valueViewer: ( { ...props }: any ) => <AmountCol.Header {...{ ...props, context: Store }} />
        },
        {
            className: "header",
            readOnly: true,
            width: 60,
            valueViewer: ( { ...props }: any ) => <ActionCol.Header {...{ ...props, context: Store }} />
        },
    ];

    const Items: any[][] = [Headers];

    let i = 0;

    for ( let item of Store.Request.Items ) {
        const row = i;
        let col = 0;
        const Row = [];

        Row.push(
            {
                className: `__items__${ row }__${ col++ }`,
                readOnly: true,
                valueViewer: ( { ...props }: any ) => <CounterCol.Viewer {...{ ...props, item, context: Store }} />,
            },
            {
                className: `__items__${ row }__${ col++ }`,
                readOnly: true,
                valueViewer: ( { ...props }: any ) => <ServiceCol.Viewer {...{ ...props, item, context: Store }} />,
            },
            {
                className: `__items__${ row }__${ col++ }`,
                valueViewer: ( { ...props }: any ) => <QuantityCol.Viewer {...{ ...props, item, context: Store }} />,
                dataEditor: ( { ...props }: any ) => <QuantityCol.Editor {...{ ...props, item, context: Store }} />,
            },
            {
                className: `__items__${ row }__${ col++ } header`,
                readOnly: true,
                valueViewer: ( { ...props }: any ) => <AmountCol.Viewer {...{ ...props, item, context: Store }} />,
            },
            {
                className: `__items__${ row }__${ col++ } header`,
                readOnly: true,
                valueViewer: ( { ...props }: any ) => <ActionCol.Viewer {...{ ...props, item, context: Store }} />,
            }
        );

        Items.push( Row );
        i++;
    }

    return <>
        <ReactDataSheet
            ref={gridRef}
            className="grid__datatable"
            valueRenderer={( cell ) => cell.value}
            data={Items}
        />
    </>;
};

