import React from 'react';
import { Col, Content, Div, Label, Row, Spacer, Alert, Button, Colors, Chip, Panel } from '@gower/react-ui/components';
import { DataGrid, DataGridColumn, DataGridTemplateProps } from '@gower/react-ui/template/data-grid';
import { useStore } from '../store';
import { NewsCreate } from './components/news-create';
import { NewsEditor } from './components/news-editor';
import { News } from '@gower/cm-mdp-sdk/news';
import { MdAddCircleOutline, TbChevronDown } from '@gower/react-ui/icons';
import { ButtonGroup, ButtonGroupItem } from '@gower/react-ui/template/button-group';
import { WaitAtLeast } from '@gower/utils';
import { NewsItem } from 'lib/components/news-container';

export const NewsDesktop = () => {

    const store = useStore();

    if ( store.Mode === 'create' )
        return <>
            <NewsCreate store={store} />
        </>;

    else if ( store.Mode === 'edit' )
        return <>
            <NewsEditor store={store} />
        </>;
    else if ( store.Mode === 'list' ) {
        return <>
            <Content columns>
                <Col>
                    <Row padding={15}>
                        <Col gutter center>
                            <Div padding={10} >
                                <Label xxsemi p bold marginBottom={5}>Noticias</Label>
                                <Label xsmall p textGrey>Noticias del sistema.</Label>
                            </Div>
                        </Col>
                        <Spacer />
                        <Col gutter center>

                        </Col>
                        <Col gutter center>
                            <Button
                                primary
                                icon={<MdAddCircleOutline size={16} />}
                                label="Nueva noticia"
                                onClick={() => store.create()}
                            />
                        </Col>
                    </Row>
                </Col>
                <Spacer vertical noScroll>
                    <DataGrid
                        loader
                        store={store}
                        autoSorting
                        showFilters={["status", "type", "agreement"]}
                        showReload
                        scroll
                        showPagination
                        showSearch
                        mobileTemplate={( { data } ) => (
                            <Row white center borderRadius={5} minHeight={45} padding={10} activeEffect>
                                <Col cols={6}>
                                    <Label label={data.Id} />
                                </Col>
                                <Col cols={6}>
                                </Col>
                            </Row>
                        )}
                    >
                        <DataGridColumn field="Title" title="Titulo" />
                        <DataGridColumn field="Type.Description" title="Tipo" cellProps={{ bold: true }} />
                        <DataGridColumn
                            field="agreement"
                            title="Convenio"
                            template={( { data }: DataGridTemplateProps<News> ) => (
                                <>
                                    <Label label={data.Agreement.Description} show={!data.Agreement.isUnspecified} />
                                </>
                            )}
                        />
                        <DataGridColumn field="Date" title="Fecha" type="DATE" />
                        <DataGridColumn
                            field="actions"
                            title=""
                            size={130}
                            template={( { data }: DataGridTemplateProps<News> ) => (
                                <Actions data={data} />
                            )}
                        />
                    </DataGrid>
                </Spacer>
            </Content>
        </>;
    }

};



const Actions = ( { data } ) => {

    const store = useStore();
    const [loading, setLoading] = React.useState<'none' | 'active' | 'disable'>( 'none' );

    async function active () {
        setLoading( 'active' );
        const wait = WaitAtLeast( 500 );
        await store.active( data );
        await WaitAtLeast( wait );
        setLoading( 'none' );
    }

    async function disable () {
        setLoading( 'disable' );
        const wait = WaitAtLeast( 500 );
        await store.disable( data );
        await WaitAtLeast( wait );
        setLoading( 'none' );
    }

    return <>
        <Row end noWrap justifyEnd>
            <Col gutter center>
                <Button
                    minHeight={28}
                    height={28}
                    depressed
                    green={data.Status.isActive}
                    textWhite={data.Status.isActive}
                    hoverTextColor={data.Status.isActive ? "white" : undefined}
                    xsmall
                    label="Activo"
                    padding={4}
                    borderRadiusRight={0}
                    disabled={loading !== 'none'}
                    loading={loading === 'active'}
                    onClick={() => active()}
                />
                <Button
                    minHeight={28}
                    height={28}
                    depressed
                    red={data.Status.isDeactivated}
                    textWhite={data.Status.isDeactivated}
                    hoverTextColor={data.Status.isDeactivated ? "white" : undefined}
                    xsmall
                    label="Oculto"
                    padding={4}
                    borderRadiusLeft={0}
                    disabled={loading !== 'none'}
                    loading={loading === 'disable'}
                    onClick={() => disable()}
                />
            </Col>
            <Col gutter>
                <Preview item={data} />
            </Col>
            <Col gutter>
                <Button
                    block
                    bold
                    xxsmall
                    label="Editar"
                    onClick={() => store.edit( data )}
                    disabled={loading !== 'none'}
                />
            </Col>
        </Row>
    </>;
};



const Preview: React.FC<{ item: News; }> = ( { item } ) => {

    const menuEl = React.useRef( null );

    function closeMenu () {
        menuEl.current?.hide();
        return true;
    }

    return <>
        <Button
            bold
            block
            xxsmall
            label="Ver"
            onClick={( event ) => menuEl.current.toggle( event )}
            contentRight={<TbChevronDown size={16} />}
        />
        <Panel dismissable ref={menuEl}>
            <Row width={500} noWrap columns onClick={closeMenu}>
                <NewsItem item={item} />
            </Row>
        </Panel>
    </>;
};