import React from 'react';
import { Button, VerticalCenter } from '@gower/react-ui/components';
import { AiFillDelete } from '@gower/react-ui/icons';
import ReactDataSheet from '@gower/react-ui/datasheet';
import type { AuthorizationStore } from '../../store';
import type { AuthorizationRequestItem } from '@gower/cm-mdp-sdk/src/lib/AuthorizationRequests/services';
import { Wait } from '@gower/utils';

export type CellProps = {
    item: AuthorizationRequestItem,
    context: AuthorizationStore,
};

export const Viewer: React.FC<CellProps & ReactDataSheet.ValueViewerProps<any, CellProps>> = ( { item, row, context } ) => {

    if ( !item.isValid ) return null;

    return (
        <VerticalCenter fullWidth>
            <Button text textRed pa-0
                disabled={!context.canAddServices}
                onClick={() => context.removeService(item)}
            >
                <AiFillDelete size={20} />
            </Button>
        </VerticalCenter>
    );

};

export const Header: React.FC<CellProps> = ( { context, ...props } ) => {
    return null;
};