import { Render } from '@gower/react-ui/components';
import { PropsWithChildren } from 'react';
import { ContextTemplatesProps, TemplatesProps } from '../list.props';
import { Loader } from '@gower/react-ui/template/loader';
import { getActions } from '../list.actions';

export type TableLoaderProps = TemplatesProps & ContextTemplatesProps & {

};
export const TableLoader: React.FC<PropsWithChildren<TableLoaderProps>> = ( { children, ...props } ) =>
{
    const { store } = props;

    return <>
        {props.loader && <Render content={props.loader} props={{ children, ...props.injectProps, ...store.getProps() , ...getActions( props )}} />}
        {!props.loader && <>
            <Loader
                status={store.Status}
                loadingSkeletons={props.loadingSkeletons || 4}
                loadingSkeletonsHeight={props.loadingSkeletonsHeight || undefined}
            >
                {children}
            </Loader>
        </>}
    </>;

};