import { Event } from 'typescript.events';
import { ModelType , IBaseModel } from '@gower/models-sdk/models';
import type { CrudModes } from '@gower/react-ui/store';
import type { SidedCrudStore } from '../sided-crud-store';

export type Model = ( new ( ...args: any[] ) => InstanceOfModel  ) & IBaseModel<any> ;
export type InstanceOfModel = ModelType<any>;

export type SidedCrudHash = {
    'el.id': string;
    'vm': CrudModes;
};

export abstract class CrudSubStore extends Event
{
    protected get MainStore () { return this._main; }
    protected get viewName () { return this._main.viewName; }
    constructor ( private _main: SidedCrudStore )
    {
        super();
    }
}
