import React from 'react';
import { AuthorizationServiceCode } from '@gower/cm-mdp-sdk/authorizations';
import { Button, Col, Content, Label, ModalWindow, Row, SearchInput, Spacer } from '@gower/react-ui/components';
import { Loader } from '@gower/react-ui/template/loader';
import { provider, useStore } from './store';
export type VerifyServicesModalProps = {
    affiliateId: string;
    affiliatePlan: string;
    agreement: string,
    isOpen: boolean;
    canSelect: boolean;
    onClose: () => void;
    onSelect: ( service: AuthorizationServiceCode ) => void;
};
export const VerifyServicesModal: React.FC<VerifyServicesModalProps> = provider( ( { isOpen, canSelect, onSelect, onClose } ) => {

    const store = useStore();
    const [selected, setSelected] = React.useState( null );

    function close () {

        setSelected( null );
        store.Status.setOk();
        store.Services = [];
        onClose && onClose();
    }

    async function select ( item, key ) {
        if ( canSelect ) {
            setSelected( key );
            store.Status.setSaving();
            onSelect && await onSelect( item );
            close();
        }
    }


    return <>
        <ModalWindow
            variant="modal"
            overlayProps={{
                top: 0,
                left: 0,
                position: 'fixed',
                width: "100vw",
                height: "100vh",
                zIndex: 10,
                onClick: close
            }}
            isOpen={isOpen}
            onClose={close}
            title={`Buscar código de prestación - ${ store.Agreement?.Description }`}

        >
            <Row
                fullWidth
                maxWidth={1000}
                height="100%"
            >
                <Content columns>
                    <Col gutter>
                        <Row paddingX={20} paddingTop={20}>
                            <Spacer center gutter>
                                <SearchInput
                                    large
                                    autoFocus
                                    clearable={false}
                                    value={store.SearchText}
                                    onChangeText={text => store.SearchText = text}
                                    readOnly={store.Status.isLoading}
                                    placeholder="Ingresá tu búsqueda"
                                    onSearch={() => store.search()}
                                />
                            </Spacer>
                            <Col center gutter>
                                <Button
                                    loading={store.Status.isLoading}
                                    actionTheme
                                    primary
                                    label="Consultar"
                                    onClick={() => store.search()}
                                />
                            </Col>
                        </Row>
                    </Col>

                    <Spacer vertical scroll padding={20}>
                        <Loader status={store.Status}>
                            <Row start disabled={selected != null}>
                                {store.Services?.map( ( item, key ) => (
                                    <Col
                                        key={key}
                                        padding={5}
                                        color={selected === key ? "blue-1" : undefined}
                                        fullWidth
                                        onClick={() => select( item, key )}
                                        activeEffect={canSelect}
                                    >
                                        <Row noWrap>
                                            <Col gutter>
                                                <Label xxsmall bold label={`${ item?.Id }`} />
                                            </Col>
                                            <Col gutter>
                                                <Label normal label={item?.Name} />
                                            </Col>
                                        </Row>
                                    </Col>
                                ) )}
                            </Row>
                        </Loader>
                    </Spacer>

                    {/* <Spacer vertical gutter show={store.Status.isPending || store.Status.isLoading}>
                        <Content
                            textColor="blue-7"
                            color="surface-1"
                            rounded
                            padding={10}
                        >
                            <Col fullWidth center hidden={store.Status.isLoading}>
                                <Row justifyCenter>
                                    <Col center padding={5}>
                                        <AiFillInfoCircle />
                                    </Col>
                                    <Col center>
                                        <Label bold normal>Ingresá un código para validar.</Label>
                                    </Col>
                                </Row>
                            </Col>
                            <Col fullWidth center show={store.Services && store.Status.isOk}>

                            </Col>
                        </Content>
                    </Spacer> */}
                    {/* 
                    <Col gutter show={store.Status.isErrored}>
                        <Row justifyCenter>
                            <Col center>
                                <Div block>
                                    {store.lastErrors.map( ( text, key ) => <Label key={key} p textRed bold normal>{text}</Label> )}
                                </Div>
                            </Col>
                        </Row>
                    </Col> */}
                </Content>
            </Row>
        </ModalWindow>
    </>;

} );