import React from 'react';
import { Col, Label, Row } from '@gower/react-ui/components';
import { ProgressSlider } from '@gower/react-ui/template/progress-slider';
import { useStore } from '../../store';
import { Authorization } from '@gower/cm-mdp-sdk/authorizations';

export const Progress: React.FC = () => {

    const store = useStore();

    return <>
        <Row columns paddingY={10}>
            <Col padding={15}>
                <Label xxsmall textGrey label="Progreso" />
            </Col>
            <Col padding={20}>
                <ProgressSlider
                    items={[
                        { label: 'Pendiente' },
                        //{ label: 'Aprobada' },
                        { label: 'Emitida' },
                        { label: 'Presentada' },
                    ]}
                    selected={{
                        [Authorization.VO.Status.Pending.Id]: 0,
                        [Authorization.VO.Status.Issued.Id]: 1,
                        [Authorization.VO.Status.Submitted.Id]: 2,
                    }[store.Authorization.Status.Id]}
                />
            </Col>
        </Row>

    </>;

};