import React from 'react';
import { Alert, Button, Col, Content, ModalWindow, Row, TextAreaInput, TextInput } from '@gower/react-ui/components';
import { provider, useStore } from './store';
import { BiIdCard, BsTelephone, MdMail } from '@gower/react-ui/icons';

export type QuickResponseModalProps = {
    isOpen: boolean;
    onClose: () => void;
};
export const QuickResponseModal: React.FC<QuickResponseModalProps> = provider( ( { isOpen, onClose } ) => {

    const store = useStore();

    function close () {
        store.reset();
        onClose();
    }

    return <>
        <ModalWindow
            overlayProps={{
                position: 'fixed',
                zIndex: 10,
            }}
            isOpen={isOpen}
            onClose={close}
            title="Asistencia Rápida"
            height="80vh"
            footerTemplate={<>
                <Row justifyEnd padding={10}>
                    <Col center gutter show={!store.Status.isOk}>
                        <Button
                            minWidth={100}
                            normal
                            label="Cancelar"
                            onClick={close}
                        />
                    </Col>
                    <Col center gutter show={!store.Status.isOk}>
                        <Button
                            show={!store.Status.isOk}
                            actionTheme
                            minWidth={100}
                            bold
                            normal
                            label="Enviar mensaje"
                            disabled={!store.canContinue}
                            loading={store.Status.isLoading}
                            onClick={() => store.send()}
                        />
                    </Col>
                    <Col center gutter show={store.Status.isOk}>
                        <Button
                            actionTheme
                            minWidth={100}
                            bold
                            normal
                            label="Aceptar"
                            onClick={close}
                        />
                    </Col>
                </Row>
            </>}
        >
            <Content
                fullWidth
                padding={20}
                maxWidth={1000}
                maxHeight={500}
            >
                <Row columns>
                    <Col gutter>
                        <Alert
                            fullWidth
                            show={!store.Status.isOk}
                            content="Envíenos su consulta ó inconveniente completando el siguiente formulario."
                        />
                        <Alert
                            fullWidth
                            show={store.Status.isOk}
                            title="Consulta enviada"
                            color="green-3"
                            content="Le responderemos a la brevedad. ¡Gracias por su consulta!"
                        />
                    </Col>
                    <Col gutter>
                        <TextInput
                            label="Matrícula ó usuario"
                            placeholder="Ingresá tu usuario o matrícula."
                            readOnly={store.blockReadonly || !store.Status.isPending}
                            contentLeft={<BiIdCard size={16} />}
                            value={store.User}
                            onChangeText={t => store.User = t}
                        />
                    </Col>
                    <Col gutter>
                        <TextInput
                            autoFocus
                            label="Correo eléctronico"
                            placeholder="Ingresá tu email de contacto."
                            readOnly={!store.Status.isPending}
                            contentLeft={<MdMail size={16} />}
                            value={store.Email}
                            onChangeText={t => store.Email = t}
                        />
                    </Col>
                    <Col gutter>
                        <TextInput
                            label="Teléfono de contacto"
                            placeholder="Ingresá un teléfono o célular de contacto."
                            type="number"
                            readOnly={!store.Status.isPending}
                            contentLeft={<BsTelephone size={16} />}
                            value={store.Telephone}
                            onChangeText={t => store.Telephone = t}
                        />
                    </Col>
                    <Col gutter>
                        <TextAreaInput
                            height="60px"
                            label="Mensaje"
                            showCounter
                            maxLength={200}
                            placeholder="Ingresá tu consulta. Máximo 200 caracteres"
                            readOnly={!store.Status.isPending}
                            value={store.Message}
                            onChangeText={t => store.Message = t}
                        />
                    </Col>
                </Row>
            </Content>
        </ModalWindow>
    </>;

}, {} );