import React from 'react';
import { Button, Col, Content, TextInput, Label, Row, Spacer } from '@gower/react-ui/components';
import { provider, useStore } from './store';
import { AiOutlineUser } from '@gower/react-ui/icons';
import { RoutesEnum } from 'app/routes';
import { useSession } from 'features/hooks/useSession';

export const EditUserView = provider( () => {

    const { user } = useSession();
    const store = useStore();

    return <>
        <Content
            position="absolute"
            top="0"
            right="0"
            color="blue-0"
            height="30%"
            minHeight={200}
        />
        <Content justifyCenter start scroll paddingY={100}>
            <Col start maxWidth={600}>
                <Row
                    white
                    boxShadow="box-shadow-1"
                    padding={20}
                >
                    <Col cols={12}>
                        <Row>
                            <Col center>
                                <Label>Editar datos del usuario</Label>
                            </Col>
                            <Spacer />
                            <Col center>
                                <Button
                                    text
                                    label="Volver a mi perfil"
                                    reactLink={RoutesEnum.Profile}
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col cols={12} paddingTop={20}>
                        <TextInput
                            borderless
                            autoComplete="username"
                            autoCapitalize="false"
                            large
                            readOnly
                            contentLeft={<AiOutlineUser size={18} />}
                            value={user?.Nickname}
                        />
                    </Col>

                    {!store.Status.isPending &&
                        <>
                            <Col cols={6} gutter>
                                <TextInput label='Nombre' placeholder='Nombre del usuario' suffix='*' autoFocus readOnly={store.Status.isSaving} value={store.CurrentItem.Name} onChange={( e ) => store.CurrentItem.Name = e.target.value} />
                            </Col>
                            <Col cols={6} gutter>
                                <TextInput label='Apellido' placeholder='Apellido del usuario' readOnly={store.Status.isSaving} value={store.CurrentItem.Lastname} onChange={( e ) => store.CurrentItem.Lastname = e.target.value} />
                            </Col>
                            <Col cols={6} gutter>
                                <TextInput label='Email' placeholder='Email del usuario' suffix='*' readOnly={store.Status.isSaving} value={store.CurrentItem.Email} onChange={( e ) => store.CurrentItem.Email = e.target.value} />
                            </Col>
                            <Col cols={6} gutter>
                                <TextInput label='Teléfono' placeholder='Teléfono del usuario' readOnly={store.Status.isSaving} value={store.CurrentItem.Telephone} onChange={( e ) => store.CurrentItem.Telephone = e.target.value} />
                            </Col>
                            <Col cols={6} gutter>
                                <TextInput label='Celular' placeholder='Teléfono celular del usuario' readOnly={store.Status.isSaving} value={store.CurrentItem.Cellphone} onChange={( e ) => store.CurrentItem.Cellphone = e.target.value} />
                            </Col>
                        </>
                    }

                </Row>
                <Row justifyEnd paddingY={10}>
                    <Col center paddingRight={5}>
                        <Button
                            minWidth={150}
                            normal
                            label="Cancelar"
                            disabled={store.Status.isSaving}
                            reactLink={RoutesEnum.Home}
                        />
                    </Col>
                    <Col center>
                        <Button
                            minWidth={150}
                            primary
                            normal
                            label="Actualizar"
                            loading={store.Status.isSaving}
                            disabled={store.Status.isPending}
                            onClick={() => store.save()}
                        />
                    </Col>
                </Row>
            </Col>
        </Content>
    </>;
} );