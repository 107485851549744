import { Alert, Button, Col, Content, Label, Row, TextAreaInput, TextInput } from '@gower/react-ui/components';
import { BiIdCard, BsTelephone, MdMail } from '@gower/react-ui/icons';
import { RoutesEnum } from 'app/routes';
import { useNavigate } from 'react-router-dom';
import { provider, useStore } from './store';


export const QuickResponseView = provider( () => {

    const store = useStore();
    const navigate = useNavigate();

    function close () {
        navigate( RoutesEnum.Home );
    }

    return <>
        <Content
            position="absolute"
            top="0"
            right="0"
            color="blue-0"
            height="30%"
            minHeight={200}
        />
        <Content justifyCenter start scroll paddingY={20} color="surface-0">
            <Col sm={11} cols={8} >
                <Row columns white boxShadow="box-shadow-1" padding={20}>
                    <Col gutter paddingY={20}>
                        <Label label="Asistencia rápida" />
                    </Col>
                    <Col center gutter>
                        <Alert
                            fullWidth
                            show={!store.Status.isOk}
                            content="Envíenos su consulta ó inconveniente completando el siguiente formulario."
                        />
                        <Alert
                            fullWidth
                            show={store.Status.isOk}
                            title="Consulta enviada"
                            color="green-3"
                            content="Le responderemos a la brevedad. ¡Gracias por su consulta!"
                        />
                    </Col>
                    <Col gutter>
                        <TextInput
                            autoFocus
                            label="Matrícula ó usuario"
                            placeholder="Ingresá tu usuario o matrícula."
                            readOnly={!store.Status.isPending}
                            contentLeft={<BiIdCard size={16} />}
                            value={store.User}
                            onChangeText={t => store.User = t}
                        />
                    </Col>
                    <Col gutter>
                        <TextInput
                            label="Correo eléctronico"
                            placeholder="Ingresá tu email de contacto."
                            readOnly={!store.Status.isPending}
                            contentLeft={<MdMail size={16} />}
                            value={store.Email}
                            onChangeText={t => store.Email = t}
                        />
                    </Col>
                    <Col gutter>
                        <TextInput
                            label="Teléfono de contacto"
                            placeholder="Ingresá un teléfono o célular de contacto."
                            type="number"
                            readOnly={!store.Status.isPending}
                            contentLeft={<BsTelephone size={16} />}
                            value={store.Telephone}
                            onChangeText={t => store.Telephone = t}
                        />
                    </Col>
                    <Col gutter>
                        <TextAreaInput
                            height="60px"
                            label="Mensaje"
                            showCounter
                            maxLength={200}
                            placeholder="Ingresá tu consulta. Máximo 200 caracteres"
                            readOnly={!store.Status.isPending}
                            value={store.Message}
                            onChangeText={t => store.Message = t}
                        />
                    </Col>
                </Row>
                <Row justifyEnd padding={10}>
                    <Col center gutter show={!store.Status.isOk}>
                        <Button
                            minWidth={100}
                            normal
                            label="Cancelar"
                            onClick={close}
                        />
                    </Col>
                    <Col center gutter show={!store.Status.isOk}>
                        <Button
                            show={!store.Status.isOk}
                            actionTheme
                            minWidth={100}
                            bold
                            normal
                            label="Enviar mensaje"
                            disabled={!store.canContinue}
                            loading={store.Status.isLoading}
                            onClick={() => store.send()}
                        />
                    </Col>
                    <Col center gutter show={store.Status.isOk}>
                        <Button
                            actionTheme
                            minWidth={100}
                            bold
                            normal
                            label="Aceptar"
                            onClick={close}
                        />
                    </Col>
                </Row>
            </Col>
        </Content>
    </>;
} );
