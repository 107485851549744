import React from 'react';
import { ErrorManager } from '@gower/react-ui/utils';
import { Specialty } from '@gower/cm-mdp-sdk/specialties';
import { Content, Row, Col, TextInput, Label, Div, Spacer, Button, Divider, Code, Chip, Autocomplete, DivProps, Checkbox } from '@gower/react-ui/components';
import { MessageBox } from '@gower/react-ui/message-box';
import { Notifications } from '@gower/react-ui/notifications';
import { FormHeader, Form, FormInput } from '@gower/react-ui/template/form';
import { expose, ViewStatus } from '@gower/react-ui/store';
import { Wait } from '@gower/utils';
import { SpecialtySubStore } from '../../store';

class SpecialtyCreateStore extends SpecialtySubStore {

    public Status = ViewStatus.new( 'pending' );
    public EditItem = new Specialty();
    public ErrorManager = ErrorManager.new( {
        fields: ['name']
    } );

    onAfterRender () {
        this.Status.setPending();
        this.EditItem = new Specialty();
        if ( this.EditItem )
            this.Status.setOk();
    }

    public checkErrors () {

        this.ErrorManager.clear();
        if ( !this.EditItem.Name ) this.ErrorManager.setName( 'Completá este campo' );

        return !this.ErrorManager.hasErrors();
    }

    async save () {
        if ( this.checkErrors() ) {
            this.Status.setSaving();
            const result = await this.EditItem.save();
            if ( result.result ) {

                Notifications.Saved();
                await Wait( 1000 );
                this.MainStore.list( this.EditItem );

            } else {
                await MessageBox.Error( result.errorList );
            }

            this.Status.setOk();
        }
    }

    async close () {
        this.MainStore.list();
    }
}

const { provider, useStore } = expose( SpecialtyCreateStore );

export const SpecialtyCreate = provider( () => {

    const store = useStore();
    const { EditItem } = store;

    return <>
        <Content columns scrollOnlyVertical>
            <Col>
                <FormHeader
                    title="Crear nueva especialidad"
                    subTitle="Añade una especialidad al sistema."
                >
                    <Col gutter center>
                        <Button
                            actionTheme
                            disabled={!store.Status.isOk}
                            label="Cancelar"
                            onClick={() => store.close()}
                        />
                    </Col>
                    <Col gutter center>
                        <Button
                            minWidth={130}
                            primary
                            disabled={store.Status.isLoading}
                            loading={store.Status.isSaving}
                            onClick={() => store.save()}
                            label="Guardar"
                        />
                    </Col>
                </FormHeader>
            </Col>
            <Spacer>
                <Form orientation="center">
                    <FormInput
                        title="Nombre"
                        subTitle="Nombre de la especialidad"
                    >
                        <TextInput
                            filled
                            value={EditItem.Name}
                            onChangeText={t => EditItem.Name = t}
                            placeholder="Nombre"
                            readOnly={!store.Status.isOk}
                            error={store.ErrorManager.getName()}
                        />
                    </FormInput>

                    <FormInput
                        title="Código externo"
                        subTitle="Opcional. Código externo de la especialidad"
                    >
                        <TextInput
                            filled
                            value={EditItem.ExternalId}
                            onChangeText={t => EditItem.ExternalId = t}
                            placeholder="Código externo"
                            readOnly={!store.Status.isOk}
                        />
                    </FormInput>
 
                    <FormInput
                        title="Controles"
                        subTitle="Controles al autorizar"
                    >
                        <Div display="block">
                            <Checkbox
                                value={EditItem.NeedEffector}
                                onCheck={( v ) => EditItem.NeedEffector = v}
                                label="Controla Mat. Efector"
                                disabled={!store.Status.isOk}
                            />
                            <Checkbox
                                value={EditItem.NeedBilling}
                                onCheck={( v ) => EditItem.NeedBilling = v}
                                label="Controla Mat. Facturante"
                                disabled={!store.Status.isOk}
                            />
                            <Checkbox
                                value={EditItem.NeedApplicant}
                                onCheck={( v ) => EditItem.NeedApplicant = v}
                                label="Controla Mat. Solicitante"
                                disabled={!store.Status.isOk}
                            />
                        </Div>
                    </FormInput>

                    <FormInput
                        title="Configuración"
                        subTitle="Configuración al autorizar"
                    >
                        <Div display="block">
                            <Checkbox
                                value={EditItem.RequiredToken}
                                onCheck={( v ) => EditItem.RequiredToken = v}
                                label="Requiere token de afiliado"
                                disabled={!store.Status.isOk}
                            />
                            <Checkbox
                                value={EditItem.AllowPrescription}
                                onCheck={( v ) => EditItem.AllowPrescription = v}
                                label="Emite recetarios"
                                disabled={!store.Status.isOk}
                            />
                            <Div disabled={!EditItem.AllowPrescription}>
                                <Checkbox
                                    value={EditItem.AllowPrescription ? EditItem.AllowPrescriptionPapperOnly : false}
                                    onCheck={( v ) => EditItem.AllowPrescriptionPapperOnly = v}
                                    label="Emite solo recetarios en papel"
                                    disabled={!store.Status.isOk}
                                />
                            </Div>
                        </Div>
                    </FormInput>

                </Form>
            </Spacer>
        </Content>
    </>;

} );