import { theme, MediaQuery } from '@gower/react-ui/components';
import { provider } from './store';
import { NewsDesktop } from './desktop';
import { NewsMobile } from './mobile';

export const NewsView = provider( () => {
    return <>
        <MediaQuery minWidth={theme.current.Breakpoints.XsMin}>
            <NewsDesktop />
        </MediaQuery>
        <MediaQuery
            maxWidth={theme.current.Breakpoints.MobileMax}
        >
            <NewsMobile />
        </MediaQuery>
    </>;
}, { keepAlive: true } );
