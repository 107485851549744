import React from 'react';
import { Col, Content, Div, Label, Row, Spacer, Alert, Button, Colors, Chip, Panel } from '@gower/react-ui/components';
import { DataGrid, DataGridColumn, DataGridTemplateProps } from '@gower/react-ui/template/data-grid';
import { useStore } from '../store';
import { SpecialtyCreate } from './components/specialties-create';
import { SpecialtyEditor } from './components/specialties-editor';
import { Specialty } from '@gower/cm-mdp-sdk/specialties';
import { MdAddCircleOutline } from '@gower/react-ui/icons';
import { SectionHeader } from '@gower/react-ui/template/section-header';

export const SpecialtiesDesktop = () => {

    const store = useStore();

    if ( store.Mode === 'create' )
        return <>
            <SpecialtyCreate store={store} />
        </>;

    else if ( store.Mode === 'edit' )
        return <>
            <SpecialtyEditor store={store} />
        </>;
    else if ( store.Mode === 'list' ) {
        return <>
            <Content columns>
                <Col>
                    <SectionHeader
                        title="Especialidades de salud"
                        subTitle="Especialidades del sistema."
                    >
                        <Col gutter center>
                            <Button
                                primary
                                icon={<MdAddCircleOutline size={16} />}
                                label="Nueva especialidad"
                                onClick={() => store.create()}
                            />
                        </Col>
                    </SectionHeader>
                </Col>
                <Spacer vertical noScroll>
                    <DataGrid
                        loader
                        store={store}
                        autoSorting
                        showReload
                        showFilters={["status"]}
                        scroll
                        showPagination
                        showSearch
                        mobileTemplate={( { data } ) => (
                            <Row white center borderRadius={5} minHeight={45} padding={10} activeEffect>
                                <Col cols={6}>
                                    <Label label={data.Id} />
                                </Col>
                                <Col cols={6}>
                                </Col>
                            </Row>
                        )}
                    >
                        <DataGridColumn field="LocalId" title="Id" />
                        <DataGridColumn field="ExternalId" title="Código externo" />
                        <DataGridColumn field="Name" title="Nombre" />
                        <DataGridColumn
                            field="actions"
                            title=""
                            size={130}
                            template={( { data }: DataGridTemplateProps<Specialty> ) => (
                                <Actions data={data} />
                            )}
                        />
                    </DataGrid>
                </Spacer>
            </Content>
        </>;
    }

};



const Actions = ( { data } ) => {

    const store = useStore();

    return <>
        <Row end noWrap justifyEnd>
            <Col gutter>
                <Button
                    block
                    bold
                    xxsmall
                    label="Editar"
                    onClick={() => store.edit( data )}
                />
            </Col>
        </Row>
    </>;
};