import React from 'react';
import { Configuration } from '@gower/cm-mdp-sdk/src/lib/Configuration';

export function useConfiguration () {

    const [config, setConfig] = React.useState<Configuration>( null );

    async function refresh () {
        await set();
    }

    async function set () {
        const config = await Configuration.get();
        setConfig( config );
    }

    React.useEffect( () => {
        set();
    }, [] );

    return {
        config,
        refresh
    };
}