import React from 'react';
import { Col, Content, Div, Label, Row, Spacer, Alert, Button, Colors, Chip, Panel } from '@gower/react-ui/components';
import { DataGrid, DataGridColumn, DataGridTemplateProps } from '@gower/react-ui/template/data-grid';
import { useStore } from '../store';
import { UnregisteredAgreementsCreate } from './components/unregistered-agreements-create';
import { UnregisteredAgreementsEditor } from './components/unregistered-agreements-editor';
import { MdAddCircleOutline, TbChevronDown } from '@gower/react-ui/icons';
import { WaitAtLeast } from '@gower/utils';
import { UnregisteredAgreement } from '@gower/cm-mdp-sdk/unregistered-agreements';

export const UnregisteredAgreementsDesktop: React.FC = () => {

    const store = useStore();

    if ( store.Mode === 'create' )
        return <>
            <UnregisteredAgreementsCreate store={store} />
        </>;

    else if ( store.Mode === 'edit' )
        return <>
            <UnregisteredAgreementsEditor store={store} />
        </>;
    else if ( store.Mode === 'list' ) {
        return <>
            <Content columns>
                <Col>
                    <Row padding={15}>
                        <Col gutter center>
                            <Div padding={10} >
                                <Label xxsemi p bold marginBottom={5}>Convenios no registrados</Label>
                                <Label xsmall p textGrey>Convenios no integrados por el sistema.</Label>
                            </Div>
                        </Col>
                        <Spacer />
                        <Col gutter center>

                        </Col>
                        <Col gutter center>
                            <Button
                                primary
                                icon={<MdAddCircleOutline size={16} />}
                                label="Nuevo convenio"
                                onClick={() => store.create()}
                            />
                        </Col>
                    </Row>
                </Col>
                <Spacer vertical noScroll>
                    <DataGrid
                        loader
                        store={store}
                        autoSorting
                        showFilters={["status"]}
                        showReload
                        scroll
                        showPagination
                        showSearch
                        mobileTemplate={( { data } ) => (
                            <Row white center borderRadius={5} minHeight={45} padding={10} activeEffect>
                                <Col cols={6}>
                                    <Label label={data.Id} />
                                </Col>
                                <Col cols={6}>
                                </Col>
                            </Row>
                        )}
                    >
                        <DataGridColumn field="Acronym" title="Nombre" cellProps={{ bold: true, textCenter: true }} />
                        <DataGridColumn field="Name" title="Descripción" />
                        <DataGridColumn field="ExternalCode" title="Código" />
                        <DataGridColumn
                            field="actions"
                            title=""
                            size={130}
                            template={( { data }: DataGridTemplateProps<UnregisteredAgreement> ) => (
                                <Actions data={data} />
                            )}
                        />
                    </DataGrid>
                </Spacer>
            </Content>
        </>;
    }

};



const Actions = ( { data } ) => {

    const store = useStore();
    const [loading, setLoading] = React.useState<'none' | 'active' | 'disable'>( 'none' );

    async function active () {
        setLoading( 'active' );
        const wait = WaitAtLeast( 500 );
        await store.active( data );
        await WaitAtLeast( wait );
        setLoading( 'none' );
    }

    async function disable () {
        setLoading( 'disable' );
        const wait = WaitAtLeast( 500 );
        await store.disable( data );
        await WaitAtLeast( wait );
        setLoading( 'none' );
    }

    return <>
        <Row end noWrap justifyEnd>
            <Col gutter center>
                <Button
                    minHeight={28}
                    height={28}
                    depressed
                    green={data.Status.isActive}
                    textWhite={data.Status.isActive}
                    hoverTextColor={data.Status.isActive ? "white" : undefined}
                    xsmall
                    label="Activo"
                    padding={4}
                    borderRadiusRight={0}
                    disabled={loading !== 'none'}
                    loading={loading === 'active'}
                    onClick={() => active()}
                />
                <Button
                    minHeight={28}
                    height={28}
                    depressed
                    red={data.Status.isDeactivated}
                    textWhite={data.Status.isDeactivated}
                    hoverTextColor={data.Status.isDeactivated ? "white" : undefined}
                    xsmall
                    label="Oculto"
                    padding={4}
                    borderRadiusLeft={0}
                    disabled={loading !== 'none'}
                    loading={loading === 'disable'}
                    onClick={() => disable()}
                />
            </Col>
            <Col gutter>
                <Button
                    block
                    bold
                    xxsmall
                    label="Editar"
                    onClick={() => store.edit( data )}
                    disabled={loading !== 'none'}
                />
            </Col>
        </Row>
    </>;
};  