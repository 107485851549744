import type { TemplatesProps, ContextTemplatesProps } from './create.props';

type Props = TemplatesProps & ContextTemplatesProps;

export function getActions ( { store, crudStore, ...props }: Props )
{
    return {
        save: () =>
        {
            if ( props.onSave )
                props.onSave( store.getProps() );
            else
                store.save();
        },
        restore: () =>
        {
            if ( props.onRestore )
                props.onRestore( store.getProps() );
            else
                store.restore();
        },
        back: () =>
        {
            if ( props.onBack )
                props.onBack( store.getProps() );
            else
                crudStore.goList();
        }
    };
} 