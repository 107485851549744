import { Affiliate, Authorization } from '@gower/cm-mdp-sdk/authorizations';
import { AffiliatesService } from '@gower/cm-mdp-sdk/src/lib/Authorizations/services';
import { expose, MutableStore, ViewStatus } from '@gower/react-ui/store';

export class VerifyAffiliateModalStore extends MutableStore {

    public Status = ViewStatus.new( 'pending' );

    public SearchText = '';
    public lastErrors: string[] = [];
    public Affiliate: Affiliate = null;
    public Agreement: InstanceType<typeof Authorization.VO.Agreement>;
    private affiliatesService: AffiliatesService;

    async search () {

        if ( !this.SearchText ) return;

        this.Status.setLoading();
        const value = await this.affiliatesService.find( { credential: this.SearchText } );
        if ( value ) {
            this.Affiliate = value;
            this.Status.setOk();
        }
        else {
            this.lastErrors = this.affiliatesService.LastErrors || [];
            this.Status.setErrored();
        }

        this.SearchText = '';
    }

    onReceiveProps ( props ) {
        const agreementType = props?.agreement;
        if ( agreementType && agreementType !== this.Agreement?.Id ) {
            this.SearchText = '';
            this.lastErrors = [];
            this.Affiliate = null;
            this.Agreement = Authorization.VO.Agreement.toType( agreementType );
            this.affiliatesService = new AffiliatesService( this.Agreement );
            this.Status.setPending();
        }
    }
}

export const { provider, useStore } = expose( VerifyAffiliateModalStore );