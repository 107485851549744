import React, { SVGAttributes } from "react";

export const LogoSANCOR: React.FC<Omit<SVGAttributes<any>, 'dangerouslySetInnerHTML'>> = ( { ...props } ) => {
  return (
    <svg
      {...props}
      viewBox="0 0 221.962 216.81"
      dangerouslySetInnerHTML={{
        __html: `
        <?xml version="1.0" encoding="UTF-8"?>
        <svg id="a" data-name="Capa 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 221.962 216.81">
          <defs>
            <style>
              .sancor-d {
                fill: #fefefe;
              }
        
              .sancor-e {
                fill: #fefefd;
              }
        
              .sancor-f {
                fill: #fdfdfc;
              }
        
              .sancor-g {
                fill: #fdfdfd;
              }
        
              .sancor-h {
                fill: #3e3a7a;
              }
        
              .sancor-i {
                fill: #94c4d4;
              }
            </style>
          </defs>
          <g id="b" data-name="Capa 1-2">
            <g id="c" data-name="aatqgM.tif">
              <g>
                <path class="sancor-h" d="M0,163.257c.514-1.506,.543-3.099,.832-4.646,2.283-12.24,7.823-22.858,15.993-32.171,10.692-12.188,23.8-20.396,39.711-23.944,14.614-3.259,28.574-1.91,41.557,6.038,11.416,6.989,17.977,17.275,20.47,30.283,2.745,14.321-.371,27.54-7.547,40.007-11.188,19.437-27.803,31.511-49.65,36.348-9.032,2-18.268,2.404-27.186-.121-17.91-5.071-29.425-16.647-33.696-35.003-.132-.568-.114-1.185-.482-1.687v-15.103Z"/>
                <path class="sancor-h" d="M181.91,0c2.858,.699,5.781,1.135,8.561,2.144,16.941,6.152,27.287,18.051,30.52,35.749,2.905,15.901-.861,30.566-9.35,44.139-10.739,17.172-25.799,28.489-45.551,33.376-11.938,2.954-23.612,2.282-34.901-2.833-13.867-6.283-22.216-17.029-25.587-31.75-3.305-14.433-.796-28.029,5.965-41.012C121.824,20.123,137.594,7.229,159.061,1.374c2.068-.564,4.192-.921,6.29-1.374,5.52,0,11.039,0,16.559,0Z"/>
                <path class="sancor-h" d="M159.815,124.368c10.099,.014,18.174,2.843,25.689,7.483,12.354,7.627,20.596,18.336,23.576,32.618,2.229,10.685,.529,20.825-6.586,29.449-5.618,6.809-13.123,10.812-21.859,11.693-14.114,1.425-26.616-3.092-37.356-12.085-9.358-7.836-14.966-18.13-17.118-30.089-2.616-14.538,4.914-34.379,26.716-38.298,2.681-.482,5.373-.808,6.938-.771Z"/>
                <path class="sancor-h" d="M44.014,10.083c9.01,.059,17.234,2.672,24.676,7.678,11.344,7.631,19.442,17.666,22.628,31.204,2.219,9.431,1.865,18.628-3.018,27.27-5.38,9.522-13.584,15.002-24.421,16.278-13.226,1.557-24.811-2.503-35.038-10.81-10.398-8.446-16.857-19.207-18.638-32.514-1.789-13.37,2.296-24.603,13.158-32.97,4.845-3.732,10.546-5.37,16.585-5.899,1.353-.119,2.712-.16,4.069-.238Z"/>
                <path class="sancor-e" d="M112.776,147.8c.095,11.307-3.923,21.192-10.574,30.143-9.425,12.684-21.878,20.58-37.466,23.358-13.481,2.402-25.663-.389-36.023-9.642-6.271-5.601-9.688-12.769-10.96-21.003-2.118-13.717,1.964-25.765,9.981-36.74,9.309-12.745,21.769-20.629,37.245-23.506,10.611-1.973,20.934-.759,30.29,4.97,10.247,6.275,16.043,15.522,17.32,27.519,.063,.596,.163,1.19,.18,1.787,.03,1.037,.008,2.076,.008,3.115Z"/>
                <path class="sancor-e" d="M151.572,110.232c-9.382,.068-18.028-2.247-25.357-8.364-7.717-6.441-12.065-14.717-13.192-24.695-1.573-13.923,2.752-26.118,10.914-37.184,9.105-12.344,21.182-20.064,36.274-22.867,13.943-2.59,26.259,.738,36.415,10.858,5.791,5.77,8.683,12.995,9.756,20.997,2.005,14.959-3.033,27.81-12.036,39.394-8.981,11.555-20.85,18.469-35.141,21.343-1.212,.244-2.447,.419-3.679,.498-1.313,.085-2.636,.019-3.954,.019Z"/>
                <path class="sancor-g" d="M172.389,195.623c-15.372-.132-25.961-6.122-34.162-16.722-4.058-5.245-6.825-11.064-7.513-17.716-1.393-13.464,5.951-27.693,22.136-30.207,7.967-1.237,15.632,.056,22.716,3.984,10.093,5.596,17.59,13.452,20.797,24.812,1.997,7.072,2.278,14.176-1.151,20.891-5.079,9.948-13.42,14.825-22.824,14.96Z"/>
                <path class="sancor-f" d="M86.545,60.214c.718,18.741-14.063,28.135-25.795,28.023-14.409-.137-25.507-6.611-33.601-18.292-4.614-6.66-6.969-14.152-6.719-22.313,.309-10.139,6.93-22.417,21.003-24.998,7.116-1.305,14.059-.666,20.579,2.519,11.856,5.793,20.131,14.713,23.513,27.725,.625,2.405,.962,4.85,1.021,7.335Z"/>
                <path class="sancor-i" d="M108.993,147.383c.138,9.512-2.756,17.367-7.3,24.667-6.695,10.757-16.136,18.212-28.017,22.539-4.66,1.697-9.511,2.56-14.501,2.777-7.711,.336-14.908-1.291-21.506-5.279-7.861-4.751-12.598-11.829-14.861-20.619-1.724-6.696-1.182-13.366,.61-19.954,2.621-9.634,8.003-17.599,15.263-24.314,7.822-7.235,17.099-11.789,27.587-13.443,9.907-1.563,19.442-.057,27.988,5.545,8.049,5.277,12.688,12.815,14.25,22.294,.359,2.178,.5,4.352,.486,5.787Z"/>
                <path class="sancor-i" d="M202.104,55.725c-.149,11.015-3.735,20.826-10.41,29.559-5.068,6.631-11.215,12.008-18.651,15.828-5.174,2.658-10.622,4.499-16.457,5.151-6.947,.776-13.7,.176-20.139-2.64-9.155-4.005-15.211-10.857-18.32-20.325-1.654-5.037-1.987-10.188-1.55-15.458,.873-10.513,5.141-19.559,11.737-27.609,6.777-8.27,15.302-14.033,25.485-17.308,3.735-1.201,7.592-1.698,11.52-1.892,6.609-.327,12.872,.905,18.722,3.934,9.442,4.889,14.876,12.862,17.297,23.042,.601,2.529,.855,5.105,.765,7.718Z"/>
                <path class="sancor-i" d="M194.42,168.215c.418,13.094-9.309,21.978-20.106,23.955-4.996,.915-10.037,.043-14.867-1.445-9.926-3.058-17.402-9.238-22.35-18.342-2.991-5.504-4.556-11.457-3.853-17.739,1.096-9.801,7.856-18.775,18.979-20.886,10.835-2.056,20.242,1.014,28.673,7.747,5.788,4.622,10.069,10.302,12.197,17.473,.911,3.071,1.449,6.202,1.328,9.237Z"/>
                <path class="sancor-i" d="M23.987,50.11c-.435-7.429,2.285-13.586,7.552-18.683,3.381-3.272,7.529-5.028,12.127-5.77,10.384-1.675,19.235,1.72,27.132,8.241,7.1,5.862,11.422,13.351,12.868,22.411,1.323,8.29-.5,15.818-6.332,22.054-3.941,4.215-8.975,6.345-14.697,7.005-9.619,1.109-17.816-2.217-25.162-8.049-6.497-5.157-10.611-11.917-12.811-19.878-.667-2.413-.78-4.87-.677-7.332Z"/>
                <path class="sancor-d" d="M34.321,159.442c-.009-8.369,2.752-15.277,7.15-21.629,4.759-6.874,11.187-11.693,18.766-15.039,5.167-2.281,10.592-3.619,16.263-3.345,7.86,.381,14.938,2.865,20.579,8.591,3.639,3.694,5.611,8.27,6.613,13.293,2.053,10.294-.907,19.372-6.833,27.696-4.366,6.132-9.978,10.795-16.81,13.987-5.677,2.652-11.577,4.269-17.911,3.965-4.875-.235-9.503-1.258-13.879-3.57-7.747-4.092-12.121-10.454-13.472-19.019-.286-1.81-.509-3.619-.467-4.931Z"/>
                <path class="sancor-d" d="M191.065,61.013c.094,8.921-2.943,16.772-8.282,23.795-6.304,8.294-14.492,13.872-24.668,16.209-8.687,1.994-17.016,.857-24.467-4.297-5.703-3.945-9.12-9.588-10.517-16.285-2.301-11.036,.865-20.882,7.278-29.836,4.013-5.603,9.18-9.943,15.309-13.095,4.694-2.414,9.686-3.784,14.973-4.241,7.776-.673,14.723,1.318,20.741,6.235,4.937,4.034,7.818,9.365,9.152,15.567,.423,1.966,.517,3.946,.481,5.948Z"/>
                <path class="sancor-d" d="M156.104,137.562c7.626-.115,13.576,2.522,18.898,6.878,5.211,4.265,8.824,9.606,10.096,16.202,1.573,8.161-.247,15.381-7.202,20.666-2.796,2.125-6.051,3.135-9.513,3.554-8.362,1.011-15.369-1.965-21.674-7.192-5.769-4.782-9.173-10.887-10.106-18.248-1.382-10.908,5.873-19.656,15.242-21.518,1.613-.321,3.245-.389,4.258-.341Z"/>
                <path class="sancor-d" d="M31.461,54.244c-.17-5.108,.879-9.477,3.942-13.267,3.509-4.341,7.972-6.877,13.572-7.15,9.632-.469,17.438,3.24,23.679,10.499,3.397,3.951,5.824,8.366,6.68,13.526,1.223,7.372-.056,14.064-5.788,19.368-2.886,2.67-6.337,4.222-10.234,4.783-5.388,.775-10.461-.226-15.296-2.649-8.638-4.33-13.982-11.256-16.263-20.587-.196-.802-.234-1.649-.283-2.479-.046-.796-.01-1.597-.01-2.043Z"/>
              </g>
            </g>
          </g>
        </svg>
      `}}>
    </svg>
  );
};