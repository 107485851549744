import type { TemplatesProps, ContextTemplatesProps } from './view.props';

type Props = TemplatesProps & ContextTemplatesProps;

export function getActions ( { store, crudStore, ...props }: Props )
{
    return {
        remove: () =>
        {
            if ( props.onDelete )
                props.onDelete( store.getProps() );
            else
                store.remove();
        },
        edit: () =>
        {
            if ( props.onEdit )
                props.onEdit( store.getProps() );
            else
                store.edit();
        },
        save: () =>
        {
            if ( props.onSave )
                props.onSave( store.getProps() );
            else
                store.save();
        },
        restore: () =>
        {
            if ( props.onRestore )
                props.onRestore( store.getProps() );
            else
                store.restore();
        },
        back: () =>
        {
            if ( props.onBack )
                props.onBack( store.getProps() );
            else
                crudStore.goList();
        }
    };
} 