import { AgreementConfig } from '@gower/cm-mdp-sdk/agreement-config';
import { Authorization, VO } from '@gower/cm-mdp-sdk/authorizations';
import { Specialty } from '@gower/cm-mdp-sdk/specialties';
import {
    AuthorizationService,
    ProfessionalType,
} from '@gower/cm-mdp-sdk/src/lib/AuthorizationRequests/services';
import { MessageBox } from '@gower/react-ui/message-box';
import { Notifications } from '@gower/react-ui/notifications';
import { expose, MutableStore, ViewStatus } from '@gower/react-ui/store';
import { Redirect } from '@gower/react-ui/utils';
import CredentialProvider from 'app/credential-provider';
import { RoutesEnum } from 'app/routes';

export class AuthorizationStore extends MutableStore {

    public readonly SetupStatus = ViewStatus.new( 'loading' );
    public readonly Status = ViewStatus.new( 'pending' );
    public emitForms = true;
    public allowForms = false;
    public downloadingPrescription = false;
    public needToken = true;
    public get needDiagnostic () { return this.Config?.CheckDiagnostic === true && this.request.Items.some( item => !item.Service.IsConsultation ); }
    private request: AuthorizationService = null;
    public get Request () { return this.request; }
    public get Agreement () { return this.agreement; }
    public get Affiliate () { return this.request?.Affiliate; }
    public get EffectorProfessional () { return this.request?.EffectorProfessional; }
    public get ApplicantProfessional () { return this.request?.ApplicantProfessional; }
    public get BillingProfessional () { return this.request?.BillingProfessional; }
    public get Items () { return this.request?.Items; }
    public get TotalItems () { return this.request?.TotalItems; }
    public Specialty: Specialty = null;
    public Config: AgreementConfig = null;
    private affiliateInputMode: 'credential' | 'document' = 'credential';
    public get AffiliateInputMode () { return this.affiliateInputMode; }
    public setAffiliateInputMode ( mode: 'credential' | 'document' ) {
        this.affiliateInputMode = mode;
    }

    private lastErrors: string[];
    public get LastErrors () { return this.lastErrors; }
    private agreement: InstanceType<typeof Authorization.VO.Agreement>;

    public get canConfirm () {
        return Boolean(
            !this.Status.isPending &&
            !this.Status.isLoading &&
            !this.Status.isSaving &&
            this.request.Agreement?.Id &&
            this.request.Affiliate?.Id &&
            this.checkProfessional( 'applicant' ) &&
            this.checkProfessional( 'effector' ) &&
            this.checkProfessional( 'billing' ) &&
            this.request.Items.some( item => item.isValid ) &&
            ( !this.needDiagnostic || ( this.needDiagnostic && this.Request.Message ) )
        );
    }

    public get canAddAffiliate () {
        return Boolean(
            !this.Status.isPending &&
            !this.Status.isLoading &&
            !this.Status.isSaving &&
            this.request.Agreement?.Id
        );
    }

    public get canAddProfessional () {
        return Boolean(
            !this.Status.isPending &&
            !this.Status.isLoading &&
            !this.Status.isSaving &&
            this.request.Agreement?.Id &&
            this.request.Affiliate?.Id
        );
    }

    public get canAddServices () {
        return Boolean(
            !this.Status.isPending &&
            !this.Status.isLoading &&
            !this.Status.isSaving &&
            this.request.Agreement?.Id &&
            this.request.Affiliate?.Id &&
            this.checkProfessional( 'effector' )
        );
    }

    public get canFindServices () {
        return Boolean(
            !this.Status.isPending &&
            !this.Status.isLoading &&
            !this.Status.isSaving &&
            this.request.Agreement?.Id &&
            this.request.Affiliate?.Id
        );
    }

    public get canGenerateElectronicPrescription () {
        return Boolean(
            !this.Status.isPending &&
            !this.Status.isLoading &&
            !this.Status.isSaving &&
            this.request.Agreement?.Id &&
            this.request.Affiliate?.Id &&
            this.checkProfessional( 'effector', true )
        );
    }

    public get canGenerateAllPrescriptions () {
        return this.canGeneratePrescription || this.canGenerateBirthControlPrescription;
    }

    public get canGeneratePrescription () {
        return Boolean(
            !this.Status.isPending &&
            !this.Status.isLoading &&
            !this.Status.isSaving &&
            this.request.Agreement?.Id &&
            this.request.Affiliate?.Id &&
            this.request.Affiliate?.AllowPrescription
        );
    }

    public get canGenerateBirthControlPrescription () {
        return Boolean(
            !this.Status.isPending &&
            !this.Status.isLoading &&
            !this.Status.isSaving &&
            this.request.Agreement?.Id &&
            this.request.Affiliate?.Id &&
            this.request.Affiliate?.AllowBirthControlPrescription
        );
    }

    public get showGeneratePrescription () {
        return Boolean(
            this.request.Agreement?.Id &&
            this.request.Affiliate?.Id &&
            this.request.Affiliate?.AllowPrescription
        );
    }

    public get showGenerateBirthControlPrescription () {
        return Boolean(
            this.request.Agreement?.Id &&
            this.request.Affiliate?.Id &&
            this.request.Affiliate?.AllowBirthControlPrescription
        );
    }

    public get showPapperPrescription () {

        if ( !this.Config?.AllowPrescriptionForm )
            return false;

        if ( this.Specialty && !this.Specialty?.AllowPrescription )
            return false;

        if ( this.Specialty?.AllowPrescriptionPapperOnly )
            return true;

        if ( this.Agreement.haveElectronicPrescription )
            return false;

        if ( this.Config?.GsPrescriptionActive )
            return false;

        return true;
    }

    public get showElectronicPrescription () {

        if ( !this.Config?.GsPrescriptionActive && !this.Agreement.haveElectronicPrescription )
            return false;

        if ( this.Specialty && !this.Specialty?.AllowPrescription )
            return false;

        if ( this.Specialty?.AllowPrescriptionPapperOnly )
            return false;


        return true;
    }

    public get showMedereElectronicPrescription () {

        if ( !this.Config?.MederePrescriptionActive )
            return false;

        if ( this.Specialty && !this.Specialty?.AllowPrescription )
            return false;

        if ( this.Specialty?.AllowPrescriptionPapperOnly )
            return false;

        return true;
    }

    public get showIOMAElectronicPrescription () {

        if ( !this.Config?.IOMAPrescriptionActive )
            return false;

        if ( this.Specialty && !this.Specialty?.AllowPrescription )
            return false;

        if ( this.Specialty?.AllowPrescriptionPapperOnly )
            return false;

        return true;
    }

    protected async onAfterRender ( props ) {

        this.SetupStatus.setLoading();

        const agreementType = props?.routerParams?.agreement;
        this.agreement = Authorization.VO.Agreement.toType( agreementType );

        if ( this.agreement.isUnspecified )
            Redirect.go( RoutesEnum.Home );
        else {

            const user = CredentialProvider.getUser();
            if ( user['metadata']?.specialty_id ) {
                this.Specialty = await Specialty.findById( user['metadata']?.specialty_id );
                if ( !this.Specialty ) {
                    await MessageBox.Error( 'Ocurrió un error al buscar la especialidad' );
                    Redirect.go( RoutesEnum.Home );
                }
            }
            else
                this.Specialty = null;

            this.Config = await AgreementConfig.getConfig( this.agreement );

            if ( !this.Config ) {
                await MessageBox.Error( 'Ocurrió un error al buscar la configuración' );
                Redirect.go( RoutesEnum.Home );
            }

            if ( await this.checkActive() ) {
                this.checkAgreementConfig();
                this.reset();
                this.SetupStatus.setOk();
            }
        }
    }

    private checkAgreementConfig () {

        this.needToken = true;
        this.allowForms = false;

        if ( this.Config && this.Config.AllowSessionsForm ) {
            this.needToken = true;
        }

        if ( this.Config && !this.Config.RequiredToken ) {
            this.needToken = false;
        }
        else if ( this.Specialty ) {
            this.needToken = this.Specialty.RequiredToken;
        }

    }

    private async checkActive () {

        let active = true;
        if ( this.Config && !this.Config.Status.isActive ) {
            active = false;
        }

        if ( this.Specialty && !this.Specialty.Status.isActive ) {
            active = false;
        }

        if ( this.Config && this.Specialty && this.Config.BlockedSpecialities.some( id => id === this.Specialty.Id ) ) {
            active = false;
        }

        if ( !active ) {
            await MessageBox.Error( 'No tienes permisos suficientes para ejecutar esta acción' );
            Redirect.go( RoutesEnum.Home );
        } else {
            return true;
        }
    }

    public reset () {

        this.Status.setPending();
        this.request = new AuthorizationService( this.agreement );
        this.setAffiliateInputMode( 'credential' );
        this.emit( 'reset' );
        this.checkActive();
        this.checkAgreementConfig();
        this.Status.setOk();
    }

    private checkProfessional ( type: ProfessionalType, force = false ) {

        const types: Record<ProfessionalType, string> = {
            applicant: 'ApplicantProfessional',
            effector: 'EffectorProfessional',
            billing: 'BillingProfessional',
        };

        const haveProfessional = Boolean( this.request[types[type]]?.Id );

        if ( haveProfessional ) {
            return true;
        } else if ( force ) {
            return false;
        }

        const typesCheck: Record<ProfessionalType, string> = {
            applicant: 'NeedApplicant',
            effector: 'NeedEffector',
            billing: 'NeedBilling',
        };

        if ( this.Specialty ) {
            const allowSpecialty = this.Specialty[typesCheck[type]] === false;
            if ( allowSpecialty )
                return true;
        } else if ( this.Config ) {
            const allowConfig = this.Config[typesCheck[type]] === false;
            if ( allowConfig )
                return true;
        }

        return false;
    }

    public async addAffiliateByDocument ( type: VO.Documents, document: string, tokenValue: string ) {

        if ( !document )
            return;

        if ( ( document === this.request.Affiliate?.Document?.Number ) || this.Status.isLoading )
            return true;

        this.Status.setLoading();

        const result = await this.request.setAffiliate( type, document, null, tokenValue );
        if ( result?.ok ) {
            this.Status.setOk();

            if ( result.showMessage ) {
                await MessageBox.Accept( {
                    content: result.showMessage
                } );
            }

            return true;
        }
        else {
            await MessageBox.Error( this.request.LastErrors );
            this.Status.setOk();
        }

    }

    public async addAffiliate ( credential: string, secureCode?: string ) {

        if ( !credential )
            return;

        if ( ( credential == this.request.Affiliate?.Id ) || this.Status.isLoading )
            return true;

        this.Status.setLoading();

        const result = await this.request.setAffiliate( credential, secureCode );
        console.log(result);
        
        if ( result?.ok ) {
            this.Status.setOk();

            if ( result.showMessage ) {
                await MessageBox.Accept( {
                    content: result.showMessage
                } );
            }

            return true;
        }
        else {
            await MessageBox.Error( this.request.LastErrors );
            this.Status.setOk();
        }
    }

    public async addProfessional ( type: ProfessionalType, license: string ) {

        const types: Record<ProfessionalType, string> = {
            applicant: 'ApplicantProfessional',
            effector: 'EffectorProfessional',
            billing: 'BillingProfessional',
        };

        if ( !license )
            return;

        if ( ( license === this.request[types[type]]?.Id ) || this.Status.isLoading )
            return true;

        this.Status.setLoading();
        const result = await this.request.setProfessional( license, type );
        if ( result ) {
            this.Status.setOk();
            return true;
        }
        else {
            await MessageBox.Error( this.request.LastErrors );
            this.Status.setOk();
        }
    }

    public async addService ( code: string, quantity: number = 1 ) {


        if ( !code || this.Status.isLoading )
            return;

        this.Status.setLoading();
        const value = await this.request.addServiceCode( code, quantity );

        if ( value ) {
            this.Status.setOk();
            return value;
        }
        else {
            await MessageBox.Error( this.request.LastErrors );
            this.Status.setOk();
        }

        this.Status.setOk();
        return true;
    }

    public async removeAffiliate () {

        this.Status.setLoading();

        const result = await this.request.reset();

        if ( result ) {
            this.Status.setOk();
            return true;
        }
        else {
            await MessageBox.Error( this.request.LastErrors );
            this.Status.setOk();
        }
    }

    public async removeProfessional ( type: ProfessionalType ) {

        if ( this.request?.AuthorizationId ) {
            this.Status.setLoading();

            const result = await this.request.removeProfessional( type );

            if ( result ) {
                this.Status.setOk();
                return true;
            }
            else {
                await MessageBox.Error( this.request.LastErrors );
                this.Status.setOk();
            }
        }
    }

    public async removeService ( item ) {

        this.Status.setLoading();

        const result = await this.request.removeItem( item );

        if ( result ) {
            this.Status.setOk();
            return true;
        }
        else {
            await MessageBox.Error( this.request.LastErrors );
            this.Status.setOk();
        }
    }

    public async authorize () {

        this.Status.setSaving();

        const authorization = await this.request.authorize();
        if ( authorization ) {

            if ( authorization.Status.isIssued ) {

                if ( authorization.canDownloadBudget( false ) ) {
                    const budgetFile = await authorization.getAuthorizationUrl( {
                        download_form: this.emitForms,
                        download_prescription: this.emitForms,
                    } );

                    if ( budgetFile )
                        window.open( budgetFile.url );

                    Notifications.Success( 'Autorización aceptada' );
                }
                else {
                    await MessageBox.Accept( {
                        title: 'Autorización con estado invalido de confirmación',
                        content: 'La autorización fue aprobada por el convenio pero no se encuentra confirmada. No es posible descargar el Bono.',
                    } );
                }
            }
            else if ( authorization.Status.isPending ) {

                const rpFile = await authorization.getProvisionalRPUrl();

                if ( rpFile )
                    window.open( rpFile.url );

                await MessageBox.Accept( {
                    title: 'Autorización pendiente de confirmación',
                    content: 'La autorización fue aprobada por el convenio pero no se encuentra confirmada.',
                } );
            }
            else if ( authorization.Status.isCanceled ) {

                await MessageBox.Accept( {
                    title: 'Autorización cancelada',
                    content: 'La autorización fue cancelada. Intentá nuevamente.',
                } );
            }
            else if ( authorization.Status.isReject ) {

                await MessageBox.Accept( {
                    title: 'Autorización rechazada',
                    content: 'La autorización fue rechazada. Intentá nuevamente.',
                } );
            }

            this.reset();
        }
        else {
            if ( this.request.LastErrors?.length ) {
                await MessageBox.Error( this.request.LastErrors );
            } else {
                await MessageBox.Error( 'Ocurrió un error inesperado. Intentá nuevamente.' );
            }
        }

        this.Status.setOk();
    }

    public async prescription ( type: 'birth_control' | 'chronic' ) {

        this.Status.setSaving();
        this.downloadingPrescription = true;

        const authorization = await this.request.prescription( type );
        if ( authorization ) {

            if ( authorization.Status.isIssued ) {

                if ( authorization.canDownloadPrescription() ) {
                    const budgetFile = await authorization.getPrescriptionUrl();

                    if ( budgetFile )
                        window.open( budgetFile.url );

                    Notifications.Success( 'Autorización aceptada' );
                }
                else {
                    await MessageBox.Accept( {
                        title: 'Autorización pendiente de confirmación',
                        content: 'La autorización fue aprobada por el convenio pero no se encuentra confirmada.',
                    } );
                }
            }
            else if ( authorization.Status.isPending ) {

                const rpFile = await authorization.getProvisionalRPUrl();

                if ( rpFile )
                    window.open( rpFile.url );

                await MessageBox.Accept( {
                    title: 'Autorización pendiente de confirmación',
                    content: 'La autorización fue aprobada por el convenio pero no se encuentra confirmada.',
                } );
            }
            else if ( authorization.Status.isCanceled ) {

                await MessageBox.Accept( {
                    title: 'Autorización cancelada',
                    content: 'La autorización fue cancelada. Intentá nuevamente.',
                } );
            }
            else if ( authorization.Status.isReject ) {

                await MessageBox.Accept( {
                    title: 'Autorización rechazada',
                    content: 'La autorización fue rechazada. Intentá nuevamente.',
                } );
            }

            this.reset();
        }
        else {
            if ( this.request.LastErrors?.length ) {
                await MessageBox.Error( this.request.LastErrors );
            } else {
                await MessageBox.Error( 'Ocurrió un error inesperado. Intentá nuevamente.' );
            }
        }

        this.downloadingPrescription = false;
        this.Status.setOk();
    }

    public async manualReset () {

        if ( Boolean( this.Affiliate?.Id ) ) {
            const result = await MessageBox.YesNo( {
                title: 'Restablecer',
                content: 'Se perderán datos no guardados.'
            } );
            if ( result ) {
                this.reset();
                return true;
            }
        } else {
            this.reset();
            return true;
        }
    }

    public async openElectronicPrescription () {

        this.Status.setLoading();
        this.downloadingPrescription = true;
        const redirectData = await this.request.getElectronicPrescriptionUrl();
        if ( redirectData ) {
            if ( redirectData.url.includes( 'gower.com.ar' ) )
                Redirect.go( redirectData.url );
            else
                window.open( redirectData.url );
        }
        else {
            if ( this.request.LastErrors?.length ) {
                await MessageBox.Error( this.request.LastErrors );
            } else {
                await MessageBox.Error( 'Ocurrió un error inesperado. Intentá nuevamente.' );
            }
        }
        this.downloadingPrescription = false;
        this.Status.setOk();
    }

    public async openMedereElectronicPrescription () {

        this.Status.setLoading();
        this.downloadingPrescription = true;
        const redirectData = await this.request.getMedereElectronicPrescriptionUrl();
        if ( redirectData ) {
            Redirect.go( redirectData.url );
        }
        else {
            if ( this.request.LastErrors?.length ) {
                await MessageBox.Error( this.request.LastErrors );
            } else {
                await MessageBox.Error( 'Ocurrió un error inesperado en los servicios de MEDERE. Intentá nuevamente.' );
            }
        }
        this.downloadingPrescription = false;
        this.Status.setOk();
    }

    public async openIOMAElectronicPrescription () {

        this.Status.setLoading();
        this.downloadingPrescription = true;
        const redirectData = await this.request.getIOMAElectronicPrescriptionUrl();
        if ( redirectData ) {
            Redirect.go( redirectData.url );
        }
        else {
            if ( this.request.LastErrors?.length ) {
                await MessageBox.Error( this.request.LastErrors );
            } else {
                await MessageBox.Error( 'Ocurrió un error inesperado en los servicios de IOMA. Intentá nuevamente.' );
            }
        }
        this.downloadingPrescription = false;
        this.Status.setOk();
    }
}

export const { useStore, provider } = expose( AuthorizationStore );
