import React from 'react';
import { Alert, Button, Col, Content, Label, Row, Spacer } from '@gower/react-ui/components';
import { AiOutlineVerticalAlignBottom, AiOutlineVerticalAlignTop, BiChevronDown, BiChevronUp } from '@gower/react-ui/icons';
import { useStore } from '../../store';
import { DetailField } from '@gower/react-ui/template/detail-field';
import { useBoolean } from '@gower/react-ui/hooks';

export const TechnicalDetailTab: React.FC = () => {

    const store = useStore();
    const data = store.Authorization;
    const logs = store.AuthorizationLogs;

    const request = useBoolean( false );
    const response = useBoolean( false );

    return <>
        <Row columns paddingBottom={100}>
            <Col center padding={20}>
                <Alert info content="Detalle de la comunicación con el Convenio. Para mas información consulte el historial de la autorización." />
            </Col>
            <Col>
                <Row padding={10}>
                    <Col cols={12}>
                        <DetailField titleWidth="50px" title="ID CA" value={data.Id} />
                    </Col>
                    <Col cols={12}>
                        <DetailField titleWidth="50%" title="Ref. Convenio" value={data.AgreementId} />
                    </Col>
                    <Col cols={12}>
                        <DetailField titleWidth="50%" title="Ref. CM" value={data.ReferenceId} />
                    </Col>
                </Row>
            </Col>
            <Col>
                <Row>
                    <Col cols={12}>
                        <Row columns padding={10}>
                            <Col fullWidth>
                                <Button block borderRadius={0} onClick={request.toggle}>
                                    <Row>
                                        <Spacer gutter center>
                                            <Col center>
                                                <AiOutlineVerticalAlignTop size={18} />
                                            </Col>
                                            <Col center>
                                                <Label gutter xxsmall bold lineHeight={0} label="Request Payload" />
                                            </Col>
                                        </Spacer>
                                        <Col gutter center>
                                            {request.active && <BiChevronDown size={24} />}
                                            {!request.active && <BiChevronUp size={24} />}
                                        </Col>
                                    </Row>
                                </Button>
                            </Col>
                            <Col paddingTop={20} show={request.active}>
                                <Content
                                    padding={10}
                                    xsmall
                                    color="surface-1"
                                    border
                                    borderColor="surface-4"
                                    borderRadius={5}
                                    scroll
                                >
                                    <pre>
                                        {logs?.Request || 'SIN DATOS'}
                                    </pre>
                                </Content>
                            </Col>
                        </Row>
                    </Col>
                    <Col cols={12}>
                    <Row columns padding={10}>
                            <Col fullWidth>
                                <Button block borderRadius={0} onClick={response.toggle}>
                                    <Row>
                                        <Spacer gutter center>
                                            <Col center>
                                                <AiOutlineVerticalAlignBottom size={18} />
                                            </Col>
                                            <Col center>
                                                <Label gutter xxsmall bold lineHeight={0} label="Response Payload" />
                                            </Col>
                                        </Spacer>
                                        <Col gutter center>
                                            {response.active && <BiChevronDown size={24} />}
                                            {!response.active && <BiChevronUp size={24} />}
                                        </Col>
                                    </Row>
                                </Button>
                            </Col>
                            <Col paddingTop={20} show={response.active}>
                                <Content
                                    padding={10}
                                    xsmall
                                    color="surface-1"
                                    border
                                    borderColor="surface-4"
                                    borderRadius={5}
                                    scroll
                                >
                                    <pre>
                                        {logs?.Response || 'SIN DATOS'}
                                    </pre>
                                </Content>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
        </Row>
    </>;

};