import React from 'react';
import { InputAdornment, TextField } from '@mui/material';
import { Div, Button, Col, Content, Label, Spacer } from '@gower/react-ui/components';
import { AiFillLock, AiFillEye, AiFillEyeInvisible } from '@gower/react-ui/icons';
import { context } from './store';

export const Step3Form: React.FC = () =>
{
    const store = React.useContext( context ).current;
    const { status } = store;
    const [password1, setPassword1] = React.useState( '' );
    const [password2, setPassword2] = React.useState( '' );
    const [showPassword, setShowPassword] = React.useState( false );

    return <>
        <Content columns fullHeight>
            <Col fullWidth center py-5 display='block'>
                <Label p normal textWeight='500' letterSpacing="wide" label='Ingresá tu nueva contraseña.' mb-4 />
                <TextField
                    id="password"
                    autoComplete='password'
                    autoCapitalize='off'
                    autoCorrect='off'
                    placeholder="Ingresá tu contraseña"
                    variant="standard"
                    size='medium'
                    fullWidth
                    disabled={status.isLoading}
                    value={password1}
                    onChange={( e ) => setPassword1( e.target.value )}
                    type={showPassword ? "text" : "password"}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <AiFillLock size={20} />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="start">
                                <Button noFocuseable text circle pointer size={20} textGrey onClick={() => setShowPassword( !showPassword )}>
                                    {!showPassword && <AiFillEye size={18} />}
                                    {showPassword && <AiFillEyeInvisible size={18} />}
                                </Button>
                            </InputAdornment>
                        ),
                    }}
                />
                <Div mt-5 />
                <Label p normal textWeight='500' letterSpacing="wide" label='Repetí la nueva contraseña.' mb-4 />
                <TextField
                    id="password"
                    autoComplete='password'
                    autoCapitalize='off'
                    autoCorrect='off'
                    placeholder="Repetí la contraseña"
                    variant="standard"
                    size='medium'
                    fullWidth
                    disabled={status.isLoading}
                    value={password2}
                    onChange={( e ) => setPassword2( e.target.value )}
                    type={showPassword ? "text" : "password"}
                    onKeyUp={( e ) => e.key === 'Enter' && store.createPassword( password1, password2 )}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <AiFillLock size={20} />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="start">
                                <Button noFocuseable text circle pointer size={20} textGrey onClick={() => setShowPassword( !showPassword )}>
                                    {!showPassword && <AiFillEye size={18} />}
                                    {showPassword && <AiFillEyeInvisible size={18} />}
                                </Button>
                            </InputAdornment>
                        ),
                    }}
                />
            </Col>

            <Spacer vertical>
                <Div fullWidth block minHeight={40} maxHeight={100} pt-2 textCenter scrollOnlyVertical>
                    {store.LastErrors && store.LastErrors.map( ( error,key ) => <Label p key={key} textRed normal>{error}</Label> )}
                </Div>
                <Div fullWidth useMediaQuery height={46} xsHeight={64}>
                    <Button pointer
                        normal
                        textWhite
                        color="primary"
                        fullWidth
                        fullHeight
                        textWeight='500'
                        letterSpacing='wide'
                        loading={status.isLoading}
                        disabled={status.isErrored}
                        onClick={() => store.createPassword( password1, password2 )}
                    >
                        Confirmar
                    </Button>
                </Div>
                <Div fullWidth mt-2 useMediaQuery height={46} xsHeight={64}>
                    <Button
                        disabled={status.isLoading || status.isErrored}
                        text
                        normal
                        fullWidth
                        fullHeight
                        textColor='surface-8'
                        textWeight='500'
                        letterSpacing='wide'
                        label="Volver"
                        onClick={() => store.backStep()}
                    />
                </Div>
            </Spacer>
        </Content>
    </>;
};