import { MessageBox } from '@gower/react-ui/message-box';
import { Notifications } from '@gower/react-ui/notifications';
import { expose, MutableStore, SubStore, ViewStatus } from '@gower/react-ui/store';
import { Configuration } from '@gower/cm-mdp-sdk/src/lib/Configuration';
import { ErrorManager } from '@gower/react-ui/utils';
import { Validators } from '@gower/utils';

export class ConfigurationSubStore extends SubStore<ConfigurationStore>
{
    public get Status () { return this.MainStore.Status; }
    public get ErrorManager () { return this.MainStore.ErrorManager; }
    public get Configuration () { return this.MainStore.Configuration; }
    public get Data () { return this.Configuration?.data; };
}

class ConfigurationStore extends MutableStore {

    public readonly Status = ViewStatus.new( 'loading' );
    public readonly ErrorManager = ErrorManager.new( {
        autoClearTimeOut: 4000,
        fields: ['port', 'email', 'targets']
    } );

    public Configuration: Configuration = null;
    public get Data () { return this.Configuration?.data; };

    public checkErrors () {
        this.ErrorManager.clear();

        if ( this.Data.quick_response.email )
            if ( !Validators.Email( this.Data.quick_response.email ) ) this.ErrorManager.setEmail( 'Completá este campo con un email válido' );
        if ( this.Data.quick_response.port )
            if ( Number( this.Data.quick_response.port ).isNan() ) this.ErrorManager.setPort( 'Completá este campo con un número válido' );
        if ( this.Data.quick_response.targets )
            if ( this.Data.quick_response.targets.split( ',' ).some( item => !Validators.Email( item ) ) ) this.ErrorManager.setTargets( 'Hay emails inválidos' );

        return !this.ErrorManager.hasErrors();
    }

    public reset () {
        this.ErrorManager.clear();
        this.Status.setOk();
    }

    protected async onAfterRender () {

        this.Status.setLoading();
        this.Configuration = await Configuration.get();
        if ( this.Configuration )
            this.Status.setOk();
        else
            this.Status.setEmpty();
    }

    public async save () {

        this.checkErrors();
        if ( !this.ErrorManager.hasErrors() ) {
            this.Status.setSaving();
            const result = await this.Configuration.save();
            if ( result ) {
                Notifications.Saved();
            } else {
                await MessageBox.Error();
            }
            this.Status.setOk();
        }
    }
}

export const { useStore, provider } = expose( ConfigurationStore );