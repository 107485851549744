import React from 'react';
import { Col, TextAreaInput } from '@gower/react-ui/components';
import { useStore } from '../../store';

export type InputMessageProps = {

};
export const InputMessage: React.FC<InputMessageProps> = ( { } ) => {

    const store = useStore();
    const agreement = store.Agreement;

    if ( agreement?.isSAMI )
        return <>
            <Col cols={12} paddingY={4} center>
                <RenderInput
                    prefix="Observación:"
                    placeholder="(opcional) Maximo 200 carácteres"
                />
            </Col>
        </>;
    else
        return <>
            <Col cols={12} paddingY={4} center>
                <RenderInput
                    prefix="Observación:"
                    placeholder="(opcional) Maximo 200 carácteres"
                />
            </Col>
        </>;

};

const RenderInput = ( { prefix, placeholder } ) => {

    const store = useStore();
    const status = store.Status;
    const [value, setValue] = React.useState( '' );

    async function submit () {
        store.Request.setMessage( value );
    };

    function clear () {
        store.Request.setMessage( '' );
        setValue( '' );
    };

    React.useEffect( () => {

        const listener = () => {
            setValue( '' );
        };

        store.on( 'reset', listener );

        return () => { store.removeListener( 'reset', listener ); };
    }, [] );


    return (
        <TextAreaInput
            height={100}
            large
            noDelayed
            prefix={prefix}
            placeholder={placeholder}
            showCounter
            maxLength={200}
            value={value}
            onChangeText={setValue}
            selectAllOnFocus
            readOnly={status.isLoading || status.isSaving}
            onClear={clear}
            clearableOnEscape
            onEnterUp={submit}
            onBlur={submit}

        />
    );
};