import { User, Filters } from '@gower/shared-sdk/core/users';
import { UsersWithSpecialtiesService } from '@gower/cm-mdp-sdk/specialties';
import { ViewStatus } from '@gower/models-sdk/utils';
import { MessageBox } from '@gower/react-ui/message-box';
import { expose } from '@gower/react-ui/store';
import { DataGridStore } from '@gower/react-ui/template/data-grid';

class UsersStore extends DataGridStore( User, Filters, { viewName: 'USERS' } )
{
    public readonly DownloadStatus = ViewStatus.new( 'ok' );
    async downloadList () {
        const service = UsersWithSpecialtiesService.get();
        this.DownloadStatus.setLoading();
        const file = await service.download();
        if ( file?.url ) {
            window.open( file.url );
        } else {
            await MessageBox.Error( service.LastErrors );
        }
        this.DownloadStatus.setOk();
    }


}

export const { useStore, provider } = expose( UsersStore );