import React from 'react';
import { Col, Colors, Content, Div, Divider, Input, Label, Row, SearchInput, Spacer, Tooltip } from '@gower/react-ui/components';
import { useStore } from '../store';
import { DataGrid, DataGridColumn, DataGridRef } from '@gower/react-ui/template/data-grid';
import { AiOutlineCalendar } from '@gower/react-ui/icons';
import { News } from '@gower/cm-mdp-sdk/news';

export const NewsMobile = () => {

    const store = useStore();
    const gridRef = React.useRef<DataGridRef>();

    function search ( text: string ) {
        gridRef?.current?.search( text );
    }

    return <>
        <Content start columns noScroll>
            <Col
                fullWidth
                padding={10}
            >
                <SearchInput
                    placeholder="Buscar una noticia"
                    borderless
                    onSearch={search}
                    disabled={store.Status.isLoading}
                    clearable
                />
            </Col>

            <Spacer vertical scroll white>
                <DataGrid
                    ref={gridRef}
                    store={store}
                    loading={store.Status.isLoading}
                    mobileTemplate={( { data }: { data: News; } ) => (
                        <Row
                            white
                            center
                            borderRadius={5}
                            padding={5}
                            activeEffect
                            noWrap
                        >
                            <Col
                                stretch
                                width={4}
                                color="surface-2"
                            />
                            <Spacer center gutter paddingX={8} ellipsis>
                                <Row columns>
                                    <Col>
                                        <Label p xxsmall bold>
                                            {data.Title}
                                        </Label>
                                    </Col>
                                    <Col>
                                        <Col center horizontalGutter>
                                            <AiOutlineCalendar />
                                        </Col>
                                        <Col center horizontalGutter>
                                            <Label p>
                                                <Label gutter xsmall label={( data.Date as any ).toStringDate( 'short' )} />
                                            </Label>
                                        </Col>
                                    </Col>
                                </Row>
                            </Spacer>
                            <Col center gutter>
                                <Div textRight>
                                    <Label p xsmall label={data.Type.Description} />
                                    <Label p xsmall label={data.Agreement.Description} hidden={data.Agreement.isUnspecified} />
                                    <Label p xxsmall
                                        xsmall
                                        textRed={data.Status.isDeactivated}
                                        textGreen={data.Status.isActive}
                                        label={data.Status.Description} />
                                </Div>
                            </Col>
                        </Row>
                    )}
                >
                    <DataGridColumn
                        field="LocalId"
                        title="Nº de Usuario"
                        size={80}
                        minSize={50}
                        template={( { data } ) => (
                            <Div block>
                                <Label p >
                                    #{data.LocalId}
                                </Label>
                            </Div>
                        )}
                    />
                </DataGrid>
            </Spacer>

        </Content>
    </>;
};

