import type { DrawerItemType } from '@gower/react-ui/template/drawer';
import { adminOnly } from 'app/credential-provider';


export enum RoutesEnum {
    BaseAuth = '/a',
    Login = '/a/signin',
    ActivateUser = '/a/activate-user',
    ForgotPassword = '/a/forgot-password',
    RestorePasswordUser = '/a/signin',
    PublicManual = '/a/manual',
    PublicQuickResponse = '/a/quick-response',
    PublicContingency = '/a/contingency',
    PublicFAQ = '/a/faq',

    Base = '/',
    Home = '/',
    Users = '/manage/users',
    UsersImport = '/manage/users/import',
    UsersCreate = '/manage/users/create',
    UsersEdit = '/manage/users/edit',
    News = '/manage/news',
    SecureCodes = '/manage/secure-codes',
    Authorizations = '/authorizations',
    Authorize = '/authorize',
    Manual = '/manual',
    QuickResponse = '/quick-response',
    Contingency = '/contingency',
    AuthorizeForm = '/authorize/forms',
    FAQ = '/faq',
    Profile = '/profile',
    ChangePassword = '/profile/psw',
    EditUser = '/profile/edit-user',
    Configuration = '/manage/configuration',
    AgreementConfigs = '/manage/agreement-config',
    Specialties = '/manage/specialties',
    UnregisteredAgreements = '/agreements/unregistered',
    UnregisteredElectronicPrescriptions = '/electronic-prescriptions/unregistered',
}


export const DrawerItems: DrawerItemType[] = [

    { type: 'title', label: 'Autorizaciones' },
    { link: RoutesEnum.Authorize, label: 'Autorizar prestación' },
    { link: RoutesEnum.Authorizations, label: 'Historial de autorizaciones' },
    { type: 'divider' },
    { type: 'title', label: 'Información' },
    { link: RoutesEnum.QuickResponse, label: 'Asistencia Rápida' },
    { link: RoutesEnum.Contingency, label: 'Contingencia' },
    { label: 'Preguntas Frecuentes', action: () => window.open( '/assets/faq.pdf' ) },
    { label: 'Instructivo', action: () => window.open( '/assets/manual.pdf' ) },
    { type: 'divider' },
    { type: 'title', label: 'Configuración', security: adminOnly },
    { link: RoutesEnum.News, label: 'Noticias', security: adminOnly },
    { link: RoutesEnum.Specialties, label: 'Especialidades', security: adminOnly },
    { link: RoutesEnum.Users, label: 'Operadores', security: adminOnly },
    { link: RoutesEnum.AgreementConfigs, label: 'Convenios', security: adminOnly },
    { link: RoutesEnum.UnregisteredAgreements, label: 'Convenios no registrados', security: adminOnly },
    { link: RoutesEnum.Configuration, label: 'Configuración', security: adminOnly },
    { link: RoutesEnum.SecureCodes, label: 'Códigos de seguridad', security: adminOnly },
];
