import React, { SVGAttributes } from "react";

export const LogoAMEBPBA: React.FC<Omit<SVGAttributes<any>, 'dangerouslySetInnerHTML'>> = ( { ...props } ) => {
  return (
    <svg
      {...props}
      viewBox="0 0 755.96 736.4"
      dangerouslySetInnerHTML={{
        __html: `
          <defs>
            <style>
              .amebpba-cls-1 {
                fill: #f3f5f6;
              }
        
              .amebpba-cls-2 {
                fill: #f7f7f7;
              }
        
              .amebpba-cls-3 {
                fill: #f8f9f8;
              }
        
              .amebpba-cls-4 {
                fill: #6a6a6a;
              }
        
              .amebpba-cls-5 {
                fill: #eeeff0;
              }
        
              .amebpba-cls-6 {
                fill: #e6e6e6;
              }
        
              .amebpba-cls-7 {
                fill: #f5f6f6;
              }
        
              .amebpba-cls-8 {
                fill: #fcfcfc;
              }
        
              .amebpba-cls-9 {
                fill: #e7e7e7;
              }
        
              .amebpba-cls-10 {
                fill: #ededed;
              }
        
              .amebpba-cls-11 {
                fill: #f1f2f4;
              }
        
              .amebpba-cls-12 {
                fill: #fafafa;
              }
        
              .amebpba-cls-13 {
                fill: #eff2f3;
              }
        
              .amebpba-cls-14 {
                fill: #fdfdfd;
              }
        
              .amebpba-cls-15 {
                fill: #7d8184;
              }
        
              .amebpba-cls-16 {
                fill: #969799;
              }
        
              .amebpba-cls-17 {
                fill: #7c7f7e;
              }
        
              .amebpba-cls-18 {
                fill: #9c9d9d;
              }
        
              .amebpba-cls-19 {
                fill: #9c9c9c;
              }
        
              .amebpba-cls-20 {
                fill: #b4b5b5;
              }
        
              .amebpba-cls-21 {
                fill: #b8b8b8;
              }
        
              .amebpba-cls-22 {
                fill: #b9b9b9;
              }
        
              .amebpba-cls-23 {
                fill: #8f9190;
              }
        
              .amebpba-cls-24 {
                fill: #c2c7c7;
              }
        
              .amebpba-cls-25 {
                fill: #c1c5c4;
              }
        
              .amebpba-cls-26 {
                fill: #b6b7b7;
              }
        
              .amebpba-cls-27 {
                fill: #b6b8b7;
              }
        
              .amebpba-cls-28 {
                fill: #cecece;
              }
        
              .amebpba-cls-29 {
                fill: #cecfce;
              }
        
              .amebpba-cls-30 {
                fill: #b1b5b8;
              }
        
              .amebpba-cls-31 {
                fill: #b1b5b7;
              }
        
              .amebpba-cls-32 {
                fill: #acacac;
              }
        
              .amebpba-cls-33 {
                fill: #a6a6a6;
              }
        
              .amebpba-cls-34 {
                fill: #b1b2b4;
              }
        
              .amebpba-cls-35 {
                fill: #b1b1b1;
              }
        
              .amebpba-cls-36 {
                fill: #b0b0b1;
              }
        
              .amebpba-cls-37 {
                fill: #a5a5a5;
              }
        
              .amebpba-cls-38 {
                fill: #aeafb1;
              }
        
              .amebpba-cls-39 {
                fill: #bbb;
              }
        
              .amebpba-cls-40 {
                fill: #bdbebe;
              }
        
              .amebpba-cls-41 {
                fill: silver;
              }
        
              .amebpba-cls-42 {
                fill: #bebebe;
              }
        
              .amebpba-cls-43 {
                fill: #bec0c0;
              }
        
              .amebpba-cls-44 {
                fill: #bebfbf;
              }
        
              .amebpba-cls-45 {
                fill: #c9c9c9;
              }
        
              .amebpba-cls-46 {
                fill: #c9cbcb;
              }
        
              .amebpba-cls-47 {
                fill: #d0d0d0;
              }
        
              .amebpba-cls-48 {
                fill: #cfcfcf;
              }
        
              .amebpba-cls-49 {
                fill: #c7cac9;
              }
        
              .amebpba-cls-50 {
                fill: #cbcbcb;
              }
        
              .amebpba-cls-51 {
                fill: #d2d2d2;
              }
        
              .amebpba-cls-52 {
                fill: #d1d3d2;
              }
        
              .amebpba-cls-53 {
                fill: #dadada;
              }
        
              .amebpba-cls-54 {
                fill: #dadcde;
              }
        
              .amebpba-cls-55 {
                fill: #dadbdb;
              }
        
              .amebpba-cls-56 {
                fill: #afafaf;
              }
        
              .amebpba-cls-57 {
                fill: #acafae;
              }
        
              .amebpba-cls-58 {
                fill: #c5c6c5;
              }
        
              .amebpba-cls-59 {
                fill: #c8c8c8;
              }
        
              .amebpba-cls-60 {
                fill: #d5d5d5;
              }
        
              .amebpba-cls-61 {
                fill: #121212;
              }
        
              .amebpba-cls-62 {
                fill: #131313;
              }
        
              .amebpba-cls-63 {
                fill: #131213;
              }
        
              .amebpba-cls-64 {
                fill: #171918;
              }
        
              .amebpba-cls-65 {
                fill: #161616;
              }
        
              .amebpba-cls-66 {
                fill: #161817;
              }
        
              .amebpba-cls-67 {
                fill: #131414;
              }
        
              .amebpba-cls-68 {
                fill: #0c0e0f;
              }
        
              .amebpba-cls-69 {
                fill: #0c0d0d;
              }
        
              .amebpba-cls-70 {
                fill: #0c0f0e;
              }
        
              .amebpba-cls-71 {
                fill: #121313;
              }
        
              .amebpba-cls-72 {
                fill: #121314;
              }
        
              .amebpba-cls-73 {
                fill: #121315;
              }
        
              .amebpba-cls-74 {
                fill: #151515;
              }
        
              .amebpba-cls-75 {
                fill: #151517;
              }
        
              .amebpba-cls-76 {
                fill: #151716;
              }
        
              .amebpba-cls-77 {
                fill: #151615;
              }
        
              .amebpba-cls-78 {
                fill: #0d0f0e;
              }
        
              .amebpba-cls-79 {
                fill: #0d0d0d;
              }
        
              .amebpba-cls-80 {
                fill: #0d0e0e;
              }
        
              .amebpba-cls-81 {
                fill: #111214;
              }
        
              .amebpba-cls-82 {
                fill: #111;
              }
        
              .amebpba-cls-83 {
                fill: #111415;
              }
        
              .amebpba-cls-84 {
                fill: #111314;
              }
        
              .amebpba-cls-85 {
                fill: #101213;
              }
        
              .amebpba-cls-86 {
                fill: #101211;
              }
        
              .amebpba-cls-87 {
                fill: #101111;
              }
        
              .amebpba-cls-88 {
                fill: #101110;
              }
        
              .amebpba-cls-89 {
                fill: #1c1e1d;
              }
        
              .amebpba-cls-90 {
                fill: #101010;
              }
        
              .amebpba-cls-91 {
                fill: #0f1113;
              }
        
              .amebpba-cls-92 {
                fill: #0f0f0f;
              }
        
              .amebpba-cls-93 {
                fill: #0e0e0e;
              }
        
              .amebpba-cls-94 {
                fill: #0e0f0f;
              }
        
              .amebpba-cls-95 {
                fill: #373737;
              }
        
              .amebpba-cls-96 {
                fill: #444747;
              }
        
              .amebpba-cls-97 {
                fill: #191a19;
              }
        
              .amebpba-cls-98 {
                fill: #181919;
              }
        
              .amebpba-cls-99 {
                fill: #181818;
              }
        
              .amebpba-cls-100 {
                fill: #0e100f;
              }
        
              .amebpba-cls-101 {
                fill: #1b1b1b;
              }
        
              .amebpba-cls-102 {
                fill: #1b1d1e;
              }
        
              .amebpba-cls-103 {
                fill: #1a1a1a;
              }
        
              .amebpba-cls-104 {
                fill: #a3a3a3;
              }
        
              .amebpba-cls-105 {
                fill: #a3a4a4;
              }
        
              .amebpba-cls-106 {
                fill: #9b9b9b;
              }
        
              .amebpba-cls-107 {
                fill: #6f7478;
              }
        
              .amebpba-cls-108 {
                fill: #6f6f6f;
              }
        
              .amebpba-cls-109 {
                fill: #959595;
              }
        
              .amebpba-cls-110 {
                fill: #959796;
              }
        
              .amebpba-cls-111 {
                fill: #545454;
              }
        
              .amebpba-cls-112 {
                fill: #868686;
              }
        
              .amebpba-cls-113 {
                fill: #86898b;
              }
        
              .amebpba-cls-114 {
                fill: #6e6e6e;
              }
        
              .amebpba-cls-115 {
                fill: #636363;
              }
        
              .amebpba-cls-116 {
                fill: #626262;
              }
        
              .amebpba-cls-117 {
                fill: #4f5456;
              }
            </style>
          </defs>
          <g>
            <g>
              <path class="amebpba-cls-82" d="M641.64,382.77c-1.63-42.28-9.43-83.05-27.1-121.93-23.55-51.81-59.65-91.42-110.37-117.32-27.74-14.17-57.42-21.9-88.31-25.85-28.45-3.63-56.57-3.24-84.71,2.35-51.65,10.27-96.72,33.23-134.24,70.19-37.38,36.82-61.49,81.1-73.16,132.4-6.16,27.09-8.89,54.38-6.42,81.89,3.35,37.23,13.36,72.68,31.79,105.58,22.34,39.9,54.09,70.45,92.21,94.98,4.69,3.02,6.23,.3,7.15-2.93,2.1-7.33,6.04-6.96,11.67-3.97,7.54,4,14.2,9,20.13,15.11,8.64,8.9,9.58,19.75,7.44,31.03-5.02,26.43-11.18,52.63-23.83,76.62-9.9,18.77-7.57,18.5-28.42,9.65-22.9-9.72-45.32-20.43-66.28-33.92-49.66-31.97-90.83-72.05-119.84-124.14-20.43-36.68-33.19-75.92-41.02-116.87-3.58-18.72-4.52-37.85-6.57-56.8-1.37-12.61-2.39-25.15-1.31-37.73,3.33-38.71,8.91-76.95,21.64-113.95,11.06-32.16,26.4-61.99,45.89-89.72,26.02-37.04,57.32-68.85,95.4-93.54,43.83-28.42,91.36-47.56,143.09-56.2C331.01,3.59,355.65-.61,380.45,.07c54.3,1.51,106.97,11.19,156.73,34.49,51.55,24.14,95.82,57.52,131.54,101.73,32.83,40.64,57.12,85.99,71.65,136.41,7.9,27.4,12.35,55.39,14.76,83.69,1.95,22.86,.34,45.75-3.6,68.29-5.14,29.38-9.29,58.94-19.63,87.31-17.82,48.85-43.61,92.39-79.44,130.3-38.51,40.74-84.62,69.57-135.83,91.14-5.71,2.41-8.01,1.63-10.66-4.51-9.66-22.4-20.79-44.12-29.44-67-9.76-25.84,2.62-55.34,28.25-65.79,4-1.63,6.09-.93,7.21,3.53,1.88,7.54,2.02,7.38,8.72,3.51,40.51-23.4,71.14-56.04,92.25-97.78,15.1-29.87,23.82-61.48,26.91-94.71,.86-9.27,2.13-18.54,1.77-27.92Z"/>
              <path class="amebpba-cls-93" d="M209.88,463.69c-.14-24.67,6.04-48.3,12.62-71.71,5.89-20.95,5.01-41.19-2.71-61.21-6.59-17.08-14.48-33.48-29.82-44.8-2.52-1.86-2.31-3.84-1.34-6.39,6.1-16.18,12.16-32.39,18.09-48.63,1.1-3.02,2.98-3.87,5.98-4.03,31.66-1.66,60.98-11.41,88.24-27.1,20.27-11.66,38.04-26.82,55.19-42.66,6.68-6.17,13.46-12.22,20.24-18.28,3.05-2.73,5.79-2.8,9.3-.02,11.34,8.98,21.01,19.7,31.63,29.43,19.18,17.57,40.34,32.13,64.54,41.58,19.86,7.76,40.61,12.89,61.96,14.47,6.53,.48,9.7,2.7,11.83,8.86,4.64,13.4,10.04,26.54,15.28,39.73,2.05,5.17,1.43,8.73-2.76,13.26-31.26,33.74-40.43,72.78-26.07,116.77,6.11,18.73,9.28,37.83,9.84,57.48,.5,17.52,.29,35.02-2.29,52.36-3.46,23.31-15.35,42.32-29.66,60.71-17.86,22.94-43.9,31.48-68.43,43.03-18.11,8.52-36,17.32-50.69,31.38-6.32,6.04-11.5,12.9-15.92,20.38-1.16,1.96-2.39,3.86-4.07,.68-13.49-25.53-37.63-36.55-62.8-46.02-54.38-20.45-88.56-58.74-102.39-115.19-3.55-14.48-5.6-29.14-5.75-44.07Z"/>
              <path class="amebpba-cls-8" d="M536.34,708.03c1.16-4.13,4.82-5.74,7.45-8.11,3.71-3.34,4.2-6.36,2.24-10.95-8.54-19.99-16.54-40.22-25.16-60.17-2.07-4.78-1.15-6.81,3.13-9.38,26.79-16.16,50.71-35.89,70.59-60.1,30.77-37.49,50.07-80.1,57.45-128.32,3.28-21.4,5.14-42.66,3.69-64.19-1.93-28.65-6.77-56.77-16.06-84.03-11.28-33.13-27.8-63.35-50.74-89.86-27.33-31.59-60.31-55.23-99.18-70.91-35.02-14.13-71.27-21.69-108.93-21.99-44.23-.35-86.5,9.24-126.06,28.86-40.3,19.99-73.03,49.35-99.03,85.98-27.28,38.44-44.51,81.22-52.2,127.65-5.9,35.67-4.15,71.35,4.54,106.36,10.31,41.5,29.08,78.88,57.17,111.49,21.31,24.74,45.53,46,72.83,63.8,3.19,2.08,3.33,3.91,1.87,7.08-8.61,18.73-16.75,37.69-25.72,56.25-2.94,6.09-2.47,9.66,3.39,12.89,2.4,1.32,4.28,3.58,6.5,5.51-1.93,1.99-3.48,.96-4.97,.22-58.44-29.2-108.34-68.54-143.92-124.03-26.45-41.24-42.42-86.6-49.93-135.08-3.65-23.52-6.93-47.13-6.47-70.84,1.2-61.31,13.67-119.99,42.31-174.92,33.86-64.93,83.1-114.16,148.48-146.63,37.58-18.66,77.7-29.7,119.56-34.75,47.52-5.74,94.48-3.28,140.69,8.43,58.69,14.87,110.5,42.9,154.94,84.44,27.84,26.02,51.11,55.56,69.26,88.87,15.85,29.09,27.63,59.92,35.14,92.38,6.09,26.29,9.38,52.96,9.97,79.76,.52,23.48-2.18,47.01-6.65,70.13-10.88,56.27-31.46,108.34-65.93,154.75-33.26,44.78-74.44,80.04-123,107.01-2.01,1.11-3.98,2.8-7.28,2.41Z"/>
              <path class="amebpba-cls-12" d="M488.93,643.97c-.07-9.49,1.61-16.47,7.39-21.85,2.62-2.44,4.75-2.66,6.35,1.41,8.51,21.66,17.16,43.26,25.83,64.85,.64,1.6,1.01,2.74-.54,4.06-4.22,3.59-8.39,7.25-12.44,11.03-2.4,2.24-3.66,1.44-4.89-1.14-8.08-17.02-16.05-34.09-21.04-52.33-.69-2.52-.59-5.26-.66-6.02Z"/>
              <path class="amebpba-cls-12" d="M273.32,635.01c-2.16,15.35-5.74,30.38-11.22,44.9-2.57,6.8-4.97,13.69-8.72,20.02-2.23,3.76-3.71,5.12-7.79,1.55-11.84-10.36-12.08-10.18-6.6-25.07,6.1-16.59,12.28-33.16,18.57-49.68,.93-2.44,.78-6.61,4.53-6.32,3.92,.31,7.79,1.91,9.53,6.2,1.09,2.69,1.99,5.41,1.7,8.4Z"/>
              <path class="amebpba-cls-117" d="M231.18,710.26c.21,1.1-.16,1.87-1.19,1.87-1.98-.01-3.95-.43-4.44-2.72-.24-1.13,.6-2.18,1.82-2.1,1.88,.12,2.93,1.57,3.82,2.96Z"/>
              <path class="amebpba-cls-14" d="M221.32,472.02c.53-26.86,5.71-50.1,11.83-72.98,6.7-25.03,6.67-49.32-1.93-73.79-4.51-12.85-11.79-23.91-20.89-33.62-6.37-6.79-8.7-13.13-4.81-22,3.91-8.91,6.87-18.24,10.07-27.45,1.19-3.42,3.66-4.17,6.74-4.17,19.39-.05,37.79-5.19,55.72-11.74,19.91-7.28,37.72-18.51,54.86-30.99,14.93-10.86,28.54-23.28,42.9-34.8,4.56-3.66,7.16-2.59,10.58,.75,19.1,18.65,40.62,34.09,63.81,47.15,27.59,15.53,57.21,25.04,88.81,27.9,4.48,.4,6.82,2.23,8.24,6.18,3.54,9.8,7.09,19.61,10.87,29.32,1.65,4.23,.71,7.09-2.25,10.62-20.7,24.66-31.93,53.03-31.73,85.46,.09,14.6,5.33,28.23,8.79,42.2,10.74,43.42,11.83,86.74-5.18,128.82-10.9,26.96-30.44,46.2-57.75,57.58-21.87,9.11-43.99,17.87-63.81,31.27-7.77,5.26-14.75,11.41-21.23,18.11-2.53,2.62-4.13,3.29-6.75,.26-15.26-17.61-36.07-25.75-57.11-33.79-37.19-14.21-66.45-37.35-83.81-74.38-9.76-20.83-15.69-54.07-15.98-65.9Z"/>
              <path class="amebpba-cls-91" d="M86.52,431.14c1.01,3.1-.03,4.88-3.15,6.06-3.59,1.36-7.53,2.14-11.13,5.1,4.46,1.11,8-.47,11.62-.78,2.68-.23,4.17,.6,4.55,3.44,.45,3.29-1.83,2.81-3.76,3.1-10.3,1.51-20.6,3.06-30.89,4.62-2.42,.37-5.3,1.36-5.42-2.5-.1-3.28-3.27-7.54,2.57-9.41,4.1-1.31,8.08-3.01,11.85-5.94-4.26,0-8.55-.24-12.79,.06-6.42,.45-4.42-4.94-5.55-7.91-1.38-3.63,1.31-4.44,4.3-4.79,4.18-.49,8.33-1.19,12.49-1.79,4.94-.62,9.59-2.49,14.46-3.46,2.9-.58,5.55-.62,8.08,1.01,.46,1.29,.92,2.57,1.38,3.86,1.62,3-.42,4.09-2.69,4.49-4.22,.73-8.49,1.28-11.72,1.21,4.95-.73,10.67,.28,15.81,3.63Z"/>
              <path class="amebpba-cls-82" d="M157.11,160.55c-4.94-5.04-9.86-10.09-14.82-15.1-1.5-1.51-3.1-3.29-.67-4.86,2.55-1.65,2.99-7.45,7.98-4.5,4.47,2.65,8.81,5.54,14.01,7.59-2.69-3.6-5.21-7.35-8.14-10.74-1.91-2.21-3.11-4.17,.17-5.65,2.95-1.33,4.16-8.34,9.38-2.95,3.99,4.11,8.44,7.78,11.78,12.51,3.7,2.73,7.25,5.66,10.57,8.84,1.51,1.45,3.66,2.86,1.81,5.5-.41,.49-.82,.97-1.22,1.46-1.75,2.29-3.44,4.06-6.25,1.07-2.39-2.54-5.65-4.18-7.12-6.45,1.81,1.81,2.65,5.04,3.7,8.17,2.09,2.11,1.94,4.17-.12,6.21-2.09,2.07-3.67,.19-5.19-.79-3.17-2.05-5.92-4.85-10.54-5.55,1.51,4.99,6.88,6.04,8.26,10.24-.45,4.67-2.8,4.25-5.65,2.02-2.78-2.17-4.56-5.48-7.92-7.01Z"/>
              <path class="amebpba-cls-81" d="M470.12,68.1c2.98-2.49,3.34-4.97,3.62-7.28,.32-2.73,1.74-3.32,4.04-2.93,2.54,.43,4.23,1.76,3.47,4.53-3.05,11.07-6.21,22.11-9.36,33.15-.5,1.77-1.78,2.83-3.46,1.8-2.3-1.42-6.67-.36-6.24-5.29,.38-4.46,.58-8.98-1.79-13.51-.84,3.54-1.84,7.04-2.47,10.62-.65,3.69-2.78,3.96-5.79,3.17-3.42-.9-2.2-2.95-1.57-5.13,2.68-9.33,5.19-18.71,7.86-28.05,.78-2.73,.4-6.61,5.46-5.48,4.17,.94,7.01,2.44,6.27,7.42-.29,1.93-.05,3.93-.05,6.98Z"/>
              <path class="amebpba-cls-86" d="M689,497.82c2.21,1.42,5.95,1.65,4.1,6.02-1.79,4.21-4.55,2.27-7.11,1.37-9.2-3.22-18.35-6.62-27.59-9.71-2.88-.97-3.81-2.12-3.12-5.28,.66-3.01,1.41-4.88,4.96-4.71,4.59,.22,9.19,.05,14.02-.75-3.87-1.35-7.67-2.95-11.62-3.98-3.32-.87-3.51-2.83-2.86-5.49,1.6-3.45,4.52-3.27,7.34-2.31,9.17,3.15,18.51,5.8,27.62,9.11,2.88,1.05,5.79,2.69,4.55,6.09-1.13,3.1-1.11,7.82-6.61,7.66-1.16-.03-4.16-1.72-3.69,1.98Z"/>
              <path class="amebpba-cls-86" d="M83.87,477.71c2.66-1.04,5.37-1.95,7.95-3.15,2.84-1.32,3.88,0,4.87,2.47,1.07,2.67,.66,3.97-2.26,4.84-10.38,3.1-20.71,6.36-31.02,9.67-2.71,.87-4.53,.02-5.14-2.51-.65-2.73-3.3-5.83,.65-8.29,3.1-1.92,6.66-3.24,9.15-6.51-3.97-.9-6.69,1.7-9.92,2.27-2.28,.41-3.89,.29-4.46-2.39-.49-2.29-.08-3.86,2.41-4.59,10.41-3.06,20.78-6.23,31.2-9.25,1.93-.56,4.62-.76,4.41,2.15-.17,2.51,4.23,5.43-.82,7.7-3.77,1.69-7.14,4.28-10.69,6.46-.69,.55-1.28,1.53-.37,1.92,1.32,.56,2.81,.15,4.02-.81Z"/>
              <path class="amebpba-cls-85" d="M675.81,263.24c3.9-1.41,7.91-2.61,11.68-4.32,2.46-1.11,4.35-1.4,5.27,1.46,.86,2.66,4.05,5.43,.16,8.21-1.78,1.27-3.6,2.48-5.41,3.71-.73,1.02-2.62,1.62-2.05,2.99,.27,.64,2.32,1.15,3.3-.37,.93-1.63,2.82-.87,4.12-1.67,2.22-1.37,4.13-1.31,5.27,1.52,1.22,3.05-.25,4.5-2.9,5.47-7.41,2.71-14.81,5.47-22.22,8.21-4.43,2.17-8.86,6.4-13.27-.02-1.56-3.02-1.01-5.27,1.99-7.2,3.8-2.44,7.35-5.25,10.45-8.99-3.59,1.35-7.36,2.36-10.72,4.14-3.7,1.96-5.59,.65-7.07-2.59-1.68-3.17,.99-3.62,2.86-4.52,5.91-2.84,12.31-4.21,18.55-6.04Z"/>
              <path class="amebpba-cls-92" d="M622.39,208.5c-4.02,1.15-5.04-2.4-6.19-4.62-1.27-2.45,1.81-3.1,3.26-4.15,7.98-5.74,16.11-11.28,24.05-17.07,3.33-2.43,5.81-2.95,7.71,1.49,.6,1.39,1.7,2.57,2.62,3.82,2.14,2.89,4.57,5.93,2.55,9.63-2.25,4.12-6.07,6.28-10.84,6.57-1.12,.07-2.99-1.54-3.2,.61-.69,6.87-7.38,7.55-11.17,11.16-1.42,1.36-3.08,4.31-5.76,1.68-2.42-2.37-3.72-5.04-.36-7.79,1.58-3.41,5.18-4.58,7.57-7.15-3,2.68-6.31,4.83-10.24,5.81Z"/>
              <path class="amebpba-cls-84" d="M353.17,80.55c-3.88,6.82-10.73,4.88-16.63,5.48-2.36,.24-2.55-2.21-2.72-3.86-1.13-10.75-2.13-21.51-3.18-32.27-.16-1.65,.12-2.85,1.98-3.62,10.6-4.33,18.07,.08,19.31,11.55,2.45,7.46,5.11,14.91,1.24,22.72Z"/>
              <path class="amebpba-cls-69" d="M70.43,372.53c-7.93-.8-15.87-1.64-23.81-2.4-2.7-.26-3.98-1.35-3.56-4.3,.37-2.54,1.07-4.15,4.08-3.9,7.73,.64,15.48,1.14,23.22,1.67,1.76,.12,3.53,.19,5.29,.19,1.14,0,2.42-.32,2.5-1.64,.11-1.93-1.53-1.84-2.82-1.84-9.11-.05-18.13-1.09-27.18-1.98-3.03-.3-4.31-.95-4.32-4.21,0-3.31,1.27-4.3,4.35-4.07,9.73,.72,19.5,.99,29.18,2.11,6.97,.8,6.83,6.57,6.5,11.82-.31,4.97-2.24,8.6-8.1,8.33-1.77-.08-3.55-.01-5.33-.01,0,.08-.01,.16-.02,.23Z"/>
              <path class="amebpba-cls-92" d="M670.39,215.92c-.28,3.02-1.63,5.03-4.2,6.48-6.93,3.93-13.7,8.11-20.63,12.04-5.84,3.31-8.96,2.12-12.71-4.5-3.55-6.28-2.75-10.13,2.79-13.29,6.09-3.48,12.25-6.84,18.25-10.45,3.08-1.86,5.97-4.25,9.66-1.74,4.07,2.76,6.24,6.76,6.83,11.46Z"/>
              <path class="amebpba-cls-7" d="M689,497.82c-2.04,0-3.8-.57-5.08-2.49,2.04-1.87,4.38-.93,6.29-.46,5.58,1.37,6.88-2.15,8.12-6.26,1.6-5.28-2.98-4.58-5.4-5.44-8.77-3.1-17.7-5.76-26.48-8.83-2.58-.9-4.67-1.04-6.66,.94,.33-4.4-.38-7.95-5.91-8.34-2.27-.16-3.27-2.37-3.81-4.15-.61-1.99,1.67-2.22,2.91-2.94,2.3-1.34,3.59-.2,4.74,1.84,3.2,5.65,4.02-.41,5.9-1.27,2.72,2.52-.24,5.47,.8,8.86,2.76-3.55,5.72-6.05,10.36-4.79,4.38,1.19,7.99,2.92,7.8,9.12,3.33-2.6,5.21-5.85,9.12-4.77,3.43,.94,5.67,2.52,6.7,6.38,.83,3.12,2.35-1.43,3.83-.44,2.01,5.19,3.97,10.4,6.09,15.55,.46,1.12,.68,2.06,.06,3.09-3.04,5.03-4.82,10.27-2.87,16.18,.35,1.05-.23,2.11-1.05,1.93-2.32-.52-4.6-1.33-6.81-2.25-1.08-.45-1.76-1.63-.67-2.65,3.41-3.18-.83-9.18,4.44-11.73,.76-.37-.16-1.63-.85-2.15-.47-.35-1.16-.41-1.75-.6-.75,6.26-7.14,2.76-9.82,5.67Z"/>
              <path class="amebpba-cls-88" d="M413.35,85.82c-9.41-.18-10.14-1.08-8.99-10.35,1.07-8.53,2.17-17.07,2.92-25.63,.33-3.73,1.87-4.83,5.46-4.7,14.2,.48,16.65,4.95,9.89,18.59-.46,.92-.64,1.65,.09,2.29,3.5,3.11,2,6.97,1.51,10.56-1.05,7.67-3.19,9.39-10.88,9.24Z"/>
              <path class="amebpba-cls-92" d="M529.02,124.67c-8.22-4.39-8.19-4.36-3.45-12.07,4.84-7.89,9.64-15.79,14.37-23.75,1.51-2.54,2.95-3.64,5.67-1.35,1.65,1.4,3.79,2.19,5.61,3.41,5.23,3.51,6.18,7.52,3.05,13-2.14,3.76-4.43,7.43-6.66,11.14-2.42,9.94-5.99,14.7-18.59,9.62Z"/>
              <path class="amebpba-cls-92" d="M524.7,82.32c-2.93,9.1-6.58,19.55-13.57,27.82-3.71,4.39-9.61,.82-13.92-2.01-3.87-2.54-3.61-5.84-1.57-9.75,3.86-7.38,7.26-14.99,10.89-22.49,1.8-3.73,4.56-5.07,8.72-3.76,6.58,2.07,9.32,4.46,9.45,10.19Z"/>
              <path class="amebpba-cls-67" d="M661.47,240.49c5.67-.11,10.5-2.3,15.26-4.48,4.16-1.9,5.79,.65,7.14,3.53,1.69,3.6-1.49,3.96-3.69,4.74-9.39,3.34-18.87,6.43-28.16,10.01-5.61,2.16-6.66-1.63-8.68-5.19-2.16-3.81-1.19-5.61,2.37-7.85,8.62-5.43,16.89-11.42,25.31-17.17,1.75-1.2,3.62-3.38,5.12,.29,.94,2.31,3.28,4.58-.32,6.81-4.85,3-9.56,6.2-14.34,9.32Z"/>
              <path class="amebpba-cls-87" d="M67.23,327.72c5.54,0,11.08,.03,16.61,0,2.58-.02,4.2,.54,3.93,3.76-.23,2.75-.55,4.89-4.02,4.56-.44-.04-.9-.07-1.33,0-2.03,.34-5.25-1.13-5.82,1.45-.74,3.37,3.04,3.26,5.08,3.85,4.01,1.17,4.69,3.73,3.64,7.12-1.21,3.89-4.04,1.69-6.03,1.09-9.75-2.91-19.43-6.05-29.14-9.1-2.23-.7-5.73-.93-3.88-4.4,1.55-2.91-1.24-8.88,5.69-8.4,5.07,.35,10.18,.07,15.28,.07Z"/>
              <path class="amebpba-cls-82" d="M694.75,379.51c-3.54,0-7.09,.1-10.63-.02-6.99-.23-9.31-2.65-9.62-9.78-.09-1.99-.05-4-.36-5.96-.51-3.24,.82-4.37,3.96-4.42,10.83-.18,21.66-.45,32.48-.82,2.86-.1,4.07,.92,3.96,3.8-.11,2.87,.16,5.76-.02,8.62-.32,5.24-3.06,8.12-8.5,8.1-3.76-.01-7.52,0-11.27,0,0,.16,0,.32,0,.47Z"/>
              <path class="amebpba-cls-78" d="M285.39,56.12c3.32-.37,5,1.87,6.21,4.66,3.74,8.64,4.6,18.04,6.75,27.07,1.01,4.25-2.29,7.54-7.06,8.58-4.71,1.03-9.38,1.19-11.18-4.05-2.99-8.75-5.48-17.71-7.63-26.71-1.13-4.76,5.81-9.69,12.9-9.56Z"/>
              <path class="amebpba-cls-92" d="M268.11,103.31c-3.83,2.88-8.88,3.1-12.75,6.09-2.59,2-3.62-1.01-5.07-2.56-1.59-1.69-1.68-6.15-5.19-4.41-2.81,1.39-.71,4.34,.13,6.52,1.44,3.75,.59,6.04-3.73,6.35-5.81,1.86-2.19-4.3-4.56-5.37-2.84-9.28-5.48-18.64-8.6-27.83-1.71-5.03,2.23-5.42,4.84-7.09,2.98-1.91,5.04-1.45,7.1,1.73,5.3,8.2,10.95,16.17,16.46,24.23,1.31,1.91,2.65,3.98,3.05-.44,2.98,.31,5.77,1.2,8.34,2.77Z"/>
              <path class="amebpba-cls-71" d="M667.91,447.11c-.29-11.37,3.19-14.6,14.02-12.57,7.98,1.5,15.86,3.54,23.84,5,3.38,.62,3.59,2.22,3.15,5.07-.43,2.83-1.6,3.84-4.4,3.36-2.82-.48-5.68-.74-8.52-1.1-5.85-.17-11.65-.67-17.32-2.28,5.7,1.6,11.83,1.91,17,5.2,2.59,.43,5.18,.82,7.76,1.31,2.53,.48,4.82,1.15,3.63,4.67-.88,2.61-1.48,5.1-5.29,4.04-2.29-.64-4.75-.64-7.14-.92-.56-.36-1.14-.66-1.75-.92-5.6-1.15-11.12-2.8-16.78-3.59-4.57-.64-7.14-2.97-8.2-7.28Z"/>
              <path class="amebpba-cls-78" d="M159.68,583.37c5.24,6.55,4.69,10.32-1.5,15.8-4.62,4.09-8.59,8.91-13.14,13.08-7.06,6.47-14.05,4.61-17.59-4.4-1.29-3.29-.58-6.04,1.92-8.43,3.02-2.89,6.04-5.79,9.06-8.68,1.74-2.49,3.45-4.97,5.81-6.97,5.99-5.08,8.63-5.19,15.44-.41Z"/>
              <path class="amebpba-cls-80" d="M129.28,222c-1.29,5.21-3.52,5.51-7.9,2.44-7.97-5.59-16.22-10.88-24.75-15.56-4.57-2.51-4.64-4.42-1.44-7.82,1.35-1.43,2.22-3.31,3.37-4.93,3.17-4.47,6.89-5.95,11.84-2.46,3.96,2.8,8.13,5.31,12.21,7.94,.42,.51,.93,.92,1.46,1.32q9.88,7.57,5.2,19.07Z"/>
              <path class="amebpba-cls-68" d="M440.05,84.13c-.61-2.07,1.42-5.26-1.83-5.66-2.87-.36-2.56,3.04-3.5,4.88-1.1,2.15-.67,5.46-4.89,4.85-4-.58-6.07-1.07-3.93-6.06,3.91-9.13,7.36-18.51,10.26-28.01,1.65-5.42,4.58-4.98,8.99-4.53,5.4,.55,4.37,4.01,4.24,7.13-.37,9.26-.75,18.53-1.51,27.76-.19,2.26,2.46,6.64-3.67,6.19-5.14-.37-4.93-3.16-4.16-6.54Z"/>
              <path class="amebpba-cls-93" d="M659.92,499c7.18,2.88,14.43,5.6,21.52,8.69,6.39,2.79,7.83,7.21,4.9,13.55-2.96,6.41-6.72,7.75-13.31,4.86-6.71-2.94-13.48-5.74-20.18-8.7-1.55-.69-3.43-1.15-3.96-3.18-1-4.31,.37-8.04,2.44-11.78,2.02-3.65,4.65-4.97,8.59-3.44Z"/>
              <path class="amebpba-cls-79" d="M268.11,103.31c-3.07-.04-6.55,1.14-8.34-2.77-4.98-9.19-6.97-19.54-11.34-28.97-.46-1-1.13-2.61-.01-2.96,4.81-1.49,8.94-5.3,14.35-4.61,4.71,.61,6.33,4.39,7.62,8.27,1.46,4.41,2.81,8.85,4.21,13.28,3.66,8.21,3.11,11.43-2.78,16.36-1.07,.92-2.26,1.52-3.72,1.39Z"/>
              <path class="amebpba-cls-92" d="M44.04,416.31c-.03-.4-.13-.79-.29-1.16-1.74-5,.69-6.64,5.32-7.32,7.68-1.14,15.38-1.34,23.1-1.71-7.53,.7-15.1-.26-22.63,.7-3.88,.49-6.69-1.35-7.42-5.01-.9-4.52,3.11-4.43,5.96-5.23,7.7-.41,15.39-.89,23.1-1.23,9.9-.43,12.75,2.37,12.64,12.2-.01,1.1-.14,2.2-.22,3.3-2.77,7.16-9.45,6.81-15,6.67-7-.18-13.94-.85-20.86,.63-1.78,.38-2.96-.22-3.69-1.85Z"/>
              <path class="amebpba-cls-90" d="M119.67,239.07c-1.31,3.44-2.62,6.45-7.18,4.88-2.16-.74-5-2.95-6.56-.11-1.63,2.96,1.85,4.21,3.74,5.23,3.69,1.99,4.05,4.26,1.77,7.44-2.71,3.77-4.64,.42-6.53-.86-8.26-5.61-16.33-11.52-24.65-17.03-3.02-2-3.9-3.84-1.93-7.01,1.67-2.69,2.09-6.49,7.04-4.86,10.32,3.39,20.76,6.43,31.16,9.59,1.52,.46,2.99,.9,3.14,2.74Z"/>
              <path class="amebpba-cls-82" d="M582.28,156.18c8.1-6.08,16.14-12.25,24.32-18.21,4.47-3.26,5.95,2.13,8.73,3.56,2.89,1.48,1.2,3.5-.15,5.42-6.25,8.88-12.39,17.82-18.54,26.76-1.29,1.87-2.46,4.24-4.95,1.51-1.72-1.89-5.51-3.23-2.49-6.9,.42-.51,.91-.98,1.27-1.54,1.13-1.76,4.02-3.6,2.16-5.6-2.52-2.73-4.17,.92-5.87,2.14-3.02,2.15-5.47,3.24-7.09-1.31-2.06-3.26,1.59-3.96,2.62-5.84Z"/>
              <path class="amebpba-cls-94" d="M83.69,509.6c-1.83,.75-3.65,1.56-5.5,2.24-8.83,3.26-14.22-.19-15.01-9.51-.34-3.99,1.04-6.81,4.97-8.29,7.41-2.81,14.82-5.63,22.15-8.65,5.81-2.39,9.29-.46,11.09,5.29q1.95,8.91-9.17,15.93c-2.33,2.43-5.65,2.08-8.52,3Z"/>
              <path class="amebpba-cls-82" d="M122.64,555.34c-8.25,3.67-16.52,7.3-24.75,11.02-3.05,1.38-4.17-.86-4.94-2.81-.99-2.52-4.83-4.81-1.14-8.06,8.45-7.45,16.88-14.91,25.3-22.4,1.72-1.53,3.21-1.23,4.55,.37,1.36,1.63,3.5,3.13,1.64,5.68-.51,.71-1.3,1.21-1.85,1.9-1.48,1.81-5.97,2.46-3.52,5.79,1.94,2.64,4.41-.46,6.32-1.46,3.77-1.95,5.28-.2,6.3,3.04,1.54,2.26-.34,2.89-1.76,3.8-1.95,1.25-4.34,1.6-6.14,3.13Z"/>
              <path class="amebpba-cls-97" d="M164.98,625.61c-1.29,1.51-2.71,2.94-3.86,4.55-2.51,3.51-6.02,3.86-9.46,2.4-4.23-1.79-7.35-4.95-7.98-9.86-.57-4.38,3.33-5.97,5.62-8.45,1.49-1.62,2.83,.1,3.67,1.37,.98,1.48,3.34,2.77,1,5.02-1.04,1-2.67,1.85-1.26,4.73,4.26-2.7,4.61-7.08,4.06-10.69-1.02-6.68,1.04-11.49,5.96-15.82,3.34-2.93,6.51-3.88,10.05-1.05,3.57,2.85,8.53,5.72,6.55,11.09-1.5,4.08-3.56,9.18-9.8,8.62-3.22-2.52-4.64-5.3-1.18-8.82-4.36,5.05-.84,11.56-3.36,16.9Z"/>
              <path class="amebpba-cls-82" d="M200.99,623.71c-.66,.69-1.42,1.48-2.16,2.29-7.44,8.22-16.59,14.55-24.53,22.23-2.98,2.89-5.21,.57-7.32-1.44-2.07-1.96-4.28-3.53-1.98-7.18,5.66-8.97,10.87-18.23,16.19-27.41,1.19-2.06,2.38-4.16,5.02-2.21,2,1.48,4.57,2.81,2.52,6.38-1.26,2.19-5.51,4.39-2.61,7.13,3.09,2.92,4.61-1.77,6.81-3.08,1.58-.94,3.03-2.12,4.73-.53,1.19,1.12,2.16,2.46,3.33,3.81Z"/>
              <path class="amebpba-cls-70" d="M622.29,585.84c4.03,3.72,8,7.52,12.13,11.12,2.3,2,2.96,3.81,.21,5.79-4.56,3.29-6.85,11.36-14.99,7.19-2.69-2.73-6.65-4.65-5.97-9.54,.12-.86-.48-1.98-1.44-1.86-6.48,.84-9.31-5.19-14.16-7.38-.82-.46-1.64-.92-2.46-1.38-2.96-2.24-.61-3.68,.75-5.34,1.88-2.3,4.18-2.27,6.59-1.21,3.35,1.47,5.43,4.51,8.46,7.06-2.34-4.29-6.9-5.98-9.06-9.86-2.95-3.81,2.32-3.27,2.79-5.27,1.11-2.12,3.07-3.09,4.79-1.49,4.23,3.94,10.19,6.15,12.37,12.15Z"/>
              <path class="amebpba-cls-71" d="M306.4,75.32c-.95-2.51-3.37-3.33-5.32-4.75-4.38-3.17-5.79-7.88-5.16-12.94,.59-4.79,4.86-5.97,8.75-6.92,6.98-1.71,11.86,2.82,11.2,9.92-.25,2.63-1.98,2.39-3.69,2.75-2.8,.59-4.13-.51-4.55-3.27-.21-1.37-.22-3.86-2.37-3.04-2.52,.95-.69,3.08-.04,4.47,1.69,3.56,5.17,5.29,8.27,7.34,3.2,2.12,6.12,4.28,6.66,8.56,.47,3.7,2.09,7.57-1.37,10.58-3.85,3.35-8.73,3.6-13.23,2.36-5.44-1.51-4.35-7.35-5.25-11.25-.98-4.2,4.26-2.29,6.24-3.94,2.26,1.35,2.89,3.67,3.35,6.08-.16-2.6-.89-4.85-3.48-5.94Z"/>
              <path class="amebpba-cls-92" d="M674.27,412.16c12.07-.11,23.83,2.85,35.79,3.77,.75,.06,2.07,.81,2.06,1.22-.11,5.04,.15,10.22-2.7,14.71-2.17,3.41-5.64,2.82-8.95,2.38-2.35-.31-4.94-.09-6.24-2.77-.62-1.7-1.58-2.83-3.4-1.43-4.86,3.76-9.8,.98-14.58,.01-2.41-.49-3.88-2.69-3.89-5.55,0-4.23,.07-8.42,1.9-12.35Z"/>
              <path class="amebpba-cls-61" d="M657.03,539.06c-4.33,1.36-8.51,1.98-12.75-.69-3.2-2.02-7.25-3.35-6.74-8.03,.51-4.73,2.35-9.04,6.82-11.56,4.67-2.64,7.79,1.62,11.58,2.8,3.42,1.06,1.59,3.43,1.04,5.41-1,5.19-4.27,2.81-6.79,2.27,3.71,2.42,7.47-.02,11.21-.78,2.62-.53,5.07-1.27,7.62-.03,9.54,6.02,10.89,11.1,4.47,18.08-3.52,3.83-7.56,2.13-11.54,.43-4.12-1.77-1.67-4.15-.82-6.43,1.15-2.77,3.25-2.44,5.48-1.84-3.23-1.49-6.4-.47-9.58,.37Z"/>
              <path class="amebpba-cls-101" d="M490.13,76.2c-2.17,6.18-4.36,12.36-6.48,18.56-.1,.29,.32,.76,.51,1.16,2.62-.53,2.28-3.06,2.96-4.72,1.06-2.57,1.87-4.31,5.21-2.87,3,1.29,4.02,2.8,2.95,6.08-2.67,8.23-11.15,11.05-17.95,5.65-2.92-2.32-4.38-4.76-2.29-9.08,3.35-6.93,5.57-14.41,8.29-21.64,2.57-6.84,6.45-8.29,13.6-5.1,6.48,2.89,7.45,6.04,4.47,12.58-2.38,5.21-5.1,3.54-8.13,.9-1.6-1.27-.76-2.77-.57-4.4-.71,1.1-1.28,2.36-2.57,2.88Z"/>
              <path class="amebpba-cls-63" d="M631.57,557.77c-1.87-1.56-3.78-3.08-5.59-4.69-3.35-2.98-.3-5.2,1.1-7.42,2.28-3.6,3.69-.23,4.98,1,7.38,7.04,14.54,14.32,21.98,21.3,3.48,3.27,2.89,6.13,.53,9.67-2.45,3.68-4.84,3.31-8.32,1.42-9.21-5-18.62-9.62-27.95-14.39-1.43-1.4-2.14-2.7,.01-4.2,1.36-.95,2.32-3.04,4.36-1.51,1.85,1.39,4.03,1.7,6.12,2.21,2.89,.71,4.21-.33,2.78-3.4Z"/>
              <path class="amebpba-cls-79" d="M595.68,621.81c1.49,1.64,3.09,3.19,4.46,4.92,3.94,4.99,3.17,8.77-2.63,13.51-4.68,3.83-8.44,3.23-12.55-1.54-2.04-2.37-2.11-4,.25-6.11,2.22-1.99,3.84-2.02,5.06,.77,.88,2.01,2.43,2.2,3.59,.9,1.1-1.23-.52-2.52-1.39-3.23-3.31-2.69-7.45-2.89-11.36-2.53-6.39,.58-10.12-3.18-12.94-7.71-3.28-5.27,.1-9.23,4.06-12.53,3.65-3.04,7.39-3.98,11.13,.02,1.35,1.45,2.88,2.75,4.32,4.11,1.75,3.02,.13,5.25-2.16,6.7-2.77,1.75-4.81-.74-7.22-2.2,4.98,5.25,12.08,1.84,17.38,4.92Z"/>
              <path class="amebpba-cls-89" d="M687.46,296.25c-5.65,1.55-11.29,3.1-17.62,4.83,2.77,2.78,5.03,1.13,7.12,.61,2.57-.64,4.24-.23,4.9,2.67,.58,2.56,.1,4.5-2.64,5.08-4.17,.88-8.78,3.62-12.38-.58-3.78-4.41-4.99-9.64-1.9-15.15,3.46-4.64,9.09-2.03,13.34-4,4.86-1.51,9.82-2.77,14.54-4.62,3.91-1.53,6.66,0,8.66,2.79,2.85,3.98,3.88,8.85,1.67,13.24-1.71,3.39-6.1,3.3-9.5,3.75-2.66,.35-1.69-3.23-3.26-4.39-1.67-3.56,1.51-3.67,3.3-4.82-2.03,.22-4.13,.32-6.22,.6Z"/>
              <path class="amebpba-cls-92" d="M110.5,515.2c1.47,1.63,3.14,3.12,4.38,4.92,3.75,5.42,2.64,10.07-3,13.19-6.17,3.41-12.27,6.97-18.56,10.14-7.75,3.91-12.42,1.73-15.52-6.21-2.58-6.59,2.45-8.42,6.21-11.21,1.45-1.51,3.15-2.96,5-.93,2.72,2.98,1.66,6.09-.43,9.18,2.24-1.83,4.96-2.46,7.26-3.89,2.43-1.52,4.91-2.96,7.65-4.07-3.05,.7-5.34-.3-6.58-3.1-1.13-2.55,.47-4.29,2.37-5.53,3.39-2.21,6.59-5.7,11.22-2.48Z"/>
              <path class="amebpba-cls-94" d="M82.27,285.13c-5.99,3.39-11.92,6.71-18.99,2.46-4.03-2.42-4.05-6.13-3.32-9.81,.77-3.9,2.25-8.2,6.79-8.74,3.58-.42,7.39,.77,9.17,4.79-1.02,3.44-2.92,5.78-7.03,5.52,4.45,.05,8.62-.53,12.43-2.74,5.78-3.6,11.08-1.23,15.87,1.86,5.03,3.25,3.61,10.84-2.7,16.64-3.97,1.88-7.23-.55-10.63-1.85-2.45-.94-2.32-2.9-1.36-4.93-.1-1.13-.2-2.27-.3-3.4,2.03,.88,4.27,.39,6.51,1.07-2.15-1.21-4.39-.3-6.44-.88Z"/>
              <path class="amebpba-cls-100" d="M179.74,135.45c-3.56-4.74-6.97-9.59-10.73-14.17-1.97-2.4-1.98-3.6,.54-5.79,11.71-10.16,19.16-8.28,25.03,6.49,1.08,2.71,.69,5.14-1.31,6.57-5.05,3.62-3.1,6.58,.11,10,3.02,3.22,5.46,6.74-.26,9.87-2.83,1.92-3.74-.8-5.16-2.17-3.3-3.17-6.06-6.75-8.22-10.79Z"/>
              <path class="amebpba-cls-93" d="M619.64,184.68c-4.45-1.39-6.12,2.63-8.48,4.75-3,2.7-4.69,2.66-7.4-.44-2.88-3.3-.67-4.43,1.46-6.13,8.1-6.43,16.19-12.88,24.22-19.4,2.19-1.78,3.75-2.8,5.54,.63,1.06,2.04,3.34,3.41,4.86,5.24,4.59,5.53,4.04,8.68-1.84,13.01-2.64,1.94-5.05,4.19-7.56,6.3-4.7,1.65-8.18,0-10.8-3.98Z"/>
              <path class="amebpba-cls-92" d="M126.77,584.55c3.59-2.64,7.18-5.27,10.77-7.91,.14-1.07,.71-1.93,1.31-2.78-1.44,.32-3.02,.15-4.23,1.26-3,2.4-4.06-.3-5.72-1.99-2.43-2.47,.54-3.03,1.42-3.86,3.24-3.08,6.68-7.13,11.69-4.6,4.99,2.5,7.96,6.87,7.1,12.95-2.46,5.37-7.02,8.32-12.2,10.53-3.38,2.71-6.59,5.66-10.17,8.08-4.98,3.37-9.34,1.89-13.34-3.96-3.26-4.76-2.04-8.42,2.06-12.12,3.93-3.55,5.75-.49,8.06,1.75,2.36,2.6-.33,3.63-1.56,5.25,1.65-.85,2.9-2.36,4.8-2.61Z"/>
              <path class="amebpba-cls-62" d="M709.09,336.65c-10.8,3.52-21.61,7.04-32.4,10.57-1.61,.53-3.46,.68-3.58-1.35-.15-2.43-2.39-5.91,2.27-6.87,2.48-1.44,7.84,1.14,7.37-4.07-.42-4.72-5.04-2.74-8-3.13-1.06-.14-2.18,.06-3.14-.59l.03,.04c-1.53-4.53,.54-6.16,4.83-6.1,8.86,.13,17.71-.06,26.57,0,7.45,.05,9.84,4.58,6.07,11.5Z"/>
              <path class="amebpba-cls-62" d="M694.65,382.78c5.3,0,10.61,.11,15.91-.04,2.93-.08,4.03,1.24,3.93,3.95-.14,3.53-.36,7.06-.41,10.59-.03,2.04-.49,3.66-2.88,3.4-1.93-.21-2.43-1.75-2.14-3.46,.5-2.94,.53-5.55-3.53-5.32-3.03,.17-7.16-1.39-7.28,4.09-.04,1.87-1.21,2.91-2.97,2.84-2.18-.09-2.24-1.84-1.98-3.42,.44-2.69-.16-4.83-3.28-3.87-3.54,1.09-8.68-3.29-10.63,2.85-.67,2.11-.42,4.96-3.52,4.5-3.87-.58-2.02-3.66-1.83-5.85q.91-10.69,11.32-10.63c3.1,.02,6.2,0,9.3,0,0,.12,0,.24,0,.37Z"/>
              <path class="amebpba-cls-77" d="M594.52,592.13c7.37,7.36,14.63,14.82,22.16,22.01,3.06,2.93,3.72,5.12-.01,7.92-2.11,1.58-3.8,3.71-5.68,5.59-1.29,1.3-2.65,2.67-4.5,.98-1.72-1.56-1.3-3.33,.36-4.52,5.17-3.69,.45-5.78-1.2-8.43-2.19-3.53-4.1-2.15-6.14,.15-1.1,1.24-2.5,2-4.04,.64-1.71-1.51-.52-2.73,.54-3.92,5.56-6.27-2.82-7.15-4.32-10.64-1.42-3.31-3.85-1.9-5.61,.37-1.47,1.9-3.21,2.61-4.92,.51-1.75-2.16,.6-3.09,1.71-4.23,2.47-2.53,5.07-4.93,7.62-7.38,1.82-1.73,2.84-.03,4.03,.93Z"/>
              <path class="amebpba-cls-64" d="M702.22,474.78c-.53,1.2-1.03,2.41-1.6,3.59-.71,1.44-1.57,2.9-3.51,1.98-1.53-.72-1.35-2.14-.76-3.41,1.7-3.66-.01-5.28-3.41-6.18-3.1-.83-6.21-2.07-7.2,2.63-.45,2.13-1.7,3.14-3.8,2.44-2.26-.75-1.26-2.4-.77-3.83,1.78-5.27-2.82-4.75-5.31-5.25-2.68-.54-6.55-3.91-8.12,1.46-.71,2.42-1.51,4.56-4.25,3.65-3.07-1.02-1.47-3.6-1.04-5.66,.4-1.93,.78-3.86,1.17-5.79,.48-1.33,.96-2.65,1.44-3.98,.85-1.51,2.15-2.23,3.84-1.94,7.74,1.36,15.62,2.19,22.66,6.11,.53,.36,1.02,.89,1.6,1.04q11.04,2.82,9.06,13.12Z"/>
              <path class="amebpba-cls-82" d="M236.93,109.94c2.49,.96,.72,5.55,4.56,5.37-5.03,3.1-10.06,6.23-15.11,9.3-2.29,1.39-4.19,2.18-5.69-1.35-3.9-9.19-9.39-17.55-14.75-25.9-3.06-4.76-1.93-7.03,2.77-8.98,2.22-.92,4.21-2.41,6.28-3.68,1.49-.91,3-1.2,4.07,.42,1.04,1.57,.5,3.23-1.18,3.83-4.39,1.57-4.21,3.8-2.21,7.52,2.06,3.85,3.93,4.94,7.41,1.9,.93-.81,2.14-.72,2.88,.37,1.14,1.67,.07,2.78-1.1,3.96-3.38,3.42-2.62,8.38,2.26,12.6,3.97,3.43,6.4-2.6,10.01-2.06l-.22-3.28Z"/>
              <path class="amebpba-cls-75" d="M354.69,44.69c4.48-1.86,9.16-.59,13.75-.84,1.96-.11,3.67,.54,3.42,2.94-.22,2.05-1.81,2.2-3.5,1.91-2.8-.48-5.26,.01-4.57,3.51,.57,2.87-1.93,7.89,4.39,7.58,1.59-.08,3.3,.09,3.39,2.22,.09,2.13-1.5,2.45-3.16,2.41-4.98-.1-3.92,3.76-3.27,6.08,.78,2.76-2.29,7.95,4.06,7.87,2.01-.03,4.19-.38,4.15,2.72-.05,3.42-2.51,2.95-4.72,3-3.76,.07-7.51,.2-11.27,.31-3.14-8.55-4.03-17.28-2.22-26.25,.91-4.51-1.51-8.93-.45-13.46Z"/>
              <path class="amebpba-cls-98" d="M131.16,185.7c-5.76,5.56,.7,6.88,3.53,9.6,2.78,2.67,5.11,3.39,7.1-.83,.95-2.03,2.51-2.2,4.35-.85,1.9,1.39,.96,2.61-.05,3.88-10.44,13.17-5.8,12.73-18.29,3.72-6.28-4.53-12.45-9.24-18.92-13.49-3.03-1.99-3.25-3.7-1.15-6.29,1.96-2.4,3.84-4.86,5.76-7.29,1.01-1.27,2.18-2.49,3.88-1.28,1.56,1.1,1.56,2.59,.22,3.96-4.25,4.34,.31,5.82,2.55,8.17,2.85,3,4.5,1.34,6.1-1.3,.76-1.27,1.83-1.89,3.31-.93,.99,.65,1.56,1.52,1.62,2.94Z"/>
              <path class="amebpba-cls-92" d="M548.97,117.78c4-5.46,8.05-10.88,11.96-16.4,1.64-2.32,3.27-2.63,5.5-.88,2.6,2.05,5.35,3.92,7.96,5.96,1.22,.95,2.48,2.1,1.09,3.84-1.19,1.5-2.84,1.64-4.05,.39-4.25-4.4-5.73-.19-8.1,2.24-3.42,3.52-.44,4.77,1.89,6.42,1.11,.78,2.06,1.63,1.02,3.23-1.03,1.6-2.26,2.21-3.77,.9-3.69-3.19-5.99-1.55-7.57,2.14-1.34,3.16-7.65,6.04-.69,9.87,1.49,.82,2.2,2.37,1.08,3.94-1.16,1.63-2.68,1.12-4.07,.14-2.99-2.1-5.98-4.21-8.98-6.32-1.74-1.22-2.17-2.69-.92-4.52,1.53-4.37,4.54-7.7,7.64-10.98Z"/>
              <path class="amebpba-cls-74" d="M164.92,169.51c.89,4.26-6.13,14.67-10.09,15.04-1.08,.1-1.44-.61-2.1-1.19-7.95-7.07-15.87-14.17-24-21.03-2.6-2.2-3.19-3.9-.67-6.44,2.34-2.36,4.48-4.92,6.68-7.41,1.13-1.27,2.22-2.14,3.88-.69,1.5,1.31,1.64,2.65,.1,3.87-3,2.38-3.78,4.56-.4,7.42,2.91,2.46,5.37,4.96,8.39,.21,.75-1.17,2.19-1.58,3.4-.25,1.18,1.3,1.49,2.6,.06,4.05-6.58,6.68,2.91,6.72,4.46,9.96,1.78,3.72,4.21,1.32,5.88-1.11,1.08-1.57,2.71-2.2,4.58-2.25l-.17-.17Z"/>
              <path class="amebpba-cls-65" d="M64.9,388.41c-4.22,0-8.43,0-12.65,0-1.93,0-4.11-.61-4.52,2.42-.26,1.88-1.48,2.63-3.36,1.99-1.57-.53-1.73-1.8-1.72-3.2,.04-4.22,.06-8.43,.08-12.65,0-1.46,.39-2.66,2.07-2.86,1.71-.2,3.26,.06,3.17,2.26-.17,4.1,2.59,3.12,4.98,3.1,8.21-.06,16.42,.11,24.63-.08,3.44-.08,5.39,.51,5.42,4.59,.03,4.2-2.08,4.67-5.45,4.49-4.21-.21-8.43-.05-12.65-.05,0,0,0-.01,0-.02Z"/>
              <path class="amebpba-cls-99" d="M582.28,156.18c-.4,2.16-2.81,3.41-2.62,5.84-4.45-3.58-8.77-7.35-13.41-10.66-2.86-2.05-2.4-3.65-.45-5.84,7.22-8.09,14.45-16.17,21.52-24.39,2.24-2.6,3.87-1.99,6.02-.01,2.24,2.07,2.42,3.6,.23,6.03-5.91,6.58-11.5,13.44-17.25,20.17-1.29,1.5-3.47,3.39-.99,4.84,2.29,1.33,2.28,6.96,6.94,4.03Z"/>
              <path class="amebpba-cls-61" d="M73.38,260.43c-.56,.34-1.49,.6-1.92,1.22-1.21,1.7-2.32,3.69-4.65,1.74-2.12-1.79-.29-3.68,.41-5.41,1.4-3.5,3-6.92,4.39-10.42,.61-1.54,1.57-2.06,3.08-1.63,1.16,.33,2.08,1.04,1.67,2.37-1.16,3.81,1.67,4.44,4.15,5.46,6.96,2.89,13.93,5.78,20.84,8.78,2.81,1.22,6.99,1.44,4.65,6.67-2.35,5.25-5.46,2.04-8.2,1.03-7.29-2.69-14.48-5.63-21.7-8.48-.82-.32-1.59-.77-2.72-1.32Z"/>
              <path class="amebpba-cls-82" d="M91.65,312.51c-.62,3.47-1.13,6.96-1.89,10.4-.51,2.34-1.95,3.02-4.45,2.4-10.49-2.57-21.01-5.03-31.57-7.31-3.01-.65-4.36-1.76-3.33-4.91,.83-2.52,1.11-5.19,5.15-4.14,7.89,2.05,15.92,3.53,23.81,5.57,3.78,.97,6.98,1.84,8.09-3.31,.24-1.13,1.28-2,2.47-1.72,1.41,.34,2.13,1.48,1.71,3.02Z"/>
              <path class="amebpba-cls-90" d="M220.47,126.62c-1.77,4.48-6.53,6.12-10.11,8.74-2.94,2.16-3.88-1.67-5.09-3.48-5.53-8.26-10.81-16.69-16.49-24.84-2.13-3.05-2.04-4.69,1.09-6.87,3.4-2.37,4.59-.41,6.14,2.01,4.36,6.8,8.78,13.57,13.26,20.29,1.69,2.54,3.21,5.7,7.15,2.39,1.34-1.12,3.41-1.27,4.05,1.75Z"/>
              <path class="amebpba-cls-92" d="M375.84,63.41c0-5.3,.13-10.6-.05-15.9-.12-3.61,1.53-4.5,4.83-4.38,3.26,.12,4.07,1.58,3.95,4.6-.35,8.6-.35,17.21-.72,25.8-.14,3.22,.45,5.21,4.19,4.83,2.37-.24,3.9,.6,3.69,3.3-.21,2.61-2.23,2.22-3.94,2.37q-12.38,1.08-12.35-11.38c0-3.09,0-6.17,0-9.26,.13,0,.26,0,.38,0Z"/>
              <path class="amebpba-cls-102" d="M651.91,268.21c-2.68-4.33-2.59-7.14,3.11-9.23,9.33-3.41,18.37-7.65,27.45-11.72,2.77-1.24,4.23-1.31,5.42,2.06,1.08,3.05,2.13,5.04-2.1,6.45-4.48,1.49-8.68,3.81-13,5.77-6.37,4.08-12.52,8.84-20.88,6.68Z"/>
              <path class="amebpba-cls-103" d="M131.77,552.45c7.67,3.91,8.01,5.85,1.81,10.43-7.66,5.66-15.36,11.28-22.94,17.05-2.8,2.14-4.68,2.49-6.97-.89-2.29-3.38-1.1-4.78,1.76-6.79,9.03-6.36,18.55-12.1,26.47-19.94l-.14,.14Z"/>
              <path class="amebpba-cls-103" d="M106.37,503.38c.81,1.28,1.62,2.57,2.43,3.85,2.24,2.3,.9,4.19-1.08,5.33-7.63,4.38-15.1,9.22-24.14,10.34-1.85,.69-3.81,1.17-5.52,2.11-2.85,1.57-4.91,2.3-6.86-1.54-2.3-4.52,.68-5.23,3.59-6.54,4-1.81,7.92-3.79,11.88-5.7,5.9-4.29,11.76-8.69,19.71-7.85Z"/>
              <path class="amebpba-cls-76" d="M58.7,289.93c8.7,2.33,17.03,4.53,25.33,6.79,2.55,.69,5.05,1.62,7.61,2.28,2.75,.71,3.48,2.14,2.56,4.89-.87,2.59-1.57,4.3-5.02,3.3-10.41-3-20.91-5.66-31.38-8.46-2-.53-4.21-1.18-3.18-3.88,.8-2.1,1.12-5.02,4.09-4.91Z"/>
              <path class="amebpba-cls-66" d="M610.29,568.35c2.2-2.8,4.26-3.79,7.58-.99,7.77,6.55,15.74,12.91,23.92,18.94,3.29,2.42,2.84,4.04,.8,6.9-2.33,3.27-4.19,2.15-6.5,.27-3.76-3.05-7.61-5.98-11.42-8.96-4.53-2.28-8.33-5.58-12.16-8.8-2.25-1.89-4.24-4.07-2.22-7.35Z"/>
              <path class="amebpba-cls-92" d="M696.96,308.27c9.28-3.06,10.71-2.5,11.31,4.13,.14,1.56,.09,2.68-1.62,2.91-12.17,1.64-23.91,5.78-36.27,6.33q-2.23-5.78,6.65-9.14c.27-.39,.6-.46,.99-.19,6.19-1.94,12.83-1.73,18.94-4.06Z"/>
              <path class="amebpba-cls-6" d="M83.58,522.9c7.47-3.61,14.92-7.28,22.44-10.79,2.24-1.04,3.84-2.11,2.78-4.88,4.02,1.92-.27,5.61,1.7,7.97-5.24-1.49-8.42,2.6-12.3,4.66-1.77,.94-1.24,2.52,.05,3.84,1.24,1.28,2.15,2.69,4.38,1.32,1.9-1.17,5.15-3.74,6.18-1.28,1.35,3.22-3.23,3.12-5.19,4.17-6.18,3.33-12.07,7.4-19.74,9.46,1.03-4.52,8.7-5.35,4.79-11.05-1.58-2.3-3.1-.28-4.64-.3,.03-1.07,1.2-2.29-.43-3.13Z"/>
              <path class="amebpba-cls-36" d="M48.07,396.59c-2.45,1.13-5.82,1.39-4.94,5.56,.91,4.35,4.06,3,6.82,2.85,7.91-.43,15.87-.19,23.74-1.46,1.61-.26,4.12-.54,4.42,1.72,.32,2.41-2.14,2.42-3.83,2.54-8.14,.59-16.29,1.45-24.44,1.46-4.59,0-6.55,1.34-6.09,5.89-.78-4.96-1.99-9.89-2.2-14.87-.19-4.44,3.08-4.67,6.52-3.69Z"/>
              <path class="amebpba-cls-16" d="M44.04,416.31c9.61,1.65,19.09-.67,28.63-.89,4.48-.1,8.19-.83,10.92-4.56,.68,2.34-3.65,4.81,.17,7.08-7.63-.25-14.82,3.55-22.54,2.46,1.26-.68,2.53-1.37,4.81-2.6-5.77,.23-10.45,.15-15.2,.81-2.16,.3-6.01,2.66-6.78-2.29Z"/>
              <path class="amebpba-cls-38" d="M354.69,44.69c.89,13.23,1.78,26.47,2.67,39.7-3.22,.71,0-5.6-4.19-3.84-.41-7.57-.83-15.15-1.24-22.72,.84-.06,1.67,3.15,2.26,.4,.57-2.65-.11-5.58-.35-8.38-.16-1.82-1.57-3.79,.86-5.16Z"/>
              <path class="amebpba-cls-27" d="M594.52,592.13c-1.34-.31-2.69-.62-4.03-.93,3.21-4.4,5.33-10.01,11.86-10.74,5.46,1.52,7.56,7.13,12.01,9.91,.85,.53,1.38,1.89,.25,2.85-1.02,.87-2.14,.35-2.98-.39-3.12-2.79-6.12-5.73-9.3-8.46-1.31-1.13-3.04-2.31-4.62-.38-1.34,1.64-4.51,2.77-2.09,5.82-.36,.78-.73,1.55-1.09,2.33Z"/>
              <path class="amebpba-cls-34" d="M651.91,268.21c7.66-.06,13.56-5.59,20.88-6.68,1.01,.57,2.01,1.14,3.02,1.71-4.98,4.83-12.12,4.08-17.88,6.9-1.7,.83-4,.83-3.53,3.66-.83-1.86-1.66-3.72-2.49-5.58Z"/>
              <path class="amebpba-cls-89" d="M709.09,336.65c2.16-8.25,.44-10.79-7.36-10.79-8.16,0-16.31,.18-24.46-.02-4.02-.1-5.74,1.54-5.65,5.41-2.65-4.51-1.86-6.71,3.87-6.61,10.14,.18,20.29,.06,30.43-.1,3.21-.05,5.33,1,5.17,4.35-.13,2.65,4.08,6.62-2,7.76Z"/>
              <path class="amebpba-cls-43" d="M691.56,460.62c-6.75-.12-12.96-2.87-19.5-4.03-2.31-.41-4.64-1.88-7.01-.15-.45-3.23,3.52-2.14,4.18-4.28,8.35,1.99,16.93,2.93,25.11,5.68,.16,.33,.26,.69,.3,1.06-1.08,.48-2.5,.35-3.08,1.72Z"/>
              <path class="amebpba-cls-59" d="M595.68,621.81c-4.59-.37-9.17-.81-13.76-1.08-2.15-.12-3.83-.97-5.16-2.54-1.19-1.4-3.85-2.8-1.89-4.82,2.15-2.21,3.08,.89,4.52,1.89,1.67,1.16,2.22,4.5,4.98,3.4,2.54-1.01,4.34-3.05,3.32-6.26,.56,4.93,3.88,7.5,8,9.41Z"/>
              <path class="amebpba-cls-46" d="M695.69,449.8c-6.25-1.26-12.5-2.51-18.74-3.79-1.44-.3-3.58-.24-3.27-2.35,.31-2.14,2.27-1.67,3.75-1.2,6.08,1.94,12.65,1.81,18.57,4.42-3.58,.59-.66,1.9-.32,2.92Z"/>
              <path class="amebpba-cls-49" d="M610.29,568.35c-1.03,3.78,2.49,4.75,4.22,6.81,2.98,3.56,7.75,5.17,10.17,9.35-.79,.45-1.59,.89-2.38,1.34-3.66-3.11-7.43-6.11-10.94-9.38-1.94-1.81-3.7-2.98-6.22-1.29,.76-3,2.9-4.96,5.16-6.83Z"/>
              <path class="amebpba-cls-40" d="M136.91,588.14c4.07-3.51,8.14-7.02,12.2-10.53,3.03,2.82,8.88,.45,10.56,5.75-5.92-4.12-10.74-2.7-15.28,2.53-1.62,1.86-3.28,4.11-5.97,4.84,0-1.16-.35-2.12-1.51-2.6Z"/>
              <path class="amebpba-cls-71" d="M674.27,412.16c-.22,1.96-.35,3.94-.67,5.89-.65,3.95-2.1,8.78,2.37,10.7,4.29,1.84,9.3,4.74,14.19,.23,3.67-3.38,3.65,.12,4.08,2.5-1.54-1.7-2.68-.77-4.41,.1-4.24,2.13-14.37,.31-16.69-2.54-2.86-3.52-2.28-12.03,1.14-16.88Z"/>
              <path class="amebpba-cls-20" d="M179.74,135.45c4.21,2.49,6.26,6.92,9.31,10.45,1.19,1.38,2.09,2.66,4.07,2.51-.63,1.91-2.36,2.25-3.96,2.76-2.51-6.17-9.45-8.53-12.38-14.34,1.06-.3,3.02,1.31,2.95-1.39Z"/>
              <path class="amebpba-cls-105" d="M178.27,155.43c-2.88-4.16-5.76-8.33-9.39-13.58,6.27,1.7,8.16,6.5,11.38,9.63,2.5,2.44,4.62,4.02,7.69,1.16-1.1,4.45-3.52,4.67-6.54,1.84-1.87-1.75-2.79-1.69-3.13,.95Z"/>
              <path class="amebpba-cls-41" d="M106.37,503.38c-6.57,2.62-13.14,5.23-19.71,7.85-.52-1.4-1.89-1.26-2.98-1.63,2.3-1.26,4.59-2.53,6.89-3.79,4.64-1.82,9.13-3.94,13.16-6.93-.12,2.09,2.04,2.84,2.64,4.5Z"/>
              <path class="amebpba-cls-37" d="M548.97,117.78c-1.16,4.62-4.13,7.99-7.64,10.98-3.5-3.18-9.55,1.32-12.31-4.08,10.1,3.04,10.88,2.71,16.18-6.56,.64-1.11,1.6-2.04,2.4-3.06,.45,.91,.91,1.82,1.36,2.73Z"/>
              <path class="amebpba-cls-31" d="M86.52,431.14c-5.73-3.53-12.51-.75-18.79-3.03,3.96-2.86,8.35-2.33,12.46-2.64,2.68-.2,4.86-.3,4.93-3.67,4.02,5.64-2,4.3-4.07,5.81,1.7,1.4,5.66-.67,5.47,3.52Z"/>
              <path class="amebpba-cls-17" d="M81.32,276.62c-3.46,3.34-7.55,5.06-12.4,4.77-1.91-.11-3.82-.79-3.72-2.93,.13-2.63,2.31-2.46,3.93-1.56,3.82,2.12,5-1.03,6.78-3.08-.6,5.57,3.15,2.74,5.4,2.79Z"/>
              <path class="amebpba-cls-52" d="M164.98,625.61c.1-4.59,.16-9.19,.33-13.78,.05-1.27,.07-2.79,.76-3.72,1.14-1.53,2.77-2.71,4.3-3.91,.18-.15,1.34,.4,1.44,.78,.61,2.34-.98,3.83-2.59,4.89-4.37,2.85-.33,5.15,.31,7.67-1.49-.31-2.84-.77-2.9,1.74-.05,2.16,.97,4.77-1.64,6.33Z"/>
              <path class="amebpba-cls-50" d="M669.02,528.45c-4.64-1.14-8.54,1.68-12.83,2.43-3.08,.54-6.1,1.28-9.03-.48-1.36-.82-3.04-1.81-2.18-3.52,1-1.96,2.64-.6,3.99,.05,2.67,1.28,5.31,4.59,8.02,.06,1.66,2.27,3.49,.67,5.37,.38,2.26-.35,4.83-1.61,6.66,1.08Z"/>
              <path class="amebpba-cls-77" d="M598.07,591.18c5.95,.56,8.76,7.41,15.31,7.74,.91,.05,3.68,7.41,6.26,11.03-3.75-1.64-6.82-3.81-6.72-8.5,.02-1.12-.08-2.53-1.5-2.33-6.63,.91-9.29-4.66-13.35-7.94Z"/>
              <path class="amebpba-cls-66" d="M631.57,557.77c1.33,4.73,1.05,4.8-3.64,4.3-3.22-.34-6.67-4.53-9.75,.14-.36,.55,.06,1.62,.12,2.45-2.2-.74-3.76-1.91-1.57-4.11,1.5-1.51,2.18-4.79,5.51-3.23,1.77,.83,3.51,1.74,5.25,2.64,2.44,1.26,3.69,.35,4.08-2.19Z"/>
              <path class="amebpba-cls-64" d="M694.34,457.84c-8.41-1.73-17.18-1.84-25.11-5.68-.71-1.61-1.86-3.11-1.32-5.05,2.68,5.86,8.48,5.4,13.44,6.62,4.42,1.09,9.17,1.13,12.99,4.11Z"/>
              <path class="amebpba-cls-21" d="M622.39,208.5c3.68-2.72,7.34-5.46,11.06-8.13,.93-.67,2.11-.97,2.92,.15,.82,1.13,.19,2.24-.82,2.74-3.72,1.85-6.34,5.43-10.49,6.57-.42-1.38-1.68-1.1-2.67-1.34Z"/>
              <path class="amebpba-cls-23" d="M678.28,289.7c-4.02,2.77-8.85,2.82-13.34,4-1.77-1.71-5.83-1.17-5.19-5.27,4.42,5.04,8.85-1.26,13.27,.02,1.28,2.41,3.59,.46,5.26,1.25Z"/>
              <path class="amebpba-cls-103" d="M103.73,498.88c-3.02,4.91-8.12,5.85-13.16,6.93,1.02-.77,1.94-1.8,3.09-2.27,5.86-2.37,9-6.26,7.72-12.87,2.47,2.25,2.07,5.33,2.35,8.21Z"/>
              <path class="amebpba-cls-98" d="M129.28,222c.19-1.28-.04-2.85,.63-3.78,4.07-5.64,1.56-9.32-3.4-12.37-1.71-1.05-3.29-2.18-3.89-4.24,2.9,.55,4.98,2.53,7.11,4.29,5.6,4.63,5.47,7.55-.45,16.09Z"/>
              <path class="amebpba-cls-53" d="M657.03,539.06c3.84-2.44,7.73-4.31,12.15-1.22,.91,.63,2.15,1.14,1.52,2.44-.55,1.13-1.81,.96-2.94,.76-2.19-.38-4.12-4.3-6.63-.52-.94-1.67-2.64-1.22-4.09-1.47Z"/>
              <path class="amebpba-cls-61" d="M659.92,499c-7.35,.65-9.04,2.98-11.04,15.22-2.06-6.06-.17-12.63,4.57-15.61,2.21-1.39,4.45-2.01,6.47,.39Z"/>
              <path class="amebpba-cls-74" d="M157.11,160.55c4.35-.71,5.19,3.52,7.53,5.54,2.49,2.14,4.33,3.21,6.04-.55,.17,4.35-.73,4.98-5.76,3.98,0,0,.17,.18,.17,.17-1.25-4.29-6.22-5.3-7.98-9.13Z"/>
              <path class="amebpba-cls-89" d="M671.59,331.2c2.18-.03,4.36-.02,6.54-.09,3.29-.12,5.62,.86,5.56,4.67-.07,4.21-3.29,3.15-5.71,3.35-.86,.07-1.74-.09-2.61-.14,1.72-2.15,6.93-.32,6.38-4.02-.62-4.19-5-1.53-7.65-2.19-1.05-.26-2.14-.29-2.5-1.58Z"/>
              <path class="amebpba-cls-39" d="M687.46,296.25c3.18-1.94,6.46-3.35,10.49-2.24-1.22,4.05-7.07,2.07-7.57,6.45-2-.69-.82-3.59-2.92-4.22Z"/>
              <path class="amebpba-cls-45" d="M126.77,584.55c-2.38,2.34-5.34,7.29-7.84,5.03-2.84-2.57,3.94-3.32,4.37-6.37,.06-.44,.16-.87,.23-1.3,1.08,.88,2.16,1.76,3.24,2.65Z"/>
              <path class="amebpba-cls-78" d="M271.82,101.92c5.17-4.73,4.41-10.47,2.78-16.36,5.63,7.7,4.68,13.33-2.78,16.36Z"/>
              <path class="amebpba-cls-106" d="M490.13,76.2c.75-2.67,.75-7.81,3.73-6.69,4.29,1.61-1.03,5.28-.59,8.22-1.05-.51-2.1-1.02-3.15-1.52Z"/>
              <path class="amebpba-cls-89" d="M696.96,308.27c-5.87,3.42-12.46,3.46-18.94,4.06,6.15-2.13,12.43-3.63,18.94-4.06Z"/>
              <path class="amebpba-cls-76" d="M82.5,288.34c1.09,7.4,7.31,5.72,11.99,6.78-3.96,2.34-7.7,.58-11.3-.88-3.38-1.38-2.26-3.71-.69-5.9Z"/>
              <path class="amebpba-cls-109" d="M134.62,575.13c.39-.51,.7-1.31,1.2-1.47,2.11-.65,3.71-5.2,5.74-2.44,1.97,2.68-2.04,3.98-4.02,5.42-.28-1.84-1.75-1.4-2.92-1.52Z"/>
              <path class="amebpba-cls-89" d="M677.04,312.52c-.84,.63-1.65,1.74-2.53,1.8-5.3,.34-5.13,3.56-4.12,7.34-5.04-7.89-2.67-8.06,6.65-9.14Z"/>
              <path class="amebpba-cls-96" d="M83.87,477.71c-.6,1.4-1.96,1.74-3.19,2.15-1.41,.47-3.57,1.24-3.98-.62-.5-2.23,1.57-2.75,3.52-2.64,.86,1.54,2.39,.89,3.65,1.11Z"/>
              <path class="amebpba-cls-113" d="M688.76,274.92c-1.83,2.15-4.24,2.58-7.22,2.6,1.54-2.71,3.15-4.65,5.97-5.22,.1,1.02-1.32,2.77,1.25,2.61Z"/>
              <path class="amebpba-cls-103" d="M122.64,555.34c1.55-3.55,7.22-2.4,7.9-6.93,1.52,1.01,2.03,2.33,1.23,4.04,0,0,.14-.14,.14-.14-3.09,1.01-6.18,2.02-9.27,3.03Z"/>
              <path class="amebpba-cls-35" d="M619.64,184.68c3.09,2.72,6.27,5.17,10.8,3.98-5.26,3.18-8.85,1.82-10.8-3.98Z"/>
              <path class="amebpba-cls-83" d="M394.36,340.81c-1.39,10.25-2.79,20.51-4.17,30.76-.86,6.37-1.92,12.72-2.49,19.11-.47,5.3-2.95,6.74-8.15,6.69-5.18-.05-6.85-1.95-7.58-6.79-2.72-18.11-5.84-36.17-10.15-54.27,0,8.67-.04,17.33,0,26,.06,10.2,.05,20.4,.33,30.59,.22,7.82-5.53,4.24-8.93,4.81-3.88,.65-6.51,.01-6.5-5.37,.04-37.28-.2-74.55-.55-111.83-.04-4.07,1.06-5.28,5.17-5.38,19.73-.46,19.62-.56,22.27,19.03,1.68,12.4,3.73,24.75,7.11,37.12,1.08-9.7,2.17-19.39,3.24-29.09,.83-7.49,1.74-14.98,2.4-22.49,.3-3.34,1.72-4.87,5.08-4.48,.22,.03,.44,0,.67,0,6.62,.16,15.15-2.13,19.33,1.14,4.48,3.51,1.42,12.46,1.5,19.01,.38,31.5,.43,63.01,.86,94.51,.07,5.16-.81,7.53-6.74,7.34-10.77-.34-10.84,.03-10.88-11.02-.06-15.08-.01-30.17-.01-45.25-.61-.05-1.21-.1-1.82-.14Z"/>
              <path class="amebpba-cls-61" d="M260.91,529.62c0-33.71,.15-67.43-.14-101.14-.05-5.99,1.74-7.87,7.55-7.24,4.15,.45,8.42,.06,12.62-.2,10.61-.65,19.25,5.95,20.37,16.62,.83,7.9,.68,15.92,.71,23.89,.02,5.99-1.75,11.42-6.66,15.29-2.35,1.85-2.62,3.05,.06,4.94,5.98,4.23,7.77,10.41,7.65,17.46-.14,8.21,0,16.43-.06,24.64-.11,15.79-7.6,23.2-23.21,23.08-4.22-.03-8.45-.23-12.65,.07-4.22,.3-5.48-1.47-5.21-5.43,.27-3.98,.06-7.99,.06-11.98-.36,0-.73,0-1.09,0Z"/>
              <path class="amebpba-cls-73" d="M387.72,482.11c0-19.31,.03-38.63-.03-57.94-.01-3.14,.44-4.92,4.38-4.59,5.29,.43,10.65-.02,15.97,.1,12.12,.27,19.86,7.96,20.14,20.03,.14,5.99-.14,12,.09,17.98,.28,7.02-1.12,13.3-6.88,17.86-2.8,2.22-1.56,3.38,.67,4.84,4.66,3.03,6.85,7.74,6.8,13-.1,10.65,1.27,21.26,.31,31.93-1.24,13.68-7.44,19.92-21.13,20.45-4.21,.16-8.48-.32-12.64,.17-5.81,.69-7.65-1.18-7.53-7.2,.4-18.86,.15-37.73,.15-56.6-.1,0-.2,0-.31,0Z"/>
              <path class="amebpba-cls-62" d="M297.88,275.99c12.41-.02,12.26,0,13.75,12.51,4.15,34.79,8.36,69.58,12.81,104.34,.52,4.1,.15,5.41-4.31,5.35-13.9-.17-13.9,0-15.31-14.17-.42-4.19-.94-8.38-1.25-12.58-.24-3.27-.31-6.03-5-5.98-4.91,.04-5.12,2.9-5.35,6.48-.45,7.3-1.26,14.59-1.64,21.89-.16,3.06-1.16,4.14-4.32,4.35-15.17,1.03-15.22,1.13-13.67-13.45,3.49-32.85,7.02-65.7,10.57-98.54,1.11-10.27,1.17-10.26,11.71-10.2,.67,0,1.33,0,2,0Z"/>
              <path class="amebpba-cls-72" d="M474.73,418.94c12.29,.02,11.89,.07,13.2,12.35,3.45,32.22,7.28,64.4,10.96,96.6,.18,1.54,.45,3.08,.6,4.62,1.21,12.44,1.21,12.27-11.08,12.32-4.46,.02-6.41-1.24-6.58-5.95-.26-7.3-1.17-14.58-1.77-21.87-.26-3.15-.1-6.5-4.8-6.3-4.11,.17-4.23,3.09-4.45,6.08-.54,7.3-1.44,14.58-1.62,21.89-.12,4.64-1.83,6.34-6.39,6.13-12.81-.61-12.31,2.78-10.91-11.43,3.41-34.68,7.06-69.33,10.67-103.99,1.09-10.47,1.18-10.46,12.16-10.44Z"/>
              <path class="amebpba-cls-81" d="M325.37,514c-.41-28.85-1.44-57.69-1.06-86.55,.07-5.44,1.46-7.51,7.18-6.94,5.49,.55,11.09-.18,16.63,.01,10.59,.37,16.8,5.48,17.75,15.95,1.5,16.59,1.17,33.28,.09,49.89-.54,8.33-7.04,13.15-17.7,13.52-4.53,.16-5.41,1.41-5.29,5.73,.33,11.54-.19,23.1,.12,34.64,.14,5.13-1.72,6.39-6.56,6.31-11.18-.19-11.18,.04-11.17-11.27,0-7.1,0-14.2,0-21.3Z"/>
              <path class="amebpba-cls-61" d="M435.84,334.43c0-18.42,.08-36.84-.07-55.26-.03-3.82,1.26-4.98,5.01-4.88,9.09,.25,18.2,.25,27.3,.09,3.77-.07,4.93,1.27,4.97,5.04,.09,10.41,.25,10.73-10.18,10.33-5.61-.22-8.12,.86-7.42,7.15,.66,5.92,.6,12.01,.03,17.95-.61,6.45,1.51,8.78,8,7.98,3.36-.42,7.57-1.67,8.03,4.68,.74,10.24,1.11,10.44-9.66,10.2-4.71-.1-6.65,.73-6.39,6.09,.51,10.41,.4,20.86,.03,31.28-.16,4.48,1.2,6.13,5.55,5.62,1.53-.18,3.14,.18,4.65-.08,6.44-1.1,8.15,1.57,8.19,7.91,.04,6.22-1.32,8.62-7.92,8.1-7.72-.61-15.54-.5-23.29-.06-4.99,.28-6.54-1.19-6.46-6.23,.26-16.2,.05-32.4,.03-48.6,0-2.44,0-4.87,0-7.31-.13,0-.27,0-.4,0Z"/>
              <path class="amebpba-cls-103" d="M417.26,387.83c-.03-10.68-.61-8.36,9.31-9.01,9.38-.61,5.52,5.99,6.37,10.17,1.79,8.83-4.3,7.15-9.51,7.68-8.02,.81-6.05-4.57-6.18-8.84Z"/>
              <path class="amebpba-cls-99" d="M343.51,390.71c.19,3.15,1.14,8.15-6.55,7.16-4.67-.6-11.07,1.64-9.32-7.01,.85-4.21-3.69-11.4,6.35-10.81,9.41,.55,9.44,.08,9.52,10.67Z"/>
              <path class="amebpba-cls-74" d="M321.53,536.71c.02,8.88-6.28,12.88-13.52,9.52-1.46-.68-1.83-1.79-1.83-3.17,0-1.77,.05-3.53,.07-5.3,.13-9.2,4.09-12.25,12.87-9.38,5.01,1.63,.89,6.3,2.41,8.33Z"/>
              <path class="amebpba-cls-74" d="M383.82,535.55c1.83,7.88,.37,11.38-7.44,10.47-6.87-.8-7.46-1.12-7.16-13.34,.23-9.15,7.26-4.02,10.86-4.49,7.86-1.03,2.63,5.87,3.73,7.37Z"/>
              <path class="amebpba-cls-115" d="M448.14,536.57c.03,8.95-4.2,11.87-13.21,9.25-1.81-.53-2.57-1.6-2.58-3.29-.04-4.19-.06-8.39,.02-12.58,.04-2.05,1.18-2.98,3.28-3.39,10.18-1.98,12.46-.22,12.49,10.01Z"/>
              <path class="amebpba-cls-56" d="M640.23,197.57c.25-.99,.19-1.9,.59-2.18,2.56-1.82,4.49-4.86,8.19-4.52,.23,.02,.69,1.31,.47,1.62-2.17,3.15-4.81,5.52-9.25,5.07Z"/>
              <path class="amebpba-cls-30" d="M339.48,55.24c.29-1.42-1.36-4.3,1.62-4.44,2.11-.09,1.78,2.73,1.98,4.31,.87,6.98,1.54,13.99,2.22,21,.12,1.23,.53,2.88-1.39,3.07-1.8,.18-2.05-1.5-2.18-2.69-.81-6.98-1.49-13.97-2.25-21.24Z"/>
              <path class="amebpba-cls-26" d="M663.52,213.26c-.58,.7-1.09,1.84-1.95,2.24-7.06,3.27-13.38,7.77-19.97,11.8-1.07,.66-2.56,1.76-3.58,.46-1.5-1.92,.7-2.76,1.79-3.45,6.53-4.14,13.16-8.14,19.77-12.14,1.41-.85,2.88-1.36,3.93,1.09Z"/>
              <path class="amebpba-cls-9" d="M413.38,73.59c.8-2.1-1.15-6.84,1.72-6.53,3.97,.43,1.05,4.62,1.12,7.19,.02,.66,.04,1.34-.09,1.98-.28,1.39,.19,3.69-2.05,3.36-2.2-.32-1.04-2.42-1.03-3.81,0-.65,.19-1.31,.33-2.19Z"/>
              <path class="amebpba-cls-111" d="M418.41,59.17c.1,1.98-.55,3.58-2.8,3.21-1.6-.26-1.5-2.01-1.27-3.2,.5-2.59-.95-5.63,1.69-7.73,2.2,2.14-.23,5.71,2.38,7.72Z"/>
              <path class="amebpba-cls-44" d="M418.41,59.17c-1.59,1.24-2.9,1.47-2.89-1.11,.01-2.2,.32-4.41,.5-6.61,4.69,1.37,1.48,5.18,2.38,7.72Z"/>
              <path class="amebpba-cls-28" d="M531.65,119.1c4.45-7.55,8.88-15.12,13.41-22.62,.35-.59,1.53-1.11,2.19-.97,.89,.19,1.26,1.34,.82,2.17-4.28,8-8.59,15.98-14.6,22.79-.61-.45-1.22-.91-1.82-1.36Z"/>
              <path class="amebpba-cls-22" d="M506.06,96.32c2.3-5.23,4.61-10.46,6.91-15.69,.61-1.38,1.06-3.39,3.08-2.35,1.81,.93,.97,2.7,.17,4.03-3.28,5.48-4.43,11.99-8.3,17.19-1.38-.61-1.51-1.96-1.86-3.18Z"/>
              <path class="amebpba-cls-51" d="M506.06,96.32c1.4,.6,1.58,1.93,1.86,3.18-.36,1.01-.65,2.06-1.11,3.03-.55,1.17-1.24,2.7-2.78,2.09-2.2-.87-.84-2.54-.3-3.82,.65-1.55,1.54-2.99,2.32-4.48Z"/>
              <path class="amebpba-cls-110" d="M63.76,335.23c2.63-1.36,4.64-1.61,6.29,.39,.64,.77,.22,1.84-.67,2.15-2.55,.9-3.92-.7-5.62-2.54Z"/>
              <path class="amebpba-cls-114" d="M693.91,371.37c-3.53,0-7.06,.02-10.59,0-1.6-.01-3.4-.19-3.54-2.13-.15-2.15,1.74-2.17,3.29-2.23,7.26-.3,14.52-.67,21.79-.83,1.87-.04,4.59-.22,4.73,2.37,.15,2.78-2.54,2.51-4.44,2.55-3.75,.09-7.5,.03-11.25,.03,0,.08,0,.17,0,.25Z"/>
              <path class="amebpba-cls-58" d="M281.78,62.42c4.32,8.33,7.33,16.94,9.26,25.91,.19,.87,.02,1.89-1.15,1.93-.56,.02-1.54-.36-1.65-.76-2.62-8.93-6.49-17.55-6.46-27.08Z"/>
              <path class="amebpba-cls-112" d="M238.61,89.54c.56-.05,.82-.15,.94-.06,2.57,1.86,4.42,4.14,3.93,7.55-.04,.26-1.31,.7-1.63,.49-2.92-1.92-3.24-4.86-3.23-7.98Z"/>
              <path class="amebpba-cls-55" d="M156.46,589.69c-.72,.85-1.35,1.75-2.13,2.48-5.31,4.95-10.6,9.93-16.02,14.75-.97,.87-2.11,2.99-3.78,1.45-1.72-1.58,.18-2.79,1.21-3.75,5.61-5.22,11.3-10.36,16.94-15.55,.82-.75,1.64-1.62,2.81-1.21,.42,.15,.58,1.05,.97,1.82Z"/>
              <path class="amebpba-cls-57" d="M127.21,215.26c-.66,1.67-2.16,1.66-3.25,1-7.16-4.37-14.24-8.88-21.32-13.39-.7-.44-1.41-1.17-.93-2.21,.42-.89,1.32-1.53,2.12-1.04,7.7,4.73,15.34,9.55,22.98,14.39,.27,.17,.26,.79,.4,1.25Z"/>
              <path class="amebpba-cls-24" d="M439.52,73.34c-1.15-3.03-.15-4.92,1.13-7.57,2.01,3.25,1.61,5.25-1.13,7.57Z"/>
              <path class="amebpba-cls-48" d="M656.01,505.72c1.5,.68,2.91,1.3,4.31,1.93,6.01,2.71,12.02,5.43,18.04,8.13,1.19,.54,2.81,.92,2.39,2.59-.45,1.75-2.12,1.37-3.26,.86-7.04-3.13-14.04-6.33-21.01-9.6-1.13-.53-2.51-1.27-1.99-2.94,.13-.43,.98-.64,1.54-.98Z"/>
              <path class="amebpba-cls-32" d="M270.47,95.01c-.42,.84-.61,1.88-1.21,2.25-1.19,.73-2.18-.17-2.6-1.2-3.14-7.77-6.2-15.58-9.26-23.38-.43-1.09-.48-2.33,.95-2.59,.67-.12,1.92,.42,2.16,.98,3.37,7.9,6.62,15.86,9.95,23.94Z"/>
              <path class="amebpba-cls-108" d="M100.76,240.07c.11,1.57-.88,2.1-2.02,1.88-2.57-.48-5.18-1.19-6.27-3.92-.41-1.03,.87-1.58,1.65-1.42,2.54,.5,5.16,1.02,6.64,3.46Z"/>
              <path class="amebpba-cls-4" d="M603.1,149.84c.17,1.17,.3,1.44,.22,1.58-1.53,2.59-2.69,5.59-6.05,6.34-.93,.21-1.82-.82-1.63-1.66,.84-3.71,3.91-4.84,7.46-6.26Z"/>
              <path class="amebpba-cls-29" d="M96.74,493.77c-.42,1.9-1.43,2.57-2.77,3.08-7.04,2.64-14.05,5.35-21.07,8.03-1.24,.47-2.66,1.13-3.47-.45-.65-1.25,.35-2.04,1.44-2.47,7.82-3.06,15.65-6.1,23.49-9.13,1.28-.5,1.99,.15,2.37,.94Z"/>
              <path class="amebpba-cls-19" d="M105.51,553.49c1.67-2.51,3.07-4.74,6.16-4.73,.5,0,1.56,1.4,1.41,1.74-1.34,3.15-4.08,3.34-7.57,2.99Z"/>
              <path class="amebpba-cls-95" d="M182.51,631.88c-.96,2.25-2.33,4.32-4.83,4.91-1.07,.26-1.84-1.18-1.73-2.36,.26-2.73,1.67-4.41,4.59-4.6,1.53-.1,1.85,.77,1.97,2.06Z"/>
              <path class="amebpba-cls-18" d="M624.81,604.2c-2.7-1.25-4.85-3.39-6.15-6.23-.53-1.16,.69-2.6,1.63-2.09,2.64,1.46,5.26,3.23,6.58,6.1,.54,1.16-.53,1.96-2.05,2.22Z"/>
              <path class="amebpba-cls-47" d="M306.4,75.32c2.17-.05,3.95,.91,4.87,2.75,.96,1.94,1.23,4.18,.64,6.34-.1,.38-1.18,.89-1.58,.74-2.29-.88-1.89-3.14-2.36-4.97-.43-1.68-.96-3.34-1.45-5,0,0-.13,.14-.13,.14Z"/>
              <path class="amebpba-cls-42" d="M691.65,422.72c-4.79,3.11-9.04,1.32-13.15,.84-.52-.06-1.27-1.27-1.28-1.96-.04-1.51,1.34-1.58,2.39-1.48,3.83,.37,7.69,.65,12.04,2.6Z"/>
              <path class="amebpba-cls-10" d="M705.89,424.97c-3.59,1.21-7.38,2.99-10.59-1.75,4.06-.52,7.38-.04,10.59,1.75Z"/>
              <path class="amebpba-cls-116" d="M642.43,567.28c-1.09,.74-1.27,.97-1.44,.96-3.19-.19-4.86-2.49-6.43-4.82-.15-.22,.52-1.43,.88-1.46,3.91-.37,4.53,3.24,7,5.32Z"/>
              <path class="amebpba-cls-2" d="M82.27,285.13c4.17-2.77,7.63-1.49,11.61,2.19-4.94,1.58-8.31-.29-11.66-2.35,0-.02,.06,.17,.06,.17Z"/>
              <path class="amebpba-cls-25" d="M186.63,125.69c-.26,.43-.56,1.39-.88,1.4-.78,.02-1.94-.16-2.29-.7-2.12-3.19-5.55-5.54-6.24-9.6-.04-.26,1.04-1.09,1.34-.98,3.95,1.53,4.91,5.82,7.65,8.5,.27,.26,.26,.8,.44,1.38Z"/>
              <path class="amebpba-cls-104" d="M635.08,173.91c-2.64,2.86-5.48,5.88-9.75,6.75-1.22,.25-2.24-1.09-1.44-2.09,2.47-3.09,5.87-5.12,9.39-6.78,1.05-.49,2.02,.38,1.79,2.13Z"/>
              <path class="amebpba-cls-33" d="M694.32,332.47c-2.47,1.71-4.1,2.84-6.1,1.59-.33-.2-.51-1.38-.28-1.61,1.67-1.65,3.46-.93,6.38,.02Z"/>
              <path class="amebpba-cls-14" d="M284.97,509.19c0,5.3,.02,10.6-.01,15.9-.01,2.37-.7,4.92-3.36,4.84-3.14-.09-1.7-3.03-1.71-4.71-.1-11.26-.1-22.52,.04-33.78,.02-1.71-1.39-4.67,1.42-4.98,2.94-.32,3.12,2.9,3.21,4.88,.27,5.94,.1,11.9,.1,17.85,.1,0,.21,0,.31,0Z"/>
              <path class="amebpba-cls-3" d="M279.8,454.7c0-4.4-.02-8.81,.01-13.21,.01-1.24-.34-2.9,1.47-3.14,1.61-.22,2.44,1.14,2.52,2.44,.64,9.44,.71,18.89,.03,28.34-.08,1.16-.98,2.19-2.12,2.24-1.78,.08-1.97-1.44-1.98-2.79-.03-4.62-.01-9.25-.01-13.87h.06Z"/>
              <path class="amebpba-cls-5" d="M411.22,507.41c0,5.77,.1,11.55-.06,17.31-.05,1.73-.66,3.94-2.81,3.95-2.73,.02-1.92-2.41-1.95-4.04-.19-11.53-.38-23.06-.44-34.59,0-1.79-1.21-4.74,1.4-5.09,3.23-.44,3.66,2.95,3.78,5.15,.32,5.75,.11,11.54,.11,17.31h-.03Z"/>
              <path class="amebpba-cls-13" d="M406.68,436.69c2.68-1.09,2.21,1.97,3.52,2.68,1.43,9.58,1.52,19.16-.03,28.73-1.04,.76-1.91,1.92-3.46,1.38-1.53-10.93-1.42-21.86-.03-32.79Z"/>
              <path class="amebpba-cls-15" d="M406.68,436.69c0,10.93,.02,21.86,.03,32.79-2.48-.72-1.59-2.84-1.61-4.37-.13-8.31-.14-16.62-.12-24.92,0-1.36-.71-3.24,1.7-3.5Z"/>
              <path class="amebpba-cls-15" d="M410.17,468.1c.46-9.58,.71-19.15,.03-28.73,3.73,5.81,1.24,12.22,1.52,18.32,.15,3.36,1.93,7.42-1.55,10.41Z"/>
              <path class="amebpba-cls-1" d="M299.66,328.6c.29,4.98,.44,9.97,.91,14.93,.21,2.18,1.45,4.58-2.26,4.54-2.93-.03-2.75-2.1-2.45-4.19,.15-1.07,.13-2.17,.18-3.26,1.02-4.15,.18-8.37,.48-12.55,.08-1.1,.13-2.51,1.6-2.23,1.14,.22,.98,1.83,1.53,2.75Z"/>
              <path class="amebpba-cls-107" d="M299.66,328.6c-.82-.27-1.7-4.05-2.41-.14-.73,4-.83,8.11-1.21,12.17-.99-7.99-.02-15.82,1.85-25.18,2.63,5.22,2.38,9.16,1.76,13.16Z"/>
              <path class="amebpba-cls-11" d="M475.14,460.94c.98,10.94,1.77,19.94,2.6,28.94,.15,1.65,.22,3.35-1.86,3.54-2.38,.22-3.13-1.6-2.89-3.5,1.13-8.97-.04-18.11,2.15-28.98Z"/>
              <path class="amebpba-cls-54" d="M348.53,460.72c0,5.3,.13,10.61-.06,15.9-.08,2.19-.72,4.89-3.52,4.78-3.65-.14-1.99-3.24-2.05-5.15-.32-10.81-.56-21.63-.72-32.45-.03-1.82-1.01-4.58,1.74-5,3.08-.47,4.24,2.18,4.34,4.69,.22,5.73,.07,11.47,.07,17.21,.07,0,.14,0,.21,0Z"/>
              <path class="amebpba-cls-93" d="M446.57,536.3c.34,4.6,.75,8.53-6.05,8.15-4.8-.26-7.21-1.27-6.24-6.39,.76-4-3.03-10.05,5.99-10.01,7.14,.03,6.61,3.88,6.3,8.24Z"/>
              <path class="amebpba-cls-60" d="M682.28,369.11c8.13-.68,16.16-1.36,24.18-.12-8.08,1.06-16.18,1.17-24.18,.12Z"/>
            </g>
          </g>
      `}}>
    </svg>
  );
};