import React from 'react';
import { Col, Render, Row, Spacer } from '@gower/react-ui/components';
import { ContextTemplatesProps, TemplatesProps } from '../create.props';
import { getActions } from '../create.actions';

export type HeaderActionsProps = TemplatesProps & ContextTemplatesProps & {

};
export const HeaderActions: React.FC<HeaderActionsProps> = ( { ...props } ) =>
{
    const { store } = props;

    if ( props.hideActions ) return null;

    return <>
        {props.actionsTemplate && <Render content={props.actionsTemplate} props={{ ...props.injectProps, ...store.getProps(), ...getActions( props ) }} />}
        {!props.actionsTemplate && <>
            <Col paddingY={5} cols={12} disabled={!store.Status.isOk}>
                <Row fullWidth>
                    <Spacer />
                    <Render content={props.actionsContent} props={{ ...props.injectProps, ...store.getProps(), ...getActions( props ) }} />
                </Row >
            </Col >
        </>}
    </>;
};