import { ActivateUserService } from '@gower/shared-sdk/src/lib/Core/Authentication/ActivateUser';
import { MutableStore, expose, ViewStatus } from '@gower/react-ui/store';
import { Redirect } from '@gower/react-ui/utils';
import { RoutesEnum } from 'app/routes';

class ActivateUserStore extends MutableStore
{
    public status = new ViewStatus( 'ok' );
    public currentStep: 1 | 2 | 3 = 1;
    private errorMessage: string[] = [];
    public get LastErrors () { return this.errorMessage; };
    public set LastErrors ( value: string[] )
    {
        this.errorMessage = value || [];
        this.status.setErrored();
        setTimeout( () =>
        {
            this.errorMessage.clear();
            this.status.setOk();

        }, 3000 );
    };

    private service = ActivateUserService.get();
    public get userEmail () { return this.service.Email; }

    public nextStep ()
    {
        this.LastErrors.clear();
        this.currentStep++;
    }

    public backStep ()
    {
        this.LastErrors.clear();
        this.currentStep--;

    }

    public restart ()
    {
        this.LastErrors.clear();
        this.currentStep = 1;
    }

    public async sendEmail ( email: string )
    {
        if ( email.length < 6 )
        {
            this.LastErrors = ['Ingresá tu email'];
            return;
        }

        this.status.setLoading();
        if ( await this.service.sendEmail( email ) )
        {
            this.nextStep();
            this.status.setOk();
        }
        else 
        {
            this.LastErrors = this.service.LastErrors as string[];
        }
    }

    public async sendSecurityCode ( code: string )
    {
        if ( code.length !== 6 || Number( code ).isNan() )
        {
            this.LastErrors = ['Ingresá el código de seguridad'];
            return;
        }

        this.status.setLoading();
        if ( await this.service.sendSecurityCode( code ) )
        {
            this.nextStep();
            this.status.setOk();
        }
        else 
        {
            this.LastErrors = this.service.LastErrors as string[];
        }


    }

    public async createPassword ( password1: string, password2: string )
    {
        if ( password1.length < 6 )
        {
            this.LastErrors = ['Ingresá al menos 6 caracteres'];
            return;
        }
        else if ( password1 !== password2 )
        {
            this.LastErrors = ['Las contraseñas no coinciden'];
            return;
        }

        this.status.setLoading();
        if ( await this.service.setNewPassword( password1 ) )
        {
            this.nextStep();
            this.status.setOk();
            Redirect.go( RoutesEnum.Login );
        }
        else 
        {
            this.LastErrors = this.service.LastErrors as string[];
        }


    }

}

export const { context, store, provider } = expose( ActivateUserStore );