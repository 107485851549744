import { Content, Row, Col, TextInput, Label, Div, Spacer, Button, Divider, Code, Chip, Autocomplete } from '@gower/react-ui/components';
import { provider, useStore } from './store';
import { UsersEditPassword } from './forms/edit-password';
import { UsersEditForm } from './forms/edit-form';
import { Loader } from '@gower/react-ui/template/loader';


export const ProfileView = provider( () => {

    const store = useStore();

    return <>
        <Content columns scroll>
            <Col>
                <Row padding={15}>
                    <Col gutter center>
                        <Div padding={10} >
                            <Label xxsemi p bold marginBottom={5}>Mi cuenta</Label>
                            <Label xsmall p textGrey>Configurá y editá los datos de tu usuario.</Label>
                        </Div>
                    </Col>
                    <Spacer />
                </Row>
            </Col>
            <Spacer vertical>
                <Loader status={store.Status}>
                    {store.Item && <>
                        <Content start padding={20} justifyCenter>
                            <Col start maxWidth={800} paddingX={20} paddingBottom={100}>
                                <UsersEditForm store={store} />
                            </Col>
                            <Col start maxWidth={800} paddingX={20} paddingBottom={100}>
                                <UsersEditPassword store={store} />
                            </Col>
                        </Content>
                    </>}
                </Loader>
            </Spacer>
        </Content>
    </>;
} );
