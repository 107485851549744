import { UnregisteredAgreement } from '@gower/cm-mdp-sdk/unregistered-agreements';
import { MutableStore, ViewStatus, expose } from '@gower/react-ui/store';
import { MessageBox } from '@gower/react-ui/message-box';
import { Authorization } from '@gower/cm-mdp-sdk/authorizations';
import { ElectronicPrescriptionClient } from '@gower/cm-mdp-sdk/unregistered-agreements';
import { Redirect } from '@gower/react-ui/utils';

class UnregisteredElectronicPrescriptionsStore extends MutableStore {

    public readonly Status = new ViewStatus( 'pending' );
    private affiliateInputMode: 'credential' | 'document' = 'credential';
    public get AffiliateInputMode () { return this.affiliateInputMode; }
    public setAffiliateInputMode ( mode: 'credential' | 'document' ) {
        this.affiliateInputMode = mode;
    }

    private electronicPrescriptionClient = new ElectronicPrescriptionClient();

    public agreement: UnregisteredAgreement = null;
    public professionalId: string = '';
    public affiliateId: string = '';
    public affiliateDocumentNumber: string = '';
    public affiliateDocumentType: string = Authorization.VO.Documents.DNI.Id;
    public agreements: UnregisteredAgreement[] = [];

    async reset () {
        this.agreement = null;
        this.professionalId = '';
        this.affiliateId = '';
        this.affiliateDocumentNumber = '';
        this.affiliateDocumentType = Authorization.VO.Documents.DNI.Id;

        this.Status.setPending();
    }

    async removeAffiliate () {
        this.affiliateId = '';
        this.affiliateDocumentNumber = '';
        this.affiliateDocumentType = Authorization.VO.Documents.DNI.Id;

        this.Status.setPending();
    }

    get canConfirm () {
        return Boolean(
            !!this.agreement?.Id &&
            !!this.professionalId &&
            ( !!this.affiliateId || ( !!this.affiliateDocumentNumber && !!this.affiliateDocumentType ) )
        );
    }

    async confirm () {
        this.Status.setLoading();

        const redirectData = await this.electronicPrescriptionClient.getMedereElectronicPrescriptionUrl( {
            agreement_id: this.agreement.Id,
            license: this.professionalId,
            affiliate_id: this.affiliateId,
            affiliate_document_number: this.affiliateDocumentNumber,
            affiliate_document_type: this.affiliateDocumentType,
        } );


        if ( redirectData ) {
            Redirect.go( redirectData.url );
            this.Status.setOk();
        }
        else {
            if ( this.electronicPrescriptionClient.LastErrors?.length ) {
                await MessageBox.Error( this.electronicPrescriptionClient.LastErrors );
            } else {
                await MessageBox.Error( 'Ocurrió un error inesperado en los servicios de MEDERE. Intentá nuevamente.' );
            }
            this.Status.setPending();
        }
    }

    async findAllAgreements () {

        const data = await UnregisteredAgreement.find( {}, null, { all: true } );
        this.agreements = data?.items || [];
    }
}

export const { useStore, provider } = expose( UnregisteredElectronicPrescriptionsStore );