import React from 'react';
import { SidedCrudStore } from '../sided-crud-store';
import { Header } from './components/header';
import { TemplatesProps } from './create.props';
import { getActions } from './create.actions';
import { Content, Render, Row, Spacer } from '@gower/react-ui/components';
import { ContentLoader } from './components/content-loader';

export type CreateProps = TemplatesProps &
{
    crudStore: SidedCrudStore;
};
export const CreateTemplate: React.FC<CreateProps> = ( { ...props } ) =>
{
    const store = props.crudStore.CreateStore;

    return <>
        {props.template && <Render content={props.template} props={{ ...props.injectProps, ...store.getProps(), ...getActions( { store, ...props } ) }} />}
        {!props.template && <>
            <Content width="100%" height="100%" color="white" noScroll>
                <Content columns fullWidth fullHeight scrollOnlyVertical>
                    <Header {...props} store={store} />
                    <Spacer paddingX={15}>
                        <Row maxWidth={props.maxWidth}>
                            <ContentLoader {...props} store={store}>
                                <Render content={props.content} props={{ ...props.injectProps, ...store.getProps(), ...getActions( { store, ...props } ) }} />
                            </ContentLoader>
                        </Row>
                    </Spacer>
                </Content>
            </Content>
        </>}
    </>;
};