import React from 'react';
import { InputAdornment, TextField } from '@mui/material';
import { Div, Button, Col, Content, Label, Spacer } from '@gower/react-ui/components';
import { AiOutlineMail } from '@gower/react-ui/icons';
import { context } from './store';
import { RoutesEnum } from 'app/routes';


export const Step1Form: React.FC = () => {
    const store = React.useContext( context ).current;
    const { status } = store;
    const [email, setEmail] = React.useState( '' );


    return <>
        <Content columns fullHeight>
            <Col fullWidth center py-5 display='block'  >
                <Label p normal textWeight='500' letterSpacing="wide" label='Ingresá el email asociado a tu cuenta.' mb-4 />
                <TextField
                    id="username"
                    autoComplete='username'
                    autoCapitalize='off'
                    autoCorrect='off'
                    placeholder="Ingresá tu email"
                    variant="standard"
                    size='medium'
                    margin='normal'
                    fullWidth
                    autoFocus
                    disabled={status.isLoading}
                    value={email}
                    onChange={( e ) => setEmail( e.target.value )}
                    onKeyUp={( e ) => e.key === 'Enter' && store.sendEmail( email )}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <AiOutlineMail size={20} />
                            </InputAdornment>
                        ),
                    }}

                />
            </Col>

            <Col fullWidth display='block' paddingY={20}  >
                <Label p normal textCenter label='Te enviaremos un código de restablecimiento.' textGrey mb-1 />
            </Col>

            <Spacer vertical>
                <Div fullWidth block minHeight={40} maxHeight={100} pt-2 textCenter scrollOnlyVertical>
                    {store.LastErrors && store.LastErrors.map( ( error, key ) => <Label p key={key} textRed normal>{error}</Label> )}
                </Div>
                <Div fullWidth useMediaQuery height={46} xsHeight={64}>
                    <Button pointer
                        normal
                        textWhite
                        color="primary"
                        fullWidth
                        fullHeight
                        textWeight='500'
                        letterSpacing='wide'
                        loading={status.isLoading}
                        disabled={status.isErrored}
                        onClick={() => store.sendEmail( email )}
                        label="Continuar"
                    />
                </Div>
                <Div fullWidth mt-2 useMediaQuery height={46} xsHeight={64}>
                    <Button
                        disabled={status.isLoading || status.isErrored}
                        text
                        normal
                        fullWidth
                        fullHeight
                        textColor='surface-8'
                        textWeight='500'
                        letterSpacing='wide'
                        label="Volver"
                        reactLink={RoutesEnum.Login}
                    />
                </Div>
            </Spacer>
        </Content>
    </>;
};