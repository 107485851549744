import { Model, InstanceOfModel, CrudSubStore } from './common';
import { ViewStatus } from '@gower/react-ui/store';
import { URI } from '@gower/react-ui/utils';
import { Notifications } from '@gower/react-ui/notifications';
import { MessageBox } from '@gower/react-ui/message-box';
import { Wait } from '@gower/utils';
export class ViewStore<T = Model> extends CrudSubStore {
    public async show ( item: InstanceOfModel ) {
        this.Status.setLoading();

        if ( !item ) {
            const elId = URI.getHashParam( 'el.id', this.viewName );
            if ( elId )
                item = await this.MainStore.Model.findById( elId );
        }

        if ( !item ) {
            this.Status.setEmpty();
            this.MainStore.goDefault();
        }
        else {
            this.currentItem = item;
            this.currentEditItem = new this.MainStore.Model( item.getJson() );
            this.isEditing = false;
            URI.addHash( { 'el.id': this.currentItem?.Id }, this.viewName );
            this.Status.setOk();
        }
    }

    public hide () {
        this.Status.setPending();
        this.isEditing = false;
        this.currentItem = null;
        this.currentEditItem = null;
        URI.removeHashParam( 'el.id', this.viewName );
    }

    public getProps (): any {
        return {
            crudStore: this.MainStore,
            viewStore: this,
            currentItem: this.currentItem,
            currentEditItem: this.currentEditItem,
            isEditing: this.isEditing as Readonly<boolean>,
        };
    }


    setup () {
        this.MainStore.watchInternalNative( 'ViewStore.currentEditItem', () => this.setCanSave( true ) );
    }

    private editing = false;
    public get isEditing () { return this.editing; }
    public set isEditing ( value: boolean ) {
        if ( !value )
            this.setCanSave( false );
        this.editing = value;
    }
    public get canEdit () { return !this.isEditing; };
    public readonly Status = new ViewStatus( 'pending' );
    public get CurrentItem () { return this.currentItem as T; }
    private currentItem: InstanceOfModel = null;
    public get CurrentEditItem () { return this.currentEditItem as T; }
    private currentEditItem: InstanceOfModel = null;
    public get CanSave () { return this.canSave; }
    private canSave = false;
    private setCanSave ( value: boolean ) {
        this.canSave = value;
    }

    async edit () {
        this.isEditing = true;
    }

    async remove () {

        if ( await MessageBox.YesNo( 'Está acción no puede deshacerse', '¿Eliminar noticia?' ) ) {

            this.Status.setSaving();
            await Wait( 1000 );
            const result = await this.currentItem.delete();
            if ( result ) {

                this.MainStore['onDelete'] && await this.MainStore['onDelete']( this.currentEditItem );
                Notifications.Success( 'Noticia eliminada' );
                this.MainStore.ListStore.Items.remove( this.currentItem );
                this.MainStore.goList();

            } else {
                await MessageBox.Error( 'Ocurrió un error al eliminar' );
            }

            this.Status.setOk();
        }
    }

    async restore () {
        if ( await MessageBox.UnsavedChanges() ) {
            this.currentEditItem.refresh( this.currentItem.getJson() );
            this.isEditing = false;
        }
    }

    async save () {
        this.Status.setSaving();
        await Wait( 1000 );
        const result = await this.currentEditItem.save();
        if ( result.result ) {
            this.MainStore['onUpdate'] && await this.MainStore['onUpdate']( this.currentEditItem );
            this.MainStore['onSave'] && await this.MainStore['onSave']( this.currentEditItem );
            Notifications.Saved();
            this.currentItem.refresh( this.currentEditItem.getJson() );
            this.isEditing = false;
        } else {
            await MessageBox.Error( result.errorList );
        }

        this.Status.setOk();
    }
}
