import React from 'react';
import { Col, Content, Div, Label, Row, SearchInput, Spacer } from '@gower/react-ui/components';
import { useNavigate } from 'react-router-dom';
import { useStore } from '../store';
import { DataGrid, DataGridColumn, DataGridRef } from '@gower/react-ui/template/data-grid';
import { AiOutlineUser } from '@gower/react-ui/icons';
import { User } from '@gower/shared-sdk/core/users';

export const UsersMobile = () => {

    const navigate = useNavigate();
    const store = useStore();
    const gridRef = React.useRef<DataGridRef>();

    function open ( authId: string ) {
        //navigate( `${ RoutesEnum.Authorizations }/${ authId }` );
    }

    function search ( text: string ) {
        gridRef?.current?.search( text );
    }

    return <>
        <Content start columns noScroll>
            <Col
                fullWidth
                padding={10}
            >
                <SearchInput
                    placeholder="Buscar un usuario"
                    borderless
                    onSearch={search}
                    disabled={store.Status.isLoading}
                    clearable
                />
            </Col>

            <Spacer vertical scroll white>
                <DataGrid
                    ref={gridRef}
                    store={store}
                    loading={store.Status.isLoading}
                    mobileTemplate={( { data }: { data: User; } ) => (
                        <Row
                            white
                            center
                            borderRadius={5}
                            padding={5}
                            activeEffect
                            noWrap
                            onClick={() => open( data.Id )}
                        >
                            <Col
                                stretch
                                width={4}
                                color="surface-2"
                            />
                            <Spacer center gutter paddingX={8} ellipsis>
                                <Row columns>
                                    <Col>
                                        <Label p xxsmall bold show={!!data.Id}>
                                            #{data.Id}
                                        </Label>
                                    </Col>
                                    <Col>
                                        <Col center horizontalGutter>
                                            <AiOutlineUser />
                                        </Col>
                                        <Col center horizontalGutter>
                                            <Label p>
                                                <Label xxsmall label={data.CompleteName} />
                                                <Label gutter xsmall label={data.Email} />
                                            </Label>
                                        </Col>
                                    </Col>
                                </Row>
                            </Spacer>
                            <Col center gutter>
                                <Div textRight>
                                    <Label p xsmall label={data.Group.Description} />
                                    <Label p xxsmall
                                        xsmall
                                        textRed={data.Status.isBloqued}
                                        textGreen={data.Status.isActive}
                                        label={data.Status.Description} />
                                </Div>
                            </Col>
                        </Row>
                    )}
                >
                    <DataGridColumn
                        field="LocalId"
                        title="Nº de Usuario"
                        size={80}
                        minSize={50}
                        template={( { data } ) => (
                            <Div block>
                                <Label p >
                                    #{data.LocalId}
                                </Label>
                            </Div>
                        )}
                    />
                </DataGrid>
            </Spacer>

        </Content>
    </>;
};

