import React from 'react';
import { Button, Col, Content, ModalWindow } from '@gower/react-ui/components';
import { useStore } from '../../store';

export type PrescriptionFormModalProps = {
    isOpen: boolean;
    onClose: () => void;
};
export const PrescriptionFormModal: React.FC<PrescriptionFormModalProps> = ( { isOpen, onClose } ) => {

    const store = useStore();
    const status = store.Status;

    function close () {
        onClose();
    }

    async function download ( type: 'birth_control' | 'chronic' ) {
        onClose();
        await store.prescription( type );
    }

    return <>
        <ModalWindow
            overlayProps={{
                position: 'fixed',
                zIndex: 10,
            }}
            isOpen={isOpen}
            onClose={close}
            title="Descargar Recetario"
            height="auto"
            maxWidth="400px"
        >
            <Content
                fullWidth
                columns
                gap={5}
                paddingY={20}
                paddingX={5}
            >
                <Col>
                    <Button
                        block
                        pointer
                        onClick={() => download( 'chronic' )}
                        disabled={store.downloadingPrescription || !store.canGeneratePrescription || status.isSaving}
                        textLeft
                        flat
                        normal
                        label="Recetario Crónico"
                    />
                </Col>
                <Col>
                    <Button
                        block
                        pointer
                        loading={store.downloadingPrescription}
                        onClick={() => download( 'birth_control' )}
                        disabled={store.downloadingPrescription || !store.canGenerateBirthControlPrescription || status.isSaving}
                        textLeft
                        flat
                        normal
                        label="Recetario Anticonceptivos"
                    />
                </Col>
            </Content>
        </ModalWindow>
    </>;

};