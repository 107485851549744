import { Authorization, AuthorizationHistory, AuthorizationLogs } from '@gower/cm-mdp-sdk/authorizations';
import { MessageBox } from '@gower/react-ui/message-box';
import { Notifications } from '@gower/react-ui/notifications';
import { expose, MutableStore, ViewStatus } from '@gower/react-ui/store';

export class AuthorizationDetailsStore extends MutableStore {

    public Status = ViewStatus.new( 'loading' );
    public Authorization: Authorization = null;
    public AuthorizationLogs: AuthorizationLogs = null;
    public AuthorizationHistory: AuthorizationHistory[] = [];
    public isCanceling = false;

    onAfterRender ( props ) {
        this.Status.setLoading();
        const authId = props?.routerParams?.id;
        if ( authId ) {
            this.setup( authId );
        } else {
            this.Status.setEmpty();
        }
    }

    async setup ( id: string ) {

        this.Status.setLoading();
        this.Authorization = await Authorization.findById( id );
        if ( this.Authorization ) {
            this.AuthorizationLogs = await this.Authorization.getLogs();
            this.AuthorizationHistory = await this.Authorization.getHistory() || [];
            this.Status.setOk();
        } else {
            this.Status.setEmpty();
        }
    }

    public isReloadingHistory = false;
    async reloadHistory () {

        this.isReloadingHistory = true;
        this.AuthorizationHistory = await this.Authorization.getHistory() || [];
        this.isReloadingHistory = false;
    }

    async remove ( item: Authorization ) {

        const result = await MessageBox.YesNo( {
            title: 'Eliminar autorización',
            content: '¿Estás seguro de eliminar la autorizacion?. Esta acción no puede deshacerse'
        } );

        if ( result ) {
            if ( await item.delete() ) {
                Notifications.Success( 'Autorización eliminada.' );
            } else {
                Notifications.Error( 'Error al eliminar.' );
            }
        }

    }

    async cancel ( item: Authorization ) {

        const response = await MessageBox.YesNo( {
            title: 'Anular autorización',
            content: '¿Estás seguro de anular la autorizacion?. Esta acción no puede deshacerse'
        } );

        if ( response ) {
            this.isCanceling = true;
            const result = await item.cancel();
            if ( result?.result )
                Notifications.Success( 'Autorización anulada.' );
            else {
                if ( result?.errors ) {
                    MessageBox.Error( result?.errors );
                } else if ( item.Agreement.isOSARPyH || item.Agreement.isAMTAR ) {
                    MessageBox.Error( 'Se produjo un ERROR. Por favor contactarse con la OOSS.' );
                } else {
                    MessageBox.Error( 'Error al anular. Compruebe el estado de la autorización.' );
                }
            }
            this.isCanceling = false;
        }
    }

}

export const { useStore, provider } = expose( AuthorizationDetailsStore );
