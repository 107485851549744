
import { Button, Col, Colors, Div, Label, Row, Spacer } from '@gower/react-ui/components';
import { useBoolean } from '@gower/react-ui/hooks';
import { RoutesEnum } from 'app/routes';
import { useNavigate } from 'react-router-dom';
import { AgreementLogo } from 'lib/components/agreement-logo';
import { VerifyAffiliateModal } from 'lib/components/verify-affiliate';
import { VerifyProfessionalModal } from 'lib/components/verify-professional';
import { useStore } from '../../store';

export type AuthorizationHeaderProps = {

};
export const AuthorizationHeader: React.FC<AuthorizationHeaderProps> = ( { } ) => {

    const store = useStore();
    const navigate = useNavigate();
    const agreement = store.Agreement;
    const showVerifyAffiliate = useBoolean( false );
    const showVerifyProfessional = useBoolean( false );

    async function goBack () {
        if ( await store.manualReset() ) {
            navigate( RoutesEnum.Authorize );
        }
    }

    return <>
        <Row noWrap>
            <Spacer>
                <Row noWrap>
                    <Col center padding={10}>
                        <AgreementLogo agreement={agreement?.Id} height="60px" width="100px" />
                    </Col>
                    <Spacer center padding={10} xsHidden>
                        <Div block>
                            <Label p bold>{agreement?.Description}</Label>
                            <Label p>{agreement?.Name}</Label>
                        </Div>
                    </Spacer>
                </Row>
            </Spacer>
            <Col>
                <Row noWrap>
                    <Col center gutter hidden>
                        <Button
                            disabled={store.Status.isSaving || store.Status.isLoading}
                            textColor="primary"
                            label="Verificar prestador"
                            onClick={() => showVerifyProfessional.enable()}
                        />
                    </Col>
                    <Col center gutter>
                        <Button
                            disabled={store.Status.isSaving || store.Status.isLoading}
                            textColor="primary"
                            label="Verificar afiliado"
                            onClick={() => showVerifyAffiliate.enable()}
                        />
                    </Col>
                    <Col center gutter>
                        <Button
                            disabled={store.Status.isSaving || store.Status.isLoading}
                            label="Seleccionar otro convenio"
                            onClick={goBack}
                        />
                    </Col>
                </Row>
            </Col>
        </Row>
        <VerifyAffiliateModal
            agreement={agreement?.Id}
            isOpen={showVerifyAffiliate.Actived}
            onClose={() => showVerifyAffiliate.disable()}
        />
        <VerifyProfessionalModal
            agreement={agreement?.Id}
            isOpen={showVerifyProfessional.Actived}
            onClose={() => showVerifyProfessional.disable()}
        />
    </>;
};