import React from 'react';
import { Alert, Button, Col, Content, Div, Label, Panel, Row, Spacer } from '@gower/react-ui/components';
import { DetailField } from '@gower/react-ui/template/detail-field';
import { ButtonGroup, ButtonGroupItem } from '@gower/react-ui/template/button-group';
import { useStore } from '../store';
import {
    Progress,
    Header,
    DetailsTab,
    HistoryTab,
    TechnicalDetailTab,
} from './components';
import { Authorization } from '@gower/cm-mdp-sdk/authorizations';
import { VscEllipsis } from '@gower/react-ui/icons';
import { useStatus } from '@gower/react-ui/hooks';
import { adminOnly } from 'app/credential-provider';

export const AuthorizationDetailsMobile = () => {

    const store = useStore();
    const status = store.Status;
    const data = store.Authorization;
    const [page, setPage] = React.useState( 0 );

    if ( status.isLoading )
        return null;

    return <>
        <Content
            start
            scroll
            paddingBottom={100}
        >
            <Header />
            {
                store.Authorization.isPendingIssuance() &&
                <Div fullWidth gutter>
                    <Alert
                        fullWidth
                        red
                        title="Información importante"
                        content="Esta autorizacion sera confirmada a la brevedad. Aguarde para emitir su bono."
                    />
                </Div>
            }
            {
                !store.Authorization.isPendingIssuance() && store.Authorization.isPendingReport() &&
                <Div fullWidth gutter>
                    <Alert
                        fullWidth
                        warning
                        title="Información importante"
                        content="Esta autorizacion tiene cambios sin confirmar. Algunas opciones pueden estar desactivadas momentaneamente."
                    />
                </Div>
            }
            <Col cols={12} white>
                <Progress />
                <Row padding={10}>
                    <Col cols={12}>
                        <DetailField title="Afiliado" noData={!Boolean( data.Affiliate?.Id )}>
                            <Label xxsmall bold label={data.Affiliate?.Id} />
                            <Label gutter xxsmall label={data.Affiliate?.Name} />
                        </DetailField>
                    </Col>
                    <Col cols={6}>
                        <DetailField titleWidth="50%" title="Ref. Convenio" value={data.AgreementId} />
                    </Col>
                    <Col cols={6}>
                        <DetailField titleWidth="50%" title="Nº aut." value={data.AuthorizationId} />
                    </Col>
                </Row>
                <Row noWrap padding={10} color="surface-1">
                    <Col gutter center>
                        <ButtonGroup
                            mandatory
                            active={page}
                            onChange={( idx ) => setPage( idx )}
                        >
                            <ButtonGroupItem label="Detalle" />
                            <ButtonGroupItem label="Detalle técnico" disabled={!adminOnly()} />
                            <ButtonGroupItem label="Historial" />
                        </ButtonGroup>
                    </Col>
                    <Spacer />
                    <Col gutter center>

                    </Col>
                    <Col gutter center>
                        <PrintMenu item={data} />
                    </Col>
                </Row>
                <Row show={page === 0}>
                    <DetailsTab />
                </Row>
                <Row show={page === 1}>
                    <TechnicalDetailTab />
                </Row>
                <Row show={page === 2}>
                    <HistoryTab />
                </Row>
            </Col>
        </Content>
    </>;
};




const PrintMenu: React.FC<{ item: Authorization; }> = ( { item } ) => {

    const menuEl = React.useRef( null );
    const status = useStatus( 'ok' );
    const store = useStore();

    function closeMenu () {
        menuEl.current?.hide();
        return true;
    }

    async function download ( type: 'budget' | 'form' | 'prescription' ) {

        let file = null;
        status.setLoading();
        if ( type === 'budget' )
            file = await item.getBudgetUrl();
        else if ( type === 'form' )
            file = await item.getFormUrl();
        else if ( type === 'prescription' )
            file = await item.getPrescriptionUrl();

        if ( file?.url ) {
            window.open( file.url );
        }

        status.setOk();
    }

    return <>
        <Button
            textBlue
            bold
            block
            xxsmall
            label="Más"
            onClick={( event ) => menuEl.current.toggle( event )}
            contentRight={<VscEllipsis size={16} />}
            loading={status.isLoading}
        />
        <Panel dismissable ref={menuEl}>
            <Row width={200} noWrap columns onClick={closeMenu} paddingY={10}>
                <Col cols={12} padding={10}>
                    <Label bold xsmall label="Opciones:" />
                </Col>
                <Button xxsmall noneTheme justify="flex-start" width={200} flat borderRadius={0} label="Anular" disabled={!item.canCancel() || store.isCanceling} onClick={() => store.cancel( item )} />
                <Button xxsmall noneTheme justify="flex-start" width={200} flat borderRadius={0} label="Eliminar" disabled={!item.canDelete() || store.isCanceling} onClick={() => store.remove( item )} />
                <Col cols={12} padding={10}>
                    <Label bold xsmall label="Reimprimir:" />
                </Col>
                <Button xxsmall noneTheme justify="flex-start" width={200} flat borderRadius={0} disabled={status.isLoading || !item.canDownloadBudget(adminOnly())} onClick={() => download( 'budget' )} label="Bono de autorización" />
                {item.Agreement.isSAMI && <Button xxsmall noneTheme justify="flex-start" width={200} flat borderRadius={0} disabled={status.isLoading || !item.canDownloadForm()} onClick={() => download( 'form' )} label="Planilla de sesiones" />}
                {item.Agreement.isSAMI && <Button xxsmall noneTheme justify="flex-start" width={200} flat borderRadius={0} disabled={status.isLoading || !item.canDownloadPrescription()} onClick={() => download( 'prescription' )} label="Recetario" />}
            </Row>
        </Panel>
    </>;
};
