import React from 'react';
import { Button, Checkbox, Col, Content, Div, Divider, Row, Spacer } from '@gower/react-ui/components';
import { useStore } from '../store';

import {
    AuthorizationHeader,
    InputAffiliate,
    InputAffiliateByDocument,
    InputProfessional,
    InputMessage,
    Services,
    AffliateModelSelector,
} from './components';
import { useNews } from 'features/hooks/useNews';
import { NewsContainer } from 'lib/components/news-container';
import { Loader } from '@gower/react-ui/template/loader';

export const AuthorizationFormsMobile = () => {
    const store = useStore();
    const status = store.Status;
    const news = useNews();

    return <>
        <Content
            justifyCenter
            scroll
            paddingBottom={300}
        >
            <Loader status={store.SetupStatus}>
                {store.SetupStatus.isOk && <>
                    <Col cols={12}>
                        <NewsContainer news={news.getAgreementNews( store.Agreement?.Id )} />
                        <Div
                            fullWidth
                            white
                            box-shadow-1
                            paddingY={10}
                            paddingX={5}
                        >
                            <Row>
                                <AuthorizationHeader />
                            </Row>
                            <Divider paddingY={10} />

                            <Row start>

                                <AffliateModelSelector />
                                <InputAffiliateByDocument />
                                <InputAffiliate />
                                <InputProfessional type="applicant" />
                                <InputProfessional type="effector" />
                                <InputProfessional type="billing" />
                                <Services />
                                <InputMessage />

                            </Row>
                        </Div>
                    </Col>
                    <Col
                        white
                        boxShadow="box-shadow-1"
                        fullWidth
                        position="fixed"
                        bottom={0}
                        left={0}
                        zIndex={1}
                    >
                        <Row columns>
                            <Col gutter show={store.allowForms}>
                                <Checkbox
                                    value={store.emitForms}
                                    onCheck={( v ) => store.emitForms = v}
                                    label="Emitir planilla" />
                            </Col>
                            <Col gutter show={store.showElectronicPrescription}>
                                <Button
                                    block
                                    pointer
                                    loading={store.downloadingPrescription}
                                    onClick={() => store.openElectronicPrescription()}
                                    disabled={!store.canGenerateElectronicPrescription || status.isSaving}
                                    flat
                                    color="blue-4"
                                    textWhite
                                    minHeight={55}
                                    normal
                                    label="Receta electrónica"
                                />
                            </Col>
                            <Col gutter show={store.showMedereElectronicPrescription}>
                                <Button
                                    block
                                    pointer
                                    loading={store.downloadingPrescription}
                                    onClick={() => store.openMedereElectronicPrescription()}
                                    disabled={!store.canGenerateElectronicPrescription || status.isSaving}
                                    flat
                                    color="blue-4"
                                    textWhite
                                    minHeight={55}
                                    normal
                                    label="Receta electrónica"
                                />
                            </Col>
                            <Col center gutter show={store.showPapperPrescription && store.showGeneratePrescription}>
                                <Button
                                    block
                                    pointer
                                    loading={store.downloadingPrescription}
                                    onClick={() => store.prescription( 'chronic' )}
                                    disabled={!store.canGeneratePrescription || status.isSaving}
                                    flat
                                    color="blue-4"
                                    textWhite
                                    minHeight={55}
                                    normal
                                    label="Descargar Recetario Crónico"
                                />
                            </Col>
                            <Col center gutter show={store.showPapperPrescription && store.showGenerateBirthControlPrescription}>
                                <Button
                                    block
                                    pointer
                                    loading={store.downloadingPrescription}
                                    onClick={() => store.prescription( 'birth_control' )}
                                    disabled={!store.canGenerateBirthControlPrescription || status.isSaving}
                                    flat
                                    color="blue-4"
                                    textWhite
                                    minHeight={55}
                                    normal
                                    label="Descargar Recetario Anticonceptivos"
                                />
                            </Col>

                            <Col gutter>
                                <Button
                                    block
                                    pointer
                                    onClick={() => store.authorize()}
                                    disabled={!store.canConfirm || store.downloadingPrescription}
                                    loading={status.isSaving && !store.downloadingPrescription}
                                    flat
                                    primary
                                    textWhite
                                    minHeight={55}
                                    normal
                                    uppercase
                                    label="Generar Autorización"
                                />
                            </Col>
                            <Col gutter hidden>
                                <Button
                                    normalTheme
                                    textSize="normal"
                                    onClick={() => store.manualReset()}
                                    disabled={status.isSaving || status.isLoading}
                                    block
                                    label="Restablecer"
                                />
                            </Col>
                        </Row>
                    </Col>
                </>}
            </Loader>
        </Content>

    </>;
};

