import React from 'react';
import { Outlet } from 'react-router-dom';
import { Col, Content, Spacer } from '@gower/react-ui/components';
import { NotificationsProvider } from '@gower/react-ui/notifications';
import { MessageBoxProvider } from '@gower/react-ui/message-box';
import { Header } from 'layout/header';
import { Footer } from 'layout/footer';

export const Layout: React.FC = () =>
{
    return <>
        <Content id="layout__root" viewPortWidth viewPortHeight color='surface-1' columns>
            <Col fullWidth>
                <Header />
            </Col>
            <Spacer noScroll vertical>
                <Outlet />
            </Spacer>
            {/* <Col fullWidth>
                <Footer />
            </Col> */}
            <NotificationsProvider />
            <MessageBoxProvider />
        </Content>
    </>;
};