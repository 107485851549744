/// <reference path="../node_modules/@gower/utils/@types/index.d.ts" />
/// <reference path="../node_modules/@gower/utils/index.d.ts" />
import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import '@gower/react-ui/import';
import '@gower/utils/extension';
import 'app/configure-sdk';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { Router } from './app/router';
import theme from './app/theme';

window['env'] = {
  ...process.env,
  ...window['env']
};

const root = ReactDOM.createRoot(
  document.getElementById( 'root' ) as HTMLElement
);

root.render(
  // <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router />
    </ThemeProvider>,
  // </React.StrictMode>
);

//if ( window.env.REACT_APP_WEB_VITALS === 'console' )
//  reportWebVitals(console.log);
