import React from 'react';
import { Col, Colors, TextInput, Label, Tooltip, Spacer } from '@gower/react-ui/components';
import { useStatus } from '@gower/react-ui/hooks';
import { MdOutlineHelpOutline } from '@gower/react-ui/icons';
import { useStore } from '../../store';
import type { ProfessionalType } from '@gower/cm-mdp-sdk/src/lib/AuthorizationRequests/services';
import type { Professional } from '@gower/cm-mdp-sdk/authorizations';
import { NextFocus, prevent } from '@gower/react-ui/utils';

const label = {
    'applicant': {
        label: 'Matrícula solicitante:',
        placeholder: 'Ingresá el prestador solicitante',
    },
    'effector': {
        label: 'Matrícula efector:',
        placeholder: 'Solo para socios medicos',
    },
    'billing': {
        label: 'Matrícula facturación:',
        placeholder: 'Solo para socios medicos',
    }
};

export type InputProfessionalProps = {
    type: ProfessionalType;
};
export const InputProfessional: React.FC<InputProfessionalProps> = ( { type } ) => {

    const store = useStore();
    const getProfessional = () => store.Request[`${ type.toCapitalCase() }Professional`] as Professional;
    const inputStatus = useStatus( 'ok' );
    const readOnly = !store.canAddProfessional;
    const loading = inputStatus.isLoading;
    const [value, setValue] = React.useState( '' );

    async function submit ( event ) {

        if ( readOnly || loading || !value )
            return;

        prevent( event );
        inputStatus.setLoading();
        if ( await store.addProfessional( type, value ) ) {
            if ( event )
                NextFocus( '* input, * textarea' );
        }
        setValue( getProfessional()?.License || '' );
        inputStatus.setOk();
    };

    async function clear () {
        inputStatus.setLoading();
        await store.removeProfessional( type );
        setValue( '' );
        inputStatus.setOk();
    };

    React.useEffect( () => {
        const listener = () => { clear(); };
        store.on( 'reset', listener );
        return () => { store.removeListener( 'reset', listener ); };
    }, [store] );


    return <>
        <Col xs={6} sm={5} cols={4} minWidth={300} paddingY={4} center>
            <TextInput
                large
                noDelayed
                prefix={label[type].label}
                placeholder={label[type].placeholder}
                selectAllOnFocus
                readOnly={readOnly}
                loading={loading}
                clearable={Boolean( getProfessional()?.License )}
                value={value}
                onChangeText={setValue}
                onClear={() => clear()}
                onEscapeUp={() => clear()}
                onEnterUp={( event ) => submit( event )}
                onBlur={() => submit( null )}
            />
        </Col>
        <Spacer paddingX={10} center>
            <Label>{getProfessional()?.Name}</Label>
        </Spacer>
        <Col cols={12} />
    </>;

};