import { Col, Content, Div, Spacer } from '@gower/react-ui/components';
import { AppLogo } from 'lib/components/logo';
import React from 'react';
import { Outlet } from 'react-router-dom';

export const LayoutEmpty: React.FC = () => {
    return <>
        <Content viewPortWidth viewPortHeight color='surface-2' columns>
            <Col>
                {/* <Header /> */}
            </Col>
            <Spacer noScroll vertical
                style={{
                    //background: 'linear-gradient(to right, rgb(178, 212, 191), rgb(8, 71, 40))'
                    //backgroundImage: `url("/login-background.svg")` ,
                    //backgroundRepeat: 'no-repeat',
                    //backgroundSize: "100%"

                }}>
                <Div position="fixed" bottom={-350} left={-400}>
                    <AppLogo color="surface-1" size={1000} />
                </Div>
                <Outlet />
            </Spacer>
        </Content>
    </>;
};