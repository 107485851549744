import React from 'react';
import { TextInput } from '@gower/react-ui/components';
import { useStore } from '../../store';
import { NextFocus, prevent } from '@gower/react-ui/utils';

const SERVICE_TOOLTIP = "Ingresá el código de auditoría del convenio seleccionado";

export type InputPreAuthorizationCodeProps = {

};
export const InputPreAuthorizationCode: React.FC<InputPreAuthorizationCodeProps> = ( { } ) => {

    const store = useStore();
    const readOnly = store.Status.isLoading || store.Status.isSaving || !store.canAddServices;
    const loading = false;
    const [value, setValue] = React.useState( '' );

    async function submit ( event ) {
        prevent( event );
        store.Request.setPreAuthorizationCode( value );
        if ( event ) {
            NextFocus( '* input, * textarea' );
        }
    };

    function clear () {
        store.Request.setPreAuthorizationCode( '' );
        setValue( '' );
    };

    React.useEffect( () => {
        const listener = () => { clear(); };
        store.on( 'reset', listener );
        return () => { store.removeListener( 'reset', listener ); };
    }, [] );

    return <>
        <TextInput
            prefix='Código Auditoría:'
            placeholder="# # # # # #"
            selectAllOnFocus
            numeric
            noDelayed
            large
            value={value}
            onChangeText={setValue}
            loading={loading}
            readOnly={readOnly}
            onEnterUp={submit}
            onEscapeUp={clear}
            onBlur={() => submit( null )}
            // contentRight={
            //     !loading &&
            //     <Col center paddingX={5} paddingTop={5}>
            //         <Tooltip title={SERVICE_TOOLTIP}>
            //             <MdHelpOutline size={20} color={Colors['primary']} />
            //         </Tooltip>
            //     </Col>
            // }
        />
    </>;
};