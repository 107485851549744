import { MutableStore, CrudMode, CrudModes, CrudModesEnum } from '@gower/react-ui/store';
import { DefaultFilters } from '@gower/react-ui/finder';
import { URI } from '@gower/react-ui/utils';
import type { Model, InstanceOfModel } from './store/common';
import { CreateStore } from './store/create';
import { ListStore } from './store/list';
import { ViewStore } from './store/view';


export type SidedCrudHash = {
    'el.id': string;
    'vm': CrudModes;
};

export type { CreateStore, ListStore, ViewStore };

export type SidedCrudStoreProps = {
    viewName?: any;
    silent?: boolean;
    defaultMode?: CrudModes;
};
export function SidedCrudBase<
    T extends ( new ( ...args ) => any ),
    R extends ( { new: () => any; } )
>
    ( model: T, filters?: R, props?: SidedCrudStoreProps ) {
    return class extends SidedCrudStore<T>
    {
        protected ___internal___beforeRender () {
            this['setup']( model as any, filters as any, props );
        }
    };
}

export abstract class SidedCrudStore<T extends ( new ( ...args ) => any ) = Model> extends MutableStore {
    public readonly Mode = new CrudMode();
    public readonly ListStore = new ListStore<InstanceType<T>>( this as any );
    public readonly ViewStore = new ViewStore<InstanceType<T>>( this as any );
    public readonly CreateStore = new CreateStore<InstanceType<T>>( this as any );

    public get Model () { return this._model; };
    protected _model: Model = null;

    public get Filters () { return this._filters; };
    private _filters: DefaultFilters = null;

    private setup ( model: Model, filters: typeof DefaultFilters, props: SidedCrudStoreProps ) {
        this._model = model as any;
        this._filters = filters ? filters.new() : DefaultFilters.new();;
        this.setViewName( props?.viewName );
        this.Mode.on( 'changed', ( { current } ) => URI.addHashParam( 'vm', current, this.viewName ) );
        this.Mode.setDefaultMode( props?.defaultMode || CrudModesEnum.list );
        this.ListStore.setup( props?.silent );
        this.CreateStore.setup();
        this.ViewStore.setup();
    }

    onBeforeRender () {

        this.setModeByHash();
    }

    private setModeByHash () {
        this.go( URI.getHashParam( 'vm', this.viewName ) );
    }

    protected onDestroy () {
        URI.removeAll( this.viewName );
    }

    goDefault () {
        this.go( this.Mode.Default );
    }
    go ( mode: CrudModes ) {
        this.clear();
        if ( mode === 'create' ) this.goCreate();
        if ( mode === 'view' ) this.goView( null );
        else this.goList();
    }

    clear () {
        if ( this.Mode.isView ) this.ViewStore.hide();
        if ( this.Mode.isCreate ) this.CreateStore.hide();
        if ( this.Mode.isList ) this.ListStore.hide();
    }

    goList () {
        this.clear();
        this.ListStore.show();
        this.Mode.setList();
    }

    async goCreate () {
        this.clear();
        this.CreateStore.show();
        this.Mode.setCreate();
    }

    async goView ( item: InstanceOfModel ) {
        this.clear();
        this.ViewStore.show( item );
        this.Mode.setView();
    }
}