import { Authorization, AuthorizationServiceCode } from '@gower/cm-mdp-sdk/authorizations';
import { ServiceCodesService } from '@gower/cm-mdp-sdk/src/lib/Authorizations/services';
import { expose, MutableStore, ViewStatus } from '@gower/react-ui/store';

export class VerifyServicesModalStore extends MutableStore {

    public Status = ViewStatus.new( 'pending' );

    public AffiliateId = '';
    public AffiliatePlan = '';
    public SearchText = '';
    public lastErrors: string[] = [];
    public Services: AuthorizationServiceCode[] = [];
    public Agreement: InstanceType<typeof Authorization.VO.Agreement>;
    private serviceCodesService: ServiceCodesService;

    async search () {

        if ( !this.SearchText ) return;

        this.Status.setLoading();
        if ( !this.Services )
            this.Services = [];
        else
            this.Services.clear();
        const result = await this.serviceCodesService.find( {
            q: this.SearchText,
            affiliateId: this.AffiliateId,
            affiliatePlan: this.AffiliatePlan,
        } );

        if ( Array.isArray( result?.Items ) ) {
            this.Services.push( ...result.Items );
            if ( this.Services.length )
                this.Status.setOk();
            else
                this.Status.setEmpty();
        }
        else {
            this.lastErrors = this.serviceCodesService.LastErrors || [];
            this.Status.setErrored();
        }

        this.SearchText = '';
    }

    onReceiveProps ( props ) {

        const agreementType = props?.agreement;
        if ( agreementType && agreementType !== this.Agreement?.Id ) {
            this.SearchText = '';
            this.lastErrors = [];
            this.Services = [];
            this.Agreement = Authorization.VO.Agreement.toType( agreementType );
            this.serviceCodesService = new ServiceCodesService( this.Agreement );
            this.Status.setPending();
        }

        if ( this.AffiliateId != props?.affiliateId ) {
            this.AffiliateId = props?.affiliateId;
        }
        if ( this.AffiliatePlan != props?.affiliatePlan ) {
            this.AffiliatePlan = props?.affiliatePlan;
        }


    }

}

export const { provider, useStore } = expose( VerifyServicesModalStore );