import { theme, MediaQuery } from '@gower/react-ui/components';
import { provider } from './store';
import { AuthorizationsDesktop } from './desktop';
import { AuthorizationsMobile } from './mobile';

export const AuthorizationsView = provider( () => {
    return <>
        <MediaQuery minWidth={theme.current.Breakpoints.XsMin}>
            <AuthorizationsDesktop />
        </MediaQuery>
        <MediaQuery
            maxWidth={theme.current.Breakpoints.MobileMax}

        >
             <AuthorizationsMobile /> 
        </MediaQuery>
    </>;
}, {  } );
