import React from 'react';
import { News } from '@gower/cm-mdp-sdk/news';
import { NewsProvider } from 'app/news-provider';

export function useNews () {

    const [generalNews, setGeneralNews] = React.useState<News[]>( NewsProvider.GeneralNews );
    const [unregisteredPrescriptionNews, setUnregisteredPrescriptionNews] = React.useState<News[]>( NewsProvider.UnregisteredPrescriptionNews );
    const [agreementNews, setAgreementNews] = React.useState<News[]>( NewsProvider.AgreementNews );

    async function refresh () {
        await NewsProvider.refresh();
        set();
    }

    function set () {
        setGeneralNews( [...NewsProvider.GeneralNews] );
        setUnregisteredPrescriptionNews( [...NewsProvider.UnregisteredPrescriptionNews] );
        setAgreementNews( [...NewsProvider.AgreementNews] );
    }

    React.useEffect( () => {
        set();
    }, [] );

    return {
        generalNews: generalNews,
        unregisteredPrescriptionNews: unregisteredPrescriptionNews,
        agreementNews: agreementNews,
        getAgreementNews: ( agreementId: string ) => NewsProvider.getAgreementNews( agreementId ),
        refresh: refresh
    };
}