import { Button, Col, Render, Row, Spacer, Text, Title } from '@gower/react-ui/components';
import { AiOutlineReload } from '@gower/react-ui/icons';
import { ContextTemplatesProps, TemplatesProps } from '../list.props';
import { getActions } from '../list.actions';

export type HeaderToolbarProps = TemplatesProps & ContextTemplatesProps & {

};
export const HeaderToolbar: React.FC<HeaderToolbarProps> = ( { ...props } ) =>
{
    const { store } = props;
    const { create, reload } = getActions( props );

    if ( props.hideToolbar ) return null;



    return <>
        {props.toolbarTemplate && <Render content={props.toolbarTemplate} props={{ ...props.injectProps, ...store.getProps() , ...getActions( props )}} />}
        {!props.toolbarTemplate && <>
            <Col cols={12} padding={5} color='surface-1'>
                <Row fullWidth justifyEnd>
                    <Col center>
                        <Render
                            content={props.subTitle}
                            ifString={( { text } ) => <Title xxsmall>{text}</Title>}
                            props={{ ...props.injectProps, ...store.getProps() , ...getActions( props )}}
                        />
                    </Col>
                    <Spacer >
                        <Row fullWidth disabled={store.Status.isLoading}>
                            <Spacer />
                            <Col center>
                                <Render content={props.toolbarContent} props={{ ...props.injectProps, ...store.getProps() , ...getActions( props )}} />
                            </Col>
                            <Col center gutter hidden={props.hideCreateButton}>
                                <Button
                                    minWidth={120}
                                    primary
                                    label={props.createButtonLabel || "Crear nuevo"}
                                    onClick={create}
                                />
                            </Col>
                            <Col center gutter hidden={props.hideReloadButton}>
                                <Button
                                    disabled={!store.isLoadingMore && store.Status.isLoading}
                                    loading={store.isLoadingMore}
                                    flat
                                    paddingX={10}
                                    fontSize={20}
                                    textColor="surface-8"
                                    hoverTextColor='black'
                                    icon={AiOutlineReload}
                                    onClick={reload}
                                />
                            </Col>
                        </Row>
                    </Spacer>
                </Row>
            </Col>
        </>}
    </>;
};