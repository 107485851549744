import { Col, Row, TextInput, TextAreaInput, Alert } from '@gower/react-ui/components';
import { BsTelephone, BiIdCard, MdMail } from '@gower/react-ui/icons';
import { provider, useStore } from './store';

export const QuickResponseForm = provider( () => {

    const store = useStore();

    return <>
        <Row columns>
           
        </Row>
    </>;

} );