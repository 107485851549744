
import React from 'react';
import { ViewProps } from './view';
import { CreateProps } from './create';
import { ListProps } from './list';

type OmitProps = 'injectProps' | 'model' | 'filters' | 'crudStore';
export type CrudListProps = Omit<ListProps, OmitProps> & {

};
export const CrudList: React.FC<CrudListProps> = () => <></>;

export type CreateFormProps = Omit<CreateProps, OmitProps> & {

};
export const CrudCreate: React.FC<CreateFormProps> = () => <></>;

export type ViewFormProps = Omit<ViewProps, OmitProps> & {

};
export const CrudView: React.FC<ViewFormProps> = () => <></>;