import React from 'react';
import { Alert, Button, Col, Content, Div, Label, Panel, Row, Spacer } from '@gower/react-ui/components';
import { DetailField } from '@gower/react-ui/template/detail-field';
import { ButtonGroup, ButtonGroupItem } from '@gower/react-ui/template/button-group';
import { useStore } from '../store';
import {
    Progress,
    Header,
    DetailsTab,
    HistoryTab,
    TechnicalDetailTab,
} from './components';
import { Authorization } from '@gower/cm-mdp-sdk/authorizations';
import { TbChevronDown } from '@gower/react-ui/icons';
import { useStatus } from '@gower/react-ui/hooks';
import { adminOnly } from 'app/credential-provider';
import { Loader } from '@gower/react-ui/template/loader';

export const AuthorizationDetailsDesktop = () => {

    const store = useStore();
    const status = store.Status;
    const data = store.Authorization;
    const [page, setPage] = React.useState( 0 );

    return <Loader status={store.Status}>
        {status.isOk && <>
            <Content
                start
                justifyCenter
                scroll
            >
                <Col
                    cols={12}
                    start
                    maxWidth={1200}
                    paddingTop={20}
                    paddingX={20}
                    paddingBottom={100}
                >
                    <Header />
                    {
                        store.Authorization.isPendingIssuance() &&
                        <Div fullWidth gutter>
                            <Alert
                                fullWidth
                                red
                                title="Información importante"
                                content="Esta autorizacion sera confirmada a la brevedad. Aguarde para emitir su bono."
                            />
                        </Div>
                    }
                    {
                        !store.Authorization.isPendingIssuance() && store.Authorization.isPendingReport() &&
                        <Div fullWidth gutter>
                            <Alert
                                fullWidth
                                warning
                                title="Información importante"
                                content="Esta autorizacion tiene cambios sin confirmar. Algunas opciones pueden estar desactivadas momentaneamente."
                            />
                        </Div>
                    }
                    <Div
                        fullWidth
                        white
                        box-shadow-1
                    >
                        <Progress />
                        <Row padding={10}>
                            <Col cols={4}>
                                <DetailField titleWidth="auto" title="Afiliado:" noData={!Boolean( data.Affiliate?.Id )}>
                                    <Label xxsmall bold label={data.Affiliate?.Id} />
                                    <Label gutter xxsmall label={data.Affiliate?.Name} />
                                </DetailField>
                            </Col>
                            <Col cols={4}>
                                <DetailField titleWidth="auto" title="Ref. Convenio:" value={data.AgreementId} />
                            </Col>
                            <Col cols={4}>
                                <DetailField titleWidth="auto" title="Nº Autorización:" value={data.AuthorizationId} />
                            </Col>
                        </Row>
                        <Row noWrap padding={10} color="surface-1">
                            <Col gutter center>
                                <ButtonGroup
                                    mandatory
                                    active={page}
                                    onChange={( idx ) => setPage( idx )}
                                >
                                    <ButtonGroupItem label="Detalle" />
                                    <ButtonGroupItem label="Detalle técnico" disabled={!adminOnly()} />
                                    <ButtonGroupItem label="Historial" />
                                </ButtonGroup>
                            </Col>
                            <Spacer />
                            <Col gutter center>
                                {data.canCancel() && <Button block textRed bold xxsmall label="Anular" loading={store.isCanceling} onClick={() => store.cancel( data )} />}
                                {data.canDelete() && <Button block textRed bold xxsmall label="Eliminar" onClick={() => store.remove( data )} />}
                            </Col>
                            <Col gutter center>
                                <PrintMenu item={data} isAdmin={adminOnly()}/>
                            </Col>
                        </Row>
                        <Row show={page === 0}>
                            <DetailsTab />
                        </Row>
                        <Row show={page === 1}>
                            <TechnicalDetailTab />
                        </Row>
                        <Row show={page === 2}>
                            <HistoryTab />
                        </Row>
                    </Div>
                </Col>
            </Content>
        </>}
    </Loader>;
};




const PrintMenu: React.FC<{ item: Authorization; isAdmin: boolean }> = ( { item ,isAdmin} ) => {

    const menuEl = React.useRef( null );
    const status = useStatus( 'ok' );

    function closeMenu () {
        menuEl.current?.hide();
        return true;
    }

    async function download ( type: 'budget' | 'form' | 'prescription' ) {

        let data = null;

        status.setLoading();
        if ( type === 'budget' )
            data = await item.getBudgetUrl();
        else if ( type === 'form' )
            data = await item.getFormUrl();
        else if ( type === 'prescription' )
            data = await item.getPrescriptionUrl();

        if ( data?.url ) {
            window.open( data.url );
        }

        status.setOk();
    }

    return <>
        <Button
            textBlue
            bold
            block
            xxsmall
            label="Reimprimir"
            onClick={( event ) => menuEl.current.toggle( event )}
            contentRight={<TbChevronDown size={16} />}
            loading={status.isLoading}
        />
        <Panel dismissable ref={menuEl}>
            <Row width={200} noWrap columns onClick={closeMenu}>
                <Col cols={12} padding={10}>
                    <Label bold xsmall label="Reimprimir:" />
                </Col>
                <Button xxsmall noneTheme justify="flex-start" width={200} flat borderRadius={0} disabled={status.isLoading || !item.canDownloadBudget(adminOnly())} onClick={() => download( 'budget' )} label="Bono de autorización" />
                {item.Agreement.isSAMI && <Button xxsmall noneTheme justify="flex-start" width={200} flat borderRadius={0} disabled={status.isLoading || !item.canDownloadForm()} onClick={() => download( 'form' )} label="Planilla de sesiones" />}
                {item.Agreement.isSAMI && <Button xxsmall noneTheme justify="flex-start" width={200} flat borderRadius={0} disabled={status.isLoading || !item.canDownloadPrescription()} onClick={() => download( 'prescription' )} label="Recetario" />}
            </Row>
        </Panel>
    </>;
};