import { Color } from '@gower/react-ui';
import React from "react";


export type AppLogoProps = {
  color?: Color;
  dark?: boolean;
  size?: number;
  v1?: boolean;
  v2?: boolean;
};

export const AppLogo: React.FunctionComponent<AppLogoProps> = ( {
  dark, v1, v2,
  ...props
} ) =>
{

  if ( typeof v1 === 'undefined' && typeof v2 === 'undefined' )
    v2 = true;

  let width = 30;
  let height = 30;
  if ( typeof props.size !== 'undefined' )
  {
    width = props.size;
    height = props.size;
  }

  return (
    <>
      <Iso height={height} width={width} background={dark ? `var(--white)` : `var(--blue-9)`} color={props.color} />
    </>
  );


};

function Iso ( { ...props } )
{
  return (
    <svg
      height={props.height}
      width={props.width}
      viewBox="0 0 490.09 490.087"
      dangerouslySetInnerHTML={{
        __html:
          `
          <polygon style="fill: var(--${props.color});" points="166.771,245.059 112.073,190.347 2.79,190.347 2.79,299.74 112.087,299.74" />
          <polygon style="fill: var(--${props.color});" points="485.484,190.347 376.227,190.347 453.479,113.065 376.116,35.7 298.864,112.981 298.864,3.697 189.438,3.697 189.438,112.981 112.17,35.7 34.807,113.065 112.087,190.347 189.424,190.347 189.424,112.981 244.122,167.666 298.835,112.981 298.835,190.347 376.199,190.347 321.515,245.059 376.199,299.74 485.484,299.74"/>
          <polygon style="fill: var(--${props.color});" points="298.835,377.107 244.122,322.422 189.453,377.107 189.424,299.74 112.101,299.74 34.821,377.024 112.185,454.388 189.438,377.135 189.438,486.39 298.864,486.39 298.864,377.107 376.116,454.388 453.479,377.024 376.199,299.74 298.835,299.74"/>
      `}}>

    </svg>
  );
}


export default AppLogo;
