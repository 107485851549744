import { Authorization, Professional } from '@gower/cm-mdp-sdk/authorizations';
import { ProfessionalsService } from '@gower/cm-mdp-sdk/src/lib/Authorizations/services';
import { MessageBox } from '@gower/react-ui/message-box';
import { Notifications } from '@gower/react-ui/notifications';
import { expose, MutableStore, ViewStatus } from '@gower/react-ui/store';
import { User } from '@gower/shared-sdk/core/users';
import { Validators } from '@gower/utils';
import { ErrorManager } from '@gower/react-ui/utils';
import { Specialty, SpecialtyDTO } from '@gower/cm-mdp-sdk/specialties';

class UserCreateStore extends MutableStore {

    public readonly Status = ViewStatus.new( 'ok' );
    public readonly ErrorManager = ErrorManager.new( {
        autoClearTimeOut: 4000,
        fields: ['Nickname', 'Name', 'Email', 'Password', 'Group']
    } );

    public EditItem = new User();
    public Licenses: Professional[] = [];
    public Specialty: SpecialtyDTO = null;
    public Password1 = '';
    public Password2 = '';

    public checkErrors () {

        this.ErrorManager.clear();
        if ( !this.EditItem.Group || this.EditItem.Group.isUnspecified ) this.ErrorManager.setGroup( 'Completá este campo' );
        if ( !this.EditItem.Nickname || this.EditItem.Nickname.length < 4 ) this.ErrorManager.setNickname( 'Completá este campo' );
        if ( !this.EditItem.Name || this.EditItem.Name.length < 2 ) this.ErrorManager.setName( 'Completá este campo' );
        if ( !Validators.Email( this.EditItem.Email ) ) this.ErrorManager.setEmail( 'Completá este campo con un email válido' );
        if ( !this.Password1 || this.Password1.length < 6 ) this.ErrorManager.setPassword( 'Completá este campo' );
        else if ( this.Password1 != this.Password2 ) this.ErrorManager.setPassword( 'La contraseña no coincide' );

    }


    public async removeLicense ( license: string ) {
        this.Licenses.remove( item => Number( item.License ) === Number( license ) );
    }

    public async addLicense ( license: string ) {

        if ( !license || license.length < 4 || Number.isNaN( Number( license ) ) )
            return;

        if ( this.Licenses.every( item => Number( item.License ) !== Number( license ) ) ) {


            const professional = await new ProfessionalsService( Authorization.VO.Agreement.SAMI ).find( {
                license
            } );

            if ( professional ) {
                this.Licenses.push( professional );
            } else {
                Notifications.Info( 'Matrícula no existe en la base de datos' );
            }
        }
    }

    public reset () {
        this.ErrorManager.clear();
        this.EditItem = new User();
        this.Licenses.clear();
        this.Password1 = '';
        this.Password2 = '';
        this.Status.setOk();
    }

    public async save () {

        this.checkErrors();
        if ( !this.ErrorManager.hasErrors() ) {
            this.Status.setSaving();
            this.EditItem.Metadata.licenses = this.Licenses.map( item => item.License );
            this.EditItem.Metadata.specialty_id = this.Specialty?.Id || null;
            this.EditItem.Password = this.Password1;

            const result = await this.EditItem.save();
            if ( result?.result ) {

                Notifications.Saved();

                const yesno = await MessageBox.YesNo( {
                    content: '¿Ingresar otro usuario?',
                    title: '¿Continuar?'
                } );

                if ( yesno ) {
                    this.reset();
                } else {
                    window.location.href = '/';
                }

            } else {
                await MessageBox.Error( {
                    content: result.errorList
                } );
            }

            this.Status.setOk();

        }
    }
}

export const { useStore, provider } = expose( UserCreateStore );