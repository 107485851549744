import React from 'react';
import { Input, Button, Col, Div, Label, PanelModal, Row, Spacer, VerticalCenter } from '@gower/react-ui/components';
import ReactDataSheet from '@gower/react-ui/datasheet';
import type { AuthorizationStore } from '../../store';
import type { AuthorizationRequestItem } from '@gower/cm-mdp-sdk/src/lib/AuthorizationRequests/services';

export type CellProps = {
    item: AuthorizationRequestItem,
    context: AuthorizationStore,
};

export const Viewer: React.FC<CellProps & ReactDataSheet.ValueViewerProps<any, CellProps>> = ( { item, row, ...props } ) => {
    return (
        <VerticalCenter height="100%" minHeight={30}>
            <Label textCenter bold show={!!item.isValid}>{row}.</Label>
        </VerticalCenter>
    );

};

export const Header: React.FC<CellProps> = ( { context, ...props } ) => {
    return null;
};