import { Render } from '@gower/react-ui/components';
import { PropsWithChildren } from 'react';
import { ContextTemplatesProps, TemplatesProps } from '../view.props';
import { getActions } from '../view.actions';
import { Loader } from '@gower/react-ui/template/loader';

export type ContentLoaderProps = TemplatesProps & ContextTemplatesProps & {

};
export const ContentLoader: React.FC<PropsWithChildren<ContentLoaderProps>> = ( { children, ...props } ) =>
{
    const { store } = props;

    return <>
        {props.loader && <Render content={props.loader} props={{ children, ...props.injectProps, ...store.getProps(), ...getActions( props ) }} />}
        {!props.loader && <>
            <Loader status={store.Status}>
                {children}
            </Loader>
        </>}
    </>;

};