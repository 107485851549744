import React, { SVGAttributes } from "react";

export const LogoAMTAR: React.FC<Omit<SVGAttributes<any>, 'dangerouslySetInnerHTML'>> = ( { ...props } ) => {
  return (
    <svg
      {...props}
      viewBox="0 0 294.11 304.315"
      dangerouslySetInnerHTML={{
        __html: `
        <defs>
        <style>
          .amtar-b {
            fill: #fefefe;
          }
    
          .amtar-c {
            fill: #cca352;
          }
    
          .amtar-d {
            fill: #cba351;
          }
        </style>
      </defs>
      <path class="amtar-d" d="M294.016,148.132c.024,42.75-12.797,76.751-37.725,105.942-20.708,24.25-47.102,39.779-78.214,46.961-14.877,3.435-29.963,3.984-45.195,2.519-12.81-1.232-25.234-3.96-37.137-8.697-27.251-10.843-49.596-28.023-66.718-52.008-11.814-16.549-20.175-34.661-24.812-54.343-5.295-22.474-5.591-45.166-.943-67.874,5.333-26.056,16.52-49.332,33.808-69.45C55.163,30.139,77.482,15.138,104.036,6.646,118.281,2.09,132.89-.309,147.814,.032c47.837,1.091,86.037,21.211,115.287,58.862,11.631,14.971,19.673,31.772,24.926,49.818,4.117,14.142,6.607,28.671,5.989,39.42Z"/>
      <path class="amtar-b" d="M36.304,226.961c-8.389-13.248-14.045-27.023-17.58-41.642-3.877-16.036-4.53-32.343-2.984-48.71,1.466-15.515,5.641-30.377,12.347-44.431,14.432-30.243,36.715-52.704,67.38-66.462,20.778-9.323,42.632-13.248,65.293-10.255,49.284,6.508,83.943,33.208,105.548,77.557,10.836,22.242,14.009,45.876,12.528,70.256-1.235,20.335-7.038,39.414-17.135,57.148-.431,.757-.862,1.514-1.321,2.254-1.205,1.943-1.447,5.441-3.872,5.301-1.329-.077-2.556-3.559-3.66-5.597-11.804-21.789-23.587-43.59-35.351-65.4-8.514-15.784-16.994-31.586-25.477-47.386-.649-1.21-1.164-2.88-2.613-2.896-1.396-.015-2.078,1.59-2.752,2.754-12.777,22.086-25.532,44.185-38.326,66.26-.595,1.027-.858,2.755-2.193,2.76-1.378,.005-1.544-1.771-2.136-2.794-13.079-22.63-26.141-45.268-39.12-67.955-1.338-2.338-2.12-2.331-3.352-.061-14.553,26.818-29.124,53.625-43.696,80.432-6.656,12.246-13.319,24.488-19.994,36.722-.347,.636-.846,1.19-1.535,2.143Z"/>
      <path class="amtar-b" d="M188.567,168.407c5.464,9.371,10.785,18.442,16.052,27.544,6.592,11.393,13.131,22.817,19.713,34.216,1.133,1.962,2.681,2.945,5.17,2.884,6.989-.173,13.986-.068,20.98-.039,.83,.003,1.946-.394,2.417,.468,.551,1.007-.327,1.865-.928,2.623-16.079,20.298-35.489,36.029-60.051,45.217-14.902,5.574-30.221,8.654-45.956,8.323-29.228-.616-55.616-9.821-78.689-28.144-9.245-7.341-17.374-15.676-24.436-25.09-.625-.833-1.882-1.678-1.207-2.877,.543-.965,1.821-.51,2.771-.516,6.244-.038,12.491-.119,18.732,.021,2.263,.051,3.68-.635,4.837-2.609,11.505-19.627,23.13-39.185,34.574-58.847,1.537-2.641,2.049-2.774,3.753-.142,11.335,17.503,22.163,35.319,33.118,53.057,1.7,2.753,3.619,5.378,5.201,8.195,1.243,2.213,1.875,1.618,2.96-.102,10.999-17.433,22.038-34.84,33.1-52.233,2.511-3.948,5.153-7.813,7.889-11.95Z"/>
      <path class="amtar-c" d="M189.425,58.13c13.861,.344,23.014,9.987,23.17,23.142,.147,12.431-10.765,22.879-23.062,22.769-13.251-.119-23.175-10.338-23.06-23.747,.107-12.507,10.189-22.243,22.953-22.163Z"/>
      <path class="amtar-c" d="M103.893,104.093c-12.37,.222-22.664-9.9-23.183-22.142-.634-14.952,11.449-23.927,22.629-24.043,12.508-.13,23.02,9.86,23.284,22.06,.287,13.274-9.716,23.892-22.73,24.125Z"/>
      `}}>
    </svg>
  );
};